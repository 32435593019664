import {
  updateAccountPIICustom,
  updateAccountEmailCustom,
} from "graphql/customQueries";

import { GraphqlOperationExecutor } from "utility/commonMethods";
import { StatusResult } from "./types";
import {
  UpdateAccountEmailCustomMutation,
  UpdateAccountPIICustomMutation,
} from "_generated_/graphql";

const piiInformationHandler = (
  id: string,
  flag: boolean
): Promise<UpdateAccountPIICustomMutation["updateAccount"] | StatusResult> => {
  return GraphqlOperationExecutor({
    query: updateAccountPIICustom,
    variables: {
      input: {
        id,
        piiInformation: flag,
      },
    },
  });
};

const updateSchoolMail = (
  input: UpdateAccountEmailCustomMutation
): Promise<
  UpdateAccountEmailCustomMutation["updateAccount"] | StatusResult
> => {
  return GraphqlOperationExecutor({
    query: updateAccountEmailCustom,
    variables: {
      input: input,
    },
  });
};

const SchoolDetailsSDK = {
  piiInformationHandler,
  updateSchoolMail,
};

export default SchoolDetailsSDK;
