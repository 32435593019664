import React from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import FullPageLayout from "containers/FullPageLayout";
import { RouteProps } from "react-router-dom";

/**
 * All Public Routes Higher Order Components
 * @function PublicRoutes
 * @param {Object} props
 */
const PublicRoutes = (
  props: RouteProps & {
    token?: string;
    groups: string[];
    render: (props: object) => unknown;
  }
) => {
  const { token, groups, render, ...rest } = props;
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <>
          {token && groups.length > 0 && <Redirect to="/" />}
          {token && groups.length === 0 && <Redirect to="/setup" />}
          {!token && <FullPageLayout>{render(matchProps)}</FullPageLayout>}
        </>
      )}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.loginReducer.token,
    groups: state.loginReducer.groups,
    errors: state.errors,
  };
};
export default connect(mapStateToProps)(PublicRoutes);
