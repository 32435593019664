import {
  createUserNotificationPreference,
  updateUserNotificationPreference,
  updateUserNotification,
} from "graphql/mutations";
import {
  listNotificationTypes,
  listUserNotifications,
  NotificationTypeByUser,
} from "graphql/queries";
// import { updateNotificationTypes } from "graphql/mutations";
import { GraphqlOperationExecutor } from "utility/commonMethods";
import {
  CreateUserNotificationPreferenceInput,
  CreateUserNotificationPreferenceMutation,
  ListUserNotificationsQuery,
  ModelUserNotificationFilterInput,
  NotificationTypeByUserQuery,
  NotificationTypeByUserQueryVariables,
  UpdateUserNotificationInput,
  UpdateUserNotificationMutation,
  UpdateUserNotificationPreferenceInput,
  UpdateUserNotificationPreferenceMutation,
} from "../_generated_/graphql";

const listNotificationsType = async () => {
  const queryData = {
    query: listNotificationTypes,
  };

  return await GraphqlOperationExecutor(queryData);
};

const createUserNotificationPreferenceForUser = async (
  input: CreateUserNotificationPreferenceInput,
): Promise<
  CreateUserNotificationPreferenceMutation["createUserNotificationPreference"]
> => {
  const queryData = {
    query: createUserNotificationPreference,
    body: {
      input,
    },
  };

  return await GraphqlOperationExecutor(queryData);
};

const updateUserNotificationPreferenceForUser = async (
  input: UpdateUserNotificationPreferenceInput,
): Promise<
  UpdateUserNotificationPreferenceMutation["updateUserNotificationPreference"]
> => {
  const queryData = {
    query: updateUserNotificationPreference,
    body: {
      input,
    },
  };

  return await GraphqlOperationExecutor(queryData);
};

const getPreferedNotifications = async (
  input: NotificationTypeByUserQueryVariables,
): Promise<NotificationTypeByUserQuery["NotificationTypeByUser"]> => {
  const queryData = {
    query: NotificationTypeByUser,
    input,
  };

  return await GraphqlOperationExecutor(queryData);
};

const getAllUserNotifications = async (
  filter: ModelUserNotificationFilterInput,
): Promise<ListUserNotificationsQuery["listUserNotifications"]> => {
  const queryData = {
    query: listUserNotifications,
    body: {
      filter,
    },
  };

  return await GraphqlOperationExecutor(queryData);
};

const markNotificationAsRead = async (
  input: UpdateUserNotificationInput,
): Promise<UpdateUserNotificationMutation["updateUserNotification"]> => {
  const queryData = {
    query: updateUserNotification,
    body: { input },
  };

  return await GraphqlOperationExecutor(queryData);
};

const NotificationSDK = {
  listNotificationsType,
  getPreferedNotifications,
  createUserNotificationPreferenceForUser,
  updateUserNotificationPreferenceForUser,
  getAllUserNotifications,
  markNotificationAsRead,
};

export default NotificationSDK;
