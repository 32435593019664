import { MoreOutlined, UpCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Dropdown,
  Image,
  Menu,
  Row,
  Table,
  Typography,
} from "antd";
import { Images } from "assets/images/Images";
import SkeletonTable from "Common/Skeleton/SkeletonTable";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import ManageDatapackageSDK from "sdk/ManageDatapackageSDK";
import { buttonText, advancedListText } from "utility/constants";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import { API, graphqlOperation } from "aws-amplify";
import {
  onDeleteDataPackageDetail,
  onUpdateDataPackageDetail,
} from "graphql/subscriptions";
import CustomModal from "Common/Components/CustomAntD/Modal";

const DataPackages = () => {
  const { t } = useTranslation();

  const { Title, Text } = Typography;
  const { Icons, AdvancedList } = Images;
  const { FileIcon } = Icons;
  const { NoAdvancedListSelectSvg } = AdvancedList;
  const { ModalImg } = Images;
  const { VeracrossDeleteImg } = ModalImg;

  const { accountId: accountID } = useSelector((state) => state.loginReducer);

  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteConfirmLoading, setIsDeleteConfirmLoading] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [dataPackageDataSource, setDataPackageDataSource] = useState([]);

  const { Connect_Btn, Starting_Btn, Deleting_Text } = buttonText;

  const { In_Progress_Text, Failed_Text } = advancedListText;

  const handleDeletePackage = useCallback(
    async (datapackage) => {
      setIsDeleteConfirmLoading(true);
      await ManageDatapackageSDK.deleteDataPackage({
        accountId: accountID,
        datapackage,
      })
        .then(() => setDeleteConfirm(true))
        .finally(() => setIsDeleteConfirmLoading(false));
    },
    [accountID]
  );

  const dataPackageColumn = useMemo(
    () => [
      {
        title: "Data Package Name",
        dataIndex: "package_name",
        key: "dataset",
        width: "35%",
      },
      {
        title: "Last Sync Date",
        dataIndex: "last_sync_date",
        key: "lastRanDate",
        width: "35%",
        render: (item) => (item ? <Moment format="lll" date={item} /> : null),
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        width: "20%",
        render: (_, item, index) => {
          return _ === "CONNECTED" ? (
            <div className="d-flex align-center">
              <UpCircleOutlined className="circle-icon green-circle" />
              <Text>{t(Connect_Btn)}</Text>
            </div>
          ) : _ === "DISCONNECTED" ? (
            <div className="d-flex align-center">
              <UpCircleOutlined className="circle-icon grey-circle" />
              <Text>{t(Starting_Btn)}</Text>
            </div>
          ) : _ === "INPROGRESS" ? (
            <div className="d-flex align-center">
              <UpCircleOutlined className="circle-icon grey-circle" />
              <Text>{t(In_Progress_Text)}</Text>
            </div>
          ) : _ === "DELETING" ? (
            <div className="d-flex align-center">
              <UpCircleOutlined className="circle-icon red-circle" />
              <Text>{t(Deleting_Text)}</Text>
            </div>
          ) : (
            <div className="d-flex align-center">
              <UpCircleOutlined className="circle-icon red-circle" />
              <Text>{t(Failed_Text)}</Text>
            </div>
          );
        },
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        width: "10%",
        render: (_, { package_name }) => (
          <div className="d-flex justify-content-center cursor-pointer">
            <Dropdown
              menu={{
                items: [
                  {
                    key: "delete",
                    label: (
                      <Button
                        onClick={() => handleDeletePackage(package_name)}
                        disabled={isDeleteConfirmLoading}
                      >
                        Delete
                      </Button>
                    ),
                  },
                ],
              }}
            >
              <MoreOutlined className="more-outline-style primary-color" />
            </Dropdown>
          </div>
        ),
      },
    ],

    [
      Connect_Btn,
      Deleting_Text,
      Failed_Text,
      In_Progress_Text,
      Starting_Btn,
      handleDeletePackage,
      isDeleteConfirmLoading,
      t,
    ]
  );

  const fetchdata = useCallback(async () => {
    const body = {
      accountId: accountID,
      package_name: "VERACROSS_DATA_PACKAGES",
    };
    setIsLoading(true);
    const data = await ManageDatapackageSDK.getDatapackage(body).finally(() =>
      setIsLoading(false)
    );

    setDataPackageDataSource(Array.isArray(data) ? data : data?.items ?? []);
  }, [accountID]);

  useEffect(() => {
    let updateSubscriber = API.graphql(
      graphqlOperation(onUpdateDataPackageDetail, { id: accountID })
    ).subscribe({
      next: (_) => {
        fetchdata();
      },
      error: (e) => {
        console.log(e);
      },
    });

    let deleteSubscriber = API.graphql(
      graphqlOperation(onDeleteDataPackageDetail, { id: accountID })
    ).subscribe({
      next: (_) => {
        fetchdata();
      },
      error: (e) => {
        console.log(e);
      },
    });

    fetchdata();
    return () => {
      updateSubscriber.unsubscribe();
      deleteSubscriber.unsubscribe();
    };
  }, [accountID, fetchdata]);

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Row className="d-flex gap-3" align="middle" justify="space-between">
            <div className="d-flex align-items-center gap-3">
              <Image preview={false} src={FileIcon} alt="..." />
              <Title level={4} className="data-source-title m-0">
                View the data of the data packages as below
              </Title>
            </div>
          </Row>
        </Col>

        <Col span={24}>
          <Table
            columns={dataPackageColumn}
            dataSource={dataPackageDataSource}
            className="managedatasets-table-container advanced-table-container"
            pagination={false}
            locale={{
              emptyText: isLoading ? (
                <SkeletonTable />
              ) : (
                <Row
                  justify="center"
                  align="middle"
                  className="flex-col"
                  gutter={[12, 12]}
                >
                  <Image
                    preview={false}
                    src={NoAdvancedListSelectSvg}
                    alt="..."
                  />
                  <Title level={3}>No Files</Title>
                  <Text>
                    You will be able to see data packages after configuration of
                    data package from Veracross portal.
                  </Text>
                </Row>
              ),
            }}
            // rowClassName={(record) => dynamicRowClassName(record)}
            scroll={{
              x: 1000,
            }}
            showHeader={dataPackageDataSource?.length > 0 ? true : false}
          />
        </Col>
      </Row>
      <CustomModal
        open={deleteConfirm}
        closable={true}
        centered
        width="40%"
        onCancel={() => setDeleteConfirm(false)}
        footer={null}
      >
        <div className="flex-col align-center gap-3">
          <Image
            preview={false}
            src={VeracrossDeleteImg}
            height={200}
            width={200}
          />
          <Typography.Title level={4} className="m-0">
            Deletion Process Initiated
          </Typography.Title>
          <Typography.Text className="text-center">
            The deletion process has been initiated. Please log in to your
            Veracross account and remove the corresponding data package
            configuration from your account to complete the process.
          </Typography.Text>
          <Button
            type="primary"
            onClick={() =>
              window.open(
                "https://accounts.veracross.com/partners/portals/login"
              )
            }
          >
            Login to Veracross
          </Button>
        </div>
      </CustomModal>
    </>
  );
};

export default DataPackages;
