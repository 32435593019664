/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getDataSetup = /* GraphQL */ `
  query GetDataSetup($id: ID!) {
    getDataSetup(id: $id) {
      id
      school_id
      dimension_or_metrics
      field_key
      friendly_name
      field_description
      field_type_GDS
      field_type_PBI
      data_source
      data_set
      api_endpoint
      name_by_school
      worksheet_name
      googlesheet_name
      worksheet_id
      googlesheet_id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDataSetups = /* GraphQL */ `
  query ListDataSetups(
    $filter: ModeldataSetupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDataSetups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        school_id
        dimension_or_metrics
        field_key
        friendly_name
        field_description
        field_type_GDS
        field_type_PBI
        data_source
        data_set
        api_endpoint
        name_by_school
        worksheet_name
        googlesheet_name
        worksheet_id
        googlesheet_id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const DataSetupByWorksheetId = /* GraphQL */ `
  query DataSetupByWorksheetId(
    $worksheet_id: String
    $sortDirection: ModelSortDirection
    $filter: ModeldataSetupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    DataSetupByWorksheetId(
      worksheet_id: $worksheet_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        school_id
        dimension_or_metrics
        field_key
        friendly_name
        field_description
        field_type_GDS
        field_type_PBI
        data_source
        data_set
        api_endpoint
        name_by_school
        worksheet_name
        googlesheet_name
        worksheet_id
        googlesheet_id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAccount = /* GraphQL */ `
  query GetAccount($id: ID!) {
    getAccount(id: $id) {
      id
      company
      company_loweredcased
      company_description
      url
      disabled
      piiInformation
      isSchoolAdmin
      email
      users
      createdAt
      updatedAt
      data_setup {
        nextToken
        __typename
      }
      accountStatus
      stripeCustomerID
      stripeSubscriptionID
      stripePriceID
      planID
      metadata
      owners
      editors
      viewers
      datasource
      dataprovider
      advancedList {
        nextToken
        __typename
      }
      request_googlesheet {
        nextToken
        __typename
      }
      recurringSync {
        nextToken
        __typename
      }
      apihistory {
        nextToken
        __typename
      }
      dataSet {
        nextToken
        __typename
      }
      requests {
        nextToken
        __typename
      }
      plan {
        id
        name
        planDetails
        Targets
        bulletPoints
        listed
        order
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listAccounts = /* GraphQL */ `
  query ListAccounts(
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        company
        company_loweredcased
        company_description
        url
        disabled
        piiInformation
        isSchoolAdmin
        email
        users
        createdAt
        updatedAt
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        owners
        editors
        viewers
        datasource
        dataprovider
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAdvancedListDetail = /* GraphQL */ `
  query GetAdvancedListDetail($id: ID!) {
    getAdvancedListDetail(id: $id) {
      id
      school_id
      list_name
      list_id
      datasource
      dataset
      status
      preconfigure
      last_sync_date
      interval_type
      recurring_sync_status
      custom_interval
      custom_type
      custom_value
      is_initial_sync
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAdvancedListDetails = /* GraphQL */ `
  query ListAdvancedListDetails(
    $filter: ModelAdvancedListDetailFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdvancedListDetails(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        school_id
        list_name
        list_id
        datasource
        dataset
        status
        preconfigure
        last_sync_date
        interval_type
        recurring_sync_status
        custom_interval
        custom_type
        custom_value
        is_initial_sync
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDataPackageDetail = /* GraphQL */ `
  query GetDataPackageDetail($id: ID!) {
    getDataPackageDetail(id: $id) {
      id
      school_id
      package_name
      datasource
      dataset
      status
      last_sync_date
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDataPackageDetails = /* GraphQL */ `
  query ListDataPackageDetails(
    $filter: ModelDataPackageDetailFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDataPackageDetails(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        school_id
        package_name
        datasource
        dataset
        status
        last_sync_date
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGoogleSheetDetail = /* GraphQL */ `
  query GetGoogleSheetDetail($id: ID!) {
    getGoogleSheetDetail(id: $id) {
      id
      school_id
      created_by
      googlesheet_link
      googlesheet_name
      googlesheet_id
      worksheet_name
      worksheet_id
      unique_field
      is_obtained
      interval_type
      last_success_sync_date
      last_failure_sync_date
      custom_interval
      is_cancelled
      custom_type
      custom_value
      table_name
      refresh_interval_status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listGoogleSheetDetails = /* GraphQL */ `
  query ListGoogleSheetDetails(
    $filter: ModelGoogleSheetDetailFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGoogleSheetDetails(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        school_id
        created_by
        googlesheet_link
        googlesheet_name
        googlesheet_id
        worksheet_name
        worksheet_id
        unique_field
        is_obtained
        interval_type
        last_success_sync_date
        last_failure_sync_date
        custom_interval
        is_cancelled
        custom_type
        custom_value
        table_name
        refresh_interval_status
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRecurringSync = /* GraphQL */ `
  query GetRecurringSync($id: ID!) {
    getRecurringSync(id: $id) {
      id
      school_id
      success
      worksheet_id
      worksheet_name
      interval_type
      sync_for
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRecurringSyncs = /* GraphQL */ `
  query ListRecurringSyncs(
    $filter: ModelRecurringSyncFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRecurringSyncs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        school_id
        success
        worksheet_id
        worksheet_name
        interval_type
        sync_for
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCountOfAPIHistory = /* GraphQL */ `
  query GetCountOfAPIHistory($id: ID!) {
    getCountOfAPIHistory(id: $id) {
      id
      school_id
      api_key
      dataset_type
      count
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCountOfAPIHistorys = /* GraphQL */ `
  query ListCountOfAPIHistorys(
    $filter: ModelCountOfAPIHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCountOfAPIHistorys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        school_id
        api_key
        dataset_type
        count
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDataSetDetail = /* GraphQL */ `
  query GetDataSetDetail($id: ID!) {
    getDataSetDetail(id: $id) {
      id
      school_id
      data_set
      data_source
      purchased_date
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDataSetDetails = /* GraphQL */ `
  query ListDataSetDetails(
    $filter: ModelDataSetDetailFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDataSetDetails(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        school_id
        data_set
        data_source
        purchased_date
        status
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getVeracrossDetail = /* GraphQL */ `
  query GetVeracrossDetail($id: ID!) {
    getVeracrossDetail(id: $id) {
      id
      school_id
      veracross_client_id
      veracross_client_secret
      scope
      school_route
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listVeracrossDetails = /* GraphQL */ `
  query ListVeracrossDetails(
    $filter: ModelVeracrossDetailFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVeracrossDetails(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        school_id
        veracross_client_id
        veracross_client_secret
        scope
        school_route
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAccountRequest = /* GraphQL */ `
  query GetAccountRequest($id: ID!) {
    getAccountRequest(id: $id) {
      id
      account
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAccountRequests = /* GraphQL */ `
  query ListAccountRequests(
    $filter: ModelAccountRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccountRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        account
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPlan = /* GraphQL */ `
  query GetPlan($id: ID!) {
    getPlan(id: $id) {
      id
      name
      planDetails
      Targets
      bulletPoints
      listed
      order
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPlans = /* GraphQL */ `
  query ListPlans(
    $filter: ModelPlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlans(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        planDetails
        Targets
        bulletPoints
        listed
        order
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getWebProfiles = /* GraphQL */ `
  query GetWebProfiles($id: ID!) {
    getWebProfiles(id: $id) {
      id
      account
      platform
      profile_id
      metadata
      enabled
      last_sync_date
      disconnect
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listWebProfiless = /* GraphQL */ `
  query ListWebProfiless(
    $filter: ModelWebProfilesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWebProfiless(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        account
        platform
        profile_id
        metadata
        enabled
        last_sync_date
        disconnect
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOAuthCreds = /* GraphQL */ `
  query GetOAuthCreds($id: ID!) {
    getOAuthCreds(id: $id) {
      id
      account
      platform
      refresh_token
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listOAuthCredss = /* GraphQL */ `
  query ListOAuthCredss(
    $filter: ModelOAuthCredsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOAuthCredss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        account
        platform
        refresh_token
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getIntegrationSyncHistory = /* GraphQL */ `
  query GetIntegrationSyncHistory($id: ID!) {
    getIntegrationSyncHistory(id: $id) {
      id
      account
      platform
      profile_id
      source
      status
      createdAt
      detail
      updatedAt
      __typename
    }
  }
`;
export const listIntegrationSyncHistorys = /* GraphQL */ `
  query ListIntegrationSyncHistorys(
    $filter: ModelIntegrationSyncHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIntegrationSyncHistorys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        account
        platform
        profile_id
        source
        status
        createdAt
        detail
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getIntegrationSyncHistoryByDate = /* GraphQL */ `
  query GetIntegrationSyncHistoryByDate(
    $platform: SUPPORTED_PLATFORM
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelIntegrationSyncHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getIntegrationSyncHistoryByDate(
      platform: $platform
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        account
        platform
        profile_id
        source
        status
        createdAt
        detail
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getApiKey = /* GraphQL */ `
  query GetApiKey($id: ID!) {
    getApiKey(id: $id) {
      id
      account
      key
      keyid
      disabled
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listApiKeys = /* GraphQL */ `
  query ListApiKeys(
    $filter: ModelApiKeyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listApiKeys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        account
        key
        keyid
        disabled
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFAQ = /* GraphQL */ `
  query GetFAQ($id: ID!) {
    getFAQ(id: $id) {
      id
      question
      answer
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listFAQs = /* GraphQL */ `
  query ListFAQs(
    $filter: ModelFAQFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFAQs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        question
        answer
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFirebaseTokens = /* GraphQL */ `
  query GetFirebaseTokens($id: ID!) {
    getFirebaseTokens(id: $id) {
      id
      token
      user
      userDetails
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listFirebaseTokenss = /* GraphQL */ `
  query ListFirebaseTokenss(
    $filter: ModelFirebaseTokensFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFirebaseTokenss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        token
        user
        userDetails
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDatasource = /* GraphQL */ `
  query GetDatasource($id: ID!) {
    getDatasource(id: $id) {
      id
      data_provider
      data_source_name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDatasources = /* GraphQL */ `
  query ListDatasources(
    $filter: ModeldatasourceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDatasources(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        data_provider
        data_source_name
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNotificationType = /* GraphQL */ `
  query GetNotificationType($id: ID!) {
    getNotificationType(id: $id) {
      id
      notification_type
      code
      title
      notification_text
      content
      metadata
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listNotificationTypes = /* GraphQL */ `
  query ListNotificationTypes(
    $filter: ModelNotificationTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotificationTypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        notification_type
        code
        title
        notification_text
        content
        metadata
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserNotificationPreference = /* GraphQL */ `
  query GetUserNotificationPreference($id: ID!) {
    getUserNotificationPreference(id: $id) {
      id
      user
      userDetails
      notificationType
      emailNotification
      inAppNotification
      webPushNotification
      createdAt
      updatedAt
      notificationTypeDetails {
        id
        notification_type
        code
        title
        notification_text
        content
        metadata
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listUserNotificationPreferences = /* GraphQL */ `
  query ListUserNotificationPreferences(
    $filter: ModelUserNotificationPreferenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserNotificationPreferences(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user
        userDetails
        notificationType
        emailNotification
        inAppNotification
        webPushNotification
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const NotificationTypeByUser = /* GraphQL */ `
  query NotificationTypeByUser(
    $user: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserNotificationPreferenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    NotificationTypeByUser(
      user: $user
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user
        userDetails
        notificationType
        emailNotification
        inAppNotification
        webPushNotification
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserNotification = /* GraphQL */ `
  query GetUserNotification($id: ID!) {
    getUserNotification(id: $id) {
      id
      user
      userDetails
      userNotificationPreference
      title
      content
      message
      code
      metadata
      isRead
      emailSent
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUserNotifications = /* GraphQL */ `
  query ListUserNotifications(
    $filter: ModelUserNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user
        userDetails
        userNotificationPreference
        title
        content
        message
        code
        metadata
        isRead
        emailSent
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getInitialSyncDetails = /* GraphQL */ `
  query GetInitialSyncDetails($id: ID!) {
    getInitialSyncDetails(id: $id) {
      id
      school_id
      data_provider
      data_source
      last_sync_date
      initial_sync_status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listInitialSyncDetailss = /* GraphQL */ `
  query ListInitialSyncDetailss(
    $filter: ModelInitialSyncDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInitialSyncDetailss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        school_id
        data_provider
        data_source
        last_sync_date
        initial_sync_status
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRules = /* GraphQL */ `
  query GetRules($id: ID!) {
    getRules(id: $id) {
      id
      school_id
      field_key
      data_source
      data_set
      rule_values
      created_by
      updated_by
      rule_type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRuless = /* GraphQL */ `
  query ListRuless(
    $filter: ModelRulesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRuless(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        school_id
        field_key
        data_source
        data_set
        rule_values
        created_by
        updated_by
        rule_type
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDaslValues = /* GraphQL */ `
  query GetDaslValues($id: ID!) {
    getDaslValues(id: $id) {
      id
      field_key
      field_values
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDaslValuess = /* GraphQL */ `
  query ListDaslValuess(
    $filter: ModelDaslValuesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDaslValuess(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        field_key
        field_values
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCsvDetail = /* GraphQL */ `
  query GetCsvDetail($id: ID!) {
    getCsvDetail(id: $id) {
      id
      school_id
      created_by
      csv_name
      unique_field
      is_obtained
      is_cancelled
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCsvDetails = /* GraphQL */ `
  query ListCsvDetails(
    $filter: ModelCsvDetailFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCsvDetails(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        school_id
        created_by
        csv_name
        unique_field
        is_obtained
        is_cancelled
        status
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPreviewDetail = /* GraphQL */ `
  query GetPreviewDetail($id: ID!) {
    getPreviewDetail(id: $id) {
      id
      school_id
      created_by
      data_set
      show_fields
      hidden_fields
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPreviewDetails = /* GraphQL */ `
  query ListPreviewDetails(
    $filter: ModelPreviewDetailFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPreviewDetails(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        school_id
        created_by
        data_set
        show_fields
        hidden_fields
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getQsdashboardMap = /* GraphQL */ `
  query GetQsdashboardMap($id: ID!) {
    getQsdashboardMap(id: $id) {
      id
      school_id
      dashboard_id
      dashboard_name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listQsdashboardMaps = /* GraphQL */ `
  query ListQsdashboardMaps(
    $filter: ModelQsdashboardMapFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQsdashboardMaps(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        school_id
        dashboard_id
        dashboard_name
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getQsdashboardDetail = /* GraphQL */ `
  query GetQsdashboardDetail($id: ID!) {
    getQsdashboardDetail(id: $id) {
      id
      school_id
      dashboard_id
      dashboard_name
      dashboard_name_public
      dashboard_description
      dashboard_type
      topic_id
      author
      tags
      createdAt
      updatedAt
      isFavoriteOf {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listQsdashboardDetails = /* GraphQL */ `
  query ListQsdashboardDetails(
    $filter: ModelQsdashboardDetailFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQsdashboardDetails(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        school_id
        dashboard_id
        dashboard_name
        dashboard_name_public
        dashboard_description
        dashboard_type
        topic_id
        author
        tags
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFavorite = /* GraphQL */ `
  query GetFavorite($id: ID!) {
    getFavorite(id: $id) {
      id
      userId
      resourceType
      dashboard_oid
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listFavorites = /* GraphQL */ `
  query ListFavorites(
    $filter: ModelFavoriteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFavorites(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        resourceType
        dashboard_oid
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
//
// export const listTestConnectionHistorys = /* GraphQL */ `
//   query ListTestConnectionHistorys(
//     $filter: ModelTestConnectionHistoryFilterInput
//     $limit: Int
//     $nextToken: String
//   ) {
//     listTestConnectionHistorys(
//       filter: $filter
//       limit: $limit
//       nextToken: $nextToken
//     ) {
//       items {
//         id
//         api_oid
//         request_id
//         school_id
//         status
//         response
//         createdAt
//         updatedAt
//         api_details {
//           id
//           data_source
//           alias
//           api_endpoint
//           parameters
//           dependency
//           createdAt
//           updatedAt
//           __typename
//         }
//         __typename
//       }
//       nextToken
//       __typename
//     }
//   }
// `;
