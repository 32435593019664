import React, { useState } from "react";
import CustomModal from "../CustomAntD/Modal";
import { Button, Col, Image, Row, Typography } from "antd";
import { Images } from "assets/images/Images";
import {
  MiscellaneousText,
  profileText,
  modalText,
  buttonText,
} from "utility/constants";
import { Auth } from "aws-amplify";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const LogoutModal = ({
  setActiveKey,
  logoutModalOpen,
  setLogoutModalOpen,
  ...rest
}) => {
  //   const [logoutModalOpen, setLogoutModalOpen] = useState(false);

  const { t } = useTranslation();
  const history = useHistory();

  const { LogoutSvg } = Images.ModalImg;

  //** Destructing Constants */
  const { User_Profile_Text, Basic_Details_Text } = profileText;
  const { Logout_Text } = MiscellaneousText;
  const { Logout_Modal_Text_Part_1, Logout_Modal_Text_Part_2 } =
    modalText?.Profile_Modal;
  const { Cancel, Yes_Sure_Text } = buttonText;

  /**
   * @function onModalClose
   * Handles the close of the modal and activeKey
   */
  const onModalClose = () => {
    setLogoutModalOpen(false);
    if (setActiveKey) {
      setActiveKey(true);
    }
  };

  //** Handles sign out */
  const handleSignOut = async () => {
    Auth.signOut()
      .then((_) => {
        localStorage.clear();
        history.push("/signin");
        rest?.logOut();
      })
      .catch((err) => {
        if (err?.code === "NotAuthorizedException") {
          localStorage.clear();
        }
        console.log(err);
      });
  };

  return (
    <>
      <CustomModal
        open={logoutModalOpen}
        onCancel={onModalClose}
        centered
        width={400}
        footer={null}
      >
        <Row justify="center" align="middle" gutter={[16, 16]}>
          <Col span={24}>
            <Row justify="center">
              <Image
                preview={false}
                src={LogoutSvg}
                height={"50%"}
                width={"50%"}
              />
            </Row>
          </Col>
          <Col span={24}>
            <Row justify="center">
              <Typography.Title level={4} className="lh-sm text-center">
                {t(Logout_Modal_Text_Part_1)} <br />
                {t(Logout_Modal_Text_Part_2)}
              </Typography.Title>
            </Row>
          </Col>
          <Col span={24}>
            <Row gutter={16} justify="center">
              <Col span={12}>
                <Button block onClick={onModalClose}>
                  {t(Cancel)}
                </Button>
              </Col>
              <Col span={12}>
                <Button block onClick={() => handleSignOut()} type="primary">
                  {t(Yes_Sure_Text)}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </CustomModal>
    </>
  );
};

export default LogoutModal;
