import { createRules, updateRules } from "graphql/mutations";
import { listDaslValuess, listRuless } from "graphql/queries";
import { getEnv, GraphqlOperationExecutor } from "utility/commonMethods";
import axios from "axios";
import { Filter, GetPreconfigvaluesFunction } from "types";
import {
  CreateRulesInput,
  CreateRulesMutation,
  ListDaslValuessQuery,
  ListRulessQuery,
  UpdateRulesInput,
  UpdateRulesMutation,
} from "_generated_/graphql";
import { StatusResult } from "./types";

const createRuleEngine = (
  input: CreateRulesInput
): Promise<CreateRulesMutation["createRules"] | StatusResult> => {
  return GraphqlOperationExecutor({
    query: createRules,
    variables: {
      input: input,
    },
  });
};

const updateRuleEngine = (
  input: UpdateRulesInput
): Promise<UpdateRulesMutation["updateRules"] | StatusResult> => {
  return GraphqlOperationExecutor({
    query: updateRules,
    variables: {
      input: input,
    },
  });
};

const getRuleEngine = (
  input: Filter
): Promise<ListRulessQuery["listRuless"] | StatusResult> => {
  return GraphqlOperationExecutor({
    query: listRuless,
    variables: {
      filter: input,
    },
  });
};

const getPreconfigvalues: GetPreconfigvaluesFunction = async ({
  school_id,
  column_name,
  dataset,
  datasource,
}) => {
  const env: string = getEnv();

  const apiKey: { [key: string]: string } = {
    stagging:
      "IjsZEk7uL8xdvCEC7y14WG56gV72eRTCixgAwT0YY43X1Yy6F4cwR2I9GbrNfWEU",
    stage: "IjsZEk7uL8xdvCEC7y14WG56gV72eRTCixgAwT0YY43X1Yy6F4cwR2I9GbrNfWEU",
    prod: "UHk3seFKaCwGXFBpx4bvLaLAlhBKmlnISjtwFqfMf3M-vq2yXl3Abhg3DbXZwyXW",
    production:
      "UHk3seFKaCwGXFBpx4bvLaLAlhBKmlnISjtwFqfMf3M-vq2yXl3Abhg3DbXZwyXW",
  };

  const baseUrl: string = `https://api-${env}.schoolbi.com/v3/data/data/getuniquevalues?school_id=${school_id}&column_name=${column_name}&datasource=${datasource}&dataset=${dataset}&apiKey=${apiKey?.[env]}`;

  const { data } = await axios.get(baseUrl);

  return data;
};

const getDaslValues = async (
  input: Filter
): Promise<ListDaslValuessQuery["listDaslValuess"]> => {
  return GraphqlOperationExecutor({
    query: listDaslValuess,
    variables: {
      filter: input,
    },
  });
};

const RulesEngineSDK = {
  createRuleEngine,
  getRuleEngine,
  getPreconfigvalues,
  getDaslValues,
  updateRuleEngine,
};

export default RulesEngineSDK;
