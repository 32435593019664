import React from "react";
import App from "./App";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { store, persistor } from "store/index";
import { createRoot } from "react-dom/client";
import { ConfigProvider } from "antd";
import theme from "./style/theme";
import AuthorizedApolloProvider from "./providers/AuthorizedApolloProvider";

const root = createRoot(
  document.getElementById("root") || document.createElement("div")
);

root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <ConfigProvider theme={theme}>
        <AuthorizedApolloProvider>
          <App />
        </AuthorizedApolloProvider>
      </ConfigProvider>
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
