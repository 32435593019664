// Description: Billing SDK for all billing related API calls
//** Aws Amplify Imports */
import { API } from "aws-amplify";
import {
  AssignPlanFunction,
  Billing,
  CancelPlanFunction,
  GetOrgCheckoutSessionFunction,
  GetPlaidLinkTokenFunction,
  GetPortalLinkFunction,
} from "types";
//** Utility Imports */
import { apiExecuter } from "utility/commonMethods";

/**
 * Get a link token for Plaid
 */
const getPlaidLinkToken: GetPlaidLinkTokenFunction = async (id) =>
  apiExecuter(async () => {
    const url: string = `/billing/plaid/link?accountId=${id}`;
    return (await API.get("schoolBIREST", url, {})) as string;
  });

const getPortalLink: GetPortalLinkFunction = async (accountId) => {
  return apiExecuter(async () => {
    const url: string = `/billing/portal/org?accountId=${accountId}`;
    return await API.get("schoolBIREST", url, {});
  });
};

/**
 * Get Stripe checkout session for an organization account
 */
const getOrgCheckoutSession: GetOrgCheckoutSessionFunction = async ({
  planStripeId,
  topUpStripeId,
  planId,
  accountId,
  paymentType,
  paymentData,
  topQuantity = 0,
}) => {
  const url: string = "/billing/checkout/org";

  const requestBody: Billing = {
    planPriceId: planStripeId,
    planId: planId,
    accountId: accountId,
    payment_type: paymentType,
    topQuantity: topQuantity,
  };

  if (topQuantity > 0) requestBody["topUpPriceId"] = topUpStripeId;

  if (paymentType === "ach" && paymentData) {
    requestBody["plaid_public_token"] = paymentData["plaidToken"];
    requestBody["plaid_account_id"] = paymentData["plaidAccountId"];
  }

  return apiExecuter(
    async () => await API.post("schoolBIREST", url, { body: requestBody }),
  );
};

const assignPlan: AssignPlanFunction = async (accountId, planId, dataSources) =>
  apiExecuter(async () => {
    const url: string = "/admin/assignplan";

    return await API.post("schoolBIREST", url, {
      body: {
        planId: planId,
        id: accountId,
        dataSources,
      },
    });
  });

const cancelPlan: CancelPlanFunction = async (accountId, planId) =>
  apiExecuter(async () => {
    const url: string = "/admin/cancelplan";

    return await API.post("schoolBIREST", url, {
      body: {
        planId: planId,
        id: accountId,
      },
    });
  });

const BillingSDK: {
  getPlaidLinkToken: GetPlaidLinkTokenFunction;
  getPortalLink: GetPortalLinkFunction;
  getOrgCheckoutSession: GetOrgCheckoutSessionFunction;
  assignPlan: AssignPlanFunction;
  cancelPlan: CancelPlanFunction;
} = {
  getPlaidLinkToken,
  getPortalLink,
  getOrgCheckoutSession,
  assignPlan,
  cancelPlan,
};

export default BillingSDK;
