//** React Imports */
import React, { useState } from "react";
//** Ant Design Imports */
import {
  Button,
  Card,
  Col,
  Typography,
  Row,
  Form,
  Input,
  Space,
  message,
} from "antd";
//** Images Imports */
import { Images } from "assets/images/Images";
//** SDK Imports */
import sdk from "sdk/Accounts";
//** Third Party Imports */
import { useTranslation } from "react-i18next";
//** Constant Imports */
import { buttonText, messageText, setupPageText } from "utility/constants";
import { Auth } from "aws-amplify";
import CustomModal from "Common/Components/CustomAntD/Modal";
import ApiResultModal from "Common/Components/ApiResultModal/ApiResultModal";

export default function NewAccount({ reset, select, expand, fetchuser }) {
  //** Destructing Hooks */
  const { t } = useTranslation();
  //** Destructing Images */
  const { NewAccountSVG } = Images?.AccountSetup;

  //** Destructing Constants  */
  const { Get_Started_Text, Continue_Text } = buttonText;
  const {
    Com_Web_Placeholder,
    Start_New_Account,
    Free_Trial_Part1_Text,
    Free_Trial_Part2_Text,
    Create_Company_Text,
    Empty_Company_Error,
    Invalid_Company_Error,
    Company_Name_Label,
    Company_URL_Label,
    Empty_URL_Error,
    Invalid_URL_Error,
  } = setupPageText;
  const { Account_Created_Message } = messageText;

  // loader state
  const [loading, setLoading] = useState(false);
  const [apiResultModal, setApiResultModal] = useState({
    message: "",
    isApiResultModelOpen: false,
    type: "failure",
  });

  /**
   * Handles Submits of company details
   */
  const handleSubmit = async (e) => {
    const cognitoUser = await Auth.currentAuthenticatedUser();

    setLoading(true);
    let result = await sdk.createAccount(
      e.company,
      e.url,
      cognitoUser?.username,
      cognitoUser?.attributes?.email
    );
    if (result?.error) {
      // message.error(result.error, 5);
      setApiResultModal(() => ({
        message: result?.error,
        isApiResultModelOpen: true,
        type: "failure",
      }));
    } else {
      // message.success(t(Account_Created_Message), 5);
      setApiResultModal(() => ({
        message: t(Account_Created_Message),
        isApiResultModelOpen: true,
        type: "success",
      }));
      await fetchuser();
    }
    setLoading(false);
    reset();
  };

  return (
    <>
      <Card bordered={false}>
        <Row className="account-setup-card" justify="space-between">
          <Col span={24}>
            <div className="w-100 py-4">
              <img
                src={NewAccountSVG}
                alt="new account"
                className="request-access-img w-100"
              />
            </div>
            <Typography.Title
              level={4}
              className="text-center font-matter-bold color-white "
            >
              {t(Start_New_Account)}
            </Typography.Title>
            <Typography.Paragraph className="account-setup-text color-white">
              {t(Free_Trial_Part1_Text)}
              {t(Free_Trial_Part2_Text)}
            </Typography.Paragraph>
          </Col>

          <Col span={24} className="d-flex">
            <Button
              className="account-setup-btn color-black bg-white"
              size="large"
              block
              onClick={select}
            >
              {t(Continue_Text)}
            </Button>
          </Col>
        </Row>
      </Card>
      <CustomModal
        className="new-account-model"
        open={expand}
        closable={true}
        width={400}
        footer={null}
        centered
        closeClickHandler={() => reset()}
      >
        <Row justify="center">
          <Col span={24} lg={24} md={24} sm={24} xs={24}>
            <Typography.Title
              level={4}
              className="text-center font-matter-bold "
            >
              {t(Create_Company_Text)}
            </Typography.Title>
            <Form
              labelCol={{ xs: 24 }}
              requiredMark={false}
              layout="vertical"
              name="new_account"
              onFinish={(e) => handleSubmit(e)}
            >
              <Space direction="vertical" className="w-100">
                <Form.Item
                  className="font-bold"
                  name="company"
                  label={t(Company_Name_Label)}
                  rules={[
                    {
                      required: true,
                      message: t(Empty_Company_Error),
                    },
                    {
                      pattern: /(^[a-zA-Z0-9]{1})([a-zA-Z0-9& .]+)$/,
                      message: t(Invalid_Company_Error),
                    },
                  ]}
                >
                  <Input
                    size="middle"
                    id="company"
                    key="company"
                    name="company"
                    placeholder={t(Company_Name_Label)}
                    disabled={loading}
                    tabIndex={1}
                  />
                </Form.Item>
                <Form.Item
                  className="font-bold"
                  name="url"
                  label={t(Company_URL_Label)}
                  rules={[
                    {
                      required: true,
                      message: t(Empty_URL_Error),
                    },
                    {
                      pattern:
                        /(www\.)?[-a-zA-Z0-9_]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
                      message: t(Invalid_URL_Error),
                    },
                  ]}
                >
                  <Input
                    size="middle"
                    id="url"
                    key="url"
                    name="url"
                    placeholder={t(Com_Web_Placeholder)}
                    disabled={loading}
                    tabIndex={1}
                  />
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    size="large"
                    block
                    htmlType="submit"
                    loading={loading}
                    tabIndex={5}
                  >
                    {t(Get_Started_Text)}
                  </Button>
                </Form.Item>
              </Space>
            </Form>
          </Col>
        </Row>
      </CustomModal>

      <ApiResultModal
        apiResultModal={apiResultModal}
        setApiResultModal={setApiResultModal}
      />
    </>
  );
}
