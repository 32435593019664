import { CloseOutlined } from "@ant-design/icons";
import { Popconfirm, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { MiscellaneousText, PopConfirmText } from "utility/constants";

const ScopeContainer = (props) => {
  const { Title, Text } = Typography;
  //** Destructing props */
  const { groups, selectedCompanyName, admin_groups_self, switchAccountSelf } =
    props;

  const { profileText } = PopConfirmText;
  const { yesText } = MiscellaneousText;
  const { t } = useTranslation();

  return (
    <>
      {/* Admin Scope Feature Container Start*/}
      {groups && groups.includes("admin") && (
        <>
          {selectedCompanyName && (
            <>
              <div className="scoping-container">
                <div className="d-flex justify-center align-center">
                  <Text className="companyname-container">
                    Viewing as&nbsp;
                    {selectedCompanyName}
                    {/* Admin scope popconfirm start */}
                    <Popconfirm
                      className="switchDialog"
                      placement="bottomRight"
                      title={<Title level={5}>{t(profileText)}</Title>}
                      onConfirm={() => switchAccountSelf(admin_groups_self)}
                      okText={<span className="admin-btn">{t(yesText)}</span>}
                      cancelText="No"
                      icon={""}
                      okType="primary"
                      okButtonProps={{
                        size: "large",
                      }}
                      cancelButtonProps={{
                        size: "large",
                      }}
                    >
                      <strong className="cursor-pointer white-color ps-8 font-bold ">
                        <CloseOutlined />
                      </strong>
                    </Popconfirm>
                    {/* Admin scope popconfirm end */}
                  </Text>
                </div>
              </div>
            </>
          )}
        </>
      )}
      {/* Admin Scope Feature Container End*/}
    </>
  );
};

export default ScopeContainer;
