// ** React imports */
import React, { PropsWithChildren } from "react";

/**
 * FullPageLayout component
 */
const FullPageLayout: React.FC<PropsWithChildren> = ({ children }) => {
  return <main className="main text-muted">{children}</main>;
};

export default FullPageLayout;
