import * as actions from "../actions/index";

export const initialState = {
  integrations: {},
  connectorCounterRedux: 0,
  render: "phaseZero",
  selectedConnector: [],
  allSubSources: [],
  isFromStripe: false,
  currentRenderKey: "details",
  currentConfigureRenderKey: "rule",
  isManageFilesVisibility: true,
  isAdvancedListsVisibility: true,
  isUnauthorizedVisisible: false,
};

const settingRedcuer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_INTEGRATIONS:
      return { ...state, integrations: action.payload };

    case actions.CONNECTOR_COUNTER:
      return { ...state, connectorCounterRedux: action.payload };

    case actions.SET_CURRENT_RENDER:
      return { ...state, render: action.payload };

    case actions.SELECTED_CONNECTOR:
      return { ...state, selectedConnector: action.payload };
    case actions.SET_ALL_SUBSOURCES:
      return { ...state, allSubSources: action.payload };
    case actions.SET_ISFROMSTRIPE:
      return { ...state, isFromStripe: action.payload };

    case actions.SET_CURRENT_RENDER_KEY:
      return { ...state, currentRenderKey: action.payload };

    case actions.SET_CURRENT_CONFIGURE_RENDER_KEY:
      return { ...state, currentConfigureRenderKey: action.payload };

    case actions.SET_MANAGE_FILES_VISIBILITY:
      return { ...state, isManageFilesVisibility: action.payload };

    case actions.SET_ADVANCED_LIST_VISIBILITY:
      return {
        ...state,
        isAdvancedListsVisibility: action.payload,
      };

    case actions.SET_UNAUTHORIZED_VISIBILITY:
      return {
        ...state,
        isUnauthorizedVisisible: action.payload,
      };

    default:
      return state;
  }
};

export default settingRedcuer;
