import { get_attribute } from "utility/commonMethods";
import * as actions from "../actions/index";

export interface LoginReducerState {
  token: string | null;
  roles: string[];
  groups: string[];
  userData?: {
    given_name: string;
    family_name: string;
  };
  schoolData: object;
  admin_groups_self: string[];
  userPermission: string;
  viewType: string;
  accountRequests: string[];
  selectedCompanyName: string;
  themeMode: boolean;
  isLoading: boolean;
  isScopeNormalUser: boolean;
  accountId: string | null;
}

export const initialState: LoginReducerState = {
  token: null,
  roles: [],
  groups: [],
  schoolData: {},
  admin_groups_self: [],
  userPermission: "",
  viewType: "",
  accountRequests: [],
  selectedCompanyName: "",
  themeMode: false,
  isLoading: false,
  isScopeNormalUser: false,
  accountId: null,
};

const accountFromGroups = (groups) =>
  groups.reduce((acc, group) => {
    return (
      acc || (`${group}`.match(/^\w{8}-\w{4}-\w{4}-\w{4}-\w{12}$/)?.[0] ?? null)
    );
  }, null);

const loginReducer = (state: LoginReducerState = initialState, action) => {
  switch (action.type) {
    case actions.UPDATE_LOGIN:
      const userData = { ...action.data };
      let groupValue = [];
      if ("cognito:groups" in action.payload.accessToken.payload) {
        groupValue = action.payload.accessToken.payload["cognito:groups"];
      }

      //pull the school ID group out of the group list, it will be the one that is an uuid
      const accountId = accountFromGroups(groupValue);

      const givenName = userData?.given_name;
      const familyName = userData?.family_name;

      //  dispatch(setGivenName(response?.attributes?.given_name));
      //  dispatch(setFamilyName(response?.attributes?.family_name));

      return {
        ...state,
        token: action.payload.accessToken.jwtToken,
        groups: groupValue,
        admin_groups_self: groupValue,
        userData: { ...userData },
        userId: userData.sub,
        given_name: givenName,
        family_name: familyName,
        accountId,
      };

    case actions.UPDATE_COMPANY_ACCOUNT:
      const index = 0;
      const replacementItem = action.payload.id;
      const currentAuthenticatedUser = action.payload?.users;
      let given_name = state?.userData?.given_name;
      let family_name = state?.userData?.family_name;
      let userId;
      console.log(
        "currentAuthenticatedUser",
        currentAuthenticatedUser,
        action.payload
      );
      if (currentAuthenticatedUser) {
        try {
          const currentUserVal = JSON.parse(
            currentAuthenticatedUser?.filter((item) => {
              const currentUser = JSON.parse(item);
              console.log("currentUser", currentUser);
              const data = get_attribute(currentUser["Attributes"], "email");
              console.log("current data user", data);
              return data === action.payload?.email;
            })
          );

          console.log("currentUserVal", currentUserVal);
          given_name =
            get_attribute(currentUserVal["Attributes"], "given_name") || null;
          family_name =
            get_attribute(currentUserVal["Attributes"], "family_name") || null;

          userId = currentUserVal?.Username || null;
        } catch (e) {
          console.error(
            `Cannot sudo to user; no sufficient user found: ${e.message}`
          );
        }
      }

      let companyName = action.payload.company;
      const updatedAccount = Object.assign([], state.groups, {
        [index]: replacementItem,
      });
      if (state.selectedCompanyName?.[0] === state.groups?.[0])
        companyName = "";

      return {
        ...state,
        token: state.token,
        groups: updatedAccount,
        selectedCompanyName: companyName,
        userData: { ...state.userData },
        given_name,
        family_name,
        userId,
        scopedUserDetails: action.payload,
        accountId: accountFromGroups(updatedAccount),
      };

    case actions.SET_ACCOUNT_REQUESTS:
      return {
        ...state,
        accountRequests: action.payload,
      };

    case actions.SET_PERMISSION:
      return {
        ...state,
        userPermission: action.payload,
      };
    case actions.SET_THEME:
      return {
        ...state,
        themeMode: action.payload,
      };
    case actions.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case actions.SET_GIVENNAME:
      return {
        ...state,
        given_name: action.payload,
      };
    case actions.SET_FAMILYNAME:
      return {
        ...state,
        family_name: action.payload,
      };
    case actions.SET_USERDATA:
      return {
        ...state,
        userData: action.payload,
      };
    case actions.SET_IS_SCOPE_NORMALUSER:
      return {
        ...state,
        isScopeNormalUser: action.payload,
      };
    case actions.SET_SCHOOLDATA:
      return {
        ...state,
        schoolData: action.payload,
      };
    default:
      return state;
  }
};

export default loginReducer;
