//** React Imports */
import React, { useState, useEffect, useCallback } from "react";
//** Images Imports */
import { Images } from "assets/images/Images";
//** Ant Design Imports */
import { Button, Card, Col, Typography, Row, Form, Space, Input } from "antd";
//** Components Imports */
import RequestPending from "./RequestPending";
//** Constants Imports */
import { buttonText, messageText, setupPageText } from "utility/constants";
//** Third Party Imports */
import { useTranslation } from "react-i18next";
import { API, Auth } from "aws-amplify";
import CustomModal from "Common/Components/CustomAntD/Modal";
import ApiResultModal from "Common/Components/ApiResultModal/ApiResultModal";

export default function RequestAccess({
  select,
  expand,
  reset,
  accountid,
  requestAccess,
}) {
  //** Destructing Imports */
  const { t } = useTranslation();
  //** Destructing Images */
  const { RequestAccessSvg } = Images?.AccountSetup;

  /** @useState for handling data */
  const [loading, setLoading] = useState(false);
  const [pending, setPending] = useState(false);
  const [apiResultModal, setApiResultModal] = useState({
    message: "",
    isApiResultModelOpen: false,
    type: "failure",
  });

  //** Destructing Constants */
  const {
    Join_Existing_Account_Text,
    Request_Access_Part1_Text,
    Request_Access_Part2_Text,
    Customer_ID_Text,
    Account_Enter_Error,
    Account_Invalid_Error,
    Customer_Id_Label,
  } = setupPageText;
  const { Get_Started_Text, Request_Access_Text } = buttonText;
  const { Account_Invalid_Message, Request_Submitted_Message } = messageText;

  //for request to join team
  const addRequest = async (e) => {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const path = `/addapproverequest/addrequest`;
    try {
      var result = await API.post("schoolBIREST", path, {
        body: {
          requesterEmailId: cognitoUser?.attributes?.email,
          groupName: e,
        },
      });
      return result;
    } catch (e) {
      console.log(e);
      return {
        error: e.response?.data?.error
          ? e.response?.data?.error
          : "Failed to send request",
      };
    }
  };

  const handleSubmit = useCallback(
    (e) => {
      setLoading(true);
      addRequest(e.accountid)
        .then(() => {
          setPending(true);
          requestAccess();
          setLoading(false);
        })
        .catch((response) => {
          setApiResultModal(() => ({
            message: response.errors || response.error,
            isApiResultModelOpen: true,
            type: "failure",
          }));
        });

      reset();
    },
    [reset, requestAccess]
  );

  useEffect(() => {
    if (accountid) {
      if (accountid.length === 36) {
        var patternId = new RegExp(
          "[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}",
        );
        if (patternId.test(accountid)) {
          handleSubmit({ accountid: accountid });
        }
      } else {
        setApiResultModal(() => ({
          message: t(Account_Invalid_Message),
          isApiResultModelOpen: true,
          type: "failure",
        }));
      }
    }
  }, [accountid, t, Account_Invalid_Message, handleSubmit]);

  return (
    <>
      {loading ? <div className="loading" /> : null}
      {pending ? (
        <RequestPending accountid={accountid} />
      ) : (
        <Card bordered={false}>
          <Row className="account-setup-card" justify="center">
            <Col xs={24} md={24}>
              <div className="w-100 my-4">
                <img
                  preview={false}
                  src={RequestAccessSvg}
                  alt="request access"
                  className="request-access-img w-100"
                />
              </div>

              <Typography.Title
                level={4}
                className="text-center font-matter-bold"
              >
                {t(Join_Existing_Account_Text)}
              </Typography.Title>
              <Typography.Paragraph className="account-setup-text">
                {t(Request_Access_Part1_Text)}
                {t(Request_Access_Part2_Text)}
              </Typography.Paragraph>
            </Col>
            <Col span={24} className="d-flex">
              <Button
                className="account-setup-btn color-white bg-black"
                size="large"
                block
                onClick={select}
              >
                {t(Request_Access_Text)}
              </Button>
            </Col>
          </Row>
        </Card>
      )}
      <CustomModal
        className="new-account-model"
        open={expand}
        closable={true}
        width={400}
        footer={null}
        centered
        closeClickHandler={reset}
      >
        <Row justify="center">
          <Col span={24} lg={24} md={24} sm={24} xs={24}>
            <Typography.Title
              level={5}
              className="font-matter-bold text-center"
            >
              {t(Customer_ID_Text)}
            </Typography.Title>

            <Form
              labelCol={{ xs: 24 }}
              requiredMark={false}
              layout="vertical"
              name="request_access"
              onFinish={(e) => handleSubmit(e)}
            >
              <Space direction="vertical" className="w-100">
                <Form.Item
                  className="font-bold"
                  name="accountid"
                  label={t(Customer_Id_Label)}
                  rules={[
                    {
                      required: true,
                      message: t(Account_Enter_Error),
                    },
                    {
                      pattern:
                        /\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/,
                      message: t(Account_Invalid_Error),
                    },
                  ]}
                >
                  <Input
                    defaultValue={accountid}
                    autoFocus
                    size="middle"
                    id="accountid"
                    key="accountid"
                    name="accountid"
                    placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
                    disabled={loading}
                    tabIndex={1}
                  />
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    size="large"
                    block
                    htmlType="submit"
                    loading={loading}
                    tabIndex={2}
                  >
                    {t(Get_Started_Text)}
                  </Button>
                </Form.Item>
              </Space>
            </Form>
          </Col>
        </Row>
      </CustomModal>

      <ApiResultModal
        apiResultModal={apiResultModal}
        setApiResultModal={setApiResultModal}
      />
    </>
  );
}
