import { API } from "aws-amplify";
import { listQsdashboardDetails } from "graphql/queries";
import {
  createFavorite,
  deleteFavorite,
  updateQsdashboardDetail,
} from "graphql/mutations";
import { Filter, GetAIInsightFunction } from "types";
import { GraphqlOperationExecutor } from "utility/commonMethods";
import {
  CreateFavoriteInput,
  CreateFavoriteMutation,
  DeleteAccountInput,
  DeleteFavoriteMutation,
  ListQsdashboardDetailsQuery,
  UpdateQsdashboardDetailInput,
  UpdateQsdashboardDetailMutation,
} from "_generated_/graphql";
import { StatusResult } from "./types";

export const getAllCustomDashboard = (
  filter: Filter
): Promise<
  ListQsdashboardDetailsQuery["listQsdashboardDetails"] | StatusResult
> => {
  return GraphqlOperationExecutor({
    query: listQsdashboardDetails,
    variables: {
      filter,
    },
  });
};

const getAIInsight: GetAIInsightFunction = async (body) => {
  const data: { url: string } = await API.post("schoolBIREST", "/qs/qna", {
    body,
  });
  return data;
};

const updateDashboardDetails = (
  input: UpdateQsdashboardDetailInput
): Promise<UpdateQsdashboardDetailMutation["updateQsdashboardDetail"]> => {
  return GraphqlOperationExecutor({
    query: updateQsdashboardDetail,
    variables: {
      input,
    },
  });
};

const createFavoriteDashboard = (
  input: CreateFavoriteInput
): Promise<CreateFavoriteMutation["createFavorite"] | StatusResult> => {
  return GraphqlOperationExecutor({
    query: createFavorite,
    variables: {
      input,
    },
  });
};

const deleteFavoriteDashboard = (
  input: DeleteAccountInput
): Promise<DeleteFavoriteMutation["deleteFavorite"] | StatusResult> => {
  return GraphqlOperationExecutor({
    query: deleteFavorite,
    variables: {
      input,
    },
  });
};

const QsDashboardSDK = {
  getAllCustomDashboard,
  getAIInsight,
  updateDashboardDetails,
  createFavoriteDashboard,
  deleteFavoriteDashboard,
};

export default QsDashboardSDK;
