import React, { useState, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { Menu, Layout, Button } from "antd";
import BeeIcon from "assets/images/bee.svg";
import logo from "assets/images/WhiteLogo.svg";
import BeeWhiteLogo from "assets/images/Logo/BeeWhiteLogo.svg";
import SiderMenu, { FooterMenu, ReportBuilderIcon } from "utility/context/Menu";
import classNames from "classnames";
import useCustomBreakpoints from "utility/UseCustomBreakpoints";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import LogoutModal from "Common/Components/LogoutModal/LogoutModal";
import { getEnv } from "utility/commonMethods";
import { Permission, usePermissions } from "../../hooks/usePermissions";
import { Link } from "react-router-dom";
import { ItemType } from "antd/lib/menu/interface";

/**
 * Side menu component
 * @function Sider
 * @return
 */
const Sider = (props: {
  collapsed: boolean;
  toggleCollapsed: () => void;
  setActiveKey: () => void;
  menuItems?: Array<ItemType & { permission: Permission | null }>;
}): React.ReactElement => {
  const { Sider } = Layout;

  const { collapsed, toggleCollapsed } = props;

  const location = useLocation();

  const screens = useCustomBreakpoints();

  const { can } = usePermissions();

  //** State for handling Key for Side Menu */
  const [logoutModalOpen, setLogoutModalOpen] = useState(false);

  const selectedKey = useMemo(() => {
    if (location.pathname === "/") {
      return "home";
    }
    return location.pathname.replace("/", "");
  }, [location]);

  const defaultKey = useMemo(() => {
    const { xs, sm, md, lg } = screens;
    if (xs || sm || md || lg) {
      return { defaultOpenKeys: [] };
    } else if (!collapsed) {
      return {
        defaultOpenKeys: ["datamanagement", "configure"],
      };
    }
  }, [collapsed, screens]);

  const LookerStudioConnecterId = useMemo(
    () => ({
      stagging:
        "AKfycbxz3KKPZxHraRwlEKNdTBO1F0zwdZq8ohhMPO1_nWR3q8R4Er7tV784AuxnQLizbsYkmQ",
      production:
        "AKfycbzzkKjlQkL7Ctz3yewjkRf1QLAgCmITmuMkrzSQQMDdJP2A3prUrUwATAc-suKkjoZGOg",
    }),
    []
  );

  const filteredSidenavItems = useMemo(
    () =>
      (props.menuItems ?? SiderMenu).filter(
        (item) => !item.permission || can(item.permission)
      ),
    [can, props.menuItems]
  );

  return (
    <>
      {/* Sider Container Start  */}
      <Sider
        collapsedWidth="80"
        trigger={null}
        width={300}
        collapsible
        collapsed={collapsed}
        className="sider"
      >
        <div className="explores-sider-menu">
          <div className="d-flex flex-column">
            {/* SchoolBI Logo  */}

            {!collapsed ? (
              <div className="logo-container">
                <img src={logo} className="logo m-0" alt="" />
              </div>
            ) : (
              <div className="collapsed-logo-container">
                <img src={BeeWhiteLogo} className="logo m-0" alt="" />
              </div>
            )}

            <Button
              type="primary"
              onClick={toggleCollapsed}
              className="collapse-navbar"
              size="small"
            >
              {!collapsed ? <LeftOutlined /> : <RightOutlined />}
            </Button>

            <div data-tour="tour__modules" className="sider-menu-container">
              {/* Menu Container Start */}
              <Menu
                theme="dark"
                mode="inline"
                selectedKeys={[selectedKey]}
                className="bg-none mt-18 main-menu"
                inlineIndent={10}
                inlineCollapsed={collapsed}
                defaultOpenKeys={
                  collapsed ? undefined : defaultKey?.defaultOpenKeys
                }
                items={filteredSidenavItems}
              />
            </div>
          </div>
          {/* Left Below honeycomb image */}
          <img
            src={BeeIcon}
            className="footer-logo position-absolute w-100 bottom-0"
            alt="Honeycomb"
          />

          <div className={classNames({ "footer-menu": !collapsed })}>
            <Menu
              theme="dark"
              mode="inline"
              selectedKeys={[selectedKey]}
              className="bg-none mt-18 main-menu"
              inlineIndent={10}
              inlineCollapsed={collapsed}
              getPopupContainer={(triggerNode) => {
                return triggerNode?.parentElement || triggerNode;
              }}
              items={FooterMenu}
            />

            <Menu
              theme="light"
              mode="vertical"
              className="vertical-sider-menu report-builder-menu px-2"
              inlineIndent={24}
              items={[
                {
                  key: "menu",
                  icon: <ReportBuilderIcon />,
                  label: (
                    <span className="color-white font-bold px-2 fs-18">
                      Report Builder
                    </span>
                  ),

                  children: [
                    {
                      key: "powerbi",
                      label: (
                        <Link
                          type="text"
                          to="https://schoolbi-public-static.s3.us-east-1.amazonaws.com/data-connetor/SchoolBI.mez"
                          className="font-bold color-white p-0"
                        >
                          Download PowerBI
                        </Link>
                      ),
                    },
                    {
                      key: "looker",
                      label: (
                        <Link
                          type="text"
                          to={`https://lookerstudio.google.com/datasources/create?connectorId=${
                            LookerStudioConnecterId?.[getEnv()]
                          }`}
                          className="font-bold color-white d-flex justify-content-start p-0"
                        >
                          Looker Studio
                        </Link>
                      ),
                    },
                  ],
                },
              ]}
            />
          </div>
        </div>
      </Sider>
      {/*  Sider Container Start  */}

      <LogoutModal
        logoutModalOpen={logoutModalOpen}
        setLogoutModalOpen={setLogoutModalOpen}
        {...props}
      />
    </>
  );
};

export default Sider;
