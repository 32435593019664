import {
  ListAccountRequestsQuery,
  ListPlansQuery,
  ListWebProfilessQuery,
} from "_generated_/graphql";
import { API } from "aws-amplify";
import {
  listAccountRequests,
  listWebProfiless,
  listPlans,
} from "graphql/queries";
import { StatusResult } from "./types";

const getAccountRequests = async (): Promise<
  ListAccountRequestsQuery["listAccountRequests"] | StatusResult
> => {
  return (await API.graphql({
    query: listAccountRequests,
  })) as ListAccountRequestsQuery["listAccountRequests"];
};

const getIntegrationsApi = async (
  inputData: object,
  nextToken: string
): Promise<ListWebProfilessQuery["listWebProfiless"] | StatusResult> => {
  return (await API.graphql({
    query: listWebProfiless,
    variables: { filter: inputData, nextToken: nextToken },
  })) as ListWebProfilessQuery["listWebProfiless"];
};

const getPlanListApi = async (
  filter: object,
  nextToken: string
): Promise<ListPlansQuery["listPlans"] | StatusResult> => {
  return (await API.graphql({
    query: listPlans,
    variables: { filter: filter, nextToken: nextToken },
  })) as ListPlansQuery["listPlans"];
};
export const SchoolBiApi = {
  getAccountRequests,
  getIntegrationsApi,
  getPlanListApi,
};
