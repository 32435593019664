import {
  ArrowLeftOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  FilterOutlined,
  MoreOutlined,
  RedoOutlined,
  UpCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Dropdown,
  Empty,
  Form,
  Image,
  Input,
  Menu,
  Popconfirm,
  Row,
  Select,
  Table,
  Tooltip,
  Typography,
} from "antd";
import { Images } from "assets/images/Images";
import CustomModal from "Common/Components/CustomAntD/Modal";
import InfoButton from "Common/Components/InfoButtons/InfoButton";
import SuccessfulModal from "Common/Components/Modals/SuccessfulModal";
import SkeletonTable from "Common/Skeleton/SkeletonTable";
import _ from "lodash";
import FilePreviewTable from "Pages/ManageFiles/FilePreviewTable";
import RefreshIntervalModal from "Pages/ManageFiles/RefreshIntervalModal";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import AdvacnedListSDK from "sdk/AdvancedListSDK";
import {
  buttonText,
  ManageFiles_Constants,
  advancedListText,
  MiscellaneousText,
} from "utility/constants";
import ApiResultModal from "Common/Components/ApiResultModal/ApiResultModal";
import { API, graphqlOperation } from "aws-amplify";
import {
  onUpdateAdvancedListDetail,
  onDeleteAdvancedListDetail,
} from "graphql/subscriptions";
import Loader from "Common/Components/Loader/Loader";
import classNames from "classnames";
import { getFieldKey } from "utility/commonMethods";
import Moment from "react-moment";
import useCustomBreakpoints from "utility/UseCustomBreakpoints";
import NotPurchasedModal from "Common/Components/NotPurchasedModal/NotPurchasedModal";

const AdvancedList = () => {
  const { t } = useTranslation();
  const screens = useCustomBreakpoints();

  const [advancedList] = Form.useForm();

  const { Title, Text } = Typography;
  const { Icons, AdvancedList } = Images;
  const { FileIcon } = Icons;
  const { ModalSvg, FileInProgressSvg, NoAdvancedList } = AdvancedList;

  const { Top_Record_Text } = ManageFiles_Constants?.Recent_Tab;
  const {
    Save,
    Cancel,
    Connect_Btn,
    Starting_Btn,
    Add_New,
    Done_Btn,
    Deleting_Text,
  } = buttonText;
  const {
    Manage_Advanced_List,
    View_Data_List,
    Advanced_List_Model_List,
    File_Id_List,
    Enter_Here_List,
    In_Progress_Text,
    Failed_Text,
  } = advancedListText;
  const { yesText } = MiscellaneousText;

  const { accountId: accountID } = useSelector((state) => state.loginReducer);

  const initialRepeatIntervalFormValue = {
    id: null,
    interval_type: "DAILY",
  };

  const { RefreshIntervalModalSvg } = Images?.ManageFiles;

  const [addNewModal, setAddNewModal] = useState(false);
  const [advancedListVisible, setAdvancedListVisible] = useState(false);
  const [fileInProgressModal, setFileInProgressModal] = useState(false);
  const [refreshIntervalModal, setRefreshIntervalModal] = useState(false);
  const [refreshIntervalSuccessModal, setRefreshIntervalSuccessModal] =
    useState(false);
  const [repeatIntervalForm, setRepeatIntervalForm] = useState(
    initialRepeatIntervalFormValue,
  );

  const [allAdvancedList, setAllAdvancedList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [advancedListColData, setAdvancedListColData] = useState([]);
  const [preConfigureIndex, setPreConfigureIndex] = useState(null);
  const [apiResultModal, setApiResultModal] = useState({
    message: "",
    isApiResultModelOpen: false,
    type: "failure",
  });
  const [editableFlag, setEditableFlag] = useState(false);
  const [advancedListConfiguration, setAdvancedListConfiguration] =
    useState(false);
  const [advancedListDataSource, setAdvancedListDataSource] = useState([]);
  const [fieldUpdated, setFieldUpdated] = useState([]);
  const [filePreviewTableColData, setFilePreviewTableColData] = useState([]);
  const [filePreviewTableData, setFilePreviewTableData] = useState([]);
  const [dataSetName, setDataSetName] = useState("");
  const [isDisableAdvancedList, setIsDisableAdvancedList] = useState(false);

  const { isAdvancedListsVisibility, currentConfigureRenderKey } = useSelector(
    (state) => state.settingReducer,
  );

  const [isOpen, setIsOpen] = useState(false);

  const { Refresh_Interval_Success_Modal_Text } =
    ManageFiles_Constants?.Recent_Tab;

  const handelOnChange = (value, item, type) => {
    item[type] = value;
    setFieldUpdated((pS) => {
      const isExist = pS.some((field) => field?.id === item?.id);
      if (isExist) {
        pS.filter((field) => field.id === item.id)[0][type] = value;
        return [...pS];
      }

      return [...pS, item];
    });
  };

  const handlePreview = useCallback(
    async ({ list_name, list_id }) => {
      setIsLoading(true);
      const body = {
        account: accountID,
        list_name,
        list_id,
      };

      setDataSetName({ list_name, list_id });

      await AdvacnedListSDK.getPreviewData(body)
        .then((res) => {
          if (res.length === 0) {
            setApiResultModal(() => ({
              message: "Advanced list is empty",
              isApiResultModelOpen: true,
              type: "failure",
            }));
            return;
          }

          const data = res?.map((item, index) => ({
            "Sr No": !index ? (
              <InfoButton
                title={"Default Column Names"}
                placement="topLeft"
                classNames="justify-content-start"
              />
            ) : (
              index
            ),
            ...item,
          }));
          setFilePreviewTableColData(data[0]);
          setFilePreviewTableData(data);
          setAdvancedListVisible(true);
        })
        .finally(() => setIsLoading(false));
    },
    [accountID]
  );

  const metricsAndDimensionsOptions = [
    {
      label: "Metrics",
      value: "METRICS",
    },
    {
      label: "Dimensions",
      value: "DIMENSION",
    },
  ];

  const dataTypesOptions = [
    {
      label: "Boolean",
      value: "BOOLEAN",
    },
    {
      label: "Date",
      value: "YEAR_MONTH_DAY",
    },
    {
      label: "Number",
      value: "NUMBER",
    },
    {
      label: "Text",
      value: "TEXT",
    },
  ];

  const advancedListPreviewTable = [
    {
      title: "Columns Name",
      dataIndex: "field_key",
      key: "columnName",
      width: "20%",
    },
    {
      title: "Metrics/Dimensions",
      dataIndex: "dimension_or_metrics",
      key: "metricsAndDimensions",
      width: "25%",
      render: (_, item) => (
        <Select
          options={metricsAndDimensionsOptions}
          defaultValue={_}
          onChange={(v) => handelOnChange(v, item, "dimension_or_metrics")}
        />
      ),
    },
    {
      title: "Data Type",
      dataIndex: "field_type_GDS",
      key: "dataType",
      width: "25%",
      render: (_, item) => (
        <Select
          options={dataTypesOptions}
          defaultValue={_}
          onChange={(v) => handelOnChange(v, item, "field_type_GDS")}
        />
      ),
    },
    {
      title: "Friendly Name",
      dataIndex: "friendly_name",
      key: "friendlyName",
      render: (_, item) => (
        <Form.Item>
          <Input
            defaultValue={_}
            onChange={(e) =>
              handelOnChange(e.target.value, item, "friendly_name")
            }
          />
        </Form.Item>
      ),
    },
  ];

  const onCellHelper = useCallback(
    (index, type) => {
      if (index === preConfigureIndex) {
        if (type === "list_name") {
          return { colSpan: 6 };
        } else {
          return { colSpan: 0 };
        }
      } else if (index >= preConfigureIndex && type === "list_name") {
        return { colSpan: 4 };
      } else if (
        index >= preConfigureIndex &&
        type !== "status" &&
        type !== "action"
      ) {
        return { colSpan: 0 };
      } else {
        return { colSpan: 1 };
      }
    },
    [preConfigureIndex]
  );

  const handleDeleteAdvancedList = async (item) => {
    setIsLoading(true);
    const { school_id, list_id, list_name, id } = item;

    const inputData = {
      school_id,
      list_id,
      list_name,
      id,
    };

    await AdvacnedListSDK.deleteAdvancedList(inputData);
    setIsLoading(false);
  };

  const advacnedListColumns = useMemo(
    () => [
      {
        title: "Add File Name/Id. Col",
        dataIndex: "list_name",
        key: "list_name",
        width: "25%",
        render: (_, { list_name, list_id, is_initial_sync, name }, index) => {
          return index === preConfigureIndex ? (
            <div className="preconfigure-title">{list_name}</div>
          ) : (
            <>
              <div
                className={classNames("dataset-name-container", {
                  "dataset-name-container-connected":
                    is_initial_sync === "CONNECTED",
                  "pe-none":
                    is_initial_sync !== "CONNECTED" ||
                    index >= preConfigureIndex,
                  "grey-color": index > preConfigureIndex,
                })}
                onClick={() => handlePreview({ list_name, list_id })}
              >
                {!list_name ? name : `${list_name} - ${list_id}`}
              </div>
            </>
          );
        },
        onCell: (_, index) => onCellHelper(index, "list_name"),
      },
      {
        title: "Data Source",
        dataIndex: "datasource",
        key: "datasource",
        width: "25%",
        render: (item) => _.startCase(_.toLower(item)),
        onCell: (_, index) => onCellHelper(index),
      },
      {
        title: "Recurring Status",
        dataIndex: "recurring_sync_status",
        key: "recurring_sync_status",
        width: "15%",
        render: (item, { last_sync_date }) => {
          return item ? (
            <>
              <div className="d-flex align-center">
                <UpCircleOutlined className="circle-icon green-circle" />
                <Text>{"Success"}</Text>
              </div>
            </>
          ) : last_sync_date ? (
            <>
              <div className="d-flex align-center">
                <UpCircleOutlined className="circle-icon red-circle" />
                <Text>{"Failure"}</Text>
              </div>
            </>
          ) : null;
        },
        onCell: (_, index) => onCellHelper(index),
      },
      {
        title: "Last Sync Date",
        dataIndex: "last_sync_date",
        key: "lastRanDate",
        width: "23%",
        render: (item) => (item ? <Moment format="lll" date={item} /> : null),
        onCell: (_, index) => onCellHelper(index),
      },
      {
        title: "Status",
        dataIndex: "is_initial_sync",
        key: "status",
        width: "20%",
        render: (_, item, index) => {
          return _ === "CONNECTED" ? (
            <div className="d-flex align-center">
              <UpCircleOutlined className="circle-icon green-circle" />
              <Text
                className={classNames({
                  "grey-color": index > preConfigureIndex,
                })}
              >
                {t(Connect_Btn)}
              </Text>
            </div>
          ) : _ === "DISCONNECTED" ? (
            <div className="d-flex align-center">
              <UpCircleOutlined className="circle-icon grey-circle" />
              <Text>{t(Starting_Btn)}</Text>
            </div>
          ) : _ === "INPROGRESS" ? (
            <div className="d-flex align-center">
              <UpCircleOutlined className="circle-icon grey-circle" />
              <Text>{t(In_Progress_Text)}</Text>
            </div>
          ) : _ === "DELETING" ? (
            <div className="d-flex align-center">
              <UpCircleOutlined className="circle-icon red-circle" />
              <Text>{t(Deleting_Text)}</Text>
            </div>
          ) : (
            <div className="d-flex align-center">
              <UpCircleOutlined className="circle-icon red-circle" />
              <Text>{t(Failed_Text)}</Text>
            </div>
          );
        },
        onCell: (_, index) => onCellHelper(index, "status"),
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        width: "5%",
        render: (_, item, index) => (
          <>
            {index < preConfigureIndex ? (
              <div className="d-flex justify-content-center cursor-pointer">
                <Dropdown
                  placement="topLeft"
                  menu={[
                    {
                      key: "interval",
                      label: (
                        <Button
                          type="text"
                          onClick={() => setRefreshIntervalModal(true)}
                        >
                          Sets Recurring Type
                        </Button>
                      ),
                    },
                    {
                      key: "delete",
                      label: (
                        <Button
                          type="text"
                          disabled={
                            item?.is_initial_sync === "DELETING" ||
                            item?.is_initial_sync === "DISCONNECTED" ||
                            item?.is_initial_sync === "INPROGRESS"
                          }
                          onClick={() => handleDeleteAdvancedList(item)}
                        >
                          Delete
                        </Button>
                      ),
                    },
                  ]}
                >
                  <MoreOutlined className="more-outline-style primary-color" />
                </Dropdown>
              </div>
            ) : null}
          </>
        ),
        onCell: (_, index) => onCellHelper(index, "action"),
      },
    ],
    [
      preConfigureIndex,
      Connect_Btn,
      Deleting_Text,
      Failed_Text,
      In_Progress_Text,
      Starting_Btn,
      handlePreview,
      onCellHelper,
      t,
    ],
  );

  const createUserAdvancedList = async () => {
    setIsLoading(true);
    const { advancedListIdAndName } = advancedList.getFieldsValue();
    let input = {
      account: accountID,
      datasource: "BLACKBAUD_ADVANCED_LIST_MANAGEMENT",
      preconfigure: false,
      list_detail: {},
    };

    await advancedListIdAndName.forEach(async (item) => {
      const [advancedListName, advancedListId] = item.split(":::");
      input["list_detail"][advancedListId] = advancedListName;
    });
    await AdvacnedListSDK.addAdvancedList(input);
    setIsLoading(false);
    setAddNewModal(false);
    setFileInProgressModal(true);
    advancedList.resetFields();
    await fetchAdvancedList();
  };

  const fetchAdvancedList = useCallback(async () => {
    if (isAdvancedListsVisibility) {
      return;
    }
    try {
      setIsLoading(true);

      const advancedListdata =
        await AdvacnedListSDK.getAllAdvancedList(accountID);

      const advancedListById =
        await AdvacnedListSDK.getAdvancedListByAccountId(accountID);

      const unselectedAdvancedList = advancedListById?.list_of_list
        ?.filter(
          (itemData) =>
            !advancedListdata.some(
              (itemFilter) => parseInt(itemFilter?.list_id) === itemData?.id,
            ),
        )
        ?.map(({ name, id }, index) => ({
          value: `${name}:::${id}`,
          label: name,
          key: `${name}:::${id}:::${index}`,
        }));

      setAllAdvancedList(unselectedAdvancedList);

      const advancedList = advancedListById?.preconfirmed_list?.map(
        ({ id, name }) => ({
          name,
          id,
          isPreconfige: true,
          is_initial_sync: "CONNECTED",
        }),
      );

      const preConfigureList = {
        list_name: "Pre configured Advanced List By SchoolBI",
      };

      setPreConfigureIndex(advancedListdata.length);

      const inProgressFiles = advancedListdata.filter(
        ({ is_initial_sync }) => is_initial_sync === "INPROGRESS",
      );
      const completedFiles = advancedListdata.filter(
        ({ is_initial_sync }) => is_initial_sync !== "INPROGRESS",
      );

      if (advancedList?.length > 0) {
        setAdvancedListColData([
          ...inProgressFiles,
          ...completedFiles,
          preConfigureList,
          ...advancedList,
        ]);
      } else {
        setAdvancedListColData([...inProgressFiles, ...completedFiles]);
      }
      setIsDisableAdvancedList(false);
    } catch (e) {
      setApiResultModal(() => ({
        message: e?.Message || "Something went wrong",
        isApiResultModelOpen: true,
        type: "failure",
      }));
      setIsDisableAdvancedList(true);
    } finally {
      setIsLoading(false);
    }
  }, [accountID, isAdvancedListsVisibility]);

  const handleUpdatedFields = async () => {
    setIsLoading(true);
    const promises = fieldUpdated.map(
      async ({ id, dimension_or_metrics, field_type_GDS, friendly_name }) => {
        const input = {
          id,
          dimension_or_metrics,
          field_type_GDS,
          friendly_name,
        };

        await AdvacnedListSDK.updateAdvancedListDetails(input);
      },
    );

    await Promise.all(promises);

    const { list_id, list_name } = dataSetName;
    await handlePreview({ list_id, list_name });

    setAdvancedListConfiguration(false);
    setFieldUpdated([]);
    setIsLoading(false);
  };

  const dynamicRowClassName = (row) =>
    row?.is_initial_sync === "INPROGRESS" ? "advanced-list-in-progress" : null;

  const handlerFilterIcon = async () => {
    setIsLoading(true);
    const body = {
      accountId: accountID,
      data_set: getFieldKey(dataSetName?.list_name) + "_MANAGEMENT_SYSTEM",
    };
    const data = await AdvacnedListSDK.getAdvancedListFields(body);
    setAdvancedListDataSource(data);
    setAdvancedListConfiguration(true);
    setIsLoading(false);
  };

  useEffect(() => {
    let updateSubscriber = API.graphql(
      graphqlOperation(onUpdateAdvancedListDetail, { id: accountID }),
    ).subscribe({
      next: (_) => {
        fetchAdvancedList();
      },
      error: (e) => {
        console.log(e);
      },
    });

    let deleteSubscriber = API.graphql(
      graphqlOperation(onDeleteAdvancedListDetail, { id: accountID }),
    ).subscribe({
      next: (_) => {
        fetchAdvancedList();
      },
      error: (e) => {
        console.log(e);
      },
    });

    fetchAdvancedList();
    return () => {
      updateSubscriber.unsubscribe();
      deleteSubscriber.unsubscribe();
    };
  }, [accountID, fetchAdvancedList]);

  useEffect(() => {
    if (
      currentConfigureRenderKey === "advanced-lists" &&
      isAdvancedListsVisibility
    ) {
      setIsOpen(true);
    }
  }, [currentConfigureRenderKey, isAdvancedListsVisibility]);

  return (
    <>
      {advancedListVisible ? (
        <Row gutter={[16, 16]} className="file-preview-table-container py-3">
          <Col
            span={24}
            className="d-flex gap-3 justify-content-between align-center"
          >
            <Title className="fs-18 m-0 d-flex gap-1 align-items-center ">
              <ArrowLeftOutlined
                onClick={() => setAdvancedListVisible(false)}
              />

              {t(Top_Record_Text)}
              {!editableFlag ? (
                <Tooltip
                  title={"Reset column names"}
                  placement="topLeft"
                  color="#F5DB5D"
                >
                  <Button
                    shape="circle"
                    type="primary"
                    className="dataset-edit-btn"
                    icon={<RedoOutlined />}
                    size="small"
                  />
                </Tooltip>
              ) : null}

              {isLoading ? <Loader /> : null}
            </Title>

            <div className="d-flex gap-2 align-items-center editable-btns-container">
              {!editableFlag ? (
                <>
                  <Button
                    shape="circle"
                    className="primary-bg dataset-edit-btn"
                    icon={<FilterOutlined />}
                    size={"small"}
                    onClick={() => handlerFilterIcon()}
                  />
                  <InfoButton
                    title={"User can use edit icon to rename columns headings"}
                    placement="topRight"
                  />
                </>
              ) : (
                <>
                  <Button
                    type="primary"
                    size="large"
                    icon={<CheckCircleOutlined />}
                    // onClick={() => setSaveConfirmation(true)}
                  >
                    {t(Save)}
                  </Button>
                  <Button
                    type="primary"
                    className="black-bg-btn"
                    size={"large"}
                    icon={<CloseCircleOutlined />}
                    // onClick={() => setEditableFlag(false)}
                  >
                    {t(Cancel)}
                  </Button>
                </>
              )}
            </div>
          </Col>
          <Col span={24}>
            <FilePreviewTable
              filePreviewTableData={filePreviewTableData}
              filePreviewTableColData={filePreviewTableColData}
              type="preview"
            />
          </Col>
        </Row>
      ) : (
        <>
          {isAdvancedListsVisibility ? (
            <>
              <Image
                src={NoAdvancedList}
                preview={false}
                className="filter-effect"
              />
              <NotPurchasedModal
                text={"Advanced List"}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
              />
            </>
          ) : (
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Row
                  className="d-flex gap-3"
                  align="middle"
                  justify="space-between"
                >
                  <div className="d-flex align-items-center gap-3">
                    <Image preview={false} src={FileIcon} alt="..." />
                    <Title level={4} className="data-source-title m-0">
                      {t(View_Data_List)}
                    </Title>
                    {isLoading ? <Loader /> : null}
                  </div>

                  <Button
                    type="primary"
                    onClick={() => setAddNewModal(true)}
                    disabled={isDisableAdvancedList}
                  >
                    {t(Add_New)}
                  </Button>
                </Row>
              </Col>
              <Col span={24}>
                <Table
                  columns={advacnedListColumns}
                  dataSource={advancedListColData}
                  className="managedatasets-table-container advanced-table-container"
                  pagination={false}
                  locale={{
                    emptyText: isLoading ? <SkeletonTable /> : <Empty />,
                  }}
                  rowClassName={(record) => dynamicRowClassName(record)}
                  scroll={{
                    x: 1000,
                  }}
                />
              </Col>
            </Row>
          )}
        </>
      )}

      <CustomModal
        title="Configure Advanced list Data"
        open={advancedListConfiguration}
        closable={true}
        centered
        width={
          screens?.xs || screens?.sm || screens?.md || screens?.lg
            ? "90%"
            : "50%"
        }
        footer={null}
        onCancel={() => setAdvancedListConfiguration(false)}
        className="carousel-container configure-table-modal refresh-interval-modal "
      >
        <Row justify="center" gutter={[24, 24]}>
          <Table
            columns={advancedListPreviewTable}
            dataSource={advancedListDataSource}
            pagination={false}
            className="configure-table-wrapper"
            scroll={{
              y: 425,
            }}
          />
          <Button
            type="primary"
            loading={isLoading}
            onClick={handleUpdatedFields}
          >
            {t(Done_Btn)}
          </Button>
        </Row>
      </CustomModal>

      <CustomModal
        open={addNewModal}
        closable={true}
        centered
        width={
          screens?.xs || screens?.sm || screens?.md || screens?.lg
            ? "90%"
            : "50%"
        }
        footer={null}
        onCancel={() => setAddNewModal(false)}
      >
        <Col span={24}>
          <Row justify="center">
            <div className="d-flex gap-3 align-items-center">
              <Image preview={false} src={ModalSvg} width={450} />
              <Title level={5} className="m-0 fw-bold">
                {t(Advanced_List_Model_List)}
              </Title>
            </div>
            <Col span={12}>
              <Form layout="vertical" form={advancedList}>
                <Form.Item
                  label={<span className=""> {t(File_Id_List)} </span>}
                  name="advancedListIdAndName"
                >
                  <Select
                    mode="multiple"
                    allowClear
                    // defaultOpen
                    className="mb_5 invitemebers"
                    tokenSeparators={[","]}
                    notFoundContent=""
                    options={allAdvancedList}
                    placeholder={
                      <span className="text-grey"> {t(Enter_Here_List)} </span>
                    }
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    loading={isLoading}
                    type="primary"
                    className="d-flex mx-auto"
                    htmlType="submit"
                    onClick={() => createUserAdvancedList()}
                  >
                    {t(Done_Btn)}
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Col>
      </CustomModal>

      <RefreshIntervalModal
        refreshIntervalModal={refreshIntervalModal}
        setRefreshIntervalModal={setRefreshIntervalModal}
        setRefreshIntervalSuccessModal={setRefreshIntervalSuccessModal}
        repeatIntervalForm={repeatIntervalForm}
        setRepeatIntervalForm={setRepeatIntervalForm}
      />

      <SuccessfulModal
        isShowSuccessfulModal={refreshIntervalSuccessModal}
        setIsShowSuccessfulModal={setRefreshIntervalSuccessModal}
        modalText={Refresh_Interval_Success_Modal_Text}
        successImage={RefreshIntervalModalSvg}
      />

      <SuccessfulModal
        isShowSuccessfulModal={fileInProgressModal}
        setIsShowSuccessfulModal={setFileInProgressModal}
        successImage={FileInProgressSvg}
        modalText="Your File is in progress"
      />

      <ApiResultModal
        apiResultModal={apiResultModal}
        setApiResultModal={setApiResultModal}
      />
    </>
  );
};

export default AdvancedList;
