//** React imports */
import {
  Col,
  Image,
  Row,
  Segmented,
  Select,
  Typography,
  DatePicker,
  Button,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { Images } from "assets/images/Images";
import "./Dashboard.less";
import { ArrowsAltOutlined } from "@ant-design/icons";
import BlackbaudRecurringChart from "./BlackbaudRecurringChart";
import RefreshRecurringInterval from "./RefreshRecurringInterval";
import QuotaLimit from "./QuotaLimit";
import ApiCallsChart from "./ApiCallsChart";
import DashboardSDK from "sdk/DashboardSDK";
import { useDispatch, useSelector } from "react-redux";
import Loader from "Common/Components/Loader/Loader";
import moment from "moment";
import { dashboardModule, modalText } from "utility/constants";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { setCurrentRenderKey } from "store/actions/settingAction";
import SuccessfulModal from "Common/Components/Modals/SuccessfulModal";
import CustomModal from "Common/Components/CustomAntD/Modal";

const Research = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { Title, Text } = Typography;
  const { RangePicker } = DatePicker;
  const {
    Recurring_Title,
    Refresh_Recurring_Title,
    No_Data_Found,
    No_Data_Source,
    No_Api_Calls,
    No_Worksheet_Text,
    Quota_Title,
    Quota_Text,
  } = dashboardModule;

  const { No_Plan_Active } = modalText;
  const { Subscribe_Plan_Now_Text, No_Plan_Active_Text, No_Plan_Active_Title } =
    No_Plan_Active;
  const { NoCustomFile, NoApiCalls, NoDataProvider } = Images.Dashboard;
  const { NoPlanActiveImg } = Images.Billing;
  const { NoDataSourceImg } = Images.DataSource;
  const { t } = useTranslation();
  const { accountId } = useSelector((state) => state.loginReducer); // Getting group from login reducer
  const [noPlanActiveModal, setNoPlanActiveModal] = useState(false);
  const [noDataSourcePurchasedModal, setNoDataSourcePurchasedModal] =
    useState(false);
  const [googleSheet, setGoogleSheet] = useState([]);
  const [datePickersHandler, setDatePickersHandler] = useState({
    apiCalls: [moment().subtract(1, "months"), moment()],
    blackbaudRecurringRefreshes: [moment().subtract(1, "months"), moment()],
  });

  const { schoolData } = useSelector((state) => state.loginReducer);
  console.log("SCHOOLDATA", schoolData);

  const [apiQuotaData, setApiQuotaData] = useState({
    data: [],
    renderData: 0,
    optionData: [],
  });

  const [blackbaudRecurringData, setBlackbaudRecurringData] = useState({
    data: {
      Day: [],
      Week: [],
      Month: [],
    },
    renderData: "Day",
  });

  const [recurringRefreshesData, setRecurringRefreshesData] = useState({
    data: {
      Day: {},
      Week: {},
      Month: {},
    },
    refreshRecurringTimeInterval: "Day",
    refreshRecurringGoogleSheetRender: [],
    refreshRecurringGoogleSheetTimeInterval: "days7",
  });

  const [apiCallsData, setApiCallsData] = useState({
    data: {
      Day: [],
      Week: [],
      Month: [],
    },
    renderData: "Day",
  });

  const [loader, setLoader] = useState({
    quotaData: true,
    blackbaudRecurringRefreshes: true,
    recurringRefreshes: true,
    apiCalls: true,
  });

  const [dataProviderSelection, setDataProviderSelection] = useState({
    options: [
      {
        value: "BlackbaudData",
        label: "BLACKBAUD",
      },
      {
        value: "VeracrossData",
        label: "VERACROSS",
      },
    ],
    defaultValue: "BlackbaudData",
  });

  const timeIntervalItem = [
    {
      value: "days7",
      label: "Last 7 days",
    },
    {
      value: "days30",
      label: "Last 30 days",
    },
    {
      value: "days90",
      label: "Last 90 days",
    },
  ];

  const refreshIntevalTimeInterval = [
    {
      value: "Day",
      label: "Daily",
    },
    {
      value: "Week",
      label: "Weekly",
    },
    {
      value: "Month",
      label: "Monthly",
    },
  ];

  const handleDatePicker = async (date, key) => {
    setDatePickersHandler((pS) => {
      pS[key] = date;
      return { ...pS };
    });

    switch (key) {
      case "blackbaudRecurringRefreshes":
        return await fetchBlackbaudRecurringData(
          dataProviderSelection?.defaultValue
        );
      case "apiCalls":
        return await fetchApiCallsChartData();
      default:
        return;
    }
  };

  const disabledDate = (current) => {
    return current && current >= moment().subtract(0, "days").endOf("day");
  };

  const handleLoader = (key, value) => {
    setLoader((pS) => {
      pS[key] = value;
      return { ...pS };
    });
  };

  const handleGoogleSheetOptionChange = (value) => {
    setRecurringRefreshesData((pS) => {
      pS.refreshRecurringGoogleSheetRender = [
        ...pS.refreshRecurringGoogleSheetRender,
        value,
      ];
      return { ...pS };
    });
  };

  function isObjectEmpty(obj) {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  const fetchQuotaData = useCallback(async () => {
    handleLoader("quotaData", true);
    const data = await DashboardSDK.getApiQuota(accountId);
    handleLoader("quotaData", false);

    setApiQuotaData((pS) => {
      pS.optionData = data.map((_, index) => ({
        label: `Key ${index + 1}`,
        value: index,
      }));

      pS.data = data;

      return { ...pS };
    });
  }, [accountId]);

  const fetchBlackbaudRecurringData = useCallback(
    async (syncFor) => {
      handleLoader("blackbaudRecurringRefreshes", true);
      const data = await DashboardSDK.getBlackbaudRecurringChartData(
        accountId,
        datePickersHandler.blackbaudRecurringRefreshes,
        syncFor
      );

      setBlackbaudRecurringData((pS) => {
        pS.data = data;
        return { ...pS };
      });

      handleLoader("blackbaudRecurringRefreshes", false);
    },
    [accountId, datePickersHandler.blackbaudRecurringRefreshes]
  );

  const fetchRecurringRefreshChartData = useCallback(async () => {
    handleLoader("recurringRefreshes", true);
    const data = await DashboardSDK.getRecurringRefreshChartData(accountId);

    setRecurringRefreshesData((pS) => {
      pS.data = data;
      return { ...pS };
    });

    handleLoader("recurringRefreshes", false);
  }, [accountId]);

  const fetchApiCallsChartData = useCallback(async () => {
    handleLoader("apiCalls", true);
    const data = await DashboardSDK.getApiCallsChartData(
      accountId,
      datePickersHandler.apiCalls
    );
    setApiCallsData((pS) => {
      pS.data = data;
      return { ...pS };
    });

    handleLoader("apiCalls", false);
  }, [accountId, datePickersHandler.apiCalls]);

  const getGoogleSheetData = useCallback(
    (key) => {
      return Object.keys(recurringRefreshesData?.data[key]).map((item) => {
        return {
          value: item,
          label:
            recurringRefreshesData?.data[key][item]["days7"]?.worksheet_name ||
            recurringRefreshesData?.data[key][item]["days30"]?.worksheet_name ||
            recurringRefreshesData?.data[key][item]["days90"]?.worksheet_name ||
            "Unknown Sheet",
        };
      });
    },
    [recurringRefreshesData?.data]
  );

  const fetchAccountDetails = useCallback(async () => {
    const { accountStatus, datasource } = schoolData;

    if (accountStatus !== "ACTIVE") {
      setNoPlanActiveModal(true);
    } else if (!datasource || datasource.length === 0) {
      setNoDataSourcePurchasedModal(true);
    }
  }, [schoolData]);

  useEffect(() => {
    fetchAccountDetails();
    fetchQuotaData();
    fetchBlackbaudRecurringData(dataProviderSelection?.defaultValue);
    fetchRecurringRefreshChartData();
    fetchApiCallsChartData();
  }, [
    dataProviderSelection?.defaultValue,
    fetchAccountDetails,
    fetchQuotaData,
    fetchBlackbaudRecurringData,
    fetchRecurringRefreshChartData,
    fetchApiCallsChartData,
  ]);

  useEffect(() => {
    setRecurringRefreshesData((pS) => {
      if (googleSheet.length > 0) {
        pS.refreshRecurringGoogleSheetRender = [googleSheet[0]["value"]];
      }

      return { ...pS };
    });
  }, [googleSheet]);

  useEffect(() => {
    setGoogleSheet(() => {
      return getGoogleSheetData(
        recurringRefreshesData?.refreshRecurringTimeInterval
      );
    });
  }, [
    recurringRefreshesData?.refreshRecurringTimeInterval,
    recurringRefreshesData?.data,
    getGoogleSheetData,
  ]);

  useEffect(() => {
    setRecurringRefreshesData((pS) => {
      if (googleSheet.length > 0) {
        pS.refreshRecurringGoogleSheetRender = [googleSheet[0]["value"]];
      }

      return { ...pS };
    });
  }, [
    recurringRefreshesData?.refreshRecurringTimeInterval,
    recurringRefreshesData?.data,
    googleSheet,
  ]);

  const handleNoPlanActiveCTA = () => {
    history.push("/school-settings");
    dispatch(setCurrentRenderKey("billing"));
  };

  const handleNoDataSourceCTA = () => {
    history.push("/integration");
  };

  return (
    <Row className="dashboard-container">
      <Col span={24} className="blackbaud-recurring-refresh-chart flex-col">
        <Row justify="space-between" align="middle" className="pe-3">
          <Title level={5} className="chart-title d-flex gap-2">
            {t(Recurring_Title)}
            {loader?.blackbaudRecurringRefreshes ? <Loader /> : null}
          </Title>
          <ArrowsAltOutlined className="dashboard-icon-container" />
        </Row>
        <Row justify="space-between" className="chart-title-container">
          <div className="dashboard-title-container">
            <Segmented
              disabled={
                blackbaudRecurringData?.data?.[
                  blackbaudRecurringData?.renderData
                ]?.length === 0
              }
              options={["Day", "Week", "Month"]}
              onChange={(value) =>
                setBlackbaudRecurringData((pS) => {
                  return { ...pS, renderData: value };
                })
              }
            />
            <RangePicker
              value={datePickersHandler.blackbaudRecurringRefreshes}
              onChange={(value) =>
                handleDatePicker(value, "blackbaudRecurringRefreshes")
              }
              disabledDate={disabledDate}
              disabled={
                blackbaudRecurringData?.data?.[
                  blackbaudRecurringData?.renderData
                ]?.length === 0
              }
            />
          </div>
          <div className="data-provider-container ">
            <Title level={5} className="chart-title">
              Platform :
            </Title>
            <Select
              value={dataProviderSelection?.defaultValue}
              style={{
                width: 150,
              }}
              onChange={(e) => {
                fetchBlackbaudRecurringData(e);
                setDataProviderSelection((pS) => ({ ...pS, defaultValue: e }));
              }}
              options={dataProviderSelection?.options}
            />
          </div>
        </Row>

        <Row className="pe-3">
          <Col span={24}>
            {blackbaudRecurringData?.data?.[blackbaudRecurringData?.renderData]
              ?.length === 0 ? (
              <Row
                justify="space-between"
                align="middle"
                className="mh-250px px-5"
              >
                <Col span={11} xs={24} sm={24} md={11}>
                  <Image
                    src={NoDataProvider}
                    preview={false}
                    alt="..."
                    className="h-300px"
                  />
                </Col>
                <Col span={13} xs={24} sm={24} md={13}>
                  <Row justify="center">
                    <Title
                      level={3}
                      className="title refresh-recurring-nosheet-title"
                    >
                      {t(No_Data_Found)}
                    </Title>
                    <Text className="text-center refresh-recurring-nosheet-text">
                      {t(No_Data_Source)}
                    </Text>
                  </Row>
                </Col>
              </Row>
            ) : (
              <BlackbaudRecurringChart
                data={
                  blackbaudRecurringData?.data?.[
                    blackbaudRecurringData?.renderData
                  ]
                }
              />
            )}
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <Row gutter={[20, 20]}>
          <Col span={14} xs={24} sm={24} lg={14}>
            <Row className="dashboard-bg m-0 h-100" gutter={[16, 16]}>
              {isObjectEmpty(recurringRefreshesData?.data?.Day) &&
              isObjectEmpty(recurringRefreshesData?.data?.Week) &&
              isObjectEmpty(recurringRefreshesData?.data?.Month) ? (
                <Col span={24} className="p-0">
                  <Title level={5} className="title">
                    {t(Refresh_Recurring_Title)}{" "}
                    {loader?.recurringRefreshes ? <Loader /> : null}
                  </Title>

                  <Row
                    justify="space-between"
                    align="middle"
                    className="h-100 px-5"
                  >
                    <Col span={11}>
                      <Image src={NoCustomFile} preview={false} alt="..." />
                    </Col>
                    <Col span={13}>
                      <Row justify="center">
                        <Title
                          level={3}
                          className="title refresh-recurring-nosheet-title"
                        >
                          {t(No_Data_Found)}
                        </Title>
                        <Text className="text-center refresh-recurring-nosheet-text">
                          {t(No_Worksheet_Text)}
                        </Text>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              ) : (
                <>
                  <Col span={24} className="p-0 flex-col gap-2">
                    <Row justify="space-between">
                      <div className="d-flex gap-3">
                        <Title level={5} className="title">
                          {t(Refresh_Recurring_Title)}
                        </Title>
                        {loader?.recurringRefreshes ? <Loader /> : null}
                      </div>
                      <ArrowsAltOutlined className="dashboard-icon-container" />
                    </Row>
                    <Row justify="start" gutter={[8, 8]}>
                      <Col span={5} xs={24} sm={5}>
                        <Title level={5} className="title">
                          Interval for
                        </Title>
                      </Col>
                      <Col span={5} xs={12} sm={5}>
                        <Select
                          defaultValue="Day"
                          style={{
                            width: "100%",
                          }}
                          onChange={(value) =>
                            setRecurringRefreshesData((pS) => {
                              pS.refreshRecurringTimeInterval = value;
                              return { ...pS };
                            })
                          }
                          options={refreshIntevalTimeInterval}
                          className="refresh-interval-time-select"
                        />
                      </Col>
                      <Col span={5} xs={12} sm={5}>
                        <Select
                          defaultValue={googleSheet?.[0]?.value}
                          style={{
                            width: "100%",
                          }}
                          allowClear
                          onChange={(value) =>
                            handleGoogleSheetOptionChange(value)
                          }
                          options={googleSheet}
                          maxTagCount="responsive"
                          className="sheet-select"
                        />
                      </Col>
                      <Col span={2} xs={12} sm={2}>
                        <Title level={5} className="title">
                          in
                        </Title>
                      </Col>
                      <Col span={6} xs={12} sm={6}>
                        <Select
                          defaultValue="days7"
                          style={{
                            width: "100%",
                          }}
                          onChange={(value) =>
                            setRecurringRefreshesData((pS) => {
                              pS.refreshRecurringGoogleSheetTimeInterval =
                                value;
                              return { ...pS };
                            })
                          }
                          options={timeIntervalItem}
                          className="historic-time-select"
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Row justify="middle" align="center">
                      <RefreshRecurringInterval
                        data={
                          recurringRefreshesData?.data?.[
                            recurringRefreshesData?.refreshRecurringTimeInterval
                          ]?.[
                            recurringRefreshesData
                              ?.refreshRecurringGoogleSheetRender[0]
                          ]?.[
                            recurringRefreshesData
                              ?.refreshRecurringGoogleSheetTimeInterval
                          ] || {}
                        }
                      />
                    </Row>
                  </Col>
                </>
              )}
            </Row>
          </Col>
          <Col span={10} xs={24} sm={24} lg={10}>
            <Row className="dashboard-bg m-0 h-100" gutter={[16, 16]}>
              <Col span={24} className="p-0 flex-col gap-2">
                <Row justify="space-between" align="center">
                  <div className="quota-limit-container">
                    <Title level={5} className="title">
                      {t(Quota_Title)}
                    </Title>
                    {loader.quotaData ? <Loader /> : null}
                  </div>
                  <ArrowsAltOutlined className="dashboard-icon-container" />
                </Row>
                <Row>
                  {apiQuotaData?.optionData.length > 0 ? (
                    <Segmented
                      options={apiQuotaData?.optionData}
                      onChange={(value) =>
                        setApiQuotaData((pS) => {
                          pS.renderData = value;
                          return { ...pS };
                        })
                      }
                    />
                  ) : null}
                </Row>
              </Col>
              <Col span={24}>
                <QuotaLimit
                  data={apiQuotaData.data?.[apiQuotaData?.renderData]}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <Row className="dashboard-bg" gutter={[16, 16]}>
          <Col span={24} className="flex-col gap-3">
            <Row justify="space-between">
              <div className="api-calls-chart-container">
                <Title level={5} className="title">
                  {t(Quota_Text)}
                </Title>
                {loader?.apiCalls ? <Loader /> : null}
              </div>
              <ArrowsAltOutlined className="dashboard-icon-container" />
            </Row>
            <Row>
              <div className="api-calls-chart-container">
                <Segmented
                  options={["Day", "Week", "Month"]}
                  onChange={(value) =>
                    setApiCallsData((pS) => {
                      pS.renderData = value;
                      return { ...pS };
                    })
                  }
                  disabled={
                    apiCallsData?.data?.[apiCallsData?.renderData].length === 0
                  }
                />
                <RangePicker
                  value={datePickersHandler.apiCalls}
                  onChange={(value) => handleDatePicker(value, "apiCalls")}
                  disabledDate={disabledDate}
                  disabled={
                    apiCallsData?.data?.[apiCallsData?.renderData].length === 0
                  }
                />
              </div>
            </Row>
          </Col>
          <Col span={24}>
            {apiCallsData?.data?.[apiCallsData?.renderData]?.length === 0 ? (
              <Row
                justify="space-between"
                align="middle"
                className="mh-250px px-5"
              >
                <Col span={11}>
                  <Image src={NoApiCalls} preview={false} alt="..." />
                </Col>
                <Col span={13}>
                  <Row justify="center">
                    <Title
                      level={3}
                      className="title refresh-recurring-nosheet-title"
                    >
                      {t(No_Data_Found)}
                    </Title>
                    <Text className="text-center refresh-recurring-nosheet-text">
                      {t(No_Api_Calls)}
                    </Text>
                  </Row>
                </Col>
              </Row>
            ) : (
              <ApiCallsChart
                data={apiCallsData?.data?.[apiCallsData?.renderData]}
              />
            )}
          </Col>
        </Row>
      </Col>

      <CustomModal
        open={noPlanActiveModal}
        centered
        width={500}
        footer={null}
        closable={false}
      >
        <Row justify={"center"} gutter={[20, 20]}>
          <Image src={NoPlanActiveImg} preview={false} />
          <Title level={3} className="mb-0">
            {" "}
            {t(No_Plan_Active_Title)}{" "}
          </Title>
          <Text className="text-center mine-shaft-color">
            {" "}
            {t(No_Plan_Active_Text)}{" "}
          </Text>
          <Button type="primary" onClick={() => handleNoPlanActiveCTA()}>
            {t(Subscribe_Plan_Now_Text)}
          </Button>
        </Row>
      </CustomModal>

      <SuccessfulModal
        isShowSuccessfulModal={noDataSourcePurchasedModal}
        setIsShowSuccessfulModal={setNoDataSourcePurchasedModal}
        modalTitle={"No Data Source Enabled"}
        successImage={NoDataSourceImg}
        okBtnClickHandler={handleNoDataSourceCTA}
        okBtnText={"Enable"}
        closable={false}
        width={"35%"}
      />
    </Row>
  );
};

export default Research;
