import {
  Avatar,
  Button,
  Card,
  Col,
  Collapse,
  Form,
  Image,
  Input,
  Progress,
  Row,
  Space,
  Typography,
  message,
} from "antd";
import CaretUp from "Common/Components/CaretUp/CaretUp";
import { SkeletonDataSource } from "Common/Skeleton/SkeletonDataSource";
import _, { difference, groupBy, mapValues, startCase, toLower } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { IntegrationSDK } from "sdk/IntegrationSDK";
import { Images } from "assets/images/Images";
import FileIcon from "assets/images/DataSourceFile.svg";
import { buttonText, MiscellaneousText, modalText } from "utility/constants";
import { useTranslation } from "react-i18next";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CheckCircleOutlined,
  CheckOutlined,
  CloseCircleOutlined,
  UpCircleOutlined,
} from "@ant-design/icons";
import useCustomBreakpoints from "utility/UseCustomBreakpoints";
import { Link, useHistory } from "react-router-dom";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import {
  getIntegrations,
  setManageFilesVisibility,
  setAdvancedListVisibility,
  setCurrentConfigRenderKey,
} from "store/actions/settingAction";
import SuccessfulModal from "Common/Components/Modals/SuccessfulModal";
import Loader from "Common/Components/Loader/Loader";
import { setAccountDetails } from "store/actions/loginAction";
import { getAccountForDataSourceCustom } from "graphql/customQueries";
import CustomModal from "Common/Components/CustomAntD/Modal";
import "./Integration.less";
import ApiResultModal from "Common/Components/ApiResultModal/ApiResultModal";
import { ManageFileIcon } from "utility/context/Menu";
import { Permission, usePermissions } from "../../../hooks/usePermissions";

const DataSource = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const screens = useCustomBreakpoints();
  const {
    BlackbaudImg,
    VeracrossImg,
    CustomDataImg,
    VeracrossConfigImg,
    CustomDataModelImg,
    AdvancedListDataSourceImg,
    RavennaImg,
  } = Images?.DataSource;

  const { Data_Source_Modal } = modalText;
  const { Keep_Selected_Text } = modalText?.Integration_Modal;

  const [acct, setAcct] = useState(null);
  const [selectedDataSource, setSelectedDataSource] = useState([]);
  const [currentSelectedDataSource, setCurrentSelectedDataSource] = useState(
    []
  );
  const [disableCounter, setDisableCounter] = useState(0);
  const [loading, setLoading] = useState(false);
  const [connectorModal, setConnectorModal] = useState(false);
  const [customDataSourceModal, setCustomDataSourceModal] = useState(false);
  const [allDataSources, setAllDataSources] = useState({});
  const [customFilesVisible, setCustomFilesVisible] = useState(true);
  const [veracrossConfirmation, setVeracrossConfirmation] = useState(false);
  const [veracrossDetailsModal, setVeracrossDetailsModal] = useState(false);
  const [ravennaDetailsModal, setRavennaDetailsModal] = useState(false);
  const [testConnectionModal, setTestConnectionModal] = useState(false);
  const [testConnectionDetails, setTestConnectionDetails] = useState({});

  const { accountId, selectedCompanyName } = useSelector(
    (state) => state.loginReducer
  );

  const { can, cannot } = usePermissions();
  const [apiResultModal, setApiResultModal] = useState({
    message: "",
    isApiResultModelOpen: false,
    type: "failure",
  });

  const [veracrossForm] = Form.useForm();
  const [revennaForm] = Form.useForm();

  const { integrations } = useSelector((state) => state.settingReducer);

  const available = [
    "student",
    "athletics",
    "enrollment",
    "raisers",
    "advanced",
    "packages",
  ];

  const getDataProviderKey = (key) =>
    _.map(_.toLower(key.replace(/_DATA$/, "")).split("_"), _.capitalize).join(
      " "
    );

  const getDataSourceName = (key) => {
    switch (key) {
      case "CSV":
        return "CSV";
      case "BLACKBAUD_RAISERS_EDGE_NXT":
        return "Blackbaud Raiser's Edge NXT";
      case "VERACROSS_RAISERS_EDGE_NXT":
        return "Veracross Raiser's Edge NXT";
      case "BLACKBAUD_FINANCIAL_EDGE_NXT":
        return "Blackbaud Financial Edge NXT";
      default:
        return _.map(_.toLower(key).split("_"), _.capitalize).join(" ");
    }
  };

  const getIsCustomFile = (dataProvider) =>
    dataProvider?.data_source_name === "CSV" ||
    dataProvider?.data_source_name === "GOOGLE_SHEET";

  const {
    Selected_Btn,
    Disconnect_Btn,
    Connect_Btn,
    Connected_Btn,
    Disconnected_Btn,
    Done_Btn,
    Yes_Sure_Text,
  } = buttonText;
  const { Data_Source_Text, Data_Source_Selected_Text, cancel } =
    MiscellaneousText;

  const handleVeracrossIntegration = async ({ ...params }) => {
    setLoading(true);
    await IntegrationSDK?.veracrossIntegrationHandler({
      ...params,
    });
    setLoading(false);
  };

  const handleRavennaDetails = async () => {
    const { ravennaAPIKey } = revennaForm.getFieldsValue();

    if (!ravennaAPIKey) {
      return setApiResultModal(() => ({
        message: "Enter Ravenna API key",
        isApiResultModelOpen: true,
        type: "failure",
      }));
    }
    setLoading(true);
    await IntegrationSDK.ravennaIntegrationHandler({
      code: ravennaAPIKey,
      accountId,
    })
      .then((res) => {
        if (res?.message) {
          return setApiResultModal(() => ({
            message: "Invalid API key! Unable to connect.",
            isApiResultModelOpen: true,
            type: "failure",
          }));
        }
        setApiResultModal(true);
        revennaForm.resetFields();
        fetchAllData();
        toggleRavennaModal();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleVeracrossDetails = async () => {
    setLoading(false);
    const {
      school_route,
      veracross_client_id,
      veracross_client_secret,
      scope,
    } = veracrossForm.getFieldsValue();

    if (
      !school_route ||
      !veracross_client_id ||
      !veracross_client_secret ||
      !scope
    ) {
      return message.error("Invalid veracross details");
    }

    const input = {
      school_id: accountId,
      school_route,
      veracross_client_id,
      veracross_client_secret,
      scope,
    };
    await IntegrationSDK.createVeracrossDetailsHandler(input)
      .then(() =>
        handleVeracrossIntegration({
          scope,
          route: school_route,
          client_id: veracross_client_id,
          client_secret: veracross_client_secret,
          school_id: accountId,
        })
      )
      .finally(() => {
        veracrossForm.resetFields();
        fetchAllData();
        toggleVeracrossModal();
      });
  };

  const fetchDataSource = useCallback(
    async (selectedDataSource = [], integrations) => {
      const data = await IntegrationSDK?.getDataSources();

      const output = {};

      data.forEach((item) => {
        const dataProvider = getDataProviderKey(item?.data_provider);

        if (output?.[dataProvider]) {
          output?.[dataProvider]?.["dataSource"]?.push(item);
        } else {
          output[dataProvider] = {};
          output[dataProvider]["dataSource"] = [item];
        }
      });

      const keys = Object.keys(output);

      const keyOrder = [
        ...keys.filter((item) => item !== "Custom Files"),
        "Custom Files",
      ];
      const reorderedData = {};
      for (const key of keyOrder) {
        reorderedData[key] = output?.[key];
        const currentSelectedDataSources = output?.[key]?.[
          "dataSource"
        ]?.filter((item) =>
          selectedDataSource?.includes(item.data_source_name)
        );
        const currentUnselectedDataSources = output?.[key]?.[
          "dataSource"
        ]?.filter(
          (item) => !selectedDataSource?.includes(item.data_source_name)
        );

        reorderedData[key]["dataSource"] = [
          ...currentSelectedDataSources,
          ...currentUnselectedDataSources,
        ];

        reorderedData[key]["integrations"] = integrations?.[key] || [];
      }

      setAllDataSources(reorderedData);
    },
    []
  );

  const fetchAccountDetails = useCallback(
    async (integrations) => {
      setLoading(true);
      if (accountId) {
        const {
          data: { getAccount },
        } = await API.graphql(
          graphqlOperation(getAccountForDataSourceCustom, { id: accountId })
        );

        setAcct(getAccount);
        const { datasource } = getAccount;

        await fetchDataSource(datasource || [], integrations);

        setSelectedDataSource(datasource || []);
        setCurrentSelectedDataSource(datasource || []);

        setCustomFilesVisible(
          !datasource?.some((item) => item === "CUSTOM_FILES")
        );
        dispatch(
          setManageFilesVisibility(
            true //!datasource?.some((item) => item === "CUSTOM_FILES")
          )
        );
        dispatch(
          setAdvancedListVisibility(
            !datasource?.some(
              (item) => item === "BLACKBAUD_ADVANCED_LIST_MANAGEMENT"
            )
          )
        );

        dispatch(setAccountDetails(getAccount));
        const temp = JSON.parse(getAccount?.metadata);
        const totalConnectors = parseInt(temp?.totalConnectors || 0);
        if (totalConnectors) {
          setDisableCounter(totalConnectors - datasource?.length);
        }
      }
      setLoading(false);
    },
    [accountId, dispatch, fetchDataSource]
  );

  const HandleGetConnectedCRMs = useCallback(async () => {
    setLoading(true);
    await dispatch(getIntegrations())
      .then(async (_) => {
        await fetchAccountDetails(_);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  }, [dispatch, fetchAccountDetails]);

  const connectorsHandler = (connector) => {
    console.log("connectorsHandler");
    if (
      currentSelectedDataSource &&
      currentSelectedDataSource?.length > 0 &&
      currentSelectedDataSource.some((item) => item === connector)
    ) {
      setCurrentSelectedDataSource((pS) =>
        pS?.filter((item) => item !== connector)
      );
      setDisableCounter((pS) => pS + 1);
    } else {
      setCurrentSelectedDataSource((pS) => {
        return [...pS, connector];
      });
      setDisableCounter((pS) => pS - 1);
    }

    if (connector?.toLowerCase()?.includes("custom")) {
      const data = currentSelectedDataSource?.filter((cE) =>
        cE?.toLowerCase()?.includes(connector?.toLowerCase())
      ).length;
      setCustomFilesVisible(() => Boolean(data));
    }
  };

  const getCurrentDataSourceLength = (key) =>
    selectedDataSource?.filter((cE) =>
      cE?.toLowerCase()?.includes(key?.toLowerCase())
    )?.length;

  const addDataSources = useCallback(
    async (tobeSendDataSource) => {
      const cognitoUser = await Auth?.currentAuthenticatedUser();

      setLoading(true);

      return await IntegrationSDK.addDataSources({
        datasources: tobeSendDataSource,
        account: acct?.id,
        userId: cognitoUser?.username,
      })
        .then(async () => {
          await fetchAccountDetails(integrations);
        })
        .finally(() => setLoading(false));
    },
    [acct?.id, fetchAccountDetails, integrations]
  );

  const onModalClose = () => {
    setConnectorModal(false);
  };

  const toggleVeracrossModal = () => setVeracrossDetailsModal((pS) => !pS);
  const toggleRavennaModal = () => setRavennaDetailsModal((pS) => !pS);
  const toggleTestConnectionModal = () => setTestConnectionModal((pS) => !pS);

  useEffect(() => {
    setLoading(true);
    const params = new URLSearchParams(window.location.search);
    async function fetchCRMData(option) {
      if (params.has("code")) {
        const code = params.get("code");
        var response = await API.get(
          "schoolBIREST",
          `/integrations/${option}/auth?code=${code}&account=${accountId}`
        );
        if (response?.errors || response?.error) {
          setApiResultModal(() => ({
            message: response?.errors || response?.error,
            isApiResultModelOpen: true,
            type: "failure",
          }));
        } else {
          await HandleGetConnectedCRMs();
        }
      }
    }

    const option = params.get("state") || "veracross";
    fetchCRMData(option);

    for (var key of params.keys()) {
      params.delete(key);
    }
    history.replace({
      search: params.toString(),
    });
    if (params.has("error")) {
      history.replace({
        search: "",
      });
    }
    setLoading(false);
  }, [HandleGetConnectedCRMs, accountId, history]);

  const fetchAllData = useCallback(async () => {
    setLoading(true);
    await HandleGetConnectedCRMs();
    setLoading(false);
  }, [HandleGetConnectedCRMs]);

  useEffect(() => {
    fetchAllData();
  }, [fetchAllData]);

  // CONNECT CRM
  const handleDataProviderConnection = async (platform) => {
    switch (platform) {
      case "Veracross":
        setLoading(true);
        const data = await IntegrationSDK?.getVeracrossDetailsHandler({
          accountId,
        });

        const client_id = data?.[0]?.veracross_client_id,
          school_route = data?.[0]?.school_route,
          scope = data?.[0]?.scope,
          client_secret = data?.[0]?.veracross_client_secret;

        if (!client_id || !school_route || !scope || !client_secret) {
          // setVeracrossConfirmation(true);
          setLoading(false);
          return toggleVeracrossModal();
        }

        handleVeracrossIntegration({
          scope,
          route: school_route,
          client_id: client_id,
          client_secret: client_secret,
          school_id: accountId,
        })
          .then(async () => await fetchAllData())
          .finally(() => setLoading(false));

        return;
      // Dev comment :-
      // return IntegrationSDK.handlerDataProviderConnection(platform, accountId)
      //   .then(() => {
      //     HandleGetConnectedCRMs();
      //   })
      //   .finally(() => setLoading(false));
      case "Blackbaud":
        const blackbaudUrl = `https://oauth2.sky.blackbaud.com/authorization?response_type=code&client_id=${process.env.REACT_APP_BLACKBAUD_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_BLACKBAUD_REDIRECT_URL}&state=blackbaud`;
        return window.open(blackbaudUrl);
      case "Ravenna":
        setRavennaDetailsModal(true);
        return;
      default:
        return;
    }
  };

  // DISCONNECT CRM
  const handleDataProviderDisonnection = async (platform) => {
    setLoading(true);
    await IntegrationSDK.handlerDataProviderDisconnection(platform, accountId);
    await HandleGetConnectedCRMs();
    setLoading(false);
  };

  const getDataSourceLogo = (key) => {
    switch (key) {
      case "Blackbaud":
        return BlackbaudImg;
      case "Veracross":
        return VeracrossImg;
      case "Custom Files":
        return CustomDataImg;
      case "Ravenna":
        return RavennaImg;
      default:
        return BlackbaudImg;
    }
  };

  const getPanelHeaderData = (key) => {
    const getPercentage = (key) => {
      if (key === "Custom Files") {
        return (selectedDataSource?.includes("CUSTOM_FILES") ? 1 : 0) * 100;
      }

      return (
        (getCurrentDataSourceLength(key) /
          allDataSources?.[key]?.dataSource?.length) *
        100
      );
    };
    return (
      <Row className="flex-col d-flex">
        <div className="title-wrapper">
          <div className="datasource-container-col-1">
            <div className="d-flex flex-row align-items-center text-center">
              <Avatar size={50} shape="square" src={getDataSourceLogo(key)} />
            </div>
            <div className="datasource-wrapper-col-1">
              <p className="fs-18 font-bold mb-0 ">{key}</p>
            </div>
          </div>
          <div className="datasource-container-col-2">
            <div className="d-flex flex-column w-40 ">
              <Progress
                percent={getPercentage(key)}
                type="line"
                strokeColor="#000000"
                trailColor="#D2DAE1"
                showInfo={false}
              />
              <Space>
                <div className="d-flex justify-content-between align-items-center">
                  <span>
                    {key === "Custom Files"
                      ? selectedDataSource?.includes("CUSTOM_FILES")
                        ? 2
                        : 0
                      : getCurrentDataSourceLength(key)}
                    /{allDataSources?.[key]?.dataSource?.length || 0}
                  </span>
                </div>
                <span className="italic">{t(Selected_Btn)}</span>
              </Space>
            </div>

            {key === "Custom Files" ? (
              customFilesVisible ? (
                <Button
                  type="primary"
                  className="btn-disable"
                  onClick={(e) => {
                    e.stopPropagation();
                    connectorsHandler(_.snakeCase(key).toUpperCase());
                  }}
                  disabled={
                    acct?.accountStatus === null ||
                    disableCounter === 0 ||
                    cannot(Permission.ConfigureDataSources)
                  }
                >
                  Enable
                </Button>
              ) : (
                <Button
                  type="primary"
                  className="disconnect-btn btn-disable"
                  onClick={(e) => {
                    e.stopPropagation();
                    connectorsHandler(_.snakeCase(key).toUpperCase());
                  }}
                  disabled={
                    acct?.accountStatus === null ||
                    cannot(Permission.ConfigureDataSources)
                  }
                >
                  Disable
                </Button>
              )
            ) : allDataSources?.[key]?.integrations?.length > 0 &&
              !allDataSources?.[key]?.integrations[0]?.disconnect ? (
              <Button
                type="primary"
                className="disconnect-btn"
                onClick={() => handleDataProviderDisonnection(key)}
                disabled={cannot(Permission.ConfigureDataSources)}
              >
                {t(Disconnect_Btn)}
              </Button>
            ) : (
              <Button
                type="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  handleDataProviderConnection(key);
                }}
                disabled={
                  acct?.accountStatus === null ||
                  cannot(Permission.ConfigureDataSources)
                }
              >
                {t(Connect_Btn)}
              </Button>
            )}
          </div>
        </div>
      </Row>
    );
  };

  const getPanelContentData = (key) => {
    const getPanelCardClassName = (dataProvider) => {
      return (
        " connector-card " +
        (disableCounter === 0 &&
        acct?.accountStatus !== null &&
        currentSelectedDataSource &&
        currentSelectedDataSource.length > 0 &&
        !currentSelectedDataSource.some((item) => {
          if (item === "CUSTOM_FILES") return getIsCustomFile(dataProvider);

          return item === dataProvider?.data_source_name;
        })
          ? "  "
          : "") +
        (getIsCustomFile(dataProvider) ? "  " : "") +
        (!available.some(
          (item) =>
            dataProvider?.data_source_name?.toLowerCase()?.includes(item) &&
            !dataProvider?.data_source_name
              ?.toLowerCase()
              ?.includes("veracross_athletics")
        ) || cannot(Permission.ConfigureDataSources)
          ? " "
          : "") +
        (acct?.accountStatus === null ? " " : "")
      );
    };

    switch (key) {
      // Dev Comment :-
      // case "Custom Data":
      //   return (
      //     <Row align="middle" className="d-flex gap-3 p-3">
      //       <Image src={DataSourceManageFilesImg} preview={false} />
      //       <Button
      //         disabled={isManageFilesVisibility}
      //         type="primary"
      //         className="btn-disable"
      //         onClick={() => {
      //           history.push("/managefiles");
      //         }}
      //       >
      //         {t(Manage_File_Btn)}
      //       </Button>
      //     </Row>
      //   );
      default:
        return (
          <>
            <CarouselProvider
              totalSlides={
                key === "Custom Files"
                  ? 3
                  : allDataSources[key]?.["dataSource"].length
              }
              visibleSlides={
                screens.xxxl
                  ? 6
                  : screens.xxl || screens.lg || screens.xl
                  ? 4
                  : screens.md
                  ? 2
                  : 1
              }
            >
              <div className="carousel-header-wrapper data-source-carousel">
                <div className="carousel-header"> {t(Data_Source_Text)} </div>

                <div className="carousel-btn-container">
                  <ButtonBack className="carousel-btn">
                    <ArrowLeftOutlined className="carousel-btn-icon" />
                  </ButtonBack>
                  <ButtonNext className="carousel-btn">
                    <ArrowRightOutlined className="carousel-btn-icon" />
                  </ButtonNext>
                </div>
              </div>
              <Slider>
                {allDataSources[key]?.["dataSource"].map(
                  (dataProvider, index) => {
                    return (
                      <>
                        <Slide index={index} key={index}>
                          <Card
                            className={getPanelCardClassName(dataProvider)}
                            key={index}
                            style={{
                              borderColor:
                                currentSelectedDataSource &&
                                currentSelectedDataSource.some((item) => {
                                  if (item === "CUSTOM_FILES")
                                    return getIsCustomFile(dataProvider);

                                  return (
                                    item === dataProvider?.data_source_name
                                  );
                                })
                                  ? "#ffc90a"
                                  : "",
                              // Dev comment :-
                              // pointerEvents: currentSelected.some(
                              //   (item) => item.name === connector.name
                              // )
                              //   ? "none"
                              //   : "pointer",
                            }}
                            //what???
                            onClick={() =>
                              connectorsHandler(dataProvider?.data_source_name)
                            }
                          >
                            {currentSelectedDataSource &&
                            currentSelectedDataSource.some((item) => {
                              if (item === "CUSTOM_FILES")
                                return getIsCustomFile(dataProvider);

                              return item === dataProvider?.data_source_name;
                            }) ? (
                              <Button
                                shape="circle"
                                icon={<CheckOutlined />}
                                className="close-icon"
                                type="primary"
                              ></Button>
                            ) : (
                              ""
                            )}

                            <Image
                              preview={false}
                              src={getDataSourceLogo(
                                getDataProviderKey(dataProvider?.data_provider)
                              )}
                              width={60}
                              alt="..."
                            />
                            <span className="connector-btn">
                              {getDataSourceName(
                                dataProvider?.data_source_name
                              )}
                            </span>
                            {dataProvider?.data_source_name ===
                              "BLACKBAUD_ADVANCED_LIST_MANAGEMENT" &&
                            currentSelectedDataSource.some((item) => {
                              return (
                                item === "BLACKBAUD_ADVANCED_LIST_MANAGEMENT"
                              );
                            }) ? (
                              <Typography.Text className="required-configuration-container">
                                Required Configuration
                              </Typography.Text>
                            ) : null}
                          </Card>
                          <div className="data-source-status-time-container">
                            <span className="data-source-status-container data-source-time-container">
                              {currentSelectedDataSource &&
                              currentSelectedDataSource.length > 0 &&
                              currentSelectedDataSource.some((item) => {
                                if (item === "CUSTOM_FILES")
                                  return getIsCustomFile(dataProvider);
                                return item === dataProvider?.data_source_name;
                              }) ? (
                                <>
                                  <UpCircleOutlined className="circle-icon green-circle" />{" "}
                                  {t(Connected_Btn)}
                                </>
                              ) : (
                                <>
                                  <UpCircleOutlined className="circle-icon red-circle" />
                                  {t(Disconnected_Btn)}
                                </>
                              )}
                            </span>
                            {/* <span className="data-source-time-container">
                              {currentSelectedDataSource &&
                              currentSelectedDataSource.length > 0 &&
                              currentSelectedDataSource.some(
                                (item) =>
                                  item === dataProvider?.data_source_name
                              ) ? (
                                <>
                                  {gettimeDiff(
                                    new Date(),
                                    new Date(),
                                    dataProvider?.[key]?.["integrations"][0][
                                      "last_sync_date"
                                    ]
                                  )}{" "}
                                  ago
                                </>
                              ) : (
                                "NA"
                              )}
                              {}
                            </span> */}
                          </div>
                        </Slide>
                        {/* 
                        {key === "Custom Files" && (
                          <Slide>
                            <Typography.Title
                              level={4}
                              className="text-center mb-0 bg-light-yellow p-3 "
                            >
                              You can upload google sheet link by navigating to
                              "Data Management" &gt; "Configure" &gt; "Custom
                              Files"
                            </Typography.Title>
                          </Slide>
                        )} */}
                      </>
                    );
                  }
                )}
              </Slider>
            </CarouselProvider>
          </>
        );
    }
  };

  const handleTestConnection = useCallback(
    async (datasources) => {
      let testResults = {};

      for (const item of datasources || []) {
        try {
          const data = await IntegrationSDK.testConnection({
            datasources: [item],
            account: accountId,
          });

          const groupedData = groupBy(
            data,
            (entry) => entry.api_details.data_source
          );
          const result = mapValues(groupedData, (entries) => ({
            data: entries,
            status: entries.some((entry) => entry.status === "FAILED")
              ? "FAILED"
              : "SUCCESS",
          }));

          testResults = { ...testResults, ...result };
        } catch (error) {
          console.error("Error testing connection for", item, error);
          testResults[item] = { data: [], status: "FAILED" };
        }
      }

      setTestConnectionDetails((prevState) => ({
        ...prevState,
        ...testResults,
      }));

      // Filter out failed data sources
      let tobeSendDataSource = currentSelectedDataSource.filter(
        (item) => testResults[item]?.status !== "FAILED"
      );

      if (
        tobeSendDataSource.some((item) =>
          item?.toLowerCase()?.includes("custom")
        ) &&
        !tobeSendDataSource.includes("CSV_FILES")
      ) {
        tobeSendDataSource.push("CSV_FILES");
      }

      await addDataSources(tobeSendDataSource);
      toggleTestConnectionModal();
    },
    [accountId, addDataSources, currentSelectedDataSource]
  );

  const handleTestConnectionDetails = useCallback(async () => {
    setLoading(true);
    await handleTestConnection(currentSelectedDataSource);
    setLoading(false);
  }, [currentSelectedDataSource, handleTestConnection]);

  const handleDataSourceSave = useCallback(async () => {
    setConnectorModal(false);
    setLoading(true);
    const delta = difference(currentSelectedDataSource, selectedDataSource);
    await handleTestConnection(delta);
    setLoading(false);
  }, [currentSelectedDataSource, selectedDataSource, handleTestConnection]);

  const handleCloseTestConnectionModal = useCallback(() => {
    setTestConnectionModal(false);
    setTestConnectionDetails(() => ({}));
  }, []);

  const onModalCloseHandler = useCallback(() => {
    setConnectorModal(true);
  }, []);

  return (
    <>
      {loading ? (
        <SkeletonDataSource />
      ) : (
        <>
          {selectedDataSource.some((item) => {
            return (
              item === "BLACKBAUD_ADVANCED_LIST_MANAGEMENT" ||
              item === "CUSTOM_FILES"
            );
          }) ? (
            <Row className="data-provider-heading">
              <Col span={24}>
                <Row
                  justify="space-between"
                  align="middle"
                  className="advanced-list-container"
                >
                  <Col>
                    <div className="d-flex gap-2 align-items-center">
                      <ManageFileIcon />
                      <Typography.Title level={5} className="m-0 fw-bold">
                        Manage & Configure
                      </Typography.Title>
                    </div>
                    {selectedDataSource.some(
                      (item) => item === "BLACKBAUD_ADVANCED_LIST_MANAGEMENT"
                    ) ? (
                      <div className="d-flex align-items-center">
                        <UpCircleOutlined className="circle-icon yellow-circle" />
                        <Typography.Title level={5} className="m-0 fs-14">
                          Data Management &gt; Configure &gt;{" "}
                          <Link
                            to={"/configure-data"}
                            className="primary-color text-decoration-underline cursor-pointer"
                            onClick={() =>
                              dispatch(
                                setCurrentConfigRenderKey("advanced-lists")
                              )
                            }
                          >
                            Advanced List
                          </Link>
                        </Typography.Title>
                      </div>
                    ) : null}

                    {selectedDataSource.some(
                      (item) => item === "CUSTOM_FILES"
                    ) ? (
                      <div className="d-flex align-items-center">
                        <UpCircleOutlined className="circle-icon yellow-circle" />
                        <Typography.Title level={5} className="m-0 fs-14">
                          Data Management &gt; Configure &gt;{" "}
                          <Link
                            to={"/configure-data"}
                            className="primary-color text-decoration-underline cursor-pointer"
                            onClick={() =>
                              dispatch(
                                setCurrentConfigRenderKey("custom-files")
                              )
                            }
                          >
                            Custom Files
                          </Link>
                        </Typography.Title>
                      </div>
                    ) : null}
                  </Col>
                  <Col>
                    <Image
                      src={AdvancedListDataSourceImg}
                      alt="..."
                      preview={false}
                      className="advanced-list-container-image"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          ) : null}

          <Row className="data-source-wrapper flex-col">
            <Row className="data-source-title-wrapper" justify="space-between">
              <div className="d-flex align-items-center gap-3">
                <Image preview={false} src={FileIcon} alt="..." />
                <Typography.Title level={3} className="data-source-title">
                  {selectedDataSource?.length} {t(Data_Source_Selected_Text)}{" "}
                  {loading ? <Loader /> : null}
                </Typography.Title>
              </div>
              {acct?.accountStatus !== null ? (
                <Row>
                  <Button
                    onClick={handleTestConnectionDetails}
                    className="me-3"
                  >
                    Test Connection
                  </Button>
                  <Button
                    type="primary"
                    onClick={onModalCloseHandler}
                    className="me-3 success-btn"
                    disabled={cannot(Permission.ConfigureDataSources)}
                  >
                    {t(Done_Btn)}
                  </Button>
                </Row>
              ) : null}
            </Row>

            <Collapse
              bordered={false}
              defaultActiveKey={["1"]}
              expandIcon={({ isActive }) => (
                <span className="p-3 m-0">
                  <CaretUp className={isActive ? "" : "rotate-90"} />
                </span>
              )}
              className="data-source-collapse data-source-box"
            >
              {Object.keys(allDataSources).map((dataSource) => {
                return (
                  <Collapse.Panel
                    key={dataSource}
                    className="data-source-panel"
                    header={getPanelHeaderData(dataSource)}
                  >
                    {getPanelContentData(dataSource)}
                  </Collapse.Panel>
                );
              })}
            </Collapse>
          </Row>

          <CustomModal
            open={connectorModal}
            closable={false}
            onCancel={onModalClose}
            centered
            width={400}
            footer={null}
            className="suspend-company-footer"
          >
            <div className="delete-modal-body">
              <Row justify="center">
                <Button
                  shape="circle"
                  icon={<CheckOutlined className="fs-24" />}
                  className="check-outline"
                />
              </Row>
              <Typography.Title level={5} className="text-center my-4">
                {t(Keep_Selected_Text)}
              </Typography.Title>
              <div className="w-100 d-flex">
                <Button className="secondary-btn w-50" onClick={onModalClose}>
                  Go Back
                </Button>
                <Button
                  onClick={handleDataSourceSave}
                  type="primary"
                  className="w-50"
                >
                  {t(Yes_Sure_Text)}
                </Button>
              </div>
            </div>
          </CustomModal>

          <SuccessfulModal
            successImage={CustomDataModelImg}
            isShowSuccessfulModal={customDataSourceModal}
            setIsShowSuccessfulModal={setCustomDataSourceModal}
            modalText={t(Data_Source_Modal)}
          />
        </>
      )}

      <SuccessfulModal
        successImage={VeracrossConfigImg}
        isShowSuccessfulModal={veracrossConfirmation}
        setIsShowSuccessfulModal={setVeracrossConfirmation}
        modalText={
          "Your Veracross school details are missing. Please contact the SchoolBI admin to resolve this."
        }
        imageWidth={300}
      />

      <ApiResultModal
        apiResultModal={apiResultModal}
        setApiResultModal={setApiResultModal}
      />

      <CustomModal
        open={ravennaDetailsModal}
        closable={true}
        centered
        onCancel={() => {
          toggleRavennaModal();
          setLoading(false);
        }}
        width={"40%"}
        footer={null}
        className="veracross-modal"
        title={
          <Typography.Title level={5} className="m-0">
            Provide Revanna Configuration
          </Typography.Title>
        }
      >
        <Image src={RavennaImg} alt="..." preview={false} width={150} />
        <Form autoComplete="off" form={revennaForm} layout="vertical">
          <Form.Item name="ravennaAPIKey" label={<strong></strong>}>
            <Input placeholder={"Enter ravenna API Key"} />
          </Form.Item>

          <div className="d-flex gap-3">
            <Form.Item className="submitBtn">
              <Button
                type="primary"
                htmlType="submit"
                onClick={handleRavennaDetails}
                loading={loading}
              >
                Submit
              </Button>
            </Form.Item>
            <Form.Item className="submitBtn">
              <Button>Cancel</Button>
            </Form.Item>
          </div>
        </Form>
      </CustomModal>

      <CustomModal
        open={veracrossDetailsModal}
        closable={true}
        onCancel={() => {
          toggleVeracrossModal();
          setLoading(false);
        }}
        centered
        width={"40%"}
        footer={null}
        className="veracross-modal"
        title={
          <Typography.Title level={5} className="m-0">
            Enter Veracross Details
          </Typography.Title>
        }
      >
        <>
          <Form autoComplete="off" form={veracrossForm} layout="vertical">
            <Form.Item
              name="veracross_client_id"
              label={<strong>Client Id</strong>}
            >
              <Input placeholder={"Enter client id"} />
            </Form.Item>
            <Form.Item
              name="veracross_client_secret"
              label={<strong>Client Secret</strong>}
            >
              <Input placeholder={"Enter client secret"} />
            </Form.Item>
            <Form.Item name="school_route" label={<strong>Route</strong>}>
              <Input placeholder={"Enter route"} />
            </Form.Item>
            <Form.Item name="scope" label={<strong>Scope</strong>}>
              <Input placeholder={"Enter scope (space separated)"} />
            </Form.Item>
          </Form>
          <div className="d-flex gap-3">
            <Form.Item className="submitBtn">
              <Button
                type="primary"
                htmlType="submit"
                onClick={handleVeracrossDetails}
                loading={loading}
              >
                Submit
              </Button>
            </Form.Item>
            <Form.Item className="submitBtn">
              <Button>Cancel</Button>
            </Form.Item>
          </div>
        </>
      </CustomModal>

      <CustomModal
        visible={testConnectionModal}
        closable={false}
        onCancel={handleCloseTestConnectionModal}
        centered
        width={"40%"}
        footer={null}
        title={
          <Typography.Title level={5} className="m-0">
            Test Connection Details
          </Typography.Title>
        }
      >
        <>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Collapse bordered={false} expandIcon={() => false}>
                {Object.entries(testConnectionDetails)?.map(([key, value]) => {
                  return (
                    <>
                      <Collapse.Panel
                        header={
                          <Row className="gap-1">
                            {value?.status === "SUCCESS" ? (
                              <CheckCircleOutlined className="success-color" />
                            ) : (
                              <CloseCircleOutlined className="warning-color" />
                            )}
                            {startCase(toLower(key))}
                          </Row>
                        }
                      >
                        {value?.status === "SUCCESS" ? (
                          <div>200 - Successful</div>
                        ) : (
                          value?.data?.map(({ response, alias }) =>
                            response ? (
                              <div>
                                {alias} - {response?.status_code || 200} -{" "}
                                {response?.message || "Successful"}
                              </div>
                            ) : null
                          )
                        )}
                      </Collapse.Panel>
                    </>
                  );
                })}
              </Collapse>
            </Col>
            <Col span={24}>
              <Row justify="center">
                <Button type="primary" onClick={handleCloseTestConnectionModal}>
                  Done
                </Button>
              </Row>
            </Col>
          </Row>
        </>
      </CustomModal>
    </>
  );
};

export default DataSource;
