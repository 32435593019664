import React, { PropsWithChildren, useMemo } from "react";
import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { useSelector } from "react-redux";
import { LoginReducerState } from "../store/reducers/loginReducer";

type ApolloProviderProps = PropsWithChildren & { authToken?: string };

const AuthorizedApolloProvider = ({
  children,
  authToken,
}: ApolloProviderProps) => {
  const { token: reducerToken } = useSelector(
    (state: { loginReducer: LoginReducerState }) => state.loginReducer
  );

  const token = authToken || reducerToken;

  const client = useMemo(() => {
    const link = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_SERVER });
    const authLink = setContext((_, { headers }) => ({
      headers: {
        ...headers,
        "Access-Control-Allow-Origin": "*",
        authorization: token ? `Bearer: ${token}` : undefined,
      },
    }));

    console.log("setting up client for ", process.env.REACT_APP_GRAPHQL_SERVER);

    return new ApolloClient({
      uri: process.env.REACT_APP_GRAPHQL_SERVER,
      cache: new InMemoryCache(),
      link: authLink.concat(link),
    });
  }, [token]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default AuthorizedApolloProvider;
