import React from "react";
import { Typography, Image } from "antd";
import { Images } from "assets/images/Images";
import { CloseOutlined } from "@ant-design/icons";
import CustomModal from "../CustomAntD/Modal";

const ApiResultModal = ({ apiResultModal, setApiResultModal }) => {
  const { ModalImg } = Images;
  const { SuccessModalImg, FailureModalImg } = ModalImg;

  const handleCancel = () =>
    setApiResultModal(() => ({
      message: "",
      isApiResultModelOpen: false,
    }));

  const SuccessImage = {
    success: SuccessModalImg,
    failure: FailureModalImg,
  };

  return (
    <>
      <CustomModal
        open={apiResultModal?.isApiResultModelOpen}
        closable={true}
        centered
        width={450}
        footer={null}
        onCancel={() => handleCancel()}
      >
        <div className="api-result-modal">
          <Image
            preview={false}
            src={SuccessImage[apiResultModal?.type || "failure"]}
            height={200}
            width={200}
          />
          <Typography.Text className="d-flex typography-title pt_13 mb-0">
            {apiResultModal?.message}
          </Typography.Text>
        </div>
      </CustomModal>
    </>
  );
};

export default ApiResultModal;
