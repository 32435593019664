import React, { useCallback, useState } from "react";
import { QsdashboardDetail } from "_generated_/graphql";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { AddEditDashboardModal } from "../AddEditDashboardModal/AddEditDashboardModal";
import { API, graphqlOperation } from "aws-amplify";
import { deleteQsdashboardDetail } from "graphql/mutations";
import ConfirmationModal from "../../../Common/Components/Modals/ConfirmationModal";

interface DashboardItemProps {
  dashboard: QsdashboardDetail;
  onChange: () => void;
}

export const DashboardItem: React.FC<DashboardItemProps> = ({
  dashboard,
  onChange,
}) => {
  const [edit, setEdit] = useState<boolean>(false);
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

  const toggleEdit = useCallback(() => {
    setEdit((prev) => !prev);
    onChange();
  }, [onChange]);

  const handleConfirmDelete = useCallback(() => setConfirmDelete(true), []);
  const handleConfirmClose = useCallback(() => setConfirmDelete(false), []);

  const handleDelete = useCallback(async () => {
    await API.graphql(
      graphqlOperation(deleteQsdashboardDetail, {
        input: {
          id: dashboard.id,
        },
      })
    );
    onChange();
  }, [dashboard.id, onChange]);

  return (
    <Stack direction="row" alignItems="center" alignSelf="stretch">
      <Box sx={{ flexGrow: 1 }}>
        <Typography>{dashboard.dashboard_name}</Typography>
      </Box>
      <IconButton>
        <Edit onClick={toggleEdit} />
      </IconButton>
      <IconButton>
        <Delete onClick={handleConfirmDelete} />
      </IconButton>
      <AddEditDashboardModal
        dashboard={dashboard}
        open={edit}
        onClose={toggleEdit}
      />
      <ConfirmationModal
        open={confirmDelete}
        onClose={handleConfirmClose}
        onConfirm={handleDelete}
        title={"Delete dashboard"}
        message={`Are you sure you want to delete the dashboard ${dashboard.dashboard_name_public}' dashboard?`}
        buttonText={"Delete"}
        buttonColor={"warning"}
      />
    </Stack>
  );
};

export default DashboardItem;
