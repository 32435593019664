export const listEnumValues = `query ListEnumValues($enum: String!) {
    enum: __type(name: $enum) {
      enumValues {
        name
      }
    }
  }`;

export const getUserCustom = /* GraphQL */ `
  query GetUserCustom($id: ID!) {
    getAccount(id: $id) {
      id
      disabled
      users
      email
      createdAt
      updatedAt
      accountStatus
    }
  }
`;

export const updateAccountPIICustom = /* GraphQL */ `
  mutation UpdateAccountPIICustom(
    $input: UpdateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    updateAccount(input: $input, condition: $condition) {
      piiInformation
    }
  }
`;

export const updateAccountEmailCustom = /* GraphQL */ `
  mutation UpdateAccountEmailCustom(
    $input: UpdateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    updateAccount(input: $input, condition: $condition) {
      email
    }
  }
`;

export const updateDataSetupCustom = /* GraphQL */ `
  mutation UpdateDataSetupCustom(
    $input: UpdateDataSetupInput!
    $condition: ModeldataSetupConditionInput
  ) {
    updateDataSetup(input: $input, condition: $condition) {
      field_key
      name_by_school
    }
  }
`;

export const listDataSetupsCustom = /* GraphQL */ `
  query ListDataSetupsCustom(
    $filter: ModeldataSetupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDataSetups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        field_key
        friendly_name
        name_by_school
      }
      nextToken
    }
  }
`;

export const dataSetupByWorksheetId = /* GraphQL */ `
  query DataSetupByWorksheetIdCustom(
    $worksheet_id: String
    $sortDirection: ModelSortDirection
    $filter: ModeldataSetupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    DataSetupByWorksheetId(
      worksheet_id: $worksheet_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        school_id
        dimension_or_metrics
        field_key
        friendly_name
        field_description
        field_type_GDS
        field_type_PBI
        data_source
        data_set
        api_endpoint
        name_by_school
        worksheet_name
        googlesheet_name
        worksheet_id
        googlesheet_id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listAccountsCustom = /* GraphQL */ `
  query ListAccountsCustom(
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        company
        company_loweredcased
        company_description
        url
        disabled
        piiInformation
        isSchoolAdmin
        email
        users
        createdAt
        updatedAt
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        plan {
          id
          name
          planDetails
          Targets
          bulletPoints
          listed
          order
          createdAt
          updatedAt
        }
        planID
        metadata
        owners
        editors
        viewers
        datasource
        dataprovider
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const listQsdashboardDetailsCustom = /* GraphQL */ `
  query ListQsdashboardDetailsCustom(
    $filter: ModelQsdashboardDetailFilterInput
    $limit: Int
    $nextToken: String
    $userId: ID
  ) {
    listQsdashboardDetails(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        school_id
        dashboard_id
        dashboard_name
        dashboard_name_public
        dashboard_description
        dashboard_type
        topic_id
        author
        tags
        isFavoriteOf(filter: { userId: { contains: $userId } }) {
          items {
            dashboard_oid
            resourceType
            userId
            id
          }
          nextToken
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const getAccountForSchoolDetailCustom = /* GraphQL */ `
  query GetAccountForSchoolDetailCustom($id: ID!) {
    getAccount(id: $id) {
      id
      company
      url
      disabled
      piiInformation
    }
  }
`;

export const updateAccountSuspendCustom = /* GraphQL */ `
  mutation UpdateAccountSuspendCustom(
    $input: UpdateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    updateAccount(input: $input, condition: $condition) {
      disabled
    }
  }
`;

export const getAccountForDataSourceCustom = /* GraphQL */ `
  query GetAccountForDataSourceCustom($id: ID!) {
    getAccount(id: $id) {
      id
      accountStatus
      datasource
      metadata
    }
  }
`;

export const getAccountForGoogleSheetCustom = /* GraphQL */ `
  query GetAccountForGoogleSheetCustom($id: ID!) {
    getAccount(id: $id) {
      id
      users
      request_googlesheet {
        items {
          id
          school_id
          googlesheet_link
          googlesheet_name
          googlesheet_id
          worksheet_name
          worksheet_id
          unique_field
          is_obtained
          interval_type
          custom_interval
          is_cancelled
          custom_type
          created_by
          last_success_sync_date
          last_failure_sync_date
          refresh_interval_status
          custom_value
          table_name
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;

export const getAccountForDataSetCustom = /* GraphQL */ `
  query GetAccountForDataSetCustom($id: ID!) {
    getAccount(id: $id) {
      id
      dataSet {
        items {
          id
          school_id
          data_set
          data_source
          purchased_date
          status
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;

export const getAccountInitCustom = /* GraphQL */ `
  query getAccountInitCustom($id: ID!) {
    getAccount(id: $id) {
      id
      company
      url
      disabled
      email
      planID
      metadata
      piiInformation
      datasource
      accountStatus
    }
  }
`;

export const getAccountForUserDetailsCustom = /* GraphQL */ `
  query GetAccountForUserDetailsCustom($id: ID!) {
    getAccount(id: $id) {
      id
      users
      email
      requests {
        items {
          id
          account
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      company
    }
  }
`;
