import React, {
  useState,
  useEffect,
  useCallback,
  PropsWithChildren,
} from "react";
import { useHistory, withRouter } from "react-router-dom";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { Layout } from "antd";
import {
  logout,
  setPermission,
  updateCompanyAccount,
  setViewType,
  setTheme,
  setIsScopeNormalUser,
  setSchoolData,
  setAccountDetails,
} from "store/actions/loginAction";
import { connect, useDispatch, useSelector } from "react-redux";
import { Navbar, Sider } from "Common/Layouts/Layouts";
import "Common/Layouts/Layout.less";
import UnauthorizedModal from "Common/Components/Modals/UnauthorizedModal";
import { MiscellaneousText } from "utility/constants";
import { useTranslation } from "react-i18next";
import sdk from "sdk/Accounts";
import ScopeContainer from "Common/Layouts/ScopeContainer";
import {
  setAdvancedListVisibility,
  setManageFilesVisibility,
} from "store/actions/settingAction";
import { hasTargetDataSource } from "utility/commonMethods";
import { onUpdateAccount } from "graphql/subscriptions";
import { LoginReducerState } from "../store/reducers/loginReducer";
import { ItemType } from "antd/lib/menu/interface";
import { Permission } from "../hooks/usePermissions";

//** Destructing Ant Components */
const { Content, Footer } = Layout;

const initialValue = {
  isLoading: true,
  settingsMenuOpen: false,
  darkTheme: true,
  searchBarHandler: false,
  searchView: false,
};

type MainLayoutProps = PropsWithChildren &
  Pick<
    LoginReducerState,
    | "groups"
    | "selectedCompanyName"
    | "admin_groups_self"
    | "userPermission"
    | "viewType"
    | "themeMode"
    | "accountId"
  > & {
    prop: unknown;
    location: string;
    logOut: () => void;
    updateCompanyAccount: (account: unknown) => void;
    setPermission: (permisison: unknown) => void;
    setViewType: (viewType: unknown) => void;
    setTheme: (theme: unknown) => void;
    menuItems?: Array<ItemType & { permission: null | Permission }>;
  };

/**
 * MainLayout component
 */
const MainLayout: React.FC<MainLayoutProps> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { themeMode, schoolData } = useSelector(
    (state: { loginReducer: LoginReducerState }) => state.loginReducer
  );
  const { t } = useTranslation();

  const [state, setState] = useState(initialValue);
  const toggleCollapsed = () => {
    dispatch(setTheme(!themeMode));
  };

  const {
    accountId: accountID,
    groups,
    selectedCompanyName,
    admin_groups_self,
    viewType,
    menuItems,
  } = props;

  const { version } = MiscellaneousText;

  const switchAccountSelf = async (account) => {
    const result = await Auth.currentSession();
    console.log(result);
    await props.setPermission(result.getIdToken().payload);
    props.setViewType("admin");
    dispatch(setIsScopeNormalUser(false));
    await props.updateCompanyAccount({ id: account[0] });
    history.push("/");
  };

  const toggleSettingsMenuOpen = (flag) => {
    setState((pS) => ({ ...pS, settingsMenuOpen: flag }));
  };

  const toggleSearchView = (flag) => {
    setState((pS) => ({ ...pS, searchView: flag }));
  };

  const fetchAccountData = useCallback(async () => {
    if (!accountID) return;

    try {
      const account = await sdk.fetchAccount(accountID);

      function updateVisibility(datasource, target, action) {
        dispatch(action(!hasTargetDataSource(datasource, target)));
      }

      updateVisibility(
        account?.datasource,
        "custom_files",
        setManageFilesVisibility
      );
      updateVisibility(
        account?.datasource,
        "blackbaud_advanced_list_management",
        setAdvancedListVisibility
      );

      dispatch(setAccountDetails(account));
      dispatch(setSchoolData(account));
    } catch (e) {
      console.error(e);
    }
  }, [accountID, dispatch]);

  useEffect(() => {
    switch (window.location.pathname) {
      case "/":
        setState((pS) => ({ ...pS, selectedKey: "home" }));
        break;
      default:
        setState((pS) => ({
          ...pS,
          selectedKey: window.location.pathname.replace("/", ""),
        }));
        break;
    }
  }, []);

  useEffect(() => {
    if (!accountID) return;
    const AccountSubscriber = API.graphql(
      graphqlOperation(onUpdateAccount, { id: accountID })
      // @ts-ignore
    ).subscribe({
      next: () => {
        fetchAccountData();
      },
      error: (error) => {
        console.error("Subscription error:", error);
      },
    });

    if (!schoolData || Object.keys(schoolData)?.length === 0) {
      fetchAccountData();
    }

    return () => {
      AccountSubscriber.unsubscribe();
    };
  }, [fetchAccountData, accountID, schoolData]);

  return (
    <>
      <div id="menu-container" className={"slidermenu darkMode"}>
        <ScopeContainer
          groups={groups}
          selectedCompanyName={selectedCompanyName}
          admin_groups_self={admin_groups_self}
          switchAccountSelf={switchAccountSelf}
        />

        <Layout className="layout-wrapper">
          <Sider
            collapsed={themeMode}
            toggleCollapsed={toggleCollapsed}
            setActiveKey={() => {}}
            menuItems={menuItems}
          />

          <Layout
            id="site-layout"
            className={themeMode ? "layoutCollapsed" : "layout"}
          >
            <Navbar
              groups={groups}
              selectedCompanyName={selectedCompanyName}
              admin_groups_self={admin_groups_self}
              viewType={viewType}
              searchBarHandler={state.searchBarHandler}
              searchView={state.searchView}
              location={props.location}
              toggleSettingsMenuOpen={toggleSettingsMenuOpen}
              toggleSearchView={toggleSearchView}
              switchAccountSelf={switchAccountSelf}
            />

            <Content className="mainLayout">{props.children}</Content>
            <Footer className="main-layout-footer">
              {t(version)}{" "}
              {process.env.REACT_APP_VERSION
                ? process.env.REACT_APP_VERSION.replace(/^0/, "1")
                : "0.1.0"}{" "}
              | SchoolBI © {new Date().getFullYear()}
            </Footer>
          </Layout>
        </Layout>
      </div>

      <UnauthorizedModal />
    </>
  );
};

const mapStateToProps = (state) => {
  const {
    groups,
    selectedCompanyName,
    admin_groups_self,
    userPermission,
    viewType,
    themeMode,
    accountId,
  } = state.loginReducer;
  return {
    prop: state.prop,
    groups,
    selectedCompanyName,
    admin_groups_self,
    userPermission,
    viewType,
    themeMode,
    accountId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logOut: () => dispatch(logout()),
    updateCompanyAccount: (account) => dispatch(updateCompanyAccount(account)),
    setPermission: (permission) => dispatch(setPermission(permission)),
    setViewType: (viewType) => dispatch(setViewType(viewType)),
    setTheme: (themeMode) => dispatch(setTheme(themeMode)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MainLayout)
);
