import React, { useEffect, useState } from "react";
import { Images } from "assets/images/Images";
import { Form, Image, Row, Select, Typography } from "antd";
import { ManageFiles_Constants } from "utility/constants";
import { useTranslation } from "react-i18next";

const UploadedMultiWorksheet = ({
  multiWorksheetOption,
  handleWorkSheetConfig,
  selectedWorksheet,
}) => {
  const [multiWorkSheetForm] = Form.useForm();
  const { UploadedMultiWorkSheetSvg } = Images?.ManageFiles;
  const { Title } = Typography;
  const { t } = useTranslation();

  const { UploadedMultiWorksheetText_Part1, UploadedMultiWorksheetText_Part2 } =
    ManageFiles_Constants;

  useEffect(() => {
    multiWorkSheetForm?.setFieldsValue({
      workSheetSelect: selectedWorksheet || multiWorksheetOption?.[0],
    });
  }, [selectedWorksheet, multiWorksheetOption, multiWorkSheetForm]);

  return (
    <Row align="middle" className="flex-col" gutter={[24, 24]}>
      <Image src={UploadedMultiWorkSheetSvg} preview={false} />
      <div>
        <Title level={5} className="m-0 text-center">
          {t(UploadedMultiWorksheetText_Part1)}
        </Title>
        <Title level={5} className="m-0 text-center">
          {t(UploadedMultiWorksheetText_Part2)}
        </Title>
      </div>
      <Form
        form={multiWorkSheetForm}
        className=" multi-worksheet-form"
        layout="vertical"
      >
        <Form.Item label="Select a Worksheet" name={"workSheetSelect"}>
          <Select
            options={multiWorksheetOption}
            className="login-module-input"
            style={{
              width: "450px",
            }}
            onChange={(value) =>
              handleWorkSheetConfig("selectedWorksheet", value)
            }
            // value={initialValues}
            // value={selectedWorksheet || multiWorksheetOption?.[0]?.value}
          />
        </Form.Item>
      </Form>
    </Row>
  );
};

export default UploadedMultiWorksheet;
