import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { Auth } from "aws-amplify";
import MainLayout from "containers/MainLayout";
import { logout } from "store/actions/loginAction";
import config from "aws-exports";
import { ItemType } from "antd/lib/menu/interface";
import { Permission } from "../hooks/usePermissions";

type MainLayoutProps = RouteProps & {
  token?: string;
  groups: string[];
  render: (props: object) => React.ReactElement;
  menuItems?: Array<ItemType & { permission: null | Permission }>;
  logOut: () => void;
};

/**
 * All Public Routes Higher Order Components
 */
const MainLayoutRoute: React.FC<MainLayoutProps> = (props) => {
  const { token, groups, render, logOut, menuItems, ...rest } = props;

  const handleSignOut = useCallback(async () => {
    Auth.signOut({
      global: true,
    })
      .then(() => {
        logOut();
      })
      .catch((err) => console.log(err));
  }, [logOut]);

  useEffect(() => {
    const userClientId = config.aws_user_pools_web_client_id;
    const lastAuthUserKey = `CognitoIdentityServiceProvider.${userClientId}.LastAuthUser`;
    const lastAuthUser = localStorage.getItem(lastAuthUserKey);
    if (!lastAuthUser) {
      handleSignOut();
    }
  }, [handleSignOut]);

  const getCondition = () => {
    if (window.location.host.includes("stag")) {
      return groups?.length === 1 && groups?.includes("stage_users");
    } else {
      return groups?.length === 0;
    }
  };

  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <>
          {!token && <Redirect to={"/signin"} />}
          {token && getCondition() && <Redirect to="/setup" />}
          {token && (
            // @ts-ignore
            <MainLayout menuItems={menuItems}>{render(matchProps)}</MainLayout>
          )}
        </>
      )}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.loginReducer.token,
    groups: state.loginReducer.groups,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logOut: () => dispatch(logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainLayoutRoute);
