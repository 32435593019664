import { GetUserCustomQuery, ListCsvDetailsQuery } from "_generated_/graphql";
import { API } from "aws-amplify";
import { getUserCustom } from "graphql/customQueries";
import { listCsvDetails } from "graphql/queries";
import { DeleteCSVsFunction, GetCSVPreviewFunction } from "types";
import { apiExecuter, GraphqlOperationExecutor } from "utility/commonMethods";
import { StatusResult } from "./types";

const getUsersCustom = async (
  variables: object,
): Promise<GetUserCustomQuery["getAccount"] | StatusResult> => {
  const data = await GraphqlOperationExecutor({
    query: getUserCustom,
    variables,
  });

  return (await data)?.users?.map((item: string) => JSON.parse(item));
};

const getCSVs = async (
  variables: object,
): Promise<ListCsvDetailsQuery["listCsvDetails"] | StatusResult> => {
  return (await GraphqlOperationExecutor({
    query: listCsvDetails,
    variables,
  })) as ListCsvDetailsQuery["listCsvDetails"] | StatusResult;
};

const deleteCSVs: DeleteCSVsFunction = async (body) => {
  return apiExecuter(async () => {
    const url: string = "/csv/deletefile";
    return await API.post("schoolBIREST", url, { body: body });
  });
};

const getCSVPreview: GetCSVPreviewFunction = async (body) => {
  return apiExecuter(async () => {
    const url: string = "/csv/previewdata";
    return await API.post("schoolBIREST", url, { body: body });
  });
};

const CSVFilesSDK = {
  getUsersCustom,
  getCSVs,
  deleteCSVs,
  getCSVPreview,
};

export default CSVFilesSDK;
