//** Aws Amplify Imports */
import { API } from "aws-amplify";
//** Utility Imports */
import {
  apiExecuter,
  getEnv,
  GraphqlOperationExecutor,
} from "utility/commonMethods";
import axios from "axios";
import { createAdvancedListDetail, updateDataSetup } from "graphql/mutations";
import { listAdvancedListDetails, listDataSetups } from "graphql/queries";
import { AddAdvancedListInput, AdvancedListByAccountResult } from "./types";
import {
  CreateAdvancedListDetailInput,
  CreateAdvancedListDetailMutation,
  ListAdvancedListDetailsQuery,
  ListDataSetupsQuery,
  UpdateAdvancedListDetailInput,
  UpdateDataSetupMutation,
} from "../_generated_/graphql";

const env = getEnv();

const baseUrl = {
  prod: "https://493c9anhug.execute-api.us-east-1.amazonaws.com/stage/",
  stage: "https://w6ybngm0ni.execute-api.us-east-1.amazonaws.com/stagging/",
  stagging: "https://w6ybngm0ni.execute-api.us-east-1.amazonaws.com/stagging/",
  production:
    "https://tum3lz5jzl.execute-api.us-east-1.amazonaws.com/production/",
};

export const getAdvancedListByAccountId = async (
  accountId: string,
): Promise<AdvancedListByAccountResult> =>
  apiExecuter(async () => {
    const url = `${baseUrl[env]}advancedlist/getlist?account=${accountId}`;
    const {
      data: { body },
    } = await axios.get(url);
    return body;
  });

/**
 * @deprecated appears to be unused, will be removed in the future
 * @param input
 */
const createAdvancedList = async (
  input: CreateAdvancedListDetailInput,
): Promise<CreateAdvancedListDetailMutation> => {
  const queryData = {
    query: createAdvancedListDetail,
    body: {
      input,
    },
  };

  return await GraphqlOperationExecutor(queryData);
};

const getAllAdvancedList = async (
  accountId: string,
): Promise<ListAdvancedListDetailsQuery["listAdvancedListDetails"]> => {
  const queryData = {
    query: listAdvancedListDetails,
    body: {
      filter: { school_id: { eq: accountId } },
    },
  };

  return (await GraphqlOperationExecutor(
    queryData,
  )) as ListAdvancedListDetailsQuery["listAdvancedListDetails"];
};

const addAdvancedList = async (
  body: AddAdvancedListInput,
): Promise<false | Response> => {
  const url = `${baseUrl[env]}advancedlist/addlist`;

  try {
    return await fetch(url, {
      body: JSON.stringify(body),
      method: "POST",
    });
  } catch {
    return false;
  }
};

const getAdvancedListFields = async ({
  accountId,
  data_set,
}: {
  accountId: string;
  data_set: string;
}) => {
  const queryData = {
    query: listDataSetups,
    body: {
      limit: 999,
      filter: {
        // school_id: { eq: accountId },
        data_set: {
          eq: data_set,
        },
      },
    },
  };

  if (accountId) {
    queryData["body"]["filter"]["school_id"] = { eq: accountId };
  }

  return (await GraphqlOperationExecutor(
    queryData,
  )) as ListDataSetupsQuery["listDataSetups"];
};

const updateAdvancedListDetails = async (
  input: UpdateAdvancedListDetailInput,
): Promise<UpdateDataSetupMutation["updateDataSetup"]> => {
  const queryData = {
    query: updateDataSetup,
    body: {
      input,
    },
  };

  return (await GraphqlOperationExecutor(
    queryData,
  )) as UpdateDataSetupMutation["updateDataSetup"];
};

const getPreviewData = async (body: {
  account: string;
  list_name: string;
  list_id: string;
}): Promise<Array<object>> => {
  return await API.post("schoolBIREST", "/dataset/getadvancedlistdata", {
    body: body,
  });
};

const deleteAdvancedList = async (body: {
  school_id: string;
  list_id: string;
  list_name: string;
  id: string;
}): Promise<void> => {
  return await API.post("schoolBIREST", "/advancedlist/delete", {
    body: body,
  });
};

const AdvacnedListSDK = {
  getAdvancedListByAccountId,
  createAdvancedList,
  getAllAdvancedList,
  addAdvancedList,
  getAdvancedListFields,
  updateAdvancedListDetails,
  getPreviewData,
  deleteAdvancedList,
};

export default AdvacnedListSDK;
