import { API, graphqlOperation } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import { apiExecuter, GraphqlOperationExecutor } from "utility/commonMethods";
import {
  getAccountForUserDetailsCustom,
  listAccountsCustom,
  updateAccountSuspendCustom,
} from "graphql/customQueries";
import { getAccount } from "graphql/queries";
import { createAccountRequest, deleteAccountRequest } from "graphql/mutations";
import {
  CreateAccountRequestMutation,
  DeleteAccountRequestMutation,
  GetAccountQuery,
  ListAccountsCustomQuery,
  ModelAccountFilterInput,
  UpdateAccountMutation,
} from "../_generated_/graphql";
import { StatusResult } from "./types";

export const createAccount = async (
  companyName: string,
  companyUrl: string,
  companyId: string,
  emailOfAdmin: string
): Promise<StatusResult> => {
  const path = `/createaccount/account`;
  try {
    const result = await API.post("schoolBIREST", path, {
      body: { companyName, companyUrl, companyId, emailOfAdmin },
    });

    if (result?.status !== 200) {
      throw new Error(result?.message);
    }

    return result;
  } catch (e) {
    console.log(e);
    return {
      error: e.response?.data?.error ?? "Failed creating account",
    };
  }
};

export const updateProfile = async ({
  body,
}: {
  body: { email: string; given_name: string; family_name: string };
}): Promise<void> => {
  const hubspotBody = {
    email: body.email,
    first_name: body.given_name,
    last_name: body.family_name,
  };
  await API.post("schoolBIREST", "/user/update", { body: body });
  await API.post("schoolBIREST", "/createaccount/update/userattributes", {
    body: hubspotBody,
  });
};

export const requestAccess = async (
  accountID: string
): Promise<
  CreateAccountRequestMutation["createAccountRequest"] | StatusResult
> => {
  try {
    const request = (await API.graphql(
      graphqlOperation(createAccountRequest, {
        input: { id: uuidv4(), account: accountID },
      })
    )) as { data: CreateAccountRequestMutation };

    return request.data?.createAccountRequest;
  } catch (e) {
    console.log(e);
    return { errors: ["The requested account does not exist."] };
  }
};

export const fetchAccount = async (
  accountID: string
): Promise<GetAccountQuery["getAccount"]> => {
  const account = (await API.graphql(
    graphqlOperation(getAccount, { id: accountID })
  )) as { data: GetAccountQuery };
  return account.data?.getAccount;
};

export const companySuspension = async (
  companyId: string,
  suspend: boolean
): Promise<UpdateAccountMutation["updateAccount"] | StatusResult> => {
  try {
    const request = (await API.graphql(
      graphqlOperation(updateAccountSuspendCustom, {
        input: {
          id: companyId,
          disabled: suspend,
        },
      })
    )) as { data: UpdateAccountMutation };

    return request.data?.updateAccount;
  } catch (e) {
    console.log(e);
    return { errors: ["The requested account does not exist."] };
  }
};

export const deleteRequest = async (
  requestID: string
): Promise<
  DeleteAccountRequestMutation["deleteAccountRequest"] | StatusResult
> => {
  try {
    const request = (await API.graphql(
      graphqlOperation(deleteAccountRequest, {
        input: { id: requestID },
      })
    )) as { data: DeleteAccountRequestMutation };

    return request.data?.deleteAccountRequest;
  } catch (e) {
    console.log(e);
    return { errors: ["The requested account does not exist."] };
  }
};
export const approveOrDenyRequest = async (bodyData: {
  schoolName?: string;
  request: boolean;
  accountRequestId?: string;
}): Promise<StatusResult> => {
  const path = `/addapproverequest/request`;
  try {
    return (await API.post("schoolBIREST", path, {
      body: bodyData,
    })) as StatusResult;
  } catch (e) {
    console.log(e);
    return { error: "Failed approving request" };
  }
};

export const sendEmailInvitation = async (value: {
  emails: string[];
  inviteBy?: string;
}): Promise<StatusResult> => {
  const path = `/invitemember/invite`;
  const body = {
    emailId: value.emails,
    inviteBy: value.inviteBy,
  };
  try {
    return await API.post("schoolBIREST", path, {
      body: body,
    });
  } catch (e) {
    console.log(e);
    return { error: "Failed sending invitation" };
  }
};
export const getAllUsers = async (
  nextToken?: string,
  filter?: ModelAccountFilterInput
): Promise<ListAccountsCustomQuery | StatusResult> => {
  try {
    const hasFilters = filter && Object.keys(filter).length > 0;
    return (await API.graphql(
      graphqlOperation(listAccountsCustom, {
        filter: hasFilters ? filter : undefined,
        limit: hasFilters ? undefined : 15,
        nextToken: nextToken,
      })
    )) as ListAccountsCustomQuery;
  } catch (e) {
    console.log(e);
    return { errors: ["Failed retrieving Companies"] };
  }
};

export const addSecretsKey = async (id: string): Promise<StatusResult> => {
  const path = `/apikey/create`;
  const apiName = "schoolBIREST";
  const data = {
    accountRequestId: id,
  };

  try {
    return (await API.post(apiName, path, {
      body: data,
    })) as StatusResult;
  } catch (e) {
    console.log(e);
    return { error: "Failed add the secrets key" };
  }
};

export const deleteSecretsKey = async (
  id: string,
  deleteId: string
): Promise<StatusResult> => {
  const path = `/apikey/delete?account=${id}`;
  const apiName = "schoolBIREST";
  const body = { id: deleteId };
  try {
    return await API.post(apiName, path, { body: body });
  } catch (e) {
    console.log(e);
    return { error: "Failed in deleting the secrets key" };
  }
};

export const disableSecretsKey = async (
  id: string,
  disableId: string,
  value: boolean
): Promise<StatusResult> => {
  const path = `/apikey/disable?account=${id}`;
  const apiName = "schoolBIREST";
  const body = {
    id: disableId,
    disabled: value,
  };
  try {
    return await API.post(apiName, path, { body: body });
  } catch (e) {
    console.log(e);
    return { error: "Disable Secrets Key failed" };
  }
};

export const createSchoolAccount = async (
  companyName: string,
  companyUrl: string,
  emailIds: string
): Promise<StatusResult> => {
  const path = `/createaccount/byadmin`;

  try {
    const result = await API.post("schoolBIREST", path, {
      body: { companyName, companyUrl, emailIds },
    });

    if (result.status !== 200) {
      throw new Error(result.message);
    }

    return result;
  } catch (e) {
    console.log(e);
    return {
      error: e.message ? e.message : "Failed creating account",
    };
  }
};

export const updateUserRole = async (body) =>
  apiExecuter(async () => {
    const path = `/createaccount/updaterole`;
    return await API.post("schoolBIREST", path, { body: body });
  });

export const updateMFA = async ({
  emailId,
  enable,
}: {
  emailId: string;
  enable: boolean;
}): Promise<void> => {
  if (enable) {
    apiExecuter(async () => {
      const path = `/createaccount/configure/mfa`;
      return await API.post("schoolBIREST", path, {
        body: { emailId, enable },
      });
    });
  } else {
    await apiExecuter(async () => {
      const path = `/createaccount/configure/mfa`;
      return await API.post("schoolBIREST", path, {
        body: { emailId, enable },
      });
    });
    await apiExecuter(async () => {
      const path = `/createaccount/mfa/toggle`;
      return await API.post("schoolBIREST", path, {
        body: { emailId, enable },
      });
    });
  }
};

const getUsersAndRequestDetails = async (accountID) => {
  const queryData = {
    query: getAccountForUserDetailsCustom,
    body: {
      id: accountID,
    },
  };

  return GraphqlOperationExecutor(queryData);
};

const sdk = {
  createAccount,
  updateProfile,
  requestAccess,
  fetchAccount,
  companySuspension,
  deleteRequest,
  approveOrDenyRequest,
  sendEmailInvitation,
  getAllUsers,
  addSecretsKey,
  deleteSecretsKey,
  disableSecretsKey,
  createSchoolAccount,
  updateUserRole,
  updateMFA,
  getUsersAndRequestDetails,
};

export default sdk;
