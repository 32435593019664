import { listDataPackageDetails } from "graphql/queries";
import { GraphqlOperationExecutor, apiExecuter } from "utility/commonMethods";
import { API } from "aws-amplify";
import { DeleteDataPackageFunction } from "types";
import { ListDataPackageDetailsQuery } from "_generated_/graphql";
import { StatusResult } from "./types";

const getDatapackage = async ({
  accountId,
}: {
  accountId: string;
}): Promise<
  ListDataPackageDetailsQuery["listDataPackageDetails"] | StatusResult
> => {
  return await GraphqlOperationExecutor({
    query: listDataPackageDetails,
    variables: {
      limit: 999,
      filter: {
        school_id: { eq: accountId },
      },
    },
  });
};

const deleteDataPackage: DeleteDataPackageFunction = async ({
  accountId,
  datapackage,
}: {
  accountId: string;
  datapackage: string;
}) => {
  return apiExecuter(async () => {
    const url: string = `/deletedatapackage`;

    return await API.post("schoolBIREST", url, {
      body: {
        accountId,
        datapackage,
      },
    });
  });
};

const ManageDatapackageSDK = {
  getDatapackage,
  deleteDataPackage,
};

export default ManageDatapackageSDK;
