import { getAccountForDataSetCustom } from "graphql/customQueries";
import { apiExecuter, GraphqlOperationExecutor } from "utility/commonMethods";
import { API } from "aws-amplify";
import { createPreviewDetail, updatePreviewDetail } from "graphql/mutations";
import { listPreviewDetails } from "graphql/queries";
import {
  Account,
  DisconnectDatasetFunction,
  GetAllDatasetFunction,
  GetDatasetFunction,
  ResetDatasetFunction,
  UpdateDatasetFunction,
} from "types";
import {
  CreatePreviewDetailMutation,
  ListPreviewDetailsQuery,
  UpdatePreviewDetailMutation,
} from "_generated_/graphql";
import { StatusResult } from "./types";

const getAllDataset: GetAllDatasetFunction = async (accountId) => {
  const data: Account = await GraphqlOperationExecutor({
    query: getAccountForDataSetCustom,
    variables: {
      id: accountId,
    },
  });
  return { dataSet: data?.dataSet?.items, data };
};

const getDatasetData: GetDatasetFunction = async (body) =>
  apiExecuter(async () => {
    const url: string = "/dataset/getdata";
    return await API.post("schoolBIREST", url, { body });
  });

const updateDatasetData: UpdateDatasetFunction = async (body) =>
  apiExecuter(async () => {
    const url: string = "/dataset/updatenames";
    return await API.post("schoolBIREST", url, { body });
  });

const resetDatasetData: ResetDatasetFunction = async (body) =>
  apiExecuter(async () => {
    const url: string = "/dataset/reset";
    return await API.post("schoolBIREST", url, { body });
  });

const disconnectDatasetData: DisconnectDatasetFunction = async (body) =>
  apiExecuter(async () => {
    const url: string = "/datasources/disconnect";
    return await API.post("schoolBIREST", url, { body });
  });

const savePreviewOrderData = async (
  input: CreatePreviewDetailMutation,
): Promise<
  CreatePreviewDetailMutation["createPreviewDetail"] | StatusResult
> => {
  return (await GraphqlOperationExecutor({
    query: createPreviewDetail,
    variables: {
      input,
    },
  })) as CreatePreviewDetailMutation["createPreviewDetail"];
};

const updatePreviewOrderData = async (
  input: UpdatePreviewDetailMutation,
): Promise<
  UpdatePreviewDetailMutation["updatePreviewDetail"] | StatusResult
> => {
  return (await GraphqlOperationExecutor({
    query: updatePreviewDetail,
    variables: {
      input,
    },
  })) as UpdatePreviewDetailMutation["updatePreviewDetail"];
};

const getPreviewOrderData = async (
  filter: ListPreviewDetailsQuery,
): Promise<ListPreviewDetailsQuery["listPreviewDetails"] | StatusResult> => {
  return (await GraphqlOperationExecutor({
    query: listPreviewDetails,
    variables: {
      filter,
    },
  })) as ListPreviewDetailsQuery["listPreviewDetails"];
};

const ManageDatasetSDK = {
  getAllDataset,
  getDatasetData,
  updateDatasetData,
  resetDatasetData,
  disconnectDatasetData,
  savePreviewOrderData,
  getPreviewOrderData,
  updatePreviewOrderData,
};

export default ManageDatasetSDK;
