import { API } from "aws-amplify";
import { createVeracrossDetail } from "graphql/mutations";
import { listDatasources, listVeracrossDetails } from "graphql/queries";
import {
  AddDataSourcesFunction,
  DeleteUserFunction,
  HandlerDataProviderConnectionFunction,
  HandlerDataProviderDisconnectionFunction,
  RavennaIntegrationHandlerFunction,
  VeracrossIntegrationHandlerFunction,
} from "types";
import { apiExecuter, GraphqlOperationExecutor } from "utility/commonMethods";
import { StatusResult } from "./types";
import {
  CreateVeracrossDetailInput,
  CreateVeracrossDetailMutation,
  ListDatasourcesQuery,
  ListDatasourcesQueryVariables,
  ListVeracrossDetailsQuery,
} from "_generated_/graphql";

const deleteUser: DeleteUserFunction = (body) =>
  apiExecuter(async () => {
    const url: string = "/admin/deleteuser";

    return (await API.post("schoolBIREST", url, { body })) as void;
  });

const getDataSources = async (
  inputData: ListDatasourcesQueryVariables,
): Promise<ListDatasourcesQuery["listDatasources"] | StatusResult> => {
  const data = await GraphqlOperationExecutor({
    query: listDatasources,
    variables: {
      input: inputData,
    },
  });
  return data.sort((a, b) =>
    a?.data_source_name?.localeCompare(b?.data_source_name),
  );
};

const handlerDataProviderDisconnection: HandlerDataProviderDisconnectionFunction =
  async (platform, account) =>
    await apiExecuter(async () => {
      const path: string = `/integrations/disconnect`;

      return await API.post("schoolBIREST", path, {
        body: {
          dataprovider: platform.toLowerCase(),
          account: account,
        },
      });
    });

const handlerDataProviderConnection: HandlerDataProviderConnectionFunction =
  async (platform, accountId) =>
    await apiExecuter(async () => {
      const path: string = `/integrations/${platform.toLowerCase()}/auth?account=${accountId}`;
      await API.get("schoolBIREST", path, {});
    });

const addDataSources: AddDataSourcesFunction = async ({
  datasources,
  account,
  userId,
}) =>
  await apiExecuter(async () => {
    const path: string = `/datasources/adddatasource`;
    await API.post("schoolBIREST", path, {
      body: {
        datasources,
        account,
        userId,
      },
    });
    await API.post("schoolBIREST", "/integrations/sync/details", {
      body: {
        datasources,
        account,
      },
    });
  });

const getVeracrossDetailsHandler = async ({
  accountId,
}: {
  accountId: string;
}): Promise<
  ListVeracrossDetailsQuery["listVeracrossDetails"] | StatusResult
> => {
  return await GraphqlOperationExecutor({
    query: listVeracrossDetails,
    variables: {
      limit: 999,
      filter: {
        school_id: { eq: accountId },
      },
    },
  });
};

const createVeracrossDetailsHandler = async (
  input: CreateVeracrossDetailInput,
): Promise<
  CreateVeracrossDetailMutation["createVeracrossDetail"] | StatusResult
> => {
  return await GraphqlOperationExecutor({
    query: createVeracrossDetail,
    variables: {
      input,
    },
  });
};

const veracrossIntegrationHandler: VeracrossIntegrationHandlerFunction = async (
  body,
) =>
  await apiExecuter(async () => {
    const path: string = `/integrations/veracross/auth`;
    return await API.post("schoolBIREST", path, {
      body,
    });
  });

const ravennaIntegrationHandler: RavennaIntegrationHandlerFunction = async ({
  code,
  accountId,
}) =>
  await apiExecuter(async () => {
    const path: string = `/integrations/ravenna/auth?code=${code}&account=${accountId}`;
    return await API.get("schoolBIREST", path, {});
  });

export const IntegrationSDK = {
  deleteUser,
  getDataSources,
  addDataSources,
  handlerDataProviderDisconnection,
  handlerDataProviderConnection,
  getVeracrossDetailsHandler,
  createVeracrossDetailsHandler,
  veracrossIntegrationHandler,
  ravennaIntegrationHandler,
};
