import React, { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useHookifyLegacyQuery } from "hooks/useHookifyLegacyQuery";
import { fetchAccount } from "sdk/Accounts";
import Loader from "Common/Components/Loader/Loader";
import { getAllCustomDashboard } from "sdk/QsDashboardSDK";
import { CircularProgress, Grid2, IconButton } from "@mui/material";
import PaddedCard from "Common/Components/PaddedCard/PaddedCard";
import DashboardItem from "./DashboardItem";
import { Add } from "@mui/icons-material";
import { AddEditDashboardModal } from "../AddEditDashboardModal/AddEditDashboardModal";

export const School = () => {
  const { school_id } = useParams<{ school_id: string }>();

  const [addDashboardModalOpen, setAddDashboardModalOpen] =
    useState<boolean>(false);

  const { data: schoolData, loading: schoolLoading } = useHookifyLegacyQuery(
    fetchAccount,
    school_id
  );

  const dashboardParam = useMemo(
    () => ({ school_id: { eq: school_id } }),
    [school_id]
  );
  const {
    data: dashboards,
    loading: dashboardLoading,
    refetch: refetchDashboards,
  } = useHookifyLegacyQuery(getAllCustomDashboard, dashboardParam);

  const toggleAddDashboardModal = useCallback(() => {
    setAddDashboardModalOpen((prev) => !prev);
    refetchDashboards();
  }, [refetchDashboards]);

  if (schoolLoading) {
    return <Loader />;
  } else if (!schoolData) {
    return <div>Failed to load school</div>;
  }

  return (
    <>
      <h1>{schoolData.company}</h1>
      <Grid2 container>
        <Grid2 size={{ xs: 4 }}>
          <PaddedCard>
            <h5>Custom dashboards</h5>
            {dashboardLoading && <CircularProgress />}
            {Array.isArray(dashboards) &&
              dashboards.map((dashboard) => (
                <DashboardItem
                  dashboard={dashboard}
                  key={dashboard}
                  onChange={refetchDashboards}
                />
              ))}
            <IconButton onClick={toggleAddDashboardModal}>
              <Add />
            </IconButton>
          </PaddedCard>
        </Grid2>
      </Grid2>
      <AddEditDashboardModal
        open={addDashboardModalOpen}
        onClose={toggleAddDashboardModal}
        school_id={school_id}
        school_name={schoolData.company ?? undefined}
      />
    </>
  );
};
