import { MoreOutlined } from "@ant-design/icons";
import { Button, Dropdown, Empty, Menu, Table, Tag, Tooltip } from "antd";
import SkeletonTable from "Common/Skeleton/SkeletonTable";
import _, { isEmpty } from "lodash";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import ManageFileSDK from "sdk/ManageFileSDK";
import { get_attribute } from "utility/commonMethods";
import {
  buttonText,
  ManageFiles_Constants,
  MiscellaneousText,
} from "utility/constants";

const UploadedArchieve = ({
  handleRefreshIntervalModal,
  deleteBtnHandler,
  fetchAccountDetails,
  allUploadedGoogleSheets,
  dynamicRowClassName,
  Loading,
  handleFilePreview,
  users,
  setLoading,
}) => {
  const { Inactive_Text, Active_Text, Inprogress_Text } = MiscellaneousText;
  const { Edit_Refresh_Interval_Text, Archieve_Text } =
    ManageFiles_Constants?.Recent_Tab;
  const { Delete, Sync_now_Btn } = buttonText;

  const { t } = useTranslation();

  const refreshIntervalStatusColor = useMemo(
    () => ({
      DAILY: "magenta",
      WEEKLY: "volcano",
      MONTHLY: "orange",
      YEARLY: "gold",
      NONE: "red",
      CUSTOM: "green",
    }),
    []
  );

  const handleOnDemandSync = async (record) => {
    setLoading(true);
    await ManageFileSDK.onDemandSync(record);
    setLoading(false);
  };

  const fileHistoryTableColumn = [
    {
      title: "File Name",
      dataIndex: "googlesheet_name",
      key: "filename",
      render: (googlesheetName) => {
        return <>{googlesheetName ? googlesheetName : "-"} </>;
      },
    },
    {
      title: "File Upload Time & Date",
      dataIndex: "updatedAt",
      key: "fileUploadTimeAndDate",
      render: (modifyDate) => <Moment date={modifyDate} format="llll" />,
    },
    {
      title: "Refresh Interval",
      dataIndex: "interval_type",
      key: "refreshInterval",
      render: (isIntervalType) => (
        <Tag
          color={refreshIntervalStatusColor[isIntervalType]}
          className="refreshinterval-tag"
        >
          {_.capitalize(isIntervalType)}
        </Tag>
      ),
    },
    {
      title: "Refresh Interval Status",
      dataIndex: "refresh_interval_status",
      key: "refreshInterval",
      render: (isIntervalType) =>
        isIntervalType === "INACTIVE" ? (
          <Tag color="#ed4b4b" className="refreshinterval-tag">
            {t(Inactive_Text)}
          </Tag>
        ) : isIntervalType === "ACTIVE" ? (
          <Tag color="#16a34a" className="refreshinterval-tag">
            {t(Active_Text)}
          </Tag>
        ) : (
          <Tag color="#16a34a" className="refreshinterval-tag">
            {t(Inprogress_Text)}
          </Tag>
        ),
    },
    {
      title: "Last Success Sync",
      dataIndex: "last_success_sync_date",
      key: "successSync",
      render: (item) => (item ? <Moment date={item} format="lll" /> : "-"),
    },
    {
      title: "Last Failed Sync",
      dataIndex: "last_failure_sync_date",
      key: "failedSync",
      render: (item) => (item ? <Moment date={item} format="lll" /> : "-"),
    },
    {
      title: "File Type",
      dataIndex: "googlesheet_link",
      key: "fileType",
      render: (isGoogleLink) => (isGoogleLink ? "Google Sheet" : "CSV"),
    },
    {
      title: "Created By",
      dataIndex: "created_by",
      key: "created_by",
      render: (currentUserId) => {
        if (!currentUserId) {
          return "N/A";
        }

        const filteredUser = users.filter(
          ({ Username }) => currentUserId === Username
        );

        if (isEmpty(filteredUser)) {
          return "Deleted User";
        }

        return `${get_attribute(
          filteredUser[0]["Attributes"],
          "given_name"
        )} ${get_attribute(filteredUser[0]["Attributes"], "family_name")}`;
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => {
        return (
          <div className="d-flex justify-content-center cursor-pointer">
            <Dropdown
              menu={[
                {
                  key: "syncNow",
                  label: (
                    <Button
                      type="text"
                      onClick={() => handleOnDemandSync(record)}
                      disabled={
                        record?.refresh_interval_status === "STARTING" ||
                        record?.refresh_interval_status === "IN_PROGRESS"
                      }
                    >
                      {t(Sync_now_Btn)}
                    </Button>
                  ),
                },
                {
                  key: "interval",
                  label: (
                    <Button
                      type="text"
                      onClick={() => handleRefreshIntervalModal(record)}
                    >
                      {t(Edit_Refresh_Interval_Text)}
                    </Button>
                  ),
                },
                {
                  key: "delete",
                  label: (
                    <Button
                      type="text"
                      onClick={() =>
                        deleteBtnHandler(record, fetchAccountDetails)
                      }
                    >
                      {t(Delete)}
                    </Button>
                  ),
                },
              ]}
            >
              <MoreOutlined className="fs-24 primary-color" />
            </Dropdown>
          </div>
        );
      },
    },
  ];

  return (
    <Table
      columns={fileHistoryTableColumn}
      dataSource={allUploadedGoogleSheets}
      className="file-history-table w-100"
      pagination={false}
      scroll={{
        x: 1000,
      }}
      rowClassName={(record) => dynamicRowClassName(record)}
      locale={{
        emptyText: Loading ? <SkeletonTable /> : <Empty />,
      }}
      expandable={{
        expandedRowRender: (record) => {
          return (
            <Tooltip title="Worksheet" placement="bottomLeft" color="#F5DB5D">
              <Tag
                color="#ffc90a"
                className="worksheet-container cursor-pointer"
                onClick={() => handleFilePreview(record)}
              >
                {record?.worksheet_name}
              </Tag>
            </Tooltip>
          );
        },
        rowExpandable: (record) => record.name !== "Not Expandable",
      }}
    />
  );
};

export default UploadedArchieve;
