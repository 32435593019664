//** React Imports */
import React, { useState, useEffect, useCallback } from "react";
//** Ant Design Imports */
import { Row, Col, Input, Form, Button, Typography } from "antd";
//** React Router Imports */
import { useHistory } from "react-router-dom";
//** Amplify Imports */
import { Auth } from "aws-amplify";
//** Layout Imports */
import Footer from "Common/Layouts/Footer";
import LoginModuleLogo from "Common/Components/LoginModuleLogo/LoginModuleLogo";
import Background from "Common/Layouts/AuthColOne";
//** React Otp Imports */
import OtpInput from "react-otp-input";
import { useTranslation } from "react-i18next";
import {
  buttonText,
  formError,
  messageText,
  MiscellaneousText,
} from "utility/constants";
import ApiResultModal from "Common/Components/ApiResultModal/ApiResultModal";

/**
 * Confirm Email Component
 */
function ConfirmSingupEmail(props) {
  //** Destructing Hooks */
  const history = useHistory();
  const { t } = useTranslation();

  //** Destructing Ant Components */
  const { Title, Text } = Typography;

  //** Destructing Constants */
  const { Confirm_Email_Text, Enter_Code_Text } = MiscellaneousText;
  const { isEmailEmpty, isCodeEmpty } = formError;
  const { Confirm_Email_Btn, Resend_Code_Btn } = buttonText;

  //** Destructing Constants */
  const {
    New_Confirmation_Code_Message,
    Sign_In_Account_Message,
    Something_Went_Wrong_Message,
    Already_Verified_Message,
  } = messageText;

  //** Ant Design Form */
  const [form] = Form.useForm();
  //** Use States */
  const [loading, setloading] = useState(false);
  const [email] = useState(props.location.state?.userName);
  const [otp, setOtp] = useState();
  const [apiResultModal, setApiResultModal] = useState({
    message: "",
    isApiResultModelOpen: false,
    type: "failure",
  });

  //** Handles signup if code is valid */
  const handleConfirmSignUp = useCallback(
    async (e) => {
      try {
        if (e.email && e.code) {
          setloading(true);
          await Auth.confirmSignUp(e.email, e.code);
          setloading(false);
          setApiResultModal(() => ({
            message: t(Sign_In_Account_Message),
            isApiResultModelOpen: true,
            type: "success",
          }));
          history.push({
            pathname: "/signin",
            state: {
              feedback: t(Sign_In_Account_Message),
              userName: e.email,
            },
          });
        } else {
          setApiResultModal(() => ({
            message: t(Something_Went_Wrong_Message),
            isApiResultModelOpen: true,
            type: "failure",
          }));
        }
      } catch (error) {
        if (
          error.message.includes(
            "User cannot be confirmed. Current status is CONFIRMED",
          )
        ) {
          history.push("/");
          setApiResultModal(() => ({
            message: t(Already_Verified_Message),
            isApiResultModelOpen: true,
            type: "success",
          }));
        }
        console.log("error signing up:", error);
        setApiResultModal(() => ({
          message: error.message,
          isApiResultModelOpen: true,
          type: "failure",
        }));
      } finally {
        setloading(false);
      }
    },
    [
      t,
      history,
      Already_Verified_Message,
      Sign_In_Account_Message,
      Something_Went_Wrong_Message,
    ]
  );

  //** For handling if confirmation code presents in url or not */
  useEffect(() => {
    if (!props.location.state) {
      if (
        window.location.href.includes("confirmationcode=") &&
        window.location.href.includes("/confirm_email")
      ) {
        let Data = {};
        Data.code = window.location.href
          .split("confirmationcode=")[1]
          .split("&email=")[0];
        Data.email = window.location.href.split("email=")[1];
        form.setFieldsValue({
          code: Data.code,
          email: Data.email,
        });
        handleConfirmSignUp(form.getFieldsValue(["email", "code"]));
      } else {
        history.push("/");
      }
    }
  }, [form, handleConfirmSignUp, history, props.location.state]);

  //** Resending authentication code */
  const handleResendCode = async () => {
    try {
      await Auth.resendSignUp(email);
      setApiResultModal(() => ({
        message: t(New_Confirmation_Code_Message),
        isApiResultModelOpen: true,
        type: "success",
      }));

      setloading(false);
    } catch (error) {
      console.log("error signing up:", error);
      setloading(false);
    }
  };

  /** Otp Changes  */
  const handleChange = (otp) => {
    setOtp(otp);
  };

  return (
    <>
      <Row justify="center">
        <Background />

        <Col span={12} md={12} lg={12} xs={22}>
          <Row justify="center" className="login-module-col-2">
            <Col xs={24} sm={24} md={24} lg={18} xl={12} xxl={12}>
              <LoginModuleLogo />

              <Form
                form={form}
                layout="vertical"
                name="normal_signup"
                className="signup-form"
                requiredMark={false}
                onFinish={(e) => handleConfirmSignUp(e)}
              >
                <Title className="fs-18 text-center " level={5}>
                  {t(Confirm_Email_Text)}
                </Title>

                <Text className="confirm-email-paragraph">
                  {t(Enter_Code_Text)}
                </Text>

                <Form.Item
                  initialValue={email}
                  label="Email"
                  name="email"
                  className="font-bold"
                  rules={[{ required: true, message: t(isEmailEmpty) }]}
                >
                  <Input
                    value={email}
                    id="email"
                    key="email"
                    name="email"
                    placeholder="Email"
                    disabled={localStorage.getItem("User")}
                    className="border-light-color fs-18"
                  />
                </Form.Item>
                <Form.Item
                  className="otp"
                  name="code"
                  rules={[
                    {
                      required: true,
                      message: t(isCodeEmpty),
                    },
                  ]}
                >
                  <OtpInput
                    name="code"
                    id="code"
                    key="code"
                    shouldAutoFocus
                    className="otp-input"
                    value={otp}
                    onChange={handleChange}
                    numInputs={6}
                    separator={<span className="color-white"> ---</span>}
                  />
                </Form.Item>

                <Form.Item>
                  <Row justify="space-between" align="middle">
                    <Col span={2}>
                      <Button
                        type="link"
                        onClick={handleResendCode}
                        className="login-btn fs-18 font-bold"
                      >
                        {t(Resend_Code_Btn)}
                      </Button>
                    </Col>
                    <Col span={12}>
                      <Button
                        size="large"
                        htmlType="submit"
                        block
                        tabIndex={5}
                        type="primary"
                        loading={loading}
                        className="w-100"
                      >
                        {t(Confirm_Email_Btn)}
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              </Form>
            </Col>
            <Footer />
          </Row>
        </Col>
      </Row>

      <ApiResultModal
        apiResultModal={apiResultModal}
        setApiResultModal={setApiResultModal}
      />
    </>
  );
}
export default ConfirmSingupEmail;
