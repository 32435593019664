//** Amplify Imports */
import { API, graphqlOperation, Storage } from "aws-amplify";
import { StorageAccessLevel } from "@aws-amplify/storage";
//** Redux action imports */
import { setIsLoading } from "store/actions/loginAction";
//** Redux Imports */
import { store } from "store/index";
//** Ant Component Imports */
import { message } from "antd";
//** Imports Constants */
import { emailPattern } from "./constants";
// ** SDK imports */
import sdk from "sdk/Accounts";
import { multiply, some, toLower } from "lodash";
import { SortByDate } from "types";

export const swapKeyValue = (json) => {
  const ret = {};
  for (const key in json) {
    ret[json[key]] = key;
  }
  return ret;
};

/**
 * Getting User Attribute From User by Key
 * @function get_attribute
 * @return {String}
 * @param user
 * @param key
 */
export const get_attribute = (
  user: Array<{ Name: string; Value: any }>,
  key: string
): any => {
  const result = user.filter((attribute) => attribute.Name === key);
  if (result.length > 0) {
    return result[0]["Value"];
  } else {
    return "";
  }
};

/**
 * Executes Graphql query
 * @returns
 * @param masterParam
 */
export const GraphqlOperationExecutor = async (masterParam: {
  query: string;
  variables?: object;
  authToken?: string;
}) => {
  try {
    store.dispatch(setIsLoading(true));

    let masterResult;

    let hasNextToken = true;
    let nextToken = null;

    while (hasNextToken) {
      const resultPromise = API.graphql(
        // @ts-ignore
        graphqlOperation(...Object.values(masterParam))
      );
      const result = await resultPromise;

      // @ts-ignore
      const key = Object.keys(result?.data)[0];
      // @ts-ignore
      const data = result?.data?.[key];
      if (!data || !data?.items || 0 === data.items.length) {
        masterResult = data;
        hasNextToken = false;
        store.dispatch(setIsLoading(false));
        return masterResult || {};
      }

      const { nextToken: currentNextToken, items } = data;

      if (!masterResult) {
        masterResult = [];
      }
      if (items.length > 0) {
        masterResult.push(...items);
      }

      if (!currentNextToken) {
        hasNextToken = false;
        store.dispatch(setIsLoading(false));
        return masterResult || [];
      }

      nextToken = currentNextToken;
      // @ts-ignore
      masterParam.body.nextToken = nextToken;
      hasNextToken = Boolean(nextToken);
    }

    store.dispatch(setIsLoading(false));
    return masterResult || [];
  } catch (e) {
    store.dispatch(setIsLoading(false));
    console.log(e);
    return;
  }
};

/**
 * Executes function
 * @function apiExecuter
 * @param {Function} func Accepts Functions
 * @returns
 */
export const apiExecuter = async <T>(func: () => T): Promise<T> => {
  try {
    store.dispatch(setIsLoading(true));
    const result = await func();
    store.dispatch(setIsLoading(false));
    return result;
  } catch (e) {
    store.dispatch(setIsLoading(false));
    console.log("Failed in executing", func, "\nError occurs ", e);
    const data = e?.response?.data;
    throw data || "Something went wrong";
  }
};

/**
 * Given an array of email addresses, validates the last element in the array
 * @function validateEmail
 * @param {Array[string]} email
 * @returns
 */
export const validateEmail = (email: string[]) => {
  if (email?.length > 0) {
    const found = email[email.length - 1].match(emailPattern);
    if (!found) {
      email.pop();
      return message.error("Please enter valid email");
    } else {
      return (email[email.length - 1] = email[email.length - 1].toLowerCase());
    }
  }
};

/**
 * Sorting data by order
 * @function sortingOrder
 * @returns
 * @param a
 * @param b
 */
export const sortingOrder = (
  a: { order: number | string },
  b: { order: number | string }
) => {
  if (a.order < b.order) {
    return -1;
  }
  if (a.order > b.order) {
    return 1;
  }
  return 0;
};

/**
 *  Sending invitation to all email list
 * @function sendInvitation
 * @param inviteData
 * @param {Object} value it contains account details
 */
export const sendInvitation = async (
  inviteData: { inviteBy?: string },
  value: { emails: string[]; inviteBy?: string }
) => {
  return await apiExecuter(async () => {
    value.inviteBy = inviteData?.inviteBy;
    return await sdk.sendEmailInvitation(value);
  });
};

/**
 * Fetches current school data and current account
 * @function setCompanyData
 * @param {Object} account
 * @param setAcct
 */
export const setCompanyData = (
  account: { url?: string | null | undefined },
  setAcct: (account: unknown) => void
) => {
  if (!account?.url?.includes("http")) {
    account.url = "https://" + account?.url;
  }
  setAcct(account);
};

/**
 * Fetching account for updating
 * @function fetchAccountDetails
 */
export const fetchAccountDetails = async (
  setAcct?: (account: unknown) => void
) =>
  await apiExecuter(async () => {
    const accountID = store.getState()?.loginReducer?.accountId;
    if (accountID) {
      const account = await sdk.fetchAccount(accountID);
      if (account && setAcct) setCompanyData(account, setAcct);
    }
  });

export const getFieldKey = (key?: string) =>
  key?.toString()?.trim()?.toLowerCase()?.replace(/\W/g, "_");

/**
 *
 * @param {*} currentTime
 * @param {*} PreviousTime
 * @returns Differnce
 */
export const gettimeDiff = (currentTime: Date, PreviousTime: Date) => {
  const ms_Min = 60 * 1000;
  const ms_Hour = ms_Min * 60;
  const ms_Day = ms_Hour * 24;
  const ms_Mon = ms_Day * 30;
  const ms_Yr = ms_Day * 365;
  const diff = currentTime.getTime() - PreviousTime.getTime();
  if (diff < ms_Min) {
    return Math.round(diff / 1000) + " seconds";
  } else if (diff < ms_Hour) {
    return Math.round(diff / ms_Min) + " minutes";
  } else if (diff < ms_Day) {
    return Math.round(diff / ms_Hour) + " hours";
  } else if (diff < ms_Mon) {
    return "Around " + Math.round(diff / ms_Day) + " days";
  } else if (diff < ms_Yr) {
    return "Around " + Math.round(diff / ms_Mon) + " months";
  } else {
    return "Around " + Math.round(diff / ms_Yr) + " years";
  }
};

export const sortDatabyDate = (data: SortByDate) =>
  data.sort((a, b) => {
    const dateA = new Date(a?.createdAt);
    const dateB = new Date(b?.createdAt);

    return dateA.getTime() - dateB.getTime();
  });

/**
 *
 * @returns
 * @param name
 */
export const replaceFileName = (name: string) => {
  const pattern = /[^a-zA-Z0-9.-]/g;
  let fileName = `${name?.replace(/\.[^/.]+$/, "")}.${name
    ?.replace(/(.*)\./g, "")
    ?.replace(/ /g, "")}`;

  fileName = fileName.replace(pattern, "");

  return fileName;
};

const extractPathFromUrl = (url: string): string | null => {
  const parts = url.split("private/");

  if (parts.length > 1) {
    let path = parts[1].split("?")[0];
    path = path.replace(/^us-east-1%3A/, "");
    return path;
  }

  return null;
};

export const SaveFileToS3ForCSV = async (
  file: File,
  level: StorageAccessLevel = "public",
  prefix = ""
) => {
  const contents = await file.arrayBuffer();
  const data = await Storage.put(
    `${prefix}/${replaceFileName(file?.name)}`,
    contents,
    {
      level,
      contentDisposition: "attachment",
      contentType: file.type,
    }
  );

  const { key } = data;
  const getUrl = await Storage.get(key, {
    level: "private",
  });

  const extractedKey = extractPathFromUrl(getUrl);
  return { key: extractedKey };
};

/**
 * Fixme: This should be pulling from process.env, not window.location.host
 */
export const getEnv = () => {
  const host = window?.location?.host || "";

  if (host.includes("stage")) return "stagging";
  if (host.includes("app")) return "production";
  return "stagging";
};

export const TimezoneDifference = () => {
  const now = new Date();
  const timezoneOffsetMinutes = now.getTimezoneOffset();
  return multiply(-timezoneOffsetMinutes, 1);
};

export const hasTargetDataSource = (datasource, targetValue) =>
  some(datasource, (item) => toLower(item) === toLower(targetValue));
