import { useCallback, useEffect, useState } from "react";

export function useHookifyLegacyQuery<T, A, B>(
  queryFn: (arg0?: A, arg1?: B) => Promise<T>,
  arg0?: A,
  arg1?: B
): {
  data: T | null;
  loading: boolean;
  refetch: () => void;
} {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const query = useCallback(() => {
    queryFn(arg0, arg1).then((result) => {
      setData(result);
      setLoading(false);
    });
  }, [arg0, arg1, queryFn]);

  //ugh
  useEffect(() => {
    query();
  }, [query]);

  return { data, loading, refetch: query };
}
