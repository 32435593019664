import React, { useState } from "react";
import { CloseOutlined, LinkOutlined } from "@ant-design/icons";
import {
  Row,
  Typography,
  Upload,
  Input,
  Button,
  Form,
  Divider,
  Col,
} from "antd";
import { useTranslation } from "react-i18next";
import UploadIcon from "assets/images/CustomIcons/UploadIcon.svg";
import {
  buttonText,
  ManageFiles_Constants,
  MiscellaneousText,
  copyableMail,
} from "utility/constants";
import InfoButton from "Common/Components/InfoButtons/InfoButton";
import CustomModal from "Common/Components/CustomAntD/Modal";
import { SaveFileToS3ForCSV } from "utility/commonMethods";
import { useSelector } from "react-redux";
import ManageFileSDK from "sdk/ManageFileSDK";

const UploadFiles = ({
  uploadFileHandler,
  workSheetConfig,
  handleWorkSheetConfig,
  getCSVFields,
}) => {
  const { Title } = Typography;
  const { Dragger } = Upload;
  const { t } = useTranslation();

  const { Upload_Btn, OK_Btn } = buttonText;
  const {
    Drag_And_Drop,
    Upload_Url_Text,
    Google_Sheet_Upload_Url,
    Email_Share_Txt,
    Email_Share_Txt_Part2,
    Email_Share_Part_One_Txt,
  } = ManageFiles_Constants?.Upload_Tab;

  const { Copy_Text } = MiscellaneousText;

  const { accountId } = useSelector((state) => state.loginReducer); // Getting group from login reducer

  const [refreshIntervalNotSetModal, setRefreshIntervalNotSetModal] =
    useState(false);

  const [fileList, setFileList] = useState([]);

  // const [fileList,setFileList] = useState([])
  const [loader, setLoader] = useState(false);

  const uploadPolicyProps = {
    name: "file",
    multiple: false,
    // fileList: fileList,
    accept:
      "application/vnd.ms-excel, text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.google-apps.spreadsheet",
    async onChange(info) {
      setLoader(true);
      const { key } = await SaveFileToS3ForCSV(info.file, "private", accountId);
      console.log(key);
      await getCSVFields(key);
      setLoader(false);
    },
    beforeUpload() {
      return false;
    },
  };

  const csvLinkUploadInfo = (
    <div className="managefile-tooltip csvupload-tooltip ">
      <p>
        No duplicate column names will be allowed & only one unique key will be
        allowed.
      </p>
    </div>
  );

  return (
    <>
      {loader ? <div className="loading"></div> : null}
      <Row gutter={[24, 24]}>
        <Col span={12}>
          <Row className="h-100">
            <div className="file-upload-dragger">
              <Dragger {...uploadPolicyProps} className="file-upload-dragger">
                <p className="ant-upload-drag-icon">
                  <img src={UploadIcon} alt="upload" />
                </p>
                <p className="ant-upload-text font-bold primary-color">
                  <span className="dove-color"> {t(Drag_And_Drop)} </span>{" "}
                  {t(Upload_Btn)}
                </p>
              </Dragger>
            </div>
          </Row>
        </Col>
        {/* <Divider className="upload-file-divider" /> */}

        <Col span={12}>
          <Row className="gap-4 flex-col px-3">
            <Row className="p-0 flex-col gap-2">
              <Row className="d-flex gap-3 align-items-center justify-content-between emailid-container">
                <div>
                  <Title level={5} className="fs-14 m-0">
                    {t(Email_Share_Txt)}
                  </Title>
                  <Title level={5} className="fs-14 m-0">
                    {t(Email_Share_Txt_Part2)}
                  </Title>
                </div>
                <Title
                  level={5}
                  className="d-flex fs-16 m-0 emailid-copyable"
                  copyable={{
                    text: copyableMail,
                  }}
                >
                  <div className="text-one">{t(Email_Share_Part_One_Txt)}</div>
                  {/* <div className="text-two">{t(copyableMail)}</div> */}
                </Title>
              </Row>
              <Row justify="space-between" align="middle">
                <Title level={5} className="fs-18">
                  {t(Upload_Url_Text)}
                </Title>
                <InfoButton title={csvLinkUploadInfo} placement="topRight" />
              </Row>
              <Row>
                <Form onFinish={() => uploadFileHandler()} className="w-100">
                  <Form.Item className="m-0">
                    <Input.Group compact className="upload-link-container ">
                      <Input
                        addonBefore={
                          <LinkOutlined className="fs-24 rotate-45" />
                        }
                        placeholder={t(Google_Sheet_Upload_Url)}
                        onChange={(e) =>
                          handleWorkSheetConfig("uploadUrl", e.target.value)
                        }
                        value={workSheetConfig?.uploadUrl}
                      />

                      <Button
                        type="primary"
                        htmlType="submit"
                        className="sheet-upload-btn"
                      >
                        {t(Upload_Btn)}
                      </Button>
                    </Input.Group>
                  </Form.Item>
                </Form>
              </Row>
            </Row>
          </Row>
        </Col>
      </Row>

      <CustomModal
        open={refreshIntervalNotSetModal}
        closable={true}
        centered
        onCancel={() => setRefreshIntervalNotSetModal(false)}
        footer={null}
        width={472}
        bodyStyle={{
          height: 300,
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <Row
          justify="center"
          align="middle"
          gutter={[24, 24]}
          className="flex-col"
        >
          <Title className="fs-18 text-center">
            You have not set the
            <span className="fs-20 font-matter-bold "> ”Refresh Interval”</span>
            .
            <br /> Please set it for regular refresh of data
          </Title>
          <Button
            type="primary"
            onClick={() => setRefreshIntervalNotSetModal(false)}
            className="px-3"
          >
            {t(OK_Btn)}
          </Button>
        </Row>
      </CustomModal>
    </>
  );
};

export default UploadFiles;
