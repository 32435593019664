import React, { useCallback, useState } from "react";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import { Button } from "antd";
import { API, graphqlOperation } from "aws-amplify";
import {
  createQsdashboardDetail,
  updateQsdashboardDetail,
} from "graphql/mutations";
import { QS_DASHBOARD_TYPE, QsdashboardDetail } from "_generated_/graphql";

type AddDashboardModalProps = DialogProps & {
  school_id?: string;
  school_name?: string;
  dashboard?: QsdashboardDetail;
};

export const AddEditDashboardModal = ({
  open,
  onClose,
  school_id,
  school_name,
  dashboard,
  ...rest
}: AddDashboardModalProps) => {
  const [fields, setFields] = useState<Partial<QsdashboardDetail>>(
    dashboard ?? {
      dashboard_name: "", //programatic name
      dashboard_name_public: "", //display name
      dashboard_description: "",
      dashboard_id: "", //ID in quicksight
    }
  );
  const [saving, setSaving] = useState<boolean>(false);

  const handleFieldChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      console.log(e);
      setFields((existing) => ({
        ...existing,
        [e.target.name]: e.target.value,
      }));
    },
    []
  );

  const handleAddDashboard = useCallback(async () => {
    setSaving(true);
    try {
      await API.graphql(
        graphqlOperation(createQsdashboardDetail, {
          input: {
            author: school_id ? school_name : "SchoolBI",
            dashboard_description: fields.dashboard_description,
            dashboard_id: fields.dashboard_id,
            dashboard_name: fields.dashboard_name,
            dashboard_name_public: fields.dashboard_name_public,
            dashboard_type: school_id
              ? QS_DASHBOARD_TYPE.CUSTOM_DASHBOARD
              : QS_DASHBOARD_TYPE.PRESCRIPTIVE_DASHBOARD,
            school_id: school_id,
          },
        })
      );
      if (onClose) {
        onClose({}, "escapeKeyDown");
      }
    } finally {
      setSaving(false);
    }
  }, [
    school_id,
    school_name,
    fields.dashboard_description,
    fields.dashboard_id,
    fields.dashboard_name,
    fields.dashboard_name_public,
    onClose,
  ]);

  const handleUpdateDashboard = useCallback(async () => {
    if (!dashboard) {
      return;
    }
    setSaving(true);
    try {
      await API.graphql(
        graphqlOperation(updateQsdashboardDetail, {
          input: {
            id: dashboard.id,
            author: dashboard.author,
            dashboard_description: fields.dashboard_description,
            dashboard_id: fields.dashboard_id,
            dashboard_name: fields.dashboard_name,
            dashboard_name_public: fields.dashboard_name_public,
            dashboard_type: dashboard.dashboard_type,
            school_id: dashboard.school_id,
          },
        })
      );
      if (onClose) {
        onClose({}, "escapeKeyDown");
      }
    } finally {
      setSaving(false);
    }
  }, [
    dashboard,
    fields.dashboard_description,
    fields.dashboard_id,
    fields.dashboard_name,
    fields.dashboard_name_public,
    onClose,
  ]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={"md"}
      fullWidth={true}
      {...rest}
    >
      <DialogTitle>{dashboard ? "Edit" : "Add"} Dashboard</DialogTitle>
      <DialogContent>
        <Stack direction="column" spacing={2} padding={2}>
          <TextField
            type="text"
            label="Dashboard programatic name"
            value={fields.dashboard_name}
            name="dashboard_name"
            onChange={handleFieldChange}
          />
          <TextField
            type="text"
            label="Dashboard Display Name"
            name="dashboard_name_public"
            value={fields.dashboard_name_public}
            onChange={handleFieldChange}
          />
          <TextField
            type="text"
            multiline
            label="Description"
            name="dashboard_description"
            value={fields.dashboard_description}
            onChange={handleFieldChange}
          />
          <TextField
            type="text"
            label="Quicksight Dashboard ID"
            name="dashboard_id"
            value={fields.dashboard_id}
            onChange={handleFieldChange}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        {dashboard && (
          <Button
            color="primary"
            variant={"solid"}
            onClick={handleUpdateDashboard}
            disabled={saving}
          >
            {saving ? <CircularProgress /> : "Update Dashboard"}
          </Button>
        )}
        {!dashboard && (
          <Button
            color="primary"
            variant={"solid"}
            onClick={handleAddDashboard}
            disabled={saving}
          >
            {saving ? <CircularProgress /> : "Add Dashboard"}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
