import React, { useState, useEffect } from "react";
import { Column, G2 } from "@ant-design/plots";

const ApiCallsChart = ({ data }) => {
  // G2.registerInteraction("element-link", {
  //   start: [
  //     {
  //       trigger: "interval:mouseenter",
  //       action: "element-link-by-color:link",
  //     },
  //   ],
  //   end: [
  //     {
  //       trigger: "interval:mouseleave",
  //       action: "element-link-by-color:unlink",
  //     },
  //   ],
  // });

  const initialConfig = {
    data: [],
    xField: "time",
    yField: "volume",
    isGroup: true,
    isStack: true,
    seriesField: "dataset",
    groupField: "apiKey",
    color: ({ dataset }) => {
      switch (dataset) {
        case "Academics":
          return "#FFF3CA";
        case "Athletics":
          return "#ffeba0";
        case "Admissions":
          return "#F4DB5D";
        case "AdvancedList":
          return "#de9c00";
        case "Custom":
          return "#FFC90A";
        // case "Development":
        //   return "blue";
        default:
          return "#000000";
      }
    },
    interactions: [
      {
        type: "element-highlight-by-color",
      },
      {
        type: "element-link",
      },
    ],
  };

  const [config, setConfig] = useState(initialConfig);

  useEffect(() => {
    setConfig((pS) => {
      const volumeByTime = {};

      for (const entry of data) {
        const { dataset, volume, apiKey, time } = entry;
        const key = `${dataset}-${time}`;
        if (volumeByTime.hasOwnProperty(key)) {
          volumeByTime[key].volume += volume;
        } else {
          volumeByTime[key] = { dataset, volume, apiKey, time };
        }
      }
      const output = Object.values(volumeByTime);

      return { ...pS, data: output };
    });
  }, [data]);

  return <Column {...config} />;
};

export default ApiCallsChart;
