import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect, Route, RouteProps } from "react-router-dom";
import FullPageLayout from "containers/FullPageLayout";
import ApiResultModal from "Common/Components/ApiResultModal/ApiResultModal";

type AccountLayoutRouteProps = RouteProps & {
  token?: string;
  groups: string[];
  render: (props: object) => unknown;
};

/**
 * Account Setup Routes Higher Order Component
 */
const SetupLayoutRoute: React.FC<AccountLayoutRouteProps> = ({
  token,
  groups,
  render,
  ...rest
}) => {
  //** Destructing Props */

  const [apiResultModal, setApiResultModal] = useState({
    message: "",
    isApiResultModelOpen: false,
    type: "failure",
  });

  /**
   * @useEffect
   * Sending message if token does not exist
   */
  useEffect(() => {
    if (!token) {
      setApiResultModal(() => ({
        message: "Please login",
        isApiResultModelOpen: true,
        type: "failure",
      }));
    }
  }, [token]);

  const getCondition = () => {
    if (window.location.host.includes("stag")) {
      return groups?.length > 1 && groups?.includes("stage_users");
    } else {
      return groups?.length > 0;
    }
  };

  return (
    <>
      <Route
        {...rest}
        render={(matchProps) => (
          <>
            {!token && <Redirect to={"/signin"} />}
            {token && getCondition() && <Redirect to="/" />}
            {token && <FullPageLayout>{render(matchProps)}</FullPageLayout>}
          </>
        )}
      />
      <ApiResultModal
        apiResultModal={apiResultModal}
        setApiResultModal={setApiResultModal}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.loginReducer.token,
    groups: state.loginReducer.groups,
  };
};
export default connect(mapStateToProps)(SetupLayoutRoute);
// export default AccountLayoutRoute;
