import { API } from "aws-amplify";
import { apiExecuter, GraphqlOperationExecutor } from "utility/commonMethods";
import { updateGoogleSheetDetail } from "graphql/mutations";
import {
  getAccountForGoogleSheetCustom,
  listDataSetupsCustom,
} from "graphql/customQueries";
import {
  Account,
  CancelledGoogleSheetHandlerFunction,
  DeleteGoogleSheetDetailHandlerFunction,
  GetAllGoogleSheetFunction,
  GetCSVFieldsFunction,
  GetWorksheetFieldsFunction,
  GoogleSheetLinkHandlerFunction,
  OnDemandSyncFunction,
  PreviewFileHandlerFunction,
  SetCSVFieldsFunction,
  TableConfigurationHandlerFunction,
  TerminateSheetHandlerFunction,
} from "types";
import { TerminateSheetHandlerRequest } from "types/requests/WorksheetRequest";
import { WorkSheet, WorksheetRecord } from "types/models/Worksheet";
import {
  ListDataSetupsCustomQuery,
  UpdateGoogleSheetDetailMutation,
} from "_generated_/graphql";
import { StatusResult } from "./types";

const googleSheetLinkHandler: GoogleSheetLinkHandlerFunction = (body) =>
  apiExecuter(async () => {
    const url: string = "/customdata/googlesheetlink";
    return (await API.post("schoolBIREST", url, {
      variables: body,
    })) as Promise<WorkSheet>;
  });

const tableConfigurationHandler: TableConfigurationHandlerFunction = (body) => {
  return apiExecuter(async () => {
    const url: string = "/customdatauploadasync";
    return await API.post("schoolBIREST", url, { variables: body });
  });
};

const previewFileHandler: PreviewFileHandlerFunction = (body) => {
  return apiExecuter(async () => {
    const url: string = "/customdata/previewdata";
    return await API.post("schoolBIREST", url, { variables: body });
  });
};

const updateRefreshHandler = (
  inputData: UpdateGoogleSheetDetailMutation
): Promise<
  UpdateGoogleSheetDetailMutation["updateGoogleSheetDetail"] | StatusResult
> => {
  return GraphqlOperationExecutor({
    query: updateGoogleSheetDetail,
    variables: {
      input: inputData,
    },
  });
};

//FIXME: should be using graphql derived types, not hard coded nonsense
const getAllGoogleSheet: GetAllGoogleSheetFunction = async (accountId) => {
  const data: Account = (await GraphqlOperationExecutor({
    query: getAccountForGoogleSheetCustom,
    variables: {
      id: accountId,
    },
  })) as Account;

  return {
    request_googlesheet: data?.request_googlesheet?.items,
    users: data?.users?.map((item) => JSON.parse(item)) ?? [],
  } as Account;
};

const cancelledGoogleSheetHandler: CancelledGoogleSheetHandlerFunction = (
  body
) => {
  return apiExecuter(async () => {
    const url: string = "/customdata/tryagain";
    return await API.post("schoolBIREST", url, { variables: body });
  });
};

const isCancelledHandler = (
  inputData: UpdateGoogleSheetDetailMutation
): Promise<
  UpdateGoogleSheetDetailMutation["updateGoogleSheetDetail"] | StatusResult
> => {
  return GraphqlOperationExecutor({
    query: updateGoogleSheetDetail,
    variables: {
      input: inputData,
    },
  });
};

const terminateSheetHandler: TerminateSheetHandlerFunction = (body) => {
  return apiExecuter(async () => {
    const url: string = "/customdata/terminatefile";
    return await API.post("schoolBIREST", url, { variables: body });
  });
};

const deleteGoogleSheetDetailHandler: DeleteGoogleSheetDetailHandlerFunction =
  async (record) => {
    const { googlesheet_id, worksheet_id, id, school_id } = record;

    const variables: TerminateSheetHandlerRequest = {
      googlesheet_id,
      worksheet_id,
      id,
      school_id,
    };

    return apiExecuter(async () => {
      const url: string = "/customdata/deletefile";
      return await API.post("schoolBIREST", url, { variables });
    });
  };

const onDemandSync: OnDemandSyncFunction = async (record) => {
  const {
    googlesheet_id,
    worksheet_id,
    id,
    school_id,
    googlesheet_name,
    worksheet_name,
    interval_type,
    googlesheet_link,
  }: WorksheetRecord = record;

  const variables: WorksheetRecord = {
    googlesheet_id,
    worksheet_id,
    id,
    school_id,
    googlesheet_name,
    worksheet_name,
    interval_type,
    googlesheet_link,
    sheet_name: "",
    googleSheetLink: "",
  };

  return apiExecuter(async () => {
    const url: string = "/customdata/ondemand/sync";
    return await API.post("schoolBIREST", url, { variables });
  });
};

const getFileFieldsData = async ({
  school_id,
  worksheet_id,
}): Promise<ListDataSetupsCustomQuery["listDataSetups"] | StatusResult> => {
  return GraphqlOperationExecutor({
    query: listDataSetupsCustom,
    variables: {
      limit: 999,
      filter: {
        school_id: { eq: school_id },
        worksheet_id: { eq: worksheet_id },
      },
    },
  });
};

const getWorksheetFields: GetWorksheetFieldsFunction = async (record) => {
  const { sheet_name, googleSheetLink }: WorksheetRecord = record;

  return apiExecuter(async () => {
    const url: string = `/customdata/googlesheet/datatype`;

    return await API.post("schoolBIREST", url, {
      variables: {
        sheet_name,
        googleSheetLink,
      },
    });
  });
};

const getCSVFields: GetCSVFieldsFunction = async (input) =>
  apiExecuter(async () => {
    const url: string = `/customdata/csv/datatype`;

    return await API.post("schoolBIREST", url, {
      body: input,
      headers: { "content-type": "application/json" },
    });
  });

const setCSVFields: SetCSVFieldsFunction = async (input) =>
  apiExecuter(async () => {
    const url: string = `/csv/upload`;

    return await API.post("schoolBIREST", url, {
      body: input,
      headers: { "content-type": "application/json" },
    });
  });

const ManageFileSDK = {
  googleSheetLinkHandler,
  tableConfigurationHandler,
  previewFileHandler,
  updateRefreshHandler,
  getAllGoogleSheet,
  cancelledGoogleSheetHandler,
  isCancelledHandler,
  terminateSheetHandler,
  deleteGoogleSheetDetailHandler,
  onDemandSync,
  getFileFieldsData,
  getWorksheetFields,
  getCSVFields,
  setCSVFields,
};

export default ManageFileSDK;
