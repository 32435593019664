import React, { useCallback } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
} from "@mui/material";

type ConfirmationModalProps = DialogProps & {
  onConfirm: () => void;
  message: string;
  title: string;
  buttonText: string;
  buttonColor?: "primary" | "warning" | "success";
};

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  open,
  onClose,
  onConfirm,
  message,
  buttonColor,
  buttonText,
  title,
}) => {
  const handleCancel = useCallback(
    () => onClose && onClose({}, "backdropClick"),
    [onClose]
  );
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{message}</DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color={"secondary"}>
          Cancel
        </Button>
        <Button
          onClick={onConfirm}
          color={buttonColor || "primary"}
          variant="contained"
        >
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
