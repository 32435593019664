import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Companies from "./schools/Companies";
import Users from "./users/Users";
import "./Admin.less";
import Dashboard from "./Dashboard/Dashboard";
import { School } from "./schools/School";

/**
 * Admin index component
 */
export default function Admin(): React.ReactElement {
  return (
    <Switch>
      <Route path={"/admin/dashboard"} component={Dashboard} />
      <Route path="/admin/users" component={Users} />
      <Route path="/admin/schools/:school_id" component={School} />
      <Route path="/admin/schools" component={Companies} />
      <Redirect from="/admin" to={"/admin/dashboard"} />
    </Switch>
  );
}
