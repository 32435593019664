import React from "react";
import { Grid2, Typography } from "@mui/material";
import PaddedCard from "Common/Components/PaddedCard/PaddedCard";

const Dashboard: React.FC = () => {
  return (
    <Grid2 container spacing={2}>
      <Grid2 size={{ xs: 12, md: 6, lg: 4 }}>
        <PaddedCard>
          <Typography variant="h5">Overnight Ingestion Status</Typography>

          <ul>
            <li>Blackbaud</li>
            <li>Ravenna</li>
            <li>Veracross</li>
          </ul>
        </PaddedCard>
      </Grid2>
      <Grid2 size={{ xs: 12, md: 6, lg: 4 }}>
        <PaddedCard>
          <Typography variant="h5">Overnight ETL Status</Typography>
          OK
        </PaddedCard>
      </Grid2>
    </Grid2>
  );
};

export default Dashboard;
