import { ThemeConfig } from "antd";
import { ComponentToken } from "antd/es/app/style";
import { AliasToken } from "antd/lib/theme/interface";

export const palette = {
  primary: {
    foreground: "#ffc90a",
    background: "#000000",
  },
  secondary: {
    foreground: "",
    background: "",
  },
  tertiary: { foreground: "#64748b" },
};

const theme: ThemeConfig = {
  token: {
    colorPrimary: palette.primary.foreground,
    colorPrimaryBg: palette.primary.background,
    colorLink: palette.primary.foreground,
    colorLinkActive: palette.primary.foreground,
    fontFamily: "Matter",
  },
  components: {
    Button: {
      colorTextLightSolid: palette.primary.background,
      padding: 10,
      fontWeight: "bold",
      primaryShadow: "2px 2px 3px rgba(0, 0, 0, 0.3)",
    } as ComponentToken & Partial<AliasToken>,
    Input: {
      controlOutline: "2px 2px 3px rgba(0, 0, 0, 0.3)",
    },
    Select: {
      optionSelectedBg: "#fffee6",
    },
  },
};

export default theme;
