//** React Imports */
import React from "react";
//** Ant Design Imports */
import { Modal, ModalProps } from "antd";

type CustomModalProps = ModalProps & {
  //deprecated - redefining here so it doesn't vanish when upgrading to v5
  visible: boolean;

  onCancel?: () => void;
  closeClickHandler?: () => void;
};

const CustomModal = ({
  children,
  closeClickHandler,
  closable,
  visible,
  open,
  ...rest
}: CustomModalProps) => {
  return (
    <Modal
      {...rest}
      open={visible ?? open}
      styles={{ mask: { backdropFilter: "blur(4px)" } }}
      closable={closable || false}
      onCancel={() => {
        if (closeClickHandler) closeClickHandler();
        if (rest?.onCancel) rest?.onCancel();
      }}
    >
      {children}
    </Modal>
  );
};

export default CustomModal;
