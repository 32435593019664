import React from "react";
import { Amplify, Auth } from "aws-amplify";
import Routes from "./Routers/Routes";
import config from "./aws-exports";
import "style/global.less";
import "Pages/Login_Module/user.less";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "config/i18n/i18n";
import "pure-react-carousel/dist/react-carousel.es.css";

const App = () => {
  if (process.env.NODE_ENV === "production") {
    console.log = () => {};
    console.error = () => {};
    console.debug = () => {};
  }

  const updatedAwsConfig = {
    ...config,
    // Set API to use id token. This will ensure email is passed within the token
    API: {
      graphql_headers: async () => ({
        Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
      }),
    },
    Auth: {
      attributes: {
        "custom:mfa": "false",
      },
    },
  };

  Amplify.configure(updatedAwsConfig);

  return <Routes />;
};

export default App;
