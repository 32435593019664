import { useCallback } from "react";
import {
  setIsScopeNormalUser,
  setSchoolData,
  updateCompanyAccount,
} from "../../store/actions/loginAction";
import { useDispatch } from "react-redux";

/**
 * Hook to switch the admin user's school
 */
const useSelectSchool = () => {
  const dispatch = useDispatch();
  return useCallback(
    (accountId: { role: string; Company_Data: unknown }, from: string) => {
      console.log("accountId :: ", accountId);
      switch (from) {
        case "user":
          if (
            accountId?.role === "normal_user" ||
            accountId?.role === "ReportUser" ||
            accountId?.role === "SchoolAdmin"
          ) {
            dispatch(setIsScopeNormalUser(true));
          } else {
            dispatch(setIsScopeNormalUser(false));
          }
          dispatch(updateCompanyAccount(accountId?.Company_Data));
          dispatch(setSchoolData(accountId?.Company_Data));
          break;
        default:
          dispatch(updateCompanyAccount(accountId));
          dispatch(setSchoolData(accountId));
          break;
      }

      setTimeout(() => {
        window.location.href = "/";
      }, 500);
    },
    [dispatch]
  );
};

export default useSelectSchool;
