import {
  MoreOutlined,
  StarFilled,
  StarOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Drawer,
  Form,
  Image,
  Input,
  Row,
  Select,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { Images } from "assets/images/Images";
import { startCase, toLower, uniqueId } from "lodash";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import QsDashboardSDK from "sdk/QsDashboardSDK";
import { buttonText } from "utility/constants";
import BeeWhiteLogo from "assets/images/Logo/BeeWhiteLogo.svg";
import { v4 as uuidv4 } from "uuid";
import { DashboardIcon } from "utility/context/Menu";
import { usePermissions, Permission } from "../../hooks/usePermissions";

const QSDashboardCard = ({
  data,
  manipulateDashboardCard,
  arrangeDashboardCard,
  setData,
}) => {
  const history = useHistory();
  const { t } = useTranslation();

  const { accountId, userId } = useSelector((state) => state.loginReducer);
  const { can } = usePermissions();

  const [dashbordDetailForm] = Form.useForm();

  const { Text, Title } = Typography;

  const { NoDashboardSvg } = Images?.QuicksightDashboard;

  const { Save, Cancel } = buttonText;

  const [editDrawer, setEditDrawer] = useState(false);
  const [dashboardDetails, setDashboardDetails] = useState({});

  const toggleDrawer = () => {
    setEditDrawer((pS) => !pS);
  };

  const handleQSDashboardUrl = (item) => {
    const { dashboard_name_public, dashboard_name, id } = item;

    const url = `/qs-dashboard/${toLower(dashboard_name)}`;
    history.push({
      pathname: url,
      state: {
        dashboardName: toLower(dashboard_name),
        dashboardId: id,
        dashboardPublicName: dashboard_name_public,
      },
    });
  };

  const handleFavorite = async (e, ID, isCreate) => {
    e.stopPropagation();
    const manipulateDashboardCardItem = data?.find(({ id }) => ID === id);

    const {
      id,
      isFavoriteOf: { isFavoriteId },
    } = manipulateDashboardCardItem;

    if (isCreate) {
      const favId = uuidv4();

      manipulateDashboardCard(ID, {
        isFavoriteOf: {
          isFavorite: true,
          isFavoriteId: favId,
        },
      });

      const arrangeData = arrangeDashboardCard(data);
      setData(arrangeData);
      await QsDashboardSDK.createFavoriteDashboard({
        id: favId,
        dashboard_id: id,
        userId: userId || accountId,
      });
    } else {
      manipulateDashboardCard(ID, {
        isFavoriteOf: {
          isFavorite: false,
          isFavoriteId,
        },
      });
      const arrangeData = arrangeDashboardCard(data);
      setData(arrangeData);
      await QsDashboardSDK.deleteFavoriteDashboard({
        id: isFavoriteId,
      });
    }
  };

  const handleEdit = (e, dashboardDetails) => {
    e.stopPropagation();
    toggleDrawer();
    setDashboardDetails(dashboardDetails);
    dashbordDetailForm.setFieldsValue(dashboardDetails);
  };

  const handleUpdateDashboard = async () => {
    const values = dashbordDetailForm.getFieldsValue();
    const input = { id: dashboardDetails?.id, ...values };
    manipulateDashboardCard(dashboardDetails?.id, { ...values });
    toggleDrawer();
    await QsDashboardSDK.updateDashboardDetails(input);
  };

  const QsDashboardCardContainer = ({
    id,
    dashboard_id,
    dashboard_type,
    isFavoriteOf,
    dashboard_name_public,
    dashboard_description,
    author,
    tags,
    createdAt,
    className,
    onClick,
  }) => {
    return (
      <Card className={className} onClick={onClick}>
        <Row justify="space-between">
          <Row className="gap-1 align-center ">
            {dashboard_type === "PRESCRIPTIVE_DASHBOARD" ? (
              <img
                src={BeeWhiteLogo}
                className="p-0 m-0 presciptive-logo"
                alt=""
              />
            ) : (
              <DashboardIcon />
            )}
            <Text className="qs-text-color">{dashboard_name_public}</Text>
          </Row>

          <Row className="gap-1">
            {dashboard_id ? (
              <>
                {isFavoriteOf?.isFavorite ? (
                  <Tooltip title="Remove from your favorites">
                    <StarFilled
                      className="primary-color fs-20"
                      onClick={(e) => handleFavorite(e, id, false)}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip title="Add to your favorites">
                    <StarOutlined
                      className="primary-color fs-20"
                      onClick={(e) => handleFavorite(e, id, true)}
                    />
                  </Tooltip>
                )}
              </>
            ) : null}

            {dashboard_type !== "PRESCRIPTIVE_DASHBOARD" &&
            can(Permission.EditCustomDashboardMeta) ? (
              <MoreOutlined
                className="fs-20 dashboard-more-outlined"
                onClick={(e) =>
                  handleEdit(e, {
                    id,
                    dashboard_name_public,
                    dashboard_description,
                    tags,
                  })
                }
              />
            ) : null}
          </Row>
        </Row>
        <Row justify="space-between">
          <Col>
            <Row className="gap-1">
              <UserOutlined className="fs-14 qs-icon" />{" "}
              <Text className="fs-14 qs-author"> {author} </Text>
            </Row>
          </Col>
          <Col>
            <Row className="gap-1">
              <Moment date={createdAt} format="ll" className="fs-14 qs-date" />
            </Row>
          </Col>
        </Row>
        <Row>
          <Input.TextArea
            bordered={false}
            resize
            className="lh-sm fs-14 p-0 m-0 overflow-hidden cursor-pointer"
            value={dashboard_description}
            autoSize={{
              minRows: 3,
              maxRows: 3,
            }}
            readOnly
          ></Input.TextArea>
        </Row>
        <Row className="gap-1 tag-row">
          {tags?.slice(0, 5)?.map((tag) => (
            <Tag className="qs-tag m-0" key={tag}>
              {startCase(tag)}
            </Tag>
          ))}

          {tags?.length > 5 && (
            <Tooltip
              className="fs-14"
              title={
                <Row className="gap-1">
                  {tags?.slice(5)?.map((tag) => (
                    <Tag className="qs-tag m-0" key={tag}>
                      {startCase(tag)}
                    </Tag>
                  ))}
                </Row>
              }
            >
              + {tags.length - 5}
            </Tooltip>
          )}
        </Row>
      </Card>
    );
  };

  return (
    <>
      {data?.length > 0 ? (
        <Row className="qs-dashboard-card-container" gutter={[12, 12]}>
          {data?.map((item) => {
            return (
              <Col span={8}>
                {item?.dashboard_id ? (
                  <QsDashboardCardContainer
                    type="enable"
                    onClick={() => handleQSDashboardUrl(item)}
                    className="qs-dashboard-card enable-dashboard-card"
                    {...item}
                  />
                ) : (
                  <Tooltip
                    title="Coming Soon"
                    placement="topLeft"
                    color="#F5DB5D"
                  >
                    <QsDashboardCardContainer
                      type="disable"
                      className="qs-dashboard-card disable-qs-dashboard-card"
                      {...item}
                    />
                  </Tooltip>
                )}
              </Col>
            );
          })}
          <Drawer
            open={editDrawer}
            title={<span className="font-bold"> Edit details </span>}
            width={400}
            className="dashboard-drawer"
            onClose={toggleDrawer}
          >
            <Form
              name="basic"
              layout="vertical"
              requiredMark={false}
              form={dashbordDetailForm}
              onFinish={handleUpdateDashboard}
            >
              <Form.Item
                name="dashboard_name_public"
                label={"Name"}
                className="font-bold"
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
              >
                <Input.TextArea
                  showCount
                  maxLength={25}
                  rows={1}
                  placeholder="Enter dashboard name"
                  autoSize={{
                    minRows: 1,
                    maxRows: 1,
                  }}
                />
              </Form.Item>

              <Form.Item
                name="dashboard_description"
                className="font-bold"
                label="Description"
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
              >
                <Input.TextArea
                  showCount
                  maxLength={100}
                  rows={3}
                  autoSize={{
                    minRows: 3,
                    maxRows: 3,
                  }}
                  placeholder="Enter dashboard description"
                />
              </Form.Item>

              <Form.Item name="tags" label="Tags" className="font-bold">
                <Select
                  mode="tags"
                  className="dashboard-selector"
                  tokenSeparators={[","]}
                  notFoundContent=""
                  placeholder="Enter dashboard tags"
                  allowClear
                  maxTagCount={5}
                />
              </Form.Item>
              <div className="dashboard-custom-footer">
                <div className="d-flex gap-3 ">
                  <Button
                    className="secondary-btn w-50 m-0"
                    onClick={toggleDrawer}
                  >
                    {t(Cancel)}
                  </Button>

                  <Button
                    type="primary w-50"
                    className="px-3"
                    htmlType="submit"
                  >
                    {t(Save)}
                  </Button>
                </div>
              </div>
            </Form>
          </Drawer>
        </Row>
      ) : (
        <div className="flex-col bg-white  w-100 h-100 align-center rounded-3 py-5">
          <Image preview={false} src={NoDashboardSvg} alt="..." height={300} />
          <Title level={3}>No Dashboard</Title>
        </div>
      )}
    </>
  );
};

export default QSDashboardCard;
