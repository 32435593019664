import { ItemType } from "antd/lib/menu/interface";
import { Permission } from "../../hooks/usePermissions";
import { Link } from "react-router-dom";
import React from "react";
import {
  AccountBalance,
  Checklist,
  KeyboardReturn,
  Person,
} from "@mui/icons-material";

const AdminMenu: Array<ItemType & { permission: null | Permission }> = [
  {
    label: (
      <Link to="/" className="color-white font-bold">
        Exit Admin
      </Link>
    ),
    key: "home",
    icon: <KeyboardReturn />,
    permission: Permission.AdminSchools,
  },
  {
    label: (
      <Link to="/admin/dashboard" className="color-white font-bold">
        Dashboard
      </Link>
    ),
    key: "admin/dashboard",
    icon: <Checklist />,
    permission: Permission.AdminSchools,
  },
  {
    label: (
      <Link to="/admin/users" className="color-white font-bold">
        Manage Users
      </Link>
    ),
    key: "admin/users",
    icon: <Person />,
    permission: Permission.AdminSchools,
  },
  {
    label: (
      <Link to="/admin/schools" className="color-white font-bold">
        Manage Schools
      </Link>
    ),
    key: "admin/schools",
    icon: <AccountBalance />,
    permission: Permission.AdminSchools,
  },
];

export default AdminMenu;
