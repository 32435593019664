/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type __Type = {
  __typename: "__Type",
  kind: __TypeKind,
  name?: string | null,
  description?: string | null,
  specifiedByUrl?: string | null,
  fields?:  Array<__Field > | null,
  interfaces?:  Array<__Type > | null,
  possibleTypes?:  Array<__Type > | null,
  enumValues?:  Array<__EnumValue > | null,
  inputFields?:  Array<__InputValue > | null,
  ofType?: __Type | null,
};

// An enum describing what kind of type a given `__Type` is.
export enum __TypeKind {
  SCALAR = "SCALAR", // Indicates this type is a scalar.
  OBJECT = "OBJECT", // Indicates this type is an object. `fields` and `interfaces` are valid fields.
  INTERFACE = "INTERFACE", // Indicates this type is an interface. `fields`, `interfaces`, and `possibleTypes` are valid fields.
  UNION = "UNION", // Indicates this type is a union. `possibleTypes` is a valid field.
  ENUM = "ENUM", // Indicates this type is an enum. `enumValues` is a valid field.
  INPUT_OBJECT = "INPUT_OBJECT", // Indicates this type is an input object. `inputFields` is a valid field.
  LIST = "LIST", // Indicates this type is a list. `ofType` is a valid field.
  NON_NULL = "NON_NULL", // Indicates this type is a non-null. `ofType` is a valid field.
}


export type __Field = {
  __typename: "__Field",
  name: string,
  description?: string | null,
  args:  Array<__InputValue >,
  type: __Type,
  isDeprecated: boolean,
  deprecationReason?: string | null,
};

export type __InputValue = {
  __typename: "__InputValue",
  name: string,
  description?: string | null,
  type: __Type,
  // A GraphQL-formatted string representing the default value for this input value.
  defaultValue?: string | null,
  isDeprecated: boolean,
  deprecationReason?: string | null,
};

export type __EnumValue = {
  __typename: "__EnumValue",
  name: string,
  description?: string | null,
  isDeprecated: boolean,
  deprecationReason?: string | null,
};

export type Account = {
  __typename: "Account",
  id: string,
  company?: string | null,
  company_loweredcased?: string | null,
  company_description?: string | null,
  url?: string | null,
  disabled?: boolean | null,
  piiInformation?: boolean | null,
  isSchoolAdmin?: boolean | null,
  email?: string | null,
  users?: Array< string | null > | null,
  createdAt: string,
  updatedAt: string,
  data_setup?: ModeldataSetupConnection | null,
  accountStatus?: ACCT_STATUS | null,
  stripeCustomerID?: string | null,
  stripeSubscriptionID?: string | null,
  stripePriceID?: string | null,
  planID?: string | null,
  metadata?: string | null,
  owners?: Array< string | null > | null,
  editors?: Array< string | null > | null,
  viewers?: Array< string | null > | null,
  datasource?: Array< DATASOURCE | null > | null,
  dataprovider?: Array< DATAPROVIDER | null > | null,
  advancedList?: ModelAdvancedListDetailConnection | null,
  request_googlesheet?: ModelGoogleSheetDetailConnection | null,
  recurringSync?: ModelRecurringSyncConnection | null,
  apihistory?: ModelCountOfAPIHistoryConnection | null,
  dataSet?: ModelDataSetDetailConnection | null,
  requests?: ModelAccountRequestConnection | null,
  plan?: Plan | null,
};

export type ModeldataSetupConnection = {
  __typename: "ModeldataSetupConnection",
  items:  Array<dataSetup | null >,
  nextToken?: string | null,
};

export type dataSetup = {
  __typename: "dataSetup",
  id?: string | null,
  school_id?: string | null,
  dimension_or_metrics?: DMTYPE | null,
  field_key?: string | null,
  friendly_name?: string | null,
  field_description?: string | null,
  field_type_GDS?: FIELD_TYPE_GDS | null,
  field_type_PBI?: FIELD_TYPE_PBI | null,
  data_source?: Array< DATASOURCE | null > | null,
  data_set?: string | null,
  api_endpoint?: string | null,
  name_by_school?: string | null,
  worksheet_name?: string | null,
  googlesheet_name?: string | null,
  worksheet_id?: string | null,
  googlesheet_id?: string | null,
  createdAt: string,
  updatedAt: string,
};

export enum DMTYPE {
  DIMENSION = "DIMENSION",
  METRICS = "METRICS",
}


export enum FIELD_TYPE_GDS {
  TEXT = "TEXT",
  BOOLEAN = "BOOLEAN",
  NUMBER = "NUMBER",
  DATETIME = "DATETIME",
  YEAR_MONTH_DAY = "YEAR_MONTH_DAY",
}


export enum FIELD_TYPE_PBI {
  TEXT = "TEXT",
  BOOLEAN = "BOOLEAN",
  NUMBER = "NUMBER",
  YEAR_MONTH_DAY = "YEAR_MONTH_DAY",
}


export enum DATASOURCE {
  BLACKBAUD_ENROLLMENT_MANAGEMENT = "BLACKBAUD_ENROLLMENT_MANAGEMENT",
  BLACKBAUD_STUDENT_MANAGEMENT = "BLACKBAUD_STUDENT_MANAGEMENT",
  BLACKBAUD_ATHLETICS = "BLACKBAUD_ATHLETICS",
  BLACKBAUD_FUNDRAISING = "BLACKBAUD_FUNDRAISING",
  CUSTOM_FILES = "CUSTOM_FILES",
  BLACKBAUD_FINANCIAL_EDGE_NXT = "BLACKBAUD_FINANCIAL_EDGE_NXT",
  BLACKBAUD_TUITION_MANAGEMENT = "BLACKBAUD_TUITION_MANAGEMENT",
  BLACKBAUD_RAISERS_EDGE_NXT = "BLACKBAUD_RAISERS_EDGE_NXT",
  BLACKBAUD_ADVANCED_LIST_MANAGEMENT = "BLACKBAUD_ADVANCED_LIST_MANAGEMENT",
  VERACROSS_ENROLLMENT_MANAGEMENT = "VERACROSS_ENROLLMENT_MANAGEMENT",
  VERACROSS_STUDENT_MANAGEMENT = "VERACROSS_STUDENT_MANAGEMENT",
  VERACROSS_ATHLETICS = "VERACROSS_ATHLETICS",
  VERACROSS_RAISERS_EDGE_NXT = "VERACROSS_RAISERS_EDGE_NXT",
  VERACROSS_DATA_PACKAGES = "VERACROSS_DATA_PACKAGES",
  RAVENNA_ENROLLMENT_MANAGEMENT = "RAVENNA_ENROLLMENT_MANAGEMENT",
  CSV_FILES = "CSV_FILES",
}


export enum ACCT_STATUS {
  ACTIVE = "ACTIVE",
  CREATED = "CREATED",
  FREE_TRIAL = "FREE_TRIAL",
  PENDING_PAYMENT = "PENDING_PAYMENT",
  SUSPENDED = "SUSPENDED",
}


export enum DATAPROVIDER {
  CUSTOM_FILES_DATA = "CUSTOM_FILES_DATA",
  BLACKBAUD_DATA = "BLACKBAUD_DATA",
  VERACROSS_DATA = "VERACROSS_DATA",
  RAVENNA_DATA = "RAVENNA_DATA",
}


export type ModelAdvancedListDetailConnection = {
  __typename: "ModelAdvancedListDetailConnection",
  items:  Array<AdvancedListDetail | null >,
  nextToken?: string | null,
};

export type AdvancedListDetail = {
  __typename: "AdvancedListDetail",
  id: string,
  school_id?: string | null,
  list_name?: string | null,
  list_id?: string | null,
  datasource?: string | null,
  dataset?: string | null,
  status?: STATUS | null,
  preconfigure?: boolean | null,
  last_sync_date?: string | null,
  interval_type?: INTERVAL_TYPE | null,
  recurring_sync_status?: boolean | null,
  custom_interval?: number | null,
  custom_type?: CUSTOM_TYPE | null,
  custom_value?: number | null,
  is_initial_sync?: STATUS | null,
  createdAt: string,
  updatedAt: string,
};

export enum STATUS {
  CONNECTED = "CONNECTED",
  DISCONNECTED = "DISCONNECTED",
  INPROGRESS = "INPROGRESS",
  FAILED = "FAILED",
  DELETING = "DELETING",
  STARTING = "STARTING",
}


export enum INTERVAL_TYPE {
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
  MONTHLY = "MONTHLY",
  YEARLY = "YEARLY",
  NONE = "NONE",
  CUSTOM = "CUSTOM",
}


export enum CUSTOM_TYPE {
  DAY = "DAY",
  WEEK = "WEEK",
  MONTH = "MONTH",
  YEAR = "YEAR",
}


export type ModelGoogleSheetDetailConnection = {
  __typename: "ModelGoogleSheetDetailConnection",
  items:  Array<GoogleSheetDetail | null >,
  nextToken?: string | null,
};

export type GoogleSheetDetail = {
  __typename: "GoogleSheetDetail",
  id: string,
  school_id?: string | null,
  created_by?: string | null,
  googlesheet_link: string,
  googlesheet_name?: string | null,
  googlesheet_id?: string | null,
  worksheet_name?: string | null,
  worksheet_id?: string | null,
  unique_field?: string | null,
  is_obtained?: boolean | null,
  interval_type?: INTERVAL_TYPE | null,
  last_success_sync_date?: string | null,
  last_failure_sync_date?: string | null,
  custom_interval?: number | null,
  is_cancelled?: boolean | null,
  custom_type?: CUSTOM_TYPE | null,
  custom_value?: number | null,
  table_name?: string | null,
  refresh_interval_status?: REFRESH_INTERVAL_STATUS | null,
  createdAt: string,
  updatedAt: string,
};

export enum REFRESH_INTERVAL_STATUS {
  STARTING = "STARTING",
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  IN_PROGRESS = "IN_PROGRESS",
}


export type ModelRecurringSyncConnection = {
  __typename: "ModelRecurringSyncConnection",
  items:  Array<RecurringSync | null >,
  nextToken?: string | null,
};

export type RecurringSync = {
  __typename: "RecurringSync",
  id: string,
  school_id?: string | null,
  success?: boolean | null,
  worksheet_id?: string | null,
  worksheet_name?: string | null,
  interval_type?: string | null,
  sync_for?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelCountOfAPIHistoryConnection = {
  __typename: "ModelCountOfAPIHistoryConnection",
  items:  Array<CountOfAPIHistory | null >,
  nextToken?: string | null,
};

export type CountOfAPIHistory = {
  __typename: "CountOfAPIHistory",
  id: string,
  school_id?: string | null,
  api_key?: string | null,
  dataset_type?: string | null,
  count?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelDataSetDetailConnection = {
  __typename: "ModelDataSetDetailConnection",
  items:  Array<DataSetDetail | null >,
  nextToken?: string | null,
};

export type DataSetDetail = {
  __typename: "DataSetDetail",
  id: string,
  school_id?: string | null,
  data_set?: string | null,
  data_source?: DATASOURCE | null,
  purchased_date?: string | null,
  status?: STATUS | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelAccountRequestConnection = {
  __typename: "ModelAccountRequestConnection",
  items:  Array<AccountRequest | null >,
  nextToken?: string | null,
};

export type AccountRequest = {
  __typename: "AccountRequest",
  id: string,
  account: string,
  owner?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type Plan = {
  __typename: "Plan",
  id: string,
  name: string,
  planDetails?: string | null,
  Targets?: string | null,
  bulletPoints?: Array< string | null > | null,
  listed?: boolean | null,
  order?: number | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateAccountInput = {
  id: string,
  company?: string | null,
  company_loweredcased?: string | null,
  company_description?: string | null,
  url?: string | null,
  disabled?: boolean | null,
  piiInformation?: boolean | null,
  isSchoolAdmin?: boolean | null,
  email?: string | null,
  users?: Array< string | null > | null,
  accountStatus?: ACCT_STATUS | null,
  stripeCustomerID?: string | null,
  stripeSubscriptionID?: string | null,
  stripePriceID?: string | null,
  planID?: string | null,
  metadata?: string | null,
  owners?: Array< string | null > | null,
  editors?: Array< string | null > | null,
  viewers?: Array< string | null > | null,
  datasource?: Array< DATASOURCE | null > | null,
  dataprovider?: Array< DATAPROVIDER | null > | null,
};

export type ModelAccountConditionInput = {
  company?: ModelStringInput | null,
  company_loweredcased?: ModelStringInput | null,
  company_description?: ModelStringInput | null,
  url?: ModelStringInput | null,
  disabled?: ModelBooleanInput | null,
  piiInformation?: ModelBooleanInput | null,
  isSchoolAdmin?: ModelBooleanInput | null,
  email?: ModelStringInput | null,
  users?: ModelStringInput | null,
  accountStatus?: ModelACCT_STATUSInput | null,
  stripeCustomerID?: ModelStringInput | null,
  stripeSubscriptionID?: ModelStringInput | null,
  stripePriceID?: ModelStringInput | null,
  planID?: ModelIDInput | null,
  metadata?: ModelStringInput | null,
  owners?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  viewers?: ModelStringInput | null,
  datasource?: ModelDATASOURCEListInput | null,
  dataprovider?: ModelDATAPROVIDERListInput | null,
  and?: Array< ModelAccountConditionInput | null > | null,
  or?: Array< ModelAccountConditionInput | null > | null,
  not?: ModelAccountConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelACCT_STATUSInput = {
  eq?: ACCT_STATUS | null,
  ne?: ACCT_STATUS | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelDATASOURCEListInput = {
  eq?: Array< DATASOURCE | null > | null,
  ne?: Array< DATASOURCE | null > | null,
  contains?: DATASOURCE | null,
  notContains?: DATASOURCE | null,
};

export type ModelDATAPROVIDERListInput = {
  eq?: Array< DATAPROVIDER | null > | null,
  ne?: Array< DATAPROVIDER | null > | null,
  contains?: DATAPROVIDER | null,
  notContains?: DATAPROVIDER | null,
};

export type UpdateDataSetupInput = {
  id: string,
  school_id?: string | null,
  dimension_or_metrics?: DMTYPE | null,
  field_key?: string | null,
  friendly_name?: string | null,
  field_description?: string | null,
  field_type_GDS?: FIELD_TYPE_GDS | null,
  field_type_PBI?: FIELD_TYPE_PBI | null,
  data_source?: Array< DATASOURCE | null > | null,
  data_set?: string | null,
  api_endpoint?: string | null,
  name_by_school?: string | null,
  worksheet_name?: string | null,
  googlesheet_name?: string | null,
  worksheet_id?: string | null,
  googlesheet_id?: string | null,
};

export type ModeldataSetupConditionInput = {
  school_id?: ModelIDInput | null,
  dimension_or_metrics?: ModelDMTYPEInput | null,
  field_key?: ModelStringInput | null,
  friendly_name?: ModelStringInput | null,
  field_description?: ModelStringInput | null,
  field_type_GDS?: ModelFIELD_TYPE_GDSInput | null,
  field_type_PBI?: ModelFIELD_TYPE_PBIInput | null,
  data_source?: ModelDATASOURCEListInput | null,
  data_set?: ModelStringInput | null,
  api_endpoint?: ModelStringInput | null,
  name_by_school?: ModelStringInput | null,
  worksheet_name?: ModelStringInput | null,
  googlesheet_name?: ModelStringInput | null,
  worksheet_id?: ModelStringInput | null,
  googlesheet_id?: ModelStringInput | null,
  and?: Array< ModeldataSetupConditionInput | null > | null,
  or?: Array< ModeldataSetupConditionInput | null > | null,
  not?: ModeldataSetupConditionInput | null,
};

export type ModelDMTYPEInput = {
  eq?: DMTYPE | null,
  ne?: DMTYPE | null,
};

export type ModelFIELD_TYPE_GDSInput = {
  eq?: FIELD_TYPE_GDS | null,
  ne?: FIELD_TYPE_GDS | null,
};

export type ModelFIELD_TYPE_PBIInput = {
  eq?: FIELD_TYPE_PBI | null,
  ne?: FIELD_TYPE_PBI | null,
};

export type ModeldataSetupFilterInput = {
  id?: ModelIDInput | null,
  school_id?: ModelIDInput | null,
  dimension_or_metrics?: ModelDMTYPEInput | null,
  field_key?: ModelStringInput | null,
  friendly_name?: ModelStringInput | null,
  field_description?: ModelStringInput | null,
  field_type_GDS?: ModelFIELD_TYPE_GDSInput | null,
  field_type_PBI?: ModelFIELD_TYPE_PBIInput | null,
  data_source?: ModelDATASOURCEListInput | null,
  data_set?: ModelStringInput | null,
  api_endpoint?: ModelStringInput | null,
  name_by_school?: ModelStringInput | null,
  worksheet_name?: ModelStringInput | null,
  googlesheet_name?: ModelStringInput | null,
  worksheet_id?: ModelStringInput | null,
  googlesheet_id?: ModelStringInput | null,
  and?: Array< ModeldataSetupFilterInput | null > | null,
  or?: Array< ModeldataSetupFilterInput | null > | null,
  not?: ModeldataSetupFilterInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelAccountFilterInput = {
  id?: ModelIDInput | null,
  company?: ModelStringInput | null,
  company_loweredcased?: ModelStringInput | null,
  company_description?: ModelStringInput | null,
  url?: ModelStringInput | null,
  disabled?: ModelBooleanInput | null,
  piiInformation?: ModelBooleanInput | null,
  isSchoolAdmin?: ModelBooleanInput | null,
  email?: ModelStringInput | null,
  users?: ModelStringInput | null,
  accountStatus?: ModelACCT_STATUSInput | null,
  stripeCustomerID?: ModelStringInput | null,
  stripeSubscriptionID?: ModelStringInput | null,
  stripePriceID?: ModelStringInput | null,
  planID?: ModelIDInput | null,
  metadata?: ModelStringInput | null,
  owners?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  viewers?: ModelStringInput | null,
  datasource?: ModelDATASOURCEListInput | null,
  dataprovider?: ModelDATAPROVIDERListInput | null,
  and?: Array< ModelAccountFilterInput | null > | null,
  or?: Array< ModelAccountFilterInput | null > | null,
  not?: ModelAccountFilterInput | null,
};

export type ModelAccountConnection = {
  __typename: "ModelAccountConnection",
  items:  Array<Account | null >,
  nextToken?: string | null,
};

export type ModelQsdashboardDetailFilterInput = {
  id?: ModelIDInput | null,
  school_id?: ModelIDInput | null,
  dashboard_id?: ModelIDInput | null,
  dashboard_name?: ModelStringInput | null,
  dashboard_name_public?: ModelStringInput | null,
  dashboard_description?: ModelStringInput | null,
  dashboard_type?: ModelQS_DASHBOARD_TYPEInput | null,
  topic_id?: ModelIDInput | null,
  author?: ModelStringInput | null,
  tags?: ModelStringInput | null,
  and?: Array< ModelQsdashboardDetailFilterInput | null > | null,
  or?: Array< ModelQsdashboardDetailFilterInput | null > | null,
  not?: ModelQsdashboardDetailFilterInput | null,
};

export type ModelQS_DASHBOARD_TYPEInput = {
  eq?: QS_DASHBOARD_TYPE | null,
  ne?: QS_DASHBOARD_TYPE | null,
};

export enum QS_DASHBOARD_TYPE {
  CUSTOM_DASHBOARD = "CUSTOM_DASHBOARD",
  PRESCRIPTIVE_DASHBOARD = "PRESCRIPTIVE_DASHBOARD",
}


export type ModelQsdashboardDetailConnection = {
  __typename: "ModelQsdashboardDetailConnection",
  items:  Array<QsdashboardDetail | null >,
  nextToken?: string | null,
};

export type QsdashboardDetail = {
  __typename: "QsdashboardDetail",
  id: string,
  school_id?: string | null,
  dashboard_id?: string | null,
  dashboard_name?: string | null,
  dashboard_name_public?: string | null,
  dashboard_description?: string | null,
  dashboard_type?: QS_DASHBOARD_TYPE | null,
  topic_id?: string | null,
  author?: string | null,
  tags?: Array< string | null > | null,
  createdAt: string,
  updatedAt: string,
  isFavoriteOf?: ModelFavoriteConnection | null,
};

export type ModelFavoriteConnection = {
  __typename: "ModelFavoriteConnection",
  items:  Array<Favorite | null >,
  nextToken?: string | null,
};

export type Favorite = {
  __typename: "Favorite",
  id: string,
  userId?: string | null,
  resourceType?: string | null,
  dashboard_oid?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type CreateDataSetupInput = {
  id?: string | null,
  school_id?: string | null,
  dimension_or_metrics?: DMTYPE | null,
  field_key?: string | null,
  friendly_name?: string | null,
  field_description?: string | null,
  field_type_GDS?: FIELD_TYPE_GDS | null,
  field_type_PBI?: FIELD_TYPE_PBI | null,
  data_source?: Array< DATASOURCE | null > | null,
  data_set?: string | null,
  api_endpoint?: string | null,
  name_by_school?: string | null,
  worksheet_name?: string | null,
  googlesheet_name?: string | null,
  worksheet_id?: string | null,
  googlesheet_id?: string | null,
};

export type DeleteDataSetupInput = {
  id: string,
};

export type CreateAccountInput = {
  id?: string | null,
  company?: string | null,
  company_loweredcased?: string | null,
  company_description?: string | null,
  url?: string | null,
  disabled?: boolean | null,
  piiInformation?: boolean | null,
  isSchoolAdmin?: boolean | null,
  email?: string | null,
  users?: Array< string | null > | null,
  accountStatus?: ACCT_STATUS | null,
  stripeCustomerID?: string | null,
  stripeSubscriptionID?: string | null,
  stripePriceID?: string | null,
  planID?: string | null,
  metadata?: string | null,
  owners?: Array< string | null > | null,
  editors?: Array< string | null > | null,
  viewers?: Array< string | null > | null,
  datasource?: Array< DATASOURCE | null > | null,
  dataprovider?: Array< DATAPROVIDER | null > | null,
};

export type DeleteAccountInput = {
  id: string,
};

export type CreateAdvancedListDetailInput = {
  id?: string | null,
  school_id?: string | null,
  list_name?: string | null,
  list_id?: string | null,
  datasource?: string | null,
  dataset?: string | null,
  status?: STATUS | null,
  preconfigure?: boolean | null,
  last_sync_date?: string | null,
  interval_type?: INTERVAL_TYPE | null,
  recurring_sync_status?: boolean | null,
  custom_interval?: number | null,
  custom_type?: CUSTOM_TYPE | null,
  custom_value?: number | null,
  is_initial_sync?: STATUS | null,
};

export type ModelAdvancedListDetailConditionInput = {
  school_id?: ModelIDInput | null,
  list_name?: ModelStringInput | null,
  list_id?: ModelStringInput | null,
  datasource?: ModelStringInput | null,
  dataset?: ModelStringInput | null,
  status?: ModelSTATUSInput | null,
  preconfigure?: ModelBooleanInput | null,
  last_sync_date?: ModelStringInput | null,
  interval_type?: ModelINTERVAL_TYPEInput | null,
  recurring_sync_status?: ModelBooleanInput | null,
  custom_interval?: ModelIntInput | null,
  custom_type?: ModelCUSTOM_TYPEInput | null,
  custom_value?: ModelIntInput | null,
  is_initial_sync?: ModelSTATUSInput | null,
  and?: Array< ModelAdvancedListDetailConditionInput | null > | null,
  or?: Array< ModelAdvancedListDetailConditionInput | null > | null,
  not?: ModelAdvancedListDetailConditionInput | null,
};

export type ModelSTATUSInput = {
  eq?: STATUS | null,
  ne?: STATUS | null,
};

export type ModelINTERVAL_TYPEInput = {
  eq?: INTERVAL_TYPE | null,
  ne?: INTERVAL_TYPE | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelCUSTOM_TYPEInput = {
  eq?: CUSTOM_TYPE | null,
  ne?: CUSTOM_TYPE | null,
};

export type UpdateAdvancedListDetailInput = {
  id: string,
  school_id?: string | null,
  list_name?: string | null,
  list_id?: string | null,
  datasource?: string | null,
  dataset?: string | null,
  status?: STATUS | null,
  preconfigure?: boolean | null,
  last_sync_date?: string | null,
  interval_type?: INTERVAL_TYPE | null,
  recurring_sync_status?: boolean | null,
  custom_interval?: number | null,
  custom_type?: CUSTOM_TYPE | null,
  custom_value?: number | null,
  is_initial_sync?: STATUS | null,
};

export type DeleteAdvancedListDetailInput = {
  id: string,
};

export type CreateDataPackageDetailInput = {
  id?: string | null,
  school_id?: string | null,
  package_name?: string | null,
  datasource?: string | null,
  dataset?: string | null,
  status?: STATUS | null,
  last_sync_date?: string | null,
};

export type ModelDataPackageDetailConditionInput = {
  school_id?: ModelIDInput | null,
  package_name?: ModelStringInput | null,
  datasource?: ModelStringInput | null,
  dataset?: ModelStringInput | null,
  status?: ModelSTATUSInput | null,
  last_sync_date?: ModelStringInput | null,
  and?: Array< ModelDataPackageDetailConditionInput | null > | null,
  or?: Array< ModelDataPackageDetailConditionInput | null > | null,
  not?: ModelDataPackageDetailConditionInput | null,
};

export type DataPackageDetail = {
  __typename: "DataPackageDetail",
  id: string,
  school_id?: string | null,
  package_name?: string | null,
  datasource?: string | null,
  dataset?: string | null,
  status?: STATUS | null,
  last_sync_date?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateDataPackageDetailInput = {
  id: string,
  school_id?: string | null,
  package_name?: string | null,
  datasource?: string | null,
  dataset?: string | null,
  status?: STATUS | null,
  last_sync_date?: string | null,
};

export type DeleteDataPackageDetailInput = {
  id: string,
};

export type CreateGoogleSheetDetailInput = {
  id?: string | null,
  school_id?: string | null,
  created_by?: string | null,
  googlesheet_link: string,
  googlesheet_name?: string | null,
  googlesheet_id?: string | null,
  worksheet_name?: string | null,
  worksheet_id?: string | null,
  unique_field?: string | null,
  is_obtained?: boolean | null,
  interval_type?: INTERVAL_TYPE | null,
  last_success_sync_date?: string | null,
  last_failure_sync_date?: string | null,
  custom_interval?: number | null,
  is_cancelled?: boolean | null,
  custom_type?: CUSTOM_TYPE | null,
  custom_value?: number | null,
  table_name?: string | null,
  refresh_interval_status?: REFRESH_INTERVAL_STATUS | null,
};

export type ModelGoogleSheetDetailConditionInput = {
  school_id?: ModelIDInput | null,
  created_by?: ModelStringInput | null,
  googlesheet_link?: ModelStringInput | null,
  googlesheet_name?: ModelStringInput | null,
  googlesheet_id?: ModelStringInput | null,
  worksheet_name?: ModelStringInput | null,
  worksheet_id?: ModelStringInput | null,
  unique_field?: ModelStringInput | null,
  is_obtained?: ModelBooleanInput | null,
  interval_type?: ModelINTERVAL_TYPEInput | null,
  last_success_sync_date?: ModelStringInput | null,
  last_failure_sync_date?: ModelStringInput | null,
  custom_interval?: ModelIntInput | null,
  is_cancelled?: ModelBooleanInput | null,
  custom_type?: ModelCUSTOM_TYPEInput | null,
  custom_value?: ModelIntInput | null,
  table_name?: ModelStringInput | null,
  refresh_interval_status?: ModelREFRESH_INTERVAL_STATUSInput | null,
  and?: Array< ModelGoogleSheetDetailConditionInput | null > | null,
  or?: Array< ModelGoogleSheetDetailConditionInput | null > | null,
  not?: ModelGoogleSheetDetailConditionInput | null,
};

export type ModelREFRESH_INTERVAL_STATUSInput = {
  eq?: REFRESH_INTERVAL_STATUS | null,
  ne?: REFRESH_INTERVAL_STATUS | null,
};

export type UpdateGoogleSheetDetailInput = {
  id: string,
  school_id?: string | null,
  created_by?: string | null,
  googlesheet_link?: string | null,
  googlesheet_name?: string | null,
  googlesheet_id?: string | null,
  worksheet_name?: string | null,
  worksheet_id?: string | null,
  unique_field?: string | null,
  is_obtained?: boolean | null,
  interval_type?: INTERVAL_TYPE | null,
  last_success_sync_date?: string | null,
  last_failure_sync_date?: string | null,
  custom_interval?: number | null,
  is_cancelled?: boolean | null,
  custom_type?: CUSTOM_TYPE | null,
  custom_value?: number | null,
  table_name?: string | null,
  refresh_interval_status?: REFRESH_INTERVAL_STATUS | null,
};

export type DeleteGoogleSheetDetailInput = {
  id: string,
};

export type CreateRecurringSyncInput = {
  id?: string | null,
  school_id?: string | null,
  success?: boolean | null,
  worksheet_id?: string | null,
  worksheet_name?: string | null,
  interval_type?: string | null,
  sync_for?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelRecurringSyncConditionInput = {
  school_id?: ModelIDInput | null,
  success?: ModelBooleanInput | null,
  worksheet_id?: ModelStringInput | null,
  worksheet_name?: ModelStringInput | null,
  interval_type?: ModelStringInput | null,
  sync_for?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelRecurringSyncConditionInput | null > | null,
  or?: Array< ModelRecurringSyncConditionInput | null > | null,
  not?: ModelRecurringSyncConditionInput | null,
};

export type UpdateRecurringSyncInput = {
  id: string,
  school_id?: string | null,
  success?: boolean | null,
  worksheet_id?: string | null,
  worksheet_name?: string | null,
  interval_type?: string | null,
  sync_for?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteRecurringSyncInput = {
  id: string,
};

export type CreateCountOfAPIHistoryInput = {
  id?: string | null,
  school_id?: string | null,
  api_key?: string | null,
  dataset_type?: string | null,
  count?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelCountOfAPIHistoryConditionInput = {
  school_id?: ModelIDInput | null,
  api_key?: ModelStringInput | null,
  dataset_type?: ModelStringInput | null,
  count?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelCountOfAPIHistoryConditionInput | null > | null,
  or?: Array< ModelCountOfAPIHistoryConditionInput | null > | null,
  not?: ModelCountOfAPIHistoryConditionInput | null,
};

export type UpdateCountOfAPIHistoryInput = {
  id: string,
  school_id?: string | null,
  api_key?: string | null,
  dataset_type?: string | null,
  count?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteCountOfAPIHistoryInput = {
  id: string,
};

export type CreateDataSetDetailInput = {
  id?: string | null,
  school_id?: string | null,
  data_set?: string | null,
  data_source?: DATASOURCE | null,
  purchased_date?: string | null,
  status?: STATUS | null,
};

export type ModelDataSetDetailConditionInput = {
  school_id?: ModelIDInput | null,
  data_set?: ModelStringInput | null,
  data_source?: ModelDATASOURCEInput | null,
  purchased_date?: ModelStringInput | null,
  status?: ModelSTATUSInput | null,
  and?: Array< ModelDataSetDetailConditionInput | null > | null,
  or?: Array< ModelDataSetDetailConditionInput | null > | null,
  not?: ModelDataSetDetailConditionInput | null,
};

export type ModelDATASOURCEInput = {
  eq?: DATASOURCE | null,
  ne?: DATASOURCE | null,
};

export type UpdateDataSetDetailInput = {
  id: string,
  school_id?: string | null,
  data_set?: string | null,
  data_source?: DATASOURCE | null,
  purchased_date?: string | null,
  status?: STATUS | null,
};

export type DeleteDataSetDetailInput = {
  id: string,
};

export type CreateVeracrossDetailInput = {
  id?: string | null,
  school_id?: string | null,
  veracross_client_id?: string | null,
  veracross_client_secret?: string | null,
  scope?: string | null,
  school_route?: string | null,
};

export type ModelVeracrossDetailConditionInput = {
  school_id?: ModelIDInput | null,
  veracross_client_id?: ModelStringInput | null,
  veracross_client_secret?: ModelStringInput | null,
  scope?: ModelStringInput | null,
  school_route?: ModelStringInput | null,
  and?: Array< ModelVeracrossDetailConditionInput | null > | null,
  or?: Array< ModelVeracrossDetailConditionInput | null > | null,
  not?: ModelVeracrossDetailConditionInput | null,
};

export type VeracrossDetail = {
  __typename: "VeracrossDetail",
  id: string,
  school_id?: string | null,
  veracross_client_id?: string | null,
  veracross_client_secret?: string | null,
  scope?: string | null,
  school_route?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateVeracrossDetailInput = {
  id: string,
  school_id?: string | null,
  veracross_client_id?: string | null,
  veracross_client_secret?: string | null,
  scope?: string | null,
  school_route?: string | null,
};

export type DeleteVeracrossDetailInput = {
  id: string,
};

export type CreateAccountRequestInput = {
  id?: string | null,
  account: string,
  owner?: string | null,
};

export type ModelAccountRequestConditionInput = {
  and?: Array< ModelAccountRequestConditionInput | null > | null,
  or?: Array< ModelAccountRequestConditionInput | null > | null,
  not?: ModelAccountRequestConditionInput | null,
};

export type UpdateAccountRequestInput = {
  id: string,
  account?: string | null,
  owner?: string | null,
};

export type DeleteAccountRequestInput = {
  id: string,
};

export type CreatePlanInput = {
  id?: string | null,
  name: string,
  planDetails?: string | null,
  Targets?: string | null,
  bulletPoints?: Array< string | null > | null,
  listed?: boolean | null,
  order?: number | null,
};

export type ModelPlanConditionInput = {
  name?: ModelStringInput | null,
  planDetails?: ModelStringInput | null,
  Targets?: ModelStringInput | null,
  bulletPoints?: ModelStringInput | null,
  listed?: ModelBooleanInput | null,
  order?: ModelIntInput | null,
  and?: Array< ModelPlanConditionInput | null > | null,
  or?: Array< ModelPlanConditionInput | null > | null,
  not?: ModelPlanConditionInput | null,
};

export type UpdatePlanInput = {
  id: string,
  name?: string | null,
  planDetails?: string | null,
  Targets?: string | null,
  bulletPoints?: Array< string | null > | null,
  listed?: boolean | null,
  order?: number | null,
};

export type DeletePlanInput = {
  id: string,
};

export type CreateWebProfilesInput = {
  id?: string | null,
  account: string,
  platform: SUPPORTED_PLATFORM,
  profile_id?: string | null,
  metadata?: string | null,
  enabled?: boolean | null,
  last_sync_date?: string | null,
  disconnect?: boolean | null,
};

export enum SUPPORTED_PLATFORM {
  BLACKBAUD = "BLACKBAUD",
  CUSTOM_FILES = "CUSTOM_FILES",
  VERACROSS = "VERACROSS",
  RAVENNA = "RAVENNA",
}


export type ModelWebProfilesConditionInput = {
  platform?: ModelSUPPORTED_PLATFORMInput | null,
  profile_id?: ModelStringInput | null,
  metadata?: ModelStringInput | null,
  enabled?: ModelBooleanInput | null,
  last_sync_date?: ModelStringInput | null,
  disconnect?: ModelBooleanInput | null,
  and?: Array< ModelWebProfilesConditionInput | null > | null,
  or?: Array< ModelWebProfilesConditionInput | null > | null,
  not?: ModelWebProfilesConditionInput | null,
};

export type ModelSUPPORTED_PLATFORMInput = {
  eq?: SUPPORTED_PLATFORM | null,
  ne?: SUPPORTED_PLATFORM | null,
};

export type WebProfiles = {
  __typename: "WebProfiles",
  id: string,
  account: string,
  platform: SUPPORTED_PLATFORM,
  profile_id?: string | null,
  metadata?: string | null,
  enabled?: boolean | null,
  last_sync_date?: string | null,
  disconnect?: boolean | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateWebProfilesInput = {
  id: string,
  account?: string | null,
  platform?: SUPPORTED_PLATFORM | null,
  profile_id?: string | null,
  metadata?: string | null,
  enabled?: boolean | null,
  last_sync_date?: string | null,
  disconnect?: boolean | null,
};

export type DeleteWebProfilesInput = {
  id: string,
};

export type CreateOAuthCredsInput = {
  id?: string | null,
  account: string,
  platform: SUPPORTED_PLATFORM,
  refresh_token?: string | null,
};

export type ModelOAuthCredsConditionInput = {
  account?: ModelIDInput | null,
  platform?: ModelSUPPORTED_PLATFORMInput | null,
  refresh_token?: ModelStringInput | null,
  and?: Array< ModelOAuthCredsConditionInput | null > | null,
  or?: Array< ModelOAuthCredsConditionInput | null > | null,
  not?: ModelOAuthCredsConditionInput | null,
};

export type OAuthCreds = {
  __typename: "OAuthCreds",
  id: string,
  account: string,
  platform: SUPPORTED_PLATFORM,
  refresh_token?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateOAuthCredsInput = {
  id: string,
  account?: string | null,
  platform?: SUPPORTED_PLATFORM | null,
  refresh_token?: string | null,
};

export type DeleteOAuthCredsInput = {
  id: string,
};

export type CreateIntegrationSyncHistoryInput = {
  id?: string | null,
  account: string,
  platform: SUPPORTED_PLATFORM,
  profile_id: string,
  source: SYNC_SOURCE,
  status: SYNC_STATUS,
  createdAt?: string | null,
  detail?: string | null,
};

export enum SYNC_SOURCE {
  FRONT_END = "FRONT_END",
  AUTO = "AUTO",
}


export enum SYNC_STATUS {
  REQUESTED = "REQUESTED",
  SYNCING = "SYNCING",
  DONE = "DONE",
  FAILED = "FAILED",
}


export type ModelIntegrationSyncHistoryConditionInput = {
  platform?: ModelSUPPORTED_PLATFORMInput | null,
  profile_id?: ModelStringInput | null,
  source?: ModelSYNC_SOURCEInput | null,
  status?: ModelSYNC_STATUSInput | null,
  createdAt?: ModelStringInput | null,
  detail?: ModelStringInput | null,
  and?: Array< ModelIntegrationSyncHistoryConditionInput | null > | null,
  or?: Array< ModelIntegrationSyncHistoryConditionInput | null > | null,
  not?: ModelIntegrationSyncHistoryConditionInput | null,
};

export type ModelSYNC_SOURCEInput = {
  eq?: SYNC_SOURCE | null,
  ne?: SYNC_SOURCE | null,
};

export type ModelSYNC_STATUSInput = {
  eq?: SYNC_STATUS | null,
  ne?: SYNC_STATUS | null,
};

export type IntegrationSyncHistory = {
  __typename: "IntegrationSyncHistory",
  id: string,
  account: string,
  platform: SUPPORTED_PLATFORM,
  profile_id: string,
  source: SYNC_SOURCE,
  status: SYNC_STATUS,
  createdAt: string,
  detail?: string | null,
  updatedAt: string,
};

export type UpdateIntegrationSyncHistoryInput = {
  id: string,
  account?: string | null,
  platform?: SUPPORTED_PLATFORM | null,
  profile_id?: string | null,
  source?: SYNC_SOURCE | null,
  status?: SYNC_STATUS | null,
  createdAt?: string | null,
  detail?: string | null,
};

export type DeleteIntegrationSyncHistoryInput = {
  id: string,
};

export type CreateApiKeyInput = {
  id?: string | null,
  account: string,
  key: string,
  keyid: string,
  disabled: boolean,
};

export type ModelApiKeyConditionInput = {
  key?: ModelStringInput | null,
  keyid?: ModelStringInput | null,
  disabled?: ModelBooleanInput | null,
  and?: Array< ModelApiKeyConditionInput | null > | null,
  or?: Array< ModelApiKeyConditionInput | null > | null,
  not?: ModelApiKeyConditionInput | null,
};

export type ApiKey = {
  __typename: "ApiKey",
  id: string,
  account: string,
  key: string,
  keyid: string,
  disabled: boolean,
  createdAt: string,
  updatedAt: string,
};

export type UpdateApiKeyInput = {
  id: string,
  account?: string | null,
  key?: string | null,
  keyid?: string | null,
  disabled?: boolean | null,
};

export type DeleteApiKeyInput = {
  id: string,
};

export type CreateFAQInput = {
  id?: string | null,
  question: string,
  answer: string,
};

export type ModelFAQConditionInput = {
  question?: ModelStringInput | null,
  answer?: ModelStringInput | null,
  and?: Array< ModelFAQConditionInput | null > | null,
  or?: Array< ModelFAQConditionInput | null > | null,
  not?: ModelFAQConditionInput | null,
};

export type FAQ = {
  __typename: "FAQ",
  id: string,
  question: string,
  answer: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateFAQInput = {
  id: string,
  question?: string | null,
  answer?: string | null,
};

export type DeleteFAQInput = {
  id: string,
};

export type CreateFirebaseTokensInput = {
  id?: string | null,
  token?: string | null,
  user?: string | null,
  userDetails?: string | null,
};

export type ModelFirebaseTokensConditionInput = {
  token?: ModelStringInput | null,
  user?: ModelIDInput | null,
  userDetails?: ModelStringInput | null,
  and?: Array< ModelFirebaseTokensConditionInput | null > | null,
  or?: Array< ModelFirebaseTokensConditionInput | null > | null,
  not?: ModelFirebaseTokensConditionInput | null,
};

export type FirebaseTokens = {
  __typename: "FirebaseTokens",
  id: string,
  token?: string | null,
  user?: string | null,
  userDetails?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateFirebaseTokensInput = {
  id: string,
  token?: string | null,
  user?: string | null,
  userDetails?: string | null,
};

export type DeleteFirebaseTokensInput = {
  id: string,
};

export type CreateDatasourceInput = {
  id?: string | null,
  data_provider: DATAPROVIDER,
  data_source_name?: string | null,
};

export type ModeldatasourceConditionInput = {
  data_provider?: ModelDATAPROVIDERInput | null,
  data_source_name?: ModelStringInput | null,
  and?: Array< ModeldatasourceConditionInput | null > | null,
  or?: Array< ModeldatasourceConditionInput | null > | null,
  not?: ModeldatasourceConditionInput | null,
};

export type ModelDATAPROVIDERInput = {
  eq?: DATAPROVIDER | null,
  ne?: DATAPROVIDER | null,
};

export type datasource = {
  __typename: "datasource",
  id: string,
  data_provider: DATAPROVIDER,
  data_source_name?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateDatasourceInput = {
  id: string,
  data_provider?: DATAPROVIDER | null,
  data_source_name?: string | null,
};

export type DeleteDatasourceInput = {
  id: string,
};

export type CreateNotificationTypeInput = {
  id?: string | null,
  notification_type: NOTIFICATION_TYPE,
  code?: string | null,
  title?: string | null,
  notification_text?: string | null,
  content?: string | null,
  metadata?: string | null,
  createdAt?: string | null,
};

export enum NOTIFICATION_TYPE {
  ACCOUNTS = "ACCOUNTS",
  QUOTA_LIMIT = "QUOTA_LIMIT",
  DATA_SYNC__BLACKBAUD = "DATA_SYNC__BLACKBAUD",
  DATA_SYNC__REVENNA = "DATA_SYNC__REVENNA",
  DATA_SYNC__VERACROSS = "DATA_SYNC__VERACROSS",
  CUSTOM_FILE = "CUSTOM_FILE",
  SCHOOL = "SCHOOL",
  BILLING = "BILLING",
  MANAGE_FILE = "MANAGE_FILE",
  GENERAL = "GENERAL",
}


export type ModelNotificationTypeConditionInput = {
  notification_type?: ModelNOTIFICATION_TYPEInput | null,
  code?: ModelStringInput | null,
  title?: ModelStringInput | null,
  notification_text?: ModelStringInput | null,
  content?: ModelStringInput | null,
  metadata?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelNotificationTypeConditionInput | null > | null,
  or?: Array< ModelNotificationTypeConditionInput | null > | null,
  not?: ModelNotificationTypeConditionInput | null,
};

export type ModelNOTIFICATION_TYPEInput = {
  eq?: NOTIFICATION_TYPE | null,
  ne?: NOTIFICATION_TYPE | null,
};

export type NotificationType = {
  __typename: "NotificationType",
  id: string,
  notification_type: NOTIFICATION_TYPE,
  code?: string | null,
  title?: string | null,
  notification_text?: string | null,
  content?: string | null,
  metadata?: string | null,
  createdAt?: string | null,
  updatedAt: string,
};

export type UpdateNotificationTypeInput = {
  id: string,
  notification_type?: NOTIFICATION_TYPE | null,
  code?: string | null,
  title?: string | null,
  notification_text?: string | null,
  content?: string | null,
  metadata?: string | null,
  createdAt?: string | null,
};

export type DeleteNotificationTypeInput = {
  id: string,
};

export type CreateUserNotificationPreferenceInput = {
  id?: string | null,
  user?: string | null,
  userDetails?: string | null,
  notificationType?: string | null,
  emailNotification?: boolean | null,
  inAppNotification?: boolean | null,
  webPushNotification?: boolean | null,
  createdAt?: string | null,
};

export type ModelUserNotificationPreferenceConditionInput = {
  user?: ModelIDInput | null,
  userDetails?: ModelStringInput | null,
  notificationType?: ModelIDInput | null,
  emailNotification?: ModelBooleanInput | null,
  inAppNotification?: ModelBooleanInput | null,
  webPushNotification?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelUserNotificationPreferenceConditionInput | null > | null,
  or?: Array< ModelUserNotificationPreferenceConditionInput | null > | null,
  not?: ModelUserNotificationPreferenceConditionInput | null,
};

export type UserNotificationPreference = {
  __typename: "UserNotificationPreference",
  id: string,
  user?: string | null,
  userDetails?: string | null,
  notificationType?: string | null,
  emailNotification?: boolean | null,
  inAppNotification?: boolean | null,
  webPushNotification?: boolean | null,
  createdAt?: string | null,
  updatedAt: string,
  notificationTypeDetails?: NotificationType | null,
};

export type UpdateUserNotificationPreferenceInput = {
  id: string,
  user?: string | null,
  userDetails?: string | null,
  notificationType?: string | null,
  emailNotification?: boolean | null,
  inAppNotification?: boolean | null,
  webPushNotification?: boolean | null,
  createdAt?: string | null,
};

export type DeleteUserNotificationPreferenceInput = {
  id: string,
};

export type CreateUserNotificationInput = {
  id?: string | null,
  user?: string | null,
  userDetails?: string | null,
  userNotificationPreference?: string | null,
  title?: string | null,
  content?: string | null,
  message?: string | null,
  code?: string | null,
  metadata?: string | null,
  isRead?: boolean | null,
  emailSent?: boolean | null,
  createdAt?: string | null,
};

export type ModelUserNotificationConditionInput = {
  user?: ModelIDInput | null,
  userDetails?: ModelStringInput | null,
  userNotificationPreference?: ModelIDInput | null,
  title?: ModelStringInput | null,
  content?: ModelStringInput | null,
  message?: ModelStringInput | null,
  code?: ModelStringInput | null,
  metadata?: ModelStringInput | null,
  isRead?: ModelBooleanInput | null,
  emailSent?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelUserNotificationConditionInput | null > | null,
  or?: Array< ModelUserNotificationConditionInput | null > | null,
  not?: ModelUserNotificationConditionInput | null,
};

export type UserNotification = {
  __typename: "UserNotification",
  id: string,
  user?: string | null,
  userDetails?: string | null,
  userNotificationPreference?: string | null,
  title?: string | null,
  content?: string | null,
  message?: string | null,
  code?: string | null,
  metadata?: string | null,
  isRead?: boolean | null,
  emailSent?: boolean | null,
  createdAt?: string | null,
  updatedAt: string,
};

export type UpdateUserNotificationInput = {
  id: string,
  user?: string | null,
  userDetails?: string | null,
  userNotificationPreference?: string | null,
  title?: string | null,
  content?: string | null,
  message?: string | null,
  code?: string | null,
  metadata?: string | null,
  isRead?: boolean | null,
  emailSent?: boolean | null,
  createdAt?: string | null,
};

export type DeleteUserNotificationInput = {
  id: string,
};

export type CreateInitialSyncDetailsInput = {
  id?: string | null,
  school_id?: string | null,
  data_provider?: DATAPROVIDER | null,
  data_source?: DATASOURCE | null,
  last_sync_date?: string | null,
  initial_sync_status?: STATUS | null,
};

export type ModelInitialSyncDetailsConditionInput = {
  school_id?: ModelIDInput | null,
  data_provider?: ModelDATAPROVIDERInput | null,
  data_source?: ModelDATASOURCEInput | null,
  last_sync_date?: ModelStringInput | null,
  initial_sync_status?: ModelSTATUSInput | null,
  and?: Array< ModelInitialSyncDetailsConditionInput | null > | null,
  or?: Array< ModelInitialSyncDetailsConditionInput | null > | null,
  not?: ModelInitialSyncDetailsConditionInput | null,
};

export type InitialSyncDetails = {
  __typename: "InitialSyncDetails",
  id: string,
  school_id?: string | null,
  data_provider?: DATAPROVIDER | null,
  data_source?: DATASOURCE | null,
  last_sync_date?: string | null,
  initial_sync_status?: STATUS | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateInitialSyncDetailsInput = {
  id: string,
  school_id?: string | null,
  data_provider?: DATAPROVIDER | null,
  data_source?: DATASOURCE | null,
  last_sync_date?: string | null,
  initial_sync_status?: STATUS | null,
};

export type DeleteInitialSyncDetailsInput = {
  id: string,
};

export type CreateRulesInput = {
  id?: string | null,
  school_id?: string | null,
  field_key?: string | null,
  data_source?: DATASOURCE | null,
  data_set?: string | null,
  rule_values?: string | null,
  created_by?: string | null,
  updated_by?: string | null,
  rule_type?: string | null,
};

export type ModelRulesConditionInput = {
  school_id?: ModelIDInput | null,
  field_key?: ModelStringInput | null,
  data_source?: ModelDATASOURCEInput | null,
  data_set?: ModelStringInput | null,
  rule_values?: ModelStringInput | null,
  created_by?: ModelStringInput | null,
  updated_by?: ModelStringInput | null,
  rule_type?: ModelStringInput | null,
  and?: Array< ModelRulesConditionInput | null > | null,
  or?: Array< ModelRulesConditionInput | null > | null,
  not?: ModelRulesConditionInput | null,
};

export type Rules = {
  __typename: "Rules",
  id?: string | null,
  school_id?: string | null,
  field_key?: string | null,
  data_source?: DATASOURCE | null,
  data_set?: string | null,
  rule_values?: string | null,
  created_by?: string | null,
  updated_by?: string | null,
  rule_type?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateRulesInput = {
  id: string,
  school_id?: string | null,
  field_key?: string | null,
  data_source?: DATASOURCE | null,
  data_set?: string | null,
  rule_values?: string | null,
  created_by?: string | null,
  updated_by?: string | null,
  rule_type?: string | null,
};

export type DeleteRulesInput = {
  id: string,
};

export type CreateDaslValuesInput = {
  id?: string | null,
  field_key?: string | null,
  field_values?: Array< string | null > | null,
  createdAt?: string | null,
};

export type ModelDaslValuesConditionInput = {
  field_key?: ModelStringInput | null,
  field_values?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelDaslValuesConditionInput | null > | null,
  or?: Array< ModelDaslValuesConditionInput | null > | null,
  not?: ModelDaslValuesConditionInput | null,
};

export type DaslValues = {
  __typename: "DaslValues",
  id?: string | null,
  field_key?: string | null,
  field_values?: Array< string | null > | null,
  createdAt?: string | null,
  updatedAt: string,
};

export type UpdateDaslValuesInput = {
  id: string,
  field_key?: string | null,
  field_values?: Array< string | null > | null,
  createdAt?: string | null,
};

export type DeleteDaslValuesInput = {
  id: string,
};

export type CreateCsvDetailInput = {
  id?: string | null,
  school_id?: string | null,
  created_by?: string | null,
  csv_name?: string | null,
  unique_field?: string | null,
  is_obtained?: boolean | null,
  is_cancelled?: boolean | null,
  status?: STATUS | null,
};

export type ModelCsvDetailConditionInput = {
  school_id?: ModelIDInput | null,
  created_by?: ModelStringInput | null,
  csv_name?: ModelStringInput | null,
  unique_field?: ModelStringInput | null,
  is_obtained?: ModelBooleanInput | null,
  is_cancelled?: ModelBooleanInput | null,
  status?: ModelSTATUSInput | null,
  and?: Array< ModelCsvDetailConditionInput | null > | null,
  or?: Array< ModelCsvDetailConditionInput | null > | null,
  not?: ModelCsvDetailConditionInput | null,
};

export type CsvDetail = {
  __typename: "CsvDetail",
  id: string,
  school_id?: string | null,
  created_by?: string | null,
  csv_name?: string | null,
  unique_field?: string | null,
  is_obtained?: boolean | null,
  is_cancelled?: boolean | null,
  status?: STATUS | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateCsvDetailInput = {
  id: string,
  school_id?: string | null,
  created_by?: string | null,
  csv_name?: string | null,
  unique_field?: string | null,
  is_obtained?: boolean | null,
  is_cancelled?: boolean | null,
  status?: STATUS | null,
};

export type DeleteCsvDetailInput = {
  id: string,
};

export type CreatePreviewDetailInput = {
  id?: string | null,
  school_id?: string | null,
  created_by?: string | null,
  data_set?: string | null,
  show_fields?: string | null,
  hidden_fields?: string | null,
};

export type ModelPreviewDetailConditionInput = {
  school_id?: ModelIDInput | null,
  created_by?: ModelStringInput | null,
  data_set?: ModelStringInput | null,
  show_fields?: ModelStringInput | null,
  hidden_fields?: ModelStringInput | null,
  and?: Array< ModelPreviewDetailConditionInput | null > | null,
  or?: Array< ModelPreviewDetailConditionInput | null > | null,
  not?: ModelPreviewDetailConditionInput | null,
};

export type PreviewDetail = {
  __typename: "PreviewDetail",
  id: string,
  school_id?: string | null,
  created_by?: string | null,
  data_set?: string | null,
  show_fields?: string | null,
  hidden_fields?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdatePreviewDetailInput = {
  id: string,
  school_id?: string | null,
  created_by?: string | null,
  data_set?: string | null,
  show_fields?: string | null,
  hidden_fields?: string | null,
};

export type DeletePreviewDetailInput = {
  id: string,
};

export type CreateQsdashboardMapInput = {
  id?: string | null,
  school_id?: string | null,
  dashboard_id?: string | null,
  dashboard_name?: string | null,
};

export type ModelQsdashboardMapConditionInput = {
  school_id?: ModelIDInput | null,
  dashboard_id?: ModelIDInput | null,
  dashboard_name?: ModelStringInput | null,
  and?: Array< ModelQsdashboardMapConditionInput | null > | null,
  or?: Array< ModelQsdashboardMapConditionInput | null > | null,
  not?: ModelQsdashboardMapConditionInput | null,
};

export type QsdashboardMap = {
  __typename: "QsdashboardMap",
  id: string,
  school_id?: string | null,
  dashboard_id?: string | null,
  dashboard_name?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateQsdashboardMapInput = {
  id: string,
  school_id?: string | null,
  dashboard_id?: string | null,
  dashboard_name?: string | null,
};

export type DeleteQsdashboardMapInput = {
  id: string,
};

export type CreateQsdashboardDetailInput = {
  id?: string | null,
  school_id?: string | null,
  dashboard_id?: string | null,
  dashboard_name?: string | null,
  dashboard_name_public?: string | null,
  dashboard_description?: string | null,
  dashboard_type?: QS_DASHBOARD_TYPE | null,
  topic_id?: string | null,
  author?: string | null,
  tags?: Array< string | null > | null,
};

export type ModelQsdashboardDetailConditionInput = {
  school_id?: ModelIDInput | null,
  dashboard_id?: ModelIDInput | null,
  dashboard_name?: ModelStringInput | null,
  dashboard_name_public?: ModelStringInput | null,
  dashboard_description?: ModelStringInput | null,
  dashboard_type?: ModelQS_DASHBOARD_TYPEInput | null,
  topic_id?: ModelIDInput | null,
  author?: ModelStringInput | null,
  tags?: ModelStringInput | null,
  and?: Array< ModelQsdashboardDetailConditionInput | null > | null,
  or?: Array< ModelQsdashboardDetailConditionInput | null > | null,
  not?: ModelQsdashboardDetailConditionInput | null,
};

export type UpdateQsdashboardDetailInput = {
  id: string,
  school_id?: string | null,
  dashboard_id?: string | null,
  dashboard_name?: string | null,
  dashboard_name_public?: string | null,
  dashboard_description?: string | null,
  dashboard_type?: QS_DASHBOARD_TYPE | null,
  topic_id?: string | null,
  author?: string | null,
  tags?: Array< string | null > | null,
};

export type DeleteQsdashboardDetailInput = {
  id: string,
};

export type CreateFavoriteInput = {
  id?: string | null,
  userId?: string | null,
  resourceType?: string | null,
  dashboard_oid?: string | null,
};

export type ModelFavoriteConditionInput = {
  userId?: ModelIDInput | null,
  resourceType?: ModelStringInput | null,
  dashboard_oid?: ModelIDInput | null,
  and?: Array< ModelFavoriteConditionInput | null > | null,
  or?: Array< ModelFavoriteConditionInput | null > | null,
  not?: ModelFavoriteConditionInput | null,
};

export type UpdateFavoriteInput = {
  id: string,
  userId?: string | null,
  resourceType?: string | null,
  dashboard_oid?: string | null,
};

export type DeleteFavoriteInput = {
  id: string,
};

export type ModelAdvancedListDetailFilterInput = {
  id?: ModelIDInput | null,
  school_id?: ModelIDInput | null,
  list_name?: ModelStringInput | null,
  list_id?: ModelStringInput | null,
  datasource?: ModelStringInput | null,
  dataset?: ModelStringInput | null,
  status?: ModelSTATUSInput | null,
  preconfigure?: ModelBooleanInput | null,
  last_sync_date?: ModelStringInput | null,
  interval_type?: ModelINTERVAL_TYPEInput | null,
  recurring_sync_status?: ModelBooleanInput | null,
  custom_interval?: ModelIntInput | null,
  custom_type?: ModelCUSTOM_TYPEInput | null,
  custom_value?: ModelIntInput | null,
  is_initial_sync?: ModelSTATUSInput | null,
  and?: Array< ModelAdvancedListDetailFilterInput | null > | null,
  or?: Array< ModelAdvancedListDetailFilterInput | null > | null,
  not?: ModelAdvancedListDetailFilterInput | null,
};

export type ModelDataPackageDetailFilterInput = {
  id?: ModelIDInput | null,
  school_id?: ModelIDInput | null,
  package_name?: ModelStringInput | null,
  datasource?: ModelStringInput | null,
  dataset?: ModelStringInput | null,
  status?: ModelSTATUSInput | null,
  last_sync_date?: ModelStringInput | null,
  and?: Array< ModelDataPackageDetailFilterInput | null > | null,
  or?: Array< ModelDataPackageDetailFilterInput | null > | null,
  not?: ModelDataPackageDetailFilterInput | null,
};

export type ModelDataPackageDetailConnection = {
  __typename: "ModelDataPackageDetailConnection",
  items:  Array<DataPackageDetail | null >,
  nextToken?: string | null,
};

export type ModelGoogleSheetDetailFilterInput = {
  id?: ModelIDInput | null,
  school_id?: ModelIDInput | null,
  created_by?: ModelStringInput | null,
  googlesheet_link?: ModelStringInput | null,
  googlesheet_name?: ModelStringInput | null,
  googlesheet_id?: ModelStringInput | null,
  worksheet_name?: ModelStringInput | null,
  worksheet_id?: ModelStringInput | null,
  unique_field?: ModelStringInput | null,
  is_obtained?: ModelBooleanInput | null,
  interval_type?: ModelINTERVAL_TYPEInput | null,
  last_success_sync_date?: ModelStringInput | null,
  last_failure_sync_date?: ModelStringInput | null,
  custom_interval?: ModelIntInput | null,
  is_cancelled?: ModelBooleanInput | null,
  custom_type?: ModelCUSTOM_TYPEInput | null,
  custom_value?: ModelIntInput | null,
  table_name?: ModelStringInput | null,
  refresh_interval_status?: ModelREFRESH_INTERVAL_STATUSInput | null,
  and?: Array< ModelGoogleSheetDetailFilterInput | null > | null,
  or?: Array< ModelGoogleSheetDetailFilterInput | null > | null,
  not?: ModelGoogleSheetDetailFilterInput | null,
};

export type ModelRecurringSyncFilterInput = {
  id?: ModelIDInput | null,
  school_id?: ModelIDInput | null,
  success?: ModelBooleanInput | null,
  worksheet_id?: ModelStringInput | null,
  worksheet_name?: ModelStringInput | null,
  interval_type?: ModelStringInput | null,
  sync_for?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelRecurringSyncFilterInput | null > | null,
  or?: Array< ModelRecurringSyncFilterInput | null > | null,
  not?: ModelRecurringSyncFilterInput | null,
};

export type ModelCountOfAPIHistoryFilterInput = {
  id?: ModelIDInput | null,
  school_id?: ModelIDInput | null,
  api_key?: ModelStringInput | null,
  dataset_type?: ModelStringInput | null,
  count?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelCountOfAPIHistoryFilterInput | null > | null,
  or?: Array< ModelCountOfAPIHistoryFilterInput | null > | null,
  not?: ModelCountOfAPIHistoryFilterInput | null,
};

export type ModelDataSetDetailFilterInput = {
  id?: ModelIDInput | null,
  school_id?: ModelIDInput | null,
  data_set?: ModelStringInput | null,
  data_source?: ModelDATASOURCEInput | null,
  purchased_date?: ModelStringInput | null,
  status?: ModelSTATUSInput | null,
  and?: Array< ModelDataSetDetailFilterInput | null > | null,
  or?: Array< ModelDataSetDetailFilterInput | null > | null,
  not?: ModelDataSetDetailFilterInput | null,
};

export type ModelVeracrossDetailFilterInput = {
  id?: ModelIDInput | null,
  school_id?: ModelIDInput | null,
  veracross_client_id?: ModelStringInput | null,
  veracross_client_secret?: ModelStringInput | null,
  scope?: ModelStringInput | null,
  school_route?: ModelStringInput | null,
  and?: Array< ModelVeracrossDetailFilterInput | null > | null,
  or?: Array< ModelVeracrossDetailFilterInput | null > | null,
  not?: ModelVeracrossDetailFilterInput | null,
};

export type ModelVeracrossDetailConnection = {
  __typename: "ModelVeracrossDetailConnection",
  items:  Array<VeracrossDetail | null >,
  nextToken?: string | null,
};

export type ModelAccountRequestFilterInput = {
  id?: ModelIDInput | null,
  account?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelAccountRequestFilterInput | null > | null,
  or?: Array< ModelAccountRequestFilterInput | null > | null,
  not?: ModelAccountRequestFilterInput | null,
};

export type ModelPlanFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  planDetails?: ModelStringInput | null,
  Targets?: ModelStringInput | null,
  bulletPoints?: ModelStringInput | null,
  listed?: ModelBooleanInput | null,
  order?: ModelIntInput | null,
  and?: Array< ModelPlanFilterInput | null > | null,
  or?: Array< ModelPlanFilterInput | null > | null,
  not?: ModelPlanFilterInput | null,
};

export type ModelPlanConnection = {
  __typename: "ModelPlanConnection",
  items:  Array<Plan | null >,
  nextToken?: string | null,
};

export type ModelWebProfilesFilterInput = {
  id?: ModelIDInput | null,
  account?: ModelIDInput | null,
  platform?: ModelSUPPORTED_PLATFORMInput | null,
  profile_id?: ModelStringInput | null,
  metadata?: ModelStringInput | null,
  enabled?: ModelBooleanInput | null,
  last_sync_date?: ModelStringInput | null,
  disconnect?: ModelBooleanInput | null,
  and?: Array< ModelWebProfilesFilterInput | null > | null,
  or?: Array< ModelWebProfilesFilterInput | null > | null,
  not?: ModelWebProfilesFilterInput | null,
};

export type ModelWebProfilesConnection = {
  __typename: "ModelWebProfilesConnection",
  items:  Array<WebProfiles | null >,
  nextToken?: string | null,
};

export type ModelOAuthCredsFilterInput = {
  id?: ModelIDInput | null,
  account?: ModelIDInput | null,
  platform?: ModelSUPPORTED_PLATFORMInput | null,
  refresh_token?: ModelStringInput | null,
  and?: Array< ModelOAuthCredsFilterInput | null > | null,
  or?: Array< ModelOAuthCredsFilterInput | null > | null,
  not?: ModelOAuthCredsFilterInput | null,
};

export type ModelOAuthCredsConnection = {
  __typename: "ModelOAuthCredsConnection",
  items:  Array<OAuthCreds | null >,
  nextToken?: string | null,
};

export type ModelIntegrationSyncHistoryFilterInput = {
  id?: ModelIDInput | null,
  account?: ModelIDInput | null,
  platform?: ModelSUPPORTED_PLATFORMInput | null,
  profile_id?: ModelStringInput | null,
  source?: ModelSYNC_SOURCEInput | null,
  status?: ModelSYNC_STATUSInput | null,
  createdAt?: ModelStringInput | null,
  detail?: ModelStringInput | null,
  and?: Array< ModelIntegrationSyncHistoryFilterInput | null > | null,
  or?: Array< ModelIntegrationSyncHistoryFilterInput | null > | null,
  not?: ModelIntegrationSyncHistoryFilterInput | null,
};

export type ModelIntegrationSyncHistoryConnection = {
  __typename: "ModelIntegrationSyncHistoryConnection",
  items:  Array<IntegrationSyncHistory | null >,
  nextToken?: string | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelApiKeyFilterInput = {
  id?: ModelIDInput | null,
  account?: ModelIDInput | null,
  key?: ModelStringInput | null,
  keyid?: ModelStringInput | null,
  disabled?: ModelBooleanInput | null,
  and?: Array< ModelApiKeyFilterInput | null > | null,
  or?: Array< ModelApiKeyFilterInput | null > | null,
  not?: ModelApiKeyFilterInput | null,
};

export type ModelApiKeyConnection = {
  __typename: "ModelApiKeyConnection",
  items:  Array<ApiKey | null >,
  nextToken?: string | null,
};

export type ModelFAQFilterInput = {
  question?: ModelStringInput | null,
  answer?: ModelStringInput | null,
  and?: Array< ModelFAQFilterInput | null > | null,
  or?: Array< ModelFAQFilterInput | null > | null,
  not?: ModelFAQFilterInput | null,
};

export type ModelFAQConnection = {
  __typename: "ModelFAQConnection",
  items:  Array<FAQ | null >,
  nextToken?: string | null,
};

export type ModelFirebaseTokensFilterInput = {
  id?: ModelIDInput | null,
  token?: ModelStringInput | null,
  user?: ModelIDInput | null,
  userDetails?: ModelStringInput | null,
  and?: Array< ModelFirebaseTokensFilterInput | null > | null,
  or?: Array< ModelFirebaseTokensFilterInput | null > | null,
  not?: ModelFirebaseTokensFilterInput | null,
};

export type ModelFirebaseTokensConnection = {
  __typename: "ModelFirebaseTokensConnection",
  items:  Array<FirebaseTokens | null >,
  nextToken?: string | null,
};

export type ModeldatasourceFilterInput = {
  id?: ModelIDInput | null,
  data_provider?: ModelDATAPROVIDERInput | null,
  data_source_name?: ModelStringInput | null,
  and?: Array< ModeldatasourceFilterInput | null > | null,
  or?: Array< ModeldatasourceFilterInput | null > | null,
  not?: ModeldatasourceFilterInput | null,
};

export type ModeldatasourceConnection = {
  __typename: "ModeldatasourceConnection",
  items:  Array<datasource | null >,
  nextToken?: string | null,
};

export type ModelNotificationTypeFilterInput = {
  id?: ModelIDInput | null,
  notification_type?: ModelNOTIFICATION_TYPEInput | null,
  code?: ModelStringInput | null,
  title?: ModelStringInput | null,
  notification_text?: ModelStringInput | null,
  content?: ModelStringInput | null,
  metadata?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelNotificationTypeFilterInput | null > | null,
  or?: Array< ModelNotificationTypeFilterInput | null > | null,
  not?: ModelNotificationTypeFilterInput | null,
};

export type ModelNotificationTypeConnection = {
  __typename: "ModelNotificationTypeConnection",
  items:  Array<NotificationType | null >,
  nextToken?: string | null,
};

export type ModelUserNotificationPreferenceFilterInput = {
  id?: ModelIDInput | null,
  user?: ModelIDInput | null,
  userDetails?: ModelStringInput | null,
  notificationType?: ModelIDInput | null,
  emailNotification?: ModelBooleanInput | null,
  inAppNotification?: ModelBooleanInput | null,
  webPushNotification?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelUserNotificationPreferenceFilterInput | null > | null,
  or?: Array< ModelUserNotificationPreferenceFilterInput | null > | null,
  not?: ModelUserNotificationPreferenceFilterInput | null,
};

export type ModelUserNotificationPreferenceConnection = {
  __typename: "ModelUserNotificationPreferenceConnection",
  items:  Array<UserNotificationPreference | null >,
  nextToken?: string | null,
};

export type ModelUserNotificationFilterInput = {
  id?: ModelIDInput | null,
  user?: ModelIDInput | null,
  userDetails?: ModelStringInput | null,
  userNotificationPreference?: ModelIDInput | null,
  title?: ModelStringInput | null,
  content?: ModelStringInput | null,
  message?: ModelStringInput | null,
  code?: ModelStringInput | null,
  metadata?: ModelStringInput | null,
  isRead?: ModelBooleanInput | null,
  emailSent?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelUserNotificationFilterInput | null > | null,
  or?: Array< ModelUserNotificationFilterInput | null > | null,
  not?: ModelUserNotificationFilterInput | null,
};

export type ModelUserNotificationConnection = {
  __typename: "ModelUserNotificationConnection",
  items:  Array<UserNotification | null >,
  nextToken?: string | null,
};

export type ModelInitialSyncDetailsFilterInput = {
  id?: ModelIDInput | null,
  school_id?: ModelIDInput | null,
  data_provider?: ModelDATAPROVIDERInput | null,
  data_source?: ModelDATASOURCEInput | null,
  last_sync_date?: ModelStringInput | null,
  initial_sync_status?: ModelSTATUSInput | null,
  and?: Array< ModelInitialSyncDetailsFilterInput | null > | null,
  or?: Array< ModelInitialSyncDetailsFilterInput | null > | null,
  not?: ModelInitialSyncDetailsFilterInput | null,
};

export type ModelInitialSyncDetailsConnection = {
  __typename: "ModelInitialSyncDetailsConnection",
  items:  Array<InitialSyncDetails | null >,
  nextToken?: string | null,
};

export type ModelRulesFilterInput = {
  id?: ModelIDInput | null,
  school_id?: ModelIDInput | null,
  field_key?: ModelStringInput | null,
  data_source?: ModelDATASOURCEInput | null,
  data_set?: ModelStringInput | null,
  rule_values?: ModelStringInput | null,
  created_by?: ModelStringInput | null,
  updated_by?: ModelStringInput | null,
  rule_type?: ModelStringInput | null,
  and?: Array< ModelRulesFilterInput | null > | null,
  or?: Array< ModelRulesFilterInput | null > | null,
  not?: ModelRulesFilterInput | null,
};

export type ModelRulesConnection = {
  __typename: "ModelRulesConnection",
  items:  Array<Rules | null >,
  nextToken?: string | null,
};

export type ModelDaslValuesFilterInput = {
  id?: ModelIDInput | null,
  field_key?: ModelStringInput | null,
  field_values?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelDaslValuesFilterInput | null > | null,
  or?: Array< ModelDaslValuesFilterInput | null > | null,
  not?: ModelDaslValuesFilterInput | null,
};

export type ModelDaslValuesConnection = {
  __typename: "ModelDaslValuesConnection",
  items:  Array<DaslValues | null >,
  nextToken?: string | null,
};

export type ModelCsvDetailFilterInput = {
  id?: ModelIDInput | null,
  school_id?: ModelIDInput | null,
  created_by?: ModelStringInput | null,
  csv_name?: ModelStringInput | null,
  unique_field?: ModelStringInput | null,
  is_obtained?: ModelBooleanInput | null,
  is_cancelled?: ModelBooleanInput | null,
  status?: ModelSTATUSInput | null,
  and?: Array< ModelCsvDetailFilterInput | null > | null,
  or?: Array< ModelCsvDetailFilterInput | null > | null,
  not?: ModelCsvDetailFilterInput | null,
};

export type ModelCsvDetailConnection = {
  __typename: "ModelCsvDetailConnection",
  items:  Array<CsvDetail | null >,
  nextToken?: string | null,
};

export type ModelPreviewDetailFilterInput = {
  id?: ModelIDInput | null,
  school_id?: ModelIDInput | null,
  created_by?: ModelStringInput | null,
  data_set?: ModelStringInput | null,
  show_fields?: ModelStringInput | null,
  hidden_fields?: ModelStringInput | null,
  and?: Array< ModelPreviewDetailFilterInput | null > | null,
  or?: Array< ModelPreviewDetailFilterInput | null > | null,
  not?: ModelPreviewDetailFilterInput | null,
};

export type ModelPreviewDetailConnection = {
  __typename: "ModelPreviewDetailConnection",
  items:  Array<PreviewDetail | null >,
  nextToken?: string | null,
};

export type ModelQsdashboardMapFilterInput = {
  id?: ModelIDInput | null,
  school_id?: ModelIDInput | null,
  dashboard_id?: ModelIDInput | null,
  dashboard_name?: ModelStringInput | null,
  and?: Array< ModelQsdashboardMapFilterInput | null > | null,
  or?: Array< ModelQsdashboardMapFilterInput | null > | null,
  not?: ModelQsdashboardMapFilterInput | null,
};

export type ModelQsdashboardMapConnection = {
  __typename: "ModelQsdashboardMapConnection",
  items:  Array<QsdashboardMap | null >,
  nextToken?: string | null,
};

export type ModelFavoriteFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  resourceType?: ModelStringInput | null,
  dashboard_oid?: ModelIDInput | null,
  and?: Array< ModelFavoriteFilterInput | null > | null,
  or?: Array< ModelFavoriteFilterInput | null > | null,
  not?: ModelFavoriteFilterInput | null,
};

export type ListEnumValuesQueryVariables = {
  enum: string,
};

export type ListEnumValuesQuery = {
  enum?:  {
    __typename: "__Type",
    enumValues?:  Array< {
      __typename: "__EnumValue",
      name: string,
    } > | null,
  } | null,
};

export type GetUserCustomQueryVariables = {
  id: string,
};

export type GetUserCustomQuery = {
  getAccount?:  {
    __typename: "Account",
    id: string,
    disabled?: boolean | null,
    users?: Array< string | null > | null,
    email?: string | null,
    createdAt: string,
    updatedAt: string,
    accountStatus?: ACCT_STATUS | null,
  } | null,
};

export type UpdateAccountPIICustomMutationVariables = {
  input: UpdateAccountInput,
  condition?: ModelAccountConditionInput | null,
};

export type UpdateAccountPIICustomMutation = {
  updateAccount?:  {
    __typename: "Account",
    piiInformation?: boolean | null,
  } | null,
};

export type UpdateAccountEmailCustomMutationVariables = {
  input: UpdateAccountInput,
  condition?: ModelAccountConditionInput | null,
};

export type UpdateAccountEmailCustomMutation = {
  updateAccount?:  {
    __typename: "Account",
    email?: string | null,
  } | null,
};

export type UpdateDataSetupCustomMutationVariables = {
  input: UpdateDataSetupInput,
  condition?: ModeldataSetupConditionInput | null,
};

export type UpdateDataSetupCustomMutation = {
  updateDataSetup?:  {
    __typename: "dataSetup",
    field_key?: string | null,
    name_by_school?: string | null,
  } | null,
};

export type ListDataSetupsCustomQueryVariables = {
  filter?: ModeldataSetupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDataSetupsCustomQuery = {
  listDataSetups?:  {
    __typename: "ModeldataSetupConnection",
    items:  Array< {
      __typename: "dataSetup",
      id?: string | null,
      field_key?: string | null,
      friendly_name?: string | null,
      name_by_school?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type DataSetupByWorksheetIdCustomQueryVariables = {
  worksheet_id?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModeldataSetupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DataSetupByWorksheetIdCustomQuery = {
  DataSetupByWorksheetId?:  {
    __typename: "ModeldataSetupConnection",
    items:  Array< {
      __typename: "dataSetup",
      id?: string | null,
      school_id?: string | null,
      dimension_or_metrics?: DMTYPE | null,
      field_key?: string | null,
      friendly_name?: string | null,
      field_description?: string | null,
      field_type_GDS?: FIELD_TYPE_GDS | null,
      field_type_PBI?: FIELD_TYPE_PBI | null,
      data_source?: Array< DATASOURCE | null > | null,
      data_set?: string | null,
      api_endpoint?: string | null,
      name_by_school?: string | null,
      worksheet_name?: string | null,
      googlesheet_name?: string | null,
      worksheet_id?: string | null,
      googlesheet_id?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListAccountsCustomQueryVariables = {
  filter?: ModelAccountFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAccountsCustomQuery = {
  listAccounts?:  {
    __typename: "ModelAccountConnection",
    items:  Array< {
      __typename: "Account",
      id: string,
      company?: string | null,
      company_loweredcased?: string | null,
      company_description?: string | null,
      url?: string | null,
      disabled?: boolean | null,
      piiInformation?: boolean | null,
      isSchoolAdmin?: boolean | null,
      email?: string | null,
      users?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      accountStatus?: ACCT_STATUS | null,
      stripeCustomerID?: string | null,
      stripeSubscriptionID?: string | null,
      stripePriceID?: string | null,
      plan?:  {
        __typename: "Plan",
        id: string,
        name: string,
        planDetails?: string | null,
        Targets?: string | null,
        bulletPoints?: Array< string | null > | null,
        listed?: boolean | null,
        order?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      planID?: string | null,
      metadata?: string | null,
      owners?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      viewers?: Array< string | null > | null,
      datasource?: Array< DATASOURCE | null > | null,
      dataprovider?: Array< DATAPROVIDER | null > | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListQsdashboardDetailsCustomQueryVariables = {
  filter?: ModelQsdashboardDetailFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  userId?: string | null,
};

export type ListQsdashboardDetailsCustomQuery = {
  listQsdashboardDetails?:  {
    __typename: "ModelQsdashboardDetailConnection",
    items:  Array< {
      __typename: "QsdashboardDetail",
      id: string,
      school_id?: string | null,
      dashboard_id?: string | null,
      dashboard_name?: string | null,
      dashboard_name_public?: string | null,
      dashboard_description?: string | null,
      dashboard_type?: QS_DASHBOARD_TYPE | null,
      topic_id?: string | null,
      author?: string | null,
      tags?: Array< string | null > | null,
      isFavoriteOf?:  {
        __typename: "ModelFavoriteConnection",
        items:  Array< {
          __typename: "Favorite",
          dashboard_oid?: string | null,
          resourceType?: string | null,
          userId?: string | null,
          id: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAccountForSchoolDetailCustomQueryVariables = {
  id: string,
};

export type GetAccountForSchoolDetailCustomQuery = {
  getAccount?:  {
    __typename: "Account",
    id: string,
    company?: string | null,
    url?: string | null,
    disabled?: boolean | null,
    piiInformation?: boolean | null,
  } | null,
};

export type UpdateAccountSuspendCustomMutationVariables = {
  input: UpdateAccountInput,
  condition?: ModelAccountConditionInput | null,
};

export type UpdateAccountSuspendCustomMutation = {
  updateAccount?:  {
    __typename: "Account",
    disabled?: boolean | null,
  } | null,
};

export type GetAccountForDataSourceCustomQueryVariables = {
  id: string,
};

export type GetAccountForDataSourceCustomQuery = {
  getAccount?:  {
    __typename: "Account",
    id: string,
    accountStatus?: ACCT_STATUS | null,
    datasource?: Array< DATASOURCE | null > | null,
    metadata?: string | null,
  } | null,
};

export type GetAccountForGoogleSheetCustomQueryVariables = {
  id: string,
};

export type GetAccountForGoogleSheetCustomQuery = {
  getAccount?:  {
    __typename: "Account",
    id: string,
    users?: Array< string | null > | null,
    request_googlesheet?:  {
      __typename: "ModelGoogleSheetDetailConnection",
      items:  Array< {
        __typename: "GoogleSheetDetail",
        id: string,
        school_id?: string | null,
        googlesheet_link: string,
        googlesheet_name?: string | null,
        googlesheet_id?: string | null,
        worksheet_name?: string | null,
        worksheet_id?: string | null,
        unique_field?: string | null,
        is_obtained?: boolean | null,
        interval_type?: INTERVAL_TYPE | null,
        custom_interval?: number | null,
        is_cancelled?: boolean | null,
        custom_type?: CUSTOM_TYPE | null,
        created_by?: string | null,
        last_success_sync_date?: string | null,
        last_failure_sync_date?: string | null,
        refresh_interval_status?: REFRESH_INTERVAL_STATUS | null,
        custom_value?: number | null,
        table_name?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type GetAccountForDataSetCustomQueryVariables = {
  id: string,
};

export type GetAccountForDataSetCustomQuery = {
  getAccount?:  {
    __typename: "Account",
    id: string,
    dataSet?:  {
      __typename: "ModelDataSetDetailConnection",
      items:  Array< {
        __typename: "DataSetDetail",
        id: string,
        school_id?: string | null,
        data_set?: string | null,
        data_source?: DATASOURCE | null,
        purchased_date?: string | null,
        status?: STATUS | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type getAccountInitCustomQueryVariables = {
  id: string,
};

export type getAccountInitCustomQuery = {
  getAccount?:  {
    __typename: "Account",
    id: string,
    company?: string | null,
    url?: string | null,
    disabled?: boolean | null,
    email?: string | null,
    planID?: string | null,
    metadata?: string | null,
    piiInformation?: boolean | null,
    datasource?: Array< DATASOURCE | null > | null,
    accountStatus?: ACCT_STATUS | null,
  } | null,
};

export type GetAccountForUserDetailsCustomQueryVariables = {
  id: string,
};

export type GetAccountForUserDetailsCustomQuery = {
  getAccount?:  {
    __typename: "Account",
    id: string,
    users?: Array< string | null > | null,
    email?: string | null,
    requests?:  {
      __typename: "ModelAccountRequestConnection",
      items:  Array< {
        __typename: "AccountRequest",
        id: string,
        account: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    company?: string | null,
  } | null,
};

export type CreateDataSetupMutationVariables = {
  input: CreateDataSetupInput,
  condition?: ModeldataSetupConditionInput | null,
};

export type CreateDataSetupMutation = {
  createDataSetup?:  {
    __typename: "dataSetup",
    id?: string | null,
    school_id?: string | null,
    dimension_or_metrics?: DMTYPE | null,
    field_key?: string | null,
    friendly_name?: string | null,
    field_description?: string | null,
    field_type_GDS?: FIELD_TYPE_GDS | null,
    field_type_PBI?: FIELD_TYPE_PBI | null,
    data_source?: Array< DATASOURCE | null > | null,
    data_set?: string | null,
    api_endpoint?: string | null,
    name_by_school?: string | null,
    worksheet_name?: string | null,
    googlesheet_name?: string | null,
    worksheet_id?: string | null,
    googlesheet_id?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateDataSetupMutationVariables = {
  input: UpdateDataSetupInput,
  condition?: ModeldataSetupConditionInput | null,
};

export type UpdateDataSetupMutation = {
  updateDataSetup?:  {
    __typename: "dataSetup",
    id?: string | null,
    school_id?: string | null,
    dimension_or_metrics?: DMTYPE | null,
    field_key?: string | null,
    friendly_name?: string | null,
    field_description?: string | null,
    field_type_GDS?: FIELD_TYPE_GDS | null,
    field_type_PBI?: FIELD_TYPE_PBI | null,
    data_source?: Array< DATASOURCE | null > | null,
    data_set?: string | null,
    api_endpoint?: string | null,
    name_by_school?: string | null,
    worksheet_name?: string | null,
    googlesheet_name?: string | null,
    worksheet_id?: string | null,
    googlesheet_id?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteDataSetupMutationVariables = {
  input: DeleteDataSetupInput,
  condition?: ModeldataSetupConditionInput | null,
};

export type DeleteDataSetupMutation = {
  deleteDataSetup?:  {
    __typename: "dataSetup",
    id?: string | null,
    school_id?: string | null,
    dimension_or_metrics?: DMTYPE | null,
    field_key?: string | null,
    friendly_name?: string | null,
    field_description?: string | null,
    field_type_GDS?: FIELD_TYPE_GDS | null,
    field_type_PBI?: FIELD_TYPE_PBI | null,
    data_source?: Array< DATASOURCE | null > | null,
    data_set?: string | null,
    api_endpoint?: string | null,
    name_by_school?: string | null,
    worksheet_name?: string | null,
    googlesheet_name?: string | null,
    worksheet_id?: string | null,
    googlesheet_id?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateAccountMutationVariables = {
  input: CreateAccountInput,
  condition?: ModelAccountConditionInput | null,
};

export type CreateAccountMutation = {
  createAccount?:  {
    __typename: "Account",
    id: string,
    company?: string | null,
    company_loweredcased?: string | null,
    company_description?: string | null,
    url?: string | null,
    disabled?: boolean | null,
    piiInformation?: boolean | null,
    isSchoolAdmin?: boolean | null,
    email?: string | null,
    users?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    data_setup?:  {
      __typename: "ModeldataSetupConnection",
      nextToken?: string | null,
    } | null,
    accountStatus?: ACCT_STATUS | null,
    stripeCustomerID?: string | null,
    stripeSubscriptionID?: string | null,
    stripePriceID?: string | null,
    planID?: string | null,
    metadata?: string | null,
    owners?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    viewers?: Array< string | null > | null,
    datasource?: Array< DATASOURCE | null > | null,
    dataprovider?: Array< DATAPROVIDER | null > | null,
    advancedList?:  {
      __typename: "ModelAdvancedListDetailConnection",
      nextToken?: string | null,
    } | null,
    request_googlesheet?:  {
      __typename: "ModelGoogleSheetDetailConnection",
      nextToken?: string | null,
    } | null,
    recurringSync?:  {
      __typename: "ModelRecurringSyncConnection",
      nextToken?: string | null,
    } | null,
    apihistory?:  {
      __typename: "ModelCountOfAPIHistoryConnection",
      nextToken?: string | null,
    } | null,
    dataSet?:  {
      __typename: "ModelDataSetDetailConnection",
      nextToken?: string | null,
    } | null,
    requests?:  {
      __typename: "ModelAccountRequestConnection",
      nextToken?: string | null,
    } | null,
    plan?:  {
      __typename: "Plan",
      id: string,
      name: string,
      planDetails?: string | null,
      Targets?: string | null,
      bulletPoints?: Array< string | null > | null,
      listed?: boolean | null,
      order?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type UpdateAccountMutationVariables = {
  input: UpdateAccountInput,
  condition?: ModelAccountConditionInput | null,
};

export type UpdateAccountMutation = {
  updateAccount?:  {
    __typename: "Account",
    id: string,
    company?: string | null,
    company_loweredcased?: string | null,
    company_description?: string | null,
    url?: string | null,
    disabled?: boolean | null,
    piiInformation?: boolean | null,
    isSchoolAdmin?: boolean | null,
    email?: string | null,
    users?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    data_setup?:  {
      __typename: "ModeldataSetupConnection",
      nextToken?: string | null,
    } | null,
    accountStatus?: ACCT_STATUS | null,
    stripeCustomerID?: string | null,
    stripeSubscriptionID?: string | null,
    stripePriceID?: string | null,
    planID?: string | null,
    metadata?: string | null,
    owners?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    viewers?: Array< string | null > | null,
    datasource?: Array< DATASOURCE | null > | null,
    dataprovider?: Array< DATAPROVIDER | null > | null,
    advancedList?:  {
      __typename: "ModelAdvancedListDetailConnection",
      nextToken?: string | null,
    } | null,
    request_googlesheet?:  {
      __typename: "ModelGoogleSheetDetailConnection",
      nextToken?: string | null,
    } | null,
    recurringSync?:  {
      __typename: "ModelRecurringSyncConnection",
      nextToken?: string | null,
    } | null,
    apihistory?:  {
      __typename: "ModelCountOfAPIHistoryConnection",
      nextToken?: string | null,
    } | null,
    dataSet?:  {
      __typename: "ModelDataSetDetailConnection",
      nextToken?: string | null,
    } | null,
    requests?:  {
      __typename: "ModelAccountRequestConnection",
      nextToken?: string | null,
    } | null,
    plan?:  {
      __typename: "Plan",
      id: string,
      name: string,
      planDetails?: string | null,
      Targets?: string | null,
      bulletPoints?: Array< string | null > | null,
      listed?: boolean | null,
      order?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type DeleteAccountMutationVariables = {
  input: DeleteAccountInput,
  condition?: ModelAccountConditionInput | null,
};

export type DeleteAccountMutation = {
  deleteAccount?:  {
    __typename: "Account",
    id: string,
    company?: string | null,
    company_loweredcased?: string | null,
    company_description?: string | null,
    url?: string | null,
    disabled?: boolean | null,
    piiInformation?: boolean | null,
    isSchoolAdmin?: boolean | null,
    email?: string | null,
    users?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    data_setup?:  {
      __typename: "ModeldataSetupConnection",
      nextToken?: string | null,
    } | null,
    accountStatus?: ACCT_STATUS | null,
    stripeCustomerID?: string | null,
    stripeSubscriptionID?: string | null,
    stripePriceID?: string | null,
    planID?: string | null,
    metadata?: string | null,
    owners?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    viewers?: Array< string | null > | null,
    datasource?: Array< DATASOURCE | null > | null,
    dataprovider?: Array< DATAPROVIDER | null > | null,
    advancedList?:  {
      __typename: "ModelAdvancedListDetailConnection",
      nextToken?: string | null,
    } | null,
    request_googlesheet?:  {
      __typename: "ModelGoogleSheetDetailConnection",
      nextToken?: string | null,
    } | null,
    recurringSync?:  {
      __typename: "ModelRecurringSyncConnection",
      nextToken?: string | null,
    } | null,
    apihistory?:  {
      __typename: "ModelCountOfAPIHistoryConnection",
      nextToken?: string | null,
    } | null,
    dataSet?:  {
      __typename: "ModelDataSetDetailConnection",
      nextToken?: string | null,
    } | null,
    requests?:  {
      __typename: "ModelAccountRequestConnection",
      nextToken?: string | null,
    } | null,
    plan?:  {
      __typename: "Plan",
      id: string,
      name: string,
      planDetails?: string | null,
      Targets?: string | null,
      bulletPoints?: Array< string | null > | null,
      listed?: boolean | null,
      order?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type CreateAdvancedListDetailMutationVariables = {
  input: CreateAdvancedListDetailInput,
  condition?: ModelAdvancedListDetailConditionInput | null,
};

export type CreateAdvancedListDetailMutation = {
  createAdvancedListDetail?:  {
    __typename: "AdvancedListDetail",
    id: string,
    school_id?: string | null,
    list_name?: string | null,
    list_id?: string | null,
    datasource?: string | null,
    dataset?: string | null,
    status?: STATUS | null,
    preconfigure?: boolean | null,
    last_sync_date?: string | null,
    interval_type?: INTERVAL_TYPE | null,
    recurring_sync_status?: boolean | null,
    custom_interval?: number | null,
    custom_type?: CUSTOM_TYPE | null,
    custom_value?: number | null,
    is_initial_sync?: STATUS | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateAdvancedListDetailMutationVariables = {
  input: UpdateAdvancedListDetailInput,
  condition?: ModelAdvancedListDetailConditionInput | null,
};

export type UpdateAdvancedListDetailMutation = {
  updateAdvancedListDetail?:  {
    __typename: "AdvancedListDetail",
    id: string,
    school_id?: string | null,
    list_name?: string | null,
    list_id?: string | null,
    datasource?: string | null,
    dataset?: string | null,
    status?: STATUS | null,
    preconfigure?: boolean | null,
    last_sync_date?: string | null,
    interval_type?: INTERVAL_TYPE | null,
    recurring_sync_status?: boolean | null,
    custom_interval?: number | null,
    custom_type?: CUSTOM_TYPE | null,
    custom_value?: number | null,
    is_initial_sync?: STATUS | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteAdvancedListDetailMutationVariables = {
  input: DeleteAdvancedListDetailInput,
  condition?: ModelAdvancedListDetailConditionInput | null,
};

export type DeleteAdvancedListDetailMutation = {
  deleteAdvancedListDetail?:  {
    __typename: "AdvancedListDetail",
    id: string,
    school_id?: string | null,
    list_name?: string | null,
    list_id?: string | null,
    datasource?: string | null,
    dataset?: string | null,
    status?: STATUS | null,
    preconfigure?: boolean | null,
    last_sync_date?: string | null,
    interval_type?: INTERVAL_TYPE | null,
    recurring_sync_status?: boolean | null,
    custom_interval?: number | null,
    custom_type?: CUSTOM_TYPE | null,
    custom_value?: number | null,
    is_initial_sync?: STATUS | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateDataPackageDetailMutationVariables = {
  input: CreateDataPackageDetailInput,
  condition?: ModelDataPackageDetailConditionInput | null,
};

export type CreateDataPackageDetailMutation = {
  createDataPackageDetail?:  {
    __typename: "DataPackageDetail",
    id: string,
    school_id?: string | null,
    package_name?: string | null,
    datasource?: string | null,
    dataset?: string | null,
    status?: STATUS | null,
    last_sync_date?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateDataPackageDetailMutationVariables = {
  input: UpdateDataPackageDetailInput,
  condition?: ModelDataPackageDetailConditionInput | null,
};

export type UpdateDataPackageDetailMutation = {
  updateDataPackageDetail?:  {
    __typename: "DataPackageDetail",
    id: string,
    school_id?: string | null,
    package_name?: string | null,
    datasource?: string | null,
    dataset?: string | null,
    status?: STATUS | null,
    last_sync_date?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteDataPackageDetailMutationVariables = {
  input: DeleteDataPackageDetailInput,
  condition?: ModelDataPackageDetailConditionInput | null,
};

export type DeleteDataPackageDetailMutation = {
  deleteDataPackageDetail?:  {
    __typename: "DataPackageDetail",
    id: string,
    school_id?: string | null,
    package_name?: string | null,
    datasource?: string | null,
    dataset?: string | null,
    status?: STATUS | null,
    last_sync_date?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateGoogleSheetDetailMutationVariables = {
  input: CreateGoogleSheetDetailInput,
  condition?: ModelGoogleSheetDetailConditionInput | null,
};

export type CreateGoogleSheetDetailMutation = {
  createGoogleSheetDetail?:  {
    __typename: "GoogleSheetDetail",
    id: string,
    school_id?: string | null,
    created_by?: string | null,
    googlesheet_link: string,
    googlesheet_name?: string | null,
    googlesheet_id?: string | null,
    worksheet_name?: string | null,
    worksheet_id?: string | null,
    unique_field?: string | null,
    is_obtained?: boolean | null,
    interval_type?: INTERVAL_TYPE | null,
    last_success_sync_date?: string | null,
    last_failure_sync_date?: string | null,
    custom_interval?: number | null,
    is_cancelled?: boolean | null,
    custom_type?: CUSTOM_TYPE | null,
    custom_value?: number | null,
    table_name?: string | null,
    refresh_interval_status?: REFRESH_INTERVAL_STATUS | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateGoogleSheetDetailMutationVariables = {
  input: UpdateGoogleSheetDetailInput,
  condition?: ModelGoogleSheetDetailConditionInput | null,
};

export type UpdateGoogleSheetDetailMutation = {
  updateGoogleSheetDetail?:  {
    __typename: "GoogleSheetDetail",
    id: string,
    school_id?: string | null,
    created_by?: string | null,
    googlesheet_link: string,
    googlesheet_name?: string | null,
    googlesheet_id?: string | null,
    worksheet_name?: string | null,
    worksheet_id?: string | null,
    unique_field?: string | null,
    is_obtained?: boolean | null,
    interval_type?: INTERVAL_TYPE | null,
    last_success_sync_date?: string | null,
    last_failure_sync_date?: string | null,
    custom_interval?: number | null,
    is_cancelled?: boolean | null,
    custom_type?: CUSTOM_TYPE | null,
    custom_value?: number | null,
    table_name?: string | null,
    refresh_interval_status?: REFRESH_INTERVAL_STATUS | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteGoogleSheetDetailMutationVariables = {
  input: DeleteGoogleSheetDetailInput,
  condition?: ModelGoogleSheetDetailConditionInput | null,
};

export type DeleteGoogleSheetDetailMutation = {
  deleteGoogleSheetDetail?:  {
    __typename: "GoogleSheetDetail",
    id: string,
    school_id?: string | null,
    created_by?: string | null,
    googlesheet_link: string,
    googlesheet_name?: string | null,
    googlesheet_id?: string | null,
    worksheet_name?: string | null,
    worksheet_id?: string | null,
    unique_field?: string | null,
    is_obtained?: boolean | null,
    interval_type?: INTERVAL_TYPE | null,
    last_success_sync_date?: string | null,
    last_failure_sync_date?: string | null,
    custom_interval?: number | null,
    is_cancelled?: boolean | null,
    custom_type?: CUSTOM_TYPE | null,
    custom_value?: number | null,
    table_name?: string | null,
    refresh_interval_status?: REFRESH_INTERVAL_STATUS | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateRecurringSyncMutationVariables = {
  input: CreateRecurringSyncInput,
  condition?: ModelRecurringSyncConditionInput | null,
};

export type CreateRecurringSyncMutation = {
  createRecurringSync?:  {
    __typename: "RecurringSync",
    id: string,
    school_id?: string | null,
    success?: boolean | null,
    worksheet_id?: string | null,
    worksheet_name?: string | null,
    interval_type?: string | null,
    sync_for?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type UpdateRecurringSyncMutationVariables = {
  input: UpdateRecurringSyncInput,
  condition?: ModelRecurringSyncConditionInput | null,
};

export type UpdateRecurringSyncMutation = {
  updateRecurringSync?:  {
    __typename: "RecurringSync",
    id: string,
    school_id?: string | null,
    success?: boolean | null,
    worksheet_id?: string | null,
    worksheet_name?: string | null,
    interval_type?: string | null,
    sync_for?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type DeleteRecurringSyncMutationVariables = {
  input: DeleteRecurringSyncInput,
  condition?: ModelRecurringSyncConditionInput | null,
};

export type DeleteRecurringSyncMutation = {
  deleteRecurringSync?:  {
    __typename: "RecurringSync",
    id: string,
    school_id?: string | null,
    success?: boolean | null,
    worksheet_id?: string | null,
    worksheet_name?: string | null,
    interval_type?: string | null,
    sync_for?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type CreateCountOfAPIHistoryMutationVariables = {
  input: CreateCountOfAPIHistoryInput,
  condition?: ModelCountOfAPIHistoryConditionInput | null,
};

export type CreateCountOfAPIHistoryMutation = {
  createCountOfAPIHistory?:  {
    __typename: "CountOfAPIHistory",
    id: string,
    school_id?: string | null,
    api_key?: string | null,
    dataset_type?: string | null,
    count?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type UpdateCountOfAPIHistoryMutationVariables = {
  input: UpdateCountOfAPIHistoryInput,
  condition?: ModelCountOfAPIHistoryConditionInput | null,
};

export type UpdateCountOfAPIHistoryMutation = {
  updateCountOfAPIHistory?:  {
    __typename: "CountOfAPIHistory",
    id: string,
    school_id?: string | null,
    api_key?: string | null,
    dataset_type?: string | null,
    count?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type DeleteCountOfAPIHistoryMutationVariables = {
  input: DeleteCountOfAPIHistoryInput,
  condition?: ModelCountOfAPIHistoryConditionInput | null,
};

export type DeleteCountOfAPIHistoryMutation = {
  deleteCountOfAPIHistory?:  {
    __typename: "CountOfAPIHistory",
    id: string,
    school_id?: string | null,
    api_key?: string | null,
    dataset_type?: string | null,
    count?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type CreateDataSetDetailMutationVariables = {
  input: CreateDataSetDetailInput,
  condition?: ModelDataSetDetailConditionInput | null,
};

export type CreateDataSetDetailMutation = {
  createDataSetDetail?:  {
    __typename: "DataSetDetail",
    id: string,
    school_id?: string | null,
    data_set?: string | null,
    data_source?: DATASOURCE | null,
    purchased_date?: string | null,
    status?: STATUS | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateDataSetDetailMutationVariables = {
  input: UpdateDataSetDetailInput,
  condition?: ModelDataSetDetailConditionInput | null,
};

export type UpdateDataSetDetailMutation = {
  updateDataSetDetail?:  {
    __typename: "DataSetDetail",
    id: string,
    school_id?: string | null,
    data_set?: string | null,
    data_source?: DATASOURCE | null,
    purchased_date?: string | null,
    status?: STATUS | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteDataSetDetailMutationVariables = {
  input: DeleteDataSetDetailInput,
  condition?: ModelDataSetDetailConditionInput | null,
};

export type DeleteDataSetDetailMutation = {
  deleteDataSetDetail?:  {
    __typename: "DataSetDetail",
    id: string,
    school_id?: string | null,
    data_set?: string | null,
    data_source?: DATASOURCE | null,
    purchased_date?: string | null,
    status?: STATUS | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateVeracrossDetailMutationVariables = {
  input: CreateVeracrossDetailInput,
  condition?: ModelVeracrossDetailConditionInput | null,
};

export type CreateVeracrossDetailMutation = {
  createVeracrossDetail?:  {
    __typename: "VeracrossDetail",
    id: string,
    school_id?: string | null,
    veracross_client_id?: string | null,
    veracross_client_secret?: string | null,
    scope?: string | null,
    school_route?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateVeracrossDetailMutationVariables = {
  input: UpdateVeracrossDetailInput,
  condition?: ModelVeracrossDetailConditionInput | null,
};

export type UpdateVeracrossDetailMutation = {
  updateVeracrossDetail?:  {
    __typename: "VeracrossDetail",
    id: string,
    school_id?: string | null,
    veracross_client_id?: string | null,
    veracross_client_secret?: string | null,
    scope?: string | null,
    school_route?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteVeracrossDetailMutationVariables = {
  input: DeleteVeracrossDetailInput,
  condition?: ModelVeracrossDetailConditionInput | null,
};

export type DeleteVeracrossDetailMutation = {
  deleteVeracrossDetail?:  {
    __typename: "VeracrossDetail",
    id: string,
    school_id?: string | null,
    veracross_client_id?: string | null,
    veracross_client_secret?: string | null,
    scope?: string | null,
    school_route?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateAccountRequestMutationVariables = {
  input: CreateAccountRequestInput,
  condition?: ModelAccountRequestConditionInput | null,
};

export type CreateAccountRequestMutation = {
  createAccountRequest?:  {
    __typename: "AccountRequest",
    id: string,
    account: string,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateAccountRequestMutationVariables = {
  input: UpdateAccountRequestInput,
  condition?: ModelAccountRequestConditionInput | null,
};

export type UpdateAccountRequestMutation = {
  updateAccountRequest?:  {
    __typename: "AccountRequest",
    id: string,
    account: string,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteAccountRequestMutationVariables = {
  input: DeleteAccountRequestInput,
  condition?: ModelAccountRequestConditionInput | null,
};

export type DeleteAccountRequestMutation = {
  deleteAccountRequest?:  {
    __typename: "AccountRequest",
    id: string,
    account: string,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreatePlanMutationVariables = {
  input: CreatePlanInput,
  condition?: ModelPlanConditionInput | null,
};

export type CreatePlanMutation = {
  createPlan?:  {
    __typename: "Plan",
    id: string,
    name: string,
    planDetails?: string | null,
    Targets?: string | null,
    bulletPoints?: Array< string | null > | null,
    listed?: boolean | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdatePlanMutationVariables = {
  input: UpdatePlanInput,
  condition?: ModelPlanConditionInput | null,
};

export type UpdatePlanMutation = {
  updatePlan?:  {
    __typename: "Plan",
    id: string,
    name: string,
    planDetails?: string | null,
    Targets?: string | null,
    bulletPoints?: Array< string | null > | null,
    listed?: boolean | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeletePlanMutationVariables = {
  input: DeletePlanInput,
  condition?: ModelPlanConditionInput | null,
};

export type DeletePlanMutation = {
  deletePlan?:  {
    __typename: "Plan",
    id: string,
    name: string,
    planDetails?: string | null,
    Targets?: string | null,
    bulletPoints?: Array< string | null > | null,
    listed?: boolean | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateWebProfilesMutationVariables = {
  input: CreateWebProfilesInput,
  condition?: ModelWebProfilesConditionInput | null,
};

export type CreateWebProfilesMutation = {
  createWebProfiles?:  {
    __typename: "WebProfiles",
    id: string,
    account: string,
    platform: SUPPORTED_PLATFORM,
    profile_id?: string | null,
    metadata?: string | null,
    enabled?: boolean | null,
    last_sync_date?: string | null,
    disconnect?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateWebProfilesMutationVariables = {
  input: UpdateWebProfilesInput,
  condition?: ModelWebProfilesConditionInput | null,
};

export type UpdateWebProfilesMutation = {
  updateWebProfiles?:  {
    __typename: "WebProfiles",
    id: string,
    account: string,
    platform: SUPPORTED_PLATFORM,
    profile_id?: string | null,
    metadata?: string | null,
    enabled?: boolean | null,
    last_sync_date?: string | null,
    disconnect?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteWebProfilesMutationVariables = {
  input: DeleteWebProfilesInput,
  condition?: ModelWebProfilesConditionInput | null,
};

export type DeleteWebProfilesMutation = {
  deleteWebProfiles?:  {
    __typename: "WebProfiles",
    id: string,
    account: string,
    platform: SUPPORTED_PLATFORM,
    profile_id?: string | null,
    metadata?: string | null,
    enabled?: boolean | null,
    last_sync_date?: string | null,
    disconnect?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateOAuthCredsMutationVariables = {
  input: CreateOAuthCredsInput,
  condition?: ModelOAuthCredsConditionInput | null,
};

export type CreateOAuthCredsMutation = {
  createOAuthCreds?:  {
    __typename: "OAuthCreds",
    id: string,
    account: string,
    platform: SUPPORTED_PLATFORM,
    refresh_token?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateOAuthCredsMutationVariables = {
  input: UpdateOAuthCredsInput,
  condition?: ModelOAuthCredsConditionInput | null,
};

export type UpdateOAuthCredsMutation = {
  updateOAuthCreds?:  {
    __typename: "OAuthCreds",
    id: string,
    account: string,
    platform: SUPPORTED_PLATFORM,
    refresh_token?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteOAuthCredsMutationVariables = {
  input: DeleteOAuthCredsInput,
  condition?: ModelOAuthCredsConditionInput | null,
};

export type DeleteOAuthCredsMutation = {
  deleteOAuthCreds?:  {
    __typename: "OAuthCreds",
    id: string,
    account: string,
    platform: SUPPORTED_PLATFORM,
    refresh_token?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateIntegrationSyncHistoryMutationVariables = {
  input: CreateIntegrationSyncHistoryInput,
  condition?: ModelIntegrationSyncHistoryConditionInput | null,
};

export type CreateIntegrationSyncHistoryMutation = {
  createIntegrationSyncHistory?:  {
    __typename: "IntegrationSyncHistory",
    id: string,
    account: string,
    platform: SUPPORTED_PLATFORM,
    profile_id: string,
    source: SYNC_SOURCE,
    status: SYNC_STATUS,
    createdAt: string,
    detail?: string | null,
    updatedAt: string,
  } | null,
};

export type UpdateIntegrationSyncHistoryMutationVariables = {
  input: UpdateIntegrationSyncHistoryInput,
  condition?: ModelIntegrationSyncHistoryConditionInput | null,
};

export type UpdateIntegrationSyncHistoryMutation = {
  updateIntegrationSyncHistory?:  {
    __typename: "IntegrationSyncHistory",
    id: string,
    account: string,
    platform: SUPPORTED_PLATFORM,
    profile_id: string,
    source: SYNC_SOURCE,
    status: SYNC_STATUS,
    createdAt: string,
    detail?: string | null,
    updatedAt: string,
  } | null,
};

export type DeleteIntegrationSyncHistoryMutationVariables = {
  input: DeleteIntegrationSyncHistoryInput,
  condition?: ModelIntegrationSyncHistoryConditionInput | null,
};

export type DeleteIntegrationSyncHistoryMutation = {
  deleteIntegrationSyncHistory?:  {
    __typename: "IntegrationSyncHistory",
    id: string,
    account: string,
    platform: SUPPORTED_PLATFORM,
    profile_id: string,
    source: SYNC_SOURCE,
    status: SYNC_STATUS,
    createdAt: string,
    detail?: string | null,
    updatedAt: string,
  } | null,
};

export type CreateApiKeyMutationVariables = {
  input: CreateApiKeyInput,
  condition?: ModelApiKeyConditionInput | null,
};

export type CreateApiKeyMutation = {
  createApiKey?:  {
    __typename: "ApiKey",
    id: string,
    account: string,
    key: string,
    keyid: string,
    disabled: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateApiKeyMutationVariables = {
  input: UpdateApiKeyInput,
  condition?: ModelApiKeyConditionInput | null,
};

export type UpdateApiKeyMutation = {
  updateApiKey?:  {
    __typename: "ApiKey",
    id: string,
    account: string,
    key: string,
    keyid: string,
    disabled: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteApiKeyMutationVariables = {
  input: DeleteApiKeyInput,
  condition?: ModelApiKeyConditionInput | null,
};

export type DeleteApiKeyMutation = {
  deleteApiKey?:  {
    __typename: "ApiKey",
    id: string,
    account: string,
    key: string,
    keyid: string,
    disabled: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateFAQMutationVariables = {
  input: CreateFAQInput,
  condition?: ModelFAQConditionInput | null,
};

export type CreateFAQMutation = {
  createFAQ?:  {
    __typename: "FAQ",
    id: string,
    question: string,
    answer: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateFAQMutationVariables = {
  input: UpdateFAQInput,
  condition?: ModelFAQConditionInput | null,
};

export type UpdateFAQMutation = {
  updateFAQ?:  {
    __typename: "FAQ",
    id: string,
    question: string,
    answer: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteFAQMutationVariables = {
  input: DeleteFAQInput,
  condition?: ModelFAQConditionInput | null,
};

export type DeleteFAQMutation = {
  deleteFAQ?:  {
    __typename: "FAQ",
    id: string,
    question: string,
    answer: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateFirebaseTokensMutationVariables = {
  input: CreateFirebaseTokensInput,
  condition?: ModelFirebaseTokensConditionInput | null,
};

export type CreateFirebaseTokensMutation = {
  createFirebaseTokens?:  {
    __typename: "FirebaseTokens",
    id: string,
    token?: string | null,
    user?: string | null,
    userDetails?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateFirebaseTokensMutationVariables = {
  input: UpdateFirebaseTokensInput,
  condition?: ModelFirebaseTokensConditionInput | null,
};

export type UpdateFirebaseTokensMutation = {
  updateFirebaseTokens?:  {
    __typename: "FirebaseTokens",
    id: string,
    token?: string | null,
    user?: string | null,
    userDetails?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteFirebaseTokensMutationVariables = {
  input: DeleteFirebaseTokensInput,
  condition?: ModelFirebaseTokensConditionInput | null,
};

export type DeleteFirebaseTokensMutation = {
  deleteFirebaseTokens?:  {
    __typename: "FirebaseTokens",
    id: string,
    token?: string | null,
    user?: string | null,
    userDetails?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateDatasourceMutationVariables = {
  input: CreateDatasourceInput,
  condition?: ModeldatasourceConditionInput | null,
};

export type CreateDatasourceMutation = {
  createDatasource?:  {
    __typename: "datasource",
    id: string,
    data_provider: DATAPROVIDER,
    data_source_name?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateDatasourceMutationVariables = {
  input: UpdateDatasourceInput,
  condition?: ModeldatasourceConditionInput | null,
};

export type UpdateDatasourceMutation = {
  updateDatasource?:  {
    __typename: "datasource",
    id: string,
    data_provider: DATAPROVIDER,
    data_source_name?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteDatasourceMutationVariables = {
  input: DeleteDatasourceInput,
  condition?: ModeldatasourceConditionInput | null,
};

export type DeleteDatasourceMutation = {
  deleteDatasource?:  {
    __typename: "datasource",
    id: string,
    data_provider: DATAPROVIDER,
    data_source_name?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateNotificationTypeMutationVariables = {
  input: CreateNotificationTypeInput,
  condition?: ModelNotificationTypeConditionInput | null,
};

export type CreateNotificationTypeMutation = {
  createNotificationType?:  {
    __typename: "NotificationType",
    id: string,
    notification_type: NOTIFICATION_TYPE,
    code?: string | null,
    title?: string | null,
    notification_text?: string | null,
    content?: string | null,
    metadata?: string | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type UpdateNotificationTypeMutationVariables = {
  input: UpdateNotificationTypeInput,
  condition?: ModelNotificationTypeConditionInput | null,
};

export type UpdateNotificationTypeMutation = {
  updateNotificationType?:  {
    __typename: "NotificationType",
    id: string,
    notification_type: NOTIFICATION_TYPE,
    code?: string | null,
    title?: string | null,
    notification_text?: string | null,
    content?: string | null,
    metadata?: string | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type DeleteNotificationTypeMutationVariables = {
  input: DeleteNotificationTypeInput,
  condition?: ModelNotificationTypeConditionInput | null,
};

export type DeleteNotificationTypeMutation = {
  deleteNotificationType?:  {
    __typename: "NotificationType",
    id: string,
    notification_type: NOTIFICATION_TYPE,
    code?: string | null,
    title?: string | null,
    notification_text?: string | null,
    content?: string | null,
    metadata?: string | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type CreateUserNotificationPreferenceMutationVariables = {
  input: CreateUserNotificationPreferenceInput,
  condition?: ModelUserNotificationPreferenceConditionInput | null,
};

export type CreateUserNotificationPreferenceMutation = {
  createUserNotificationPreference?:  {
    __typename: "UserNotificationPreference",
    id: string,
    user?: string | null,
    userDetails?: string | null,
    notificationType?: string | null,
    emailNotification?: boolean | null,
    inAppNotification?: boolean | null,
    webPushNotification?: boolean | null,
    createdAt?: string | null,
    updatedAt: string,
    notificationTypeDetails?:  {
      __typename: "NotificationType",
      id: string,
      notification_type: NOTIFICATION_TYPE,
      code?: string | null,
      title?: string | null,
      notification_text?: string | null,
      content?: string | null,
      metadata?: string | null,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type UpdateUserNotificationPreferenceMutationVariables = {
  input: UpdateUserNotificationPreferenceInput,
  condition?: ModelUserNotificationPreferenceConditionInput | null,
};

export type UpdateUserNotificationPreferenceMutation = {
  updateUserNotificationPreference?:  {
    __typename: "UserNotificationPreference",
    id: string,
    user?: string | null,
    userDetails?: string | null,
    notificationType?: string | null,
    emailNotification?: boolean | null,
    inAppNotification?: boolean | null,
    webPushNotification?: boolean | null,
    createdAt?: string | null,
    updatedAt: string,
    notificationTypeDetails?:  {
      __typename: "NotificationType",
      id: string,
      notification_type: NOTIFICATION_TYPE,
      code?: string | null,
      title?: string | null,
      notification_text?: string | null,
      content?: string | null,
      metadata?: string | null,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type DeleteUserNotificationPreferenceMutationVariables = {
  input: DeleteUserNotificationPreferenceInput,
  condition?: ModelUserNotificationPreferenceConditionInput | null,
};

export type DeleteUserNotificationPreferenceMutation = {
  deleteUserNotificationPreference?:  {
    __typename: "UserNotificationPreference",
    id: string,
    user?: string | null,
    userDetails?: string | null,
    notificationType?: string | null,
    emailNotification?: boolean | null,
    inAppNotification?: boolean | null,
    webPushNotification?: boolean | null,
    createdAt?: string | null,
    updatedAt: string,
    notificationTypeDetails?:  {
      __typename: "NotificationType",
      id: string,
      notification_type: NOTIFICATION_TYPE,
      code?: string | null,
      title?: string | null,
      notification_text?: string | null,
      content?: string | null,
      metadata?: string | null,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type CreateUserNotificationMutationVariables = {
  input: CreateUserNotificationInput,
  condition?: ModelUserNotificationConditionInput | null,
};

export type CreateUserNotificationMutation = {
  createUserNotification?:  {
    __typename: "UserNotification",
    id: string,
    user?: string | null,
    userDetails?: string | null,
    userNotificationPreference?: string | null,
    title?: string | null,
    content?: string | null,
    message?: string | null,
    code?: string | null,
    metadata?: string | null,
    isRead?: boolean | null,
    emailSent?: boolean | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type UpdateUserNotificationMutationVariables = {
  input: UpdateUserNotificationInput,
  condition?: ModelUserNotificationConditionInput | null,
};

export type UpdateUserNotificationMutation = {
  updateUserNotification?:  {
    __typename: "UserNotification",
    id: string,
    user?: string | null,
    userDetails?: string | null,
    userNotificationPreference?: string | null,
    title?: string | null,
    content?: string | null,
    message?: string | null,
    code?: string | null,
    metadata?: string | null,
    isRead?: boolean | null,
    emailSent?: boolean | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type DeleteUserNotificationMutationVariables = {
  input: DeleteUserNotificationInput,
  condition?: ModelUserNotificationConditionInput | null,
};

export type DeleteUserNotificationMutation = {
  deleteUserNotification?:  {
    __typename: "UserNotification",
    id: string,
    user?: string | null,
    userDetails?: string | null,
    userNotificationPreference?: string | null,
    title?: string | null,
    content?: string | null,
    message?: string | null,
    code?: string | null,
    metadata?: string | null,
    isRead?: boolean | null,
    emailSent?: boolean | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type CreateInitialSyncDetailsMutationVariables = {
  input: CreateInitialSyncDetailsInput,
  condition?: ModelInitialSyncDetailsConditionInput | null,
};

export type CreateInitialSyncDetailsMutation = {
  createInitialSyncDetails?:  {
    __typename: "InitialSyncDetails",
    id: string,
    school_id?: string | null,
    data_provider?: DATAPROVIDER | null,
    data_source?: DATASOURCE | null,
    last_sync_date?: string | null,
    initial_sync_status?: STATUS | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateInitialSyncDetailsMutationVariables = {
  input: UpdateInitialSyncDetailsInput,
  condition?: ModelInitialSyncDetailsConditionInput | null,
};

export type UpdateInitialSyncDetailsMutation = {
  updateInitialSyncDetails?:  {
    __typename: "InitialSyncDetails",
    id: string,
    school_id?: string | null,
    data_provider?: DATAPROVIDER | null,
    data_source?: DATASOURCE | null,
    last_sync_date?: string | null,
    initial_sync_status?: STATUS | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteInitialSyncDetailsMutationVariables = {
  input: DeleteInitialSyncDetailsInput,
  condition?: ModelInitialSyncDetailsConditionInput | null,
};

export type DeleteInitialSyncDetailsMutation = {
  deleteInitialSyncDetails?:  {
    __typename: "InitialSyncDetails",
    id: string,
    school_id?: string | null,
    data_provider?: DATAPROVIDER | null,
    data_source?: DATASOURCE | null,
    last_sync_date?: string | null,
    initial_sync_status?: STATUS | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateRulesMutationVariables = {
  input: CreateRulesInput,
  condition?: ModelRulesConditionInput | null,
};

export type CreateRulesMutation = {
  createRules?:  {
    __typename: "Rules",
    id?: string | null,
    school_id?: string | null,
    field_key?: string | null,
    data_source?: DATASOURCE | null,
    data_set?: string | null,
    rule_values?: string | null,
    created_by?: string | null,
    updated_by?: string | null,
    rule_type?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateRulesMutationVariables = {
  input: UpdateRulesInput,
  condition?: ModelRulesConditionInput | null,
};

export type UpdateRulesMutation = {
  updateRules?:  {
    __typename: "Rules",
    id?: string | null,
    school_id?: string | null,
    field_key?: string | null,
    data_source?: DATASOURCE | null,
    data_set?: string | null,
    rule_values?: string | null,
    created_by?: string | null,
    updated_by?: string | null,
    rule_type?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteRulesMutationVariables = {
  input: DeleteRulesInput,
  condition?: ModelRulesConditionInput | null,
};

export type DeleteRulesMutation = {
  deleteRules?:  {
    __typename: "Rules",
    id?: string | null,
    school_id?: string | null,
    field_key?: string | null,
    data_source?: DATASOURCE | null,
    data_set?: string | null,
    rule_values?: string | null,
    created_by?: string | null,
    updated_by?: string | null,
    rule_type?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateDaslValuesMutationVariables = {
  input: CreateDaslValuesInput,
  condition?: ModelDaslValuesConditionInput | null,
};

export type CreateDaslValuesMutation = {
  createDaslValues?:  {
    __typename: "DaslValues",
    id?: string | null,
    field_key?: string | null,
    field_values?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type UpdateDaslValuesMutationVariables = {
  input: UpdateDaslValuesInput,
  condition?: ModelDaslValuesConditionInput | null,
};

export type UpdateDaslValuesMutation = {
  updateDaslValues?:  {
    __typename: "DaslValues",
    id?: string | null,
    field_key?: string | null,
    field_values?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type DeleteDaslValuesMutationVariables = {
  input: DeleteDaslValuesInput,
  condition?: ModelDaslValuesConditionInput | null,
};

export type DeleteDaslValuesMutation = {
  deleteDaslValues?:  {
    __typename: "DaslValues",
    id?: string | null,
    field_key?: string | null,
    field_values?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type CreateCsvDetailMutationVariables = {
  input: CreateCsvDetailInput,
  condition?: ModelCsvDetailConditionInput | null,
};

export type CreateCsvDetailMutation = {
  createCsvDetail?:  {
    __typename: "CsvDetail",
    id: string,
    school_id?: string | null,
    created_by?: string | null,
    csv_name?: string | null,
    unique_field?: string | null,
    is_obtained?: boolean | null,
    is_cancelled?: boolean | null,
    status?: STATUS | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCsvDetailMutationVariables = {
  input: UpdateCsvDetailInput,
  condition?: ModelCsvDetailConditionInput | null,
};

export type UpdateCsvDetailMutation = {
  updateCsvDetail?:  {
    __typename: "CsvDetail",
    id: string,
    school_id?: string | null,
    created_by?: string | null,
    csv_name?: string | null,
    unique_field?: string | null,
    is_obtained?: boolean | null,
    is_cancelled?: boolean | null,
    status?: STATUS | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCsvDetailMutationVariables = {
  input: DeleteCsvDetailInput,
  condition?: ModelCsvDetailConditionInput | null,
};

export type DeleteCsvDetailMutation = {
  deleteCsvDetail?:  {
    __typename: "CsvDetail",
    id: string,
    school_id?: string | null,
    created_by?: string | null,
    csv_name?: string | null,
    unique_field?: string | null,
    is_obtained?: boolean | null,
    is_cancelled?: boolean | null,
    status?: STATUS | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreatePreviewDetailMutationVariables = {
  input: CreatePreviewDetailInput,
  condition?: ModelPreviewDetailConditionInput | null,
};

export type CreatePreviewDetailMutation = {
  createPreviewDetail?:  {
    __typename: "PreviewDetail",
    id: string,
    school_id?: string | null,
    created_by?: string | null,
    data_set?: string | null,
    show_fields?: string | null,
    hidden_fields?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdatePreviewDetailMutationVariables = {
  input: UpdatePreviewDetailInput,
  condition?: ModelPreviewDetailConditionInput | null,
};

export type UpdatePreviewDetailMutation = {
  updatePreviewDetail?:  {
    __typename: "PreviewDetail",
    id: string,
    school_id?: string | null,
    created_by?: string | null,
    data_set?: string | null,
    show_fields?: string | null,
    hidden_fields?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeletePreviewDetailMutationVariables = {
  input: DeletePreviewDetailInput,
  condition?: ModelPreviewDetailConditionInput | null,
};

export type DeletePreviewDetailMutation = {
  deletePreviewDetail?:  {
    __typename: "PreviewDetail",
    id: string,
    school_id?: string | null,
    created_by?: string | null,
    data_set?: string | null,
    show_fields?: string | null,
    hidden_fields?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateQsdashboardMapMutationVariables = {
  input: CreateQsdashboardMapInput,
  condition?: ModelQsdashboardMapConditionInput | null,
};

export type CreateQsdashboardMapMutation = {
  createQsdashboardMap?:  {
    __typename: "QsdashboardMap",
    id: string,
    school_id?: string | null,
    dashboard_id?: string | null,
    dashboard_name?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateQsdashboardMapMutationVariables = {
  input: UpdateQsdashboardMapInput,
  condition?: ModelQsdashboardMapConditionInput | null,
};

export type UpdateQsdashboardMapMutation = {
  updateQsdashboardMap?:  {
    __typename: "QsdashboardMap",
    id: string,
    school_id?: string | null,
    dashboard_id?: string | null,
    dashboard_name?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteQsdashboardMapMutationVariables = {
  input: DeleteQsdashboardMapInput,
  condition?: ModelQsdashboardMapConditionInput | null,
};

export type DeleteQsdashboardMapMutation = {
  deleteQsdashboardMap?:  {
    __typename: "QsdashboardMap",
    id: string,
    school_id?: string | null,
    dashboard_id?: string | null,
    dashboard_name?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateQsdashboardDetailMutationVariables = {
  input: CreateQsdashboardDetailInput,
  condition?: ModelQsdashboardDetailConditionInput | null,
};

export type CreateQsdashboardDetailMutation = {
  createQsdashboardDetail?:  {
    __typename: "QsdashboardDetail",
    id: string,
    school_id?: string | null,
    dashboard_id?: string | null,
    dashboard_name?: string | null,
    dashboard_name_public?: string | null,
    dashboard_description?: string | null,
    dashboard_type?: QS_DASHBOARD_TYPE | null,
    topic_id?: string | null,
    author?: string | null,
    tags?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    isFavoriteOf?:  {
      __typename: "ModelFavoriteConnection",
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateQsdashboardDetailMutationVariables = {
  input: UpdateQsdashboardDetailInput,
  condition?: ModelQsdashboardDetailConditionInput | null,
};

export type UpdateQsdashboardDetailMutation = {
  updateQsdashboardDetail?:  {
    __typename: "QsdashboardDetail",
    id: string,
    school_id?: string | null,
    dashboard_id?: string | null,
    dashboard_name?: string | null,
    dashboard_name_public?: string | null,
    dashboard_description?: string | null,
    dashboard_type?: QS_DASHBOARD_TYPE | null,
    topic_id?: string | null,
    author?: string | null,
    tags?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    isFavoriteOf?:  {
      __typename: "ModelFavoriteConnection",
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteQsdashboardDetailMutationVariables = {
  input: DeleteQsdashboardDetailInput,
  condition?: ModelQsdashboardDetailConditionInput | null,
};

export type DeleteQsdashboardDetailMutation = {
  deleteQsdashboardDetail?:  {
    __typename: "QsdashboardDetail",
    id: string,
    school_id?: string | null,
    dashboard_id?: string | null,
    dashboard_name?: string | null,
    dashboard_name_public?: string | null,
    dashboard_description?: string | null,
    dashboard_type?: QS_DASHBOARD_TYPE | null,
    topic_id?: string | null,
    author?: string | null,
    tags?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    isFavoriteOf?:  {
      __typename: "ModelFavoriteConnection",
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateFavoriteMutationVariables = {
  input: CreateFavoriteInput,
  condition?: ModelFavoriteConditionInput | null,
};

export type CreateFavoriteMutation = {
  createFavorite?:  {
    __typename: "Favorite",
    id: string,
    userId?: string | null,
    resourceType?: string | null,
    dashboard_oid?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateFavoriteMutationVariables = {
  input: UpdateFavoriteInput,
  condition?: ModelFavoriteConditionInput | null,
};

export type UpdateFavoriteMutation = {
  updateFavorite?:  {
    __typename: "Favorite",
    id: string,
    userId?: string | null,
    resourceType?: string | null,
    dashboard_oid?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteFavoriteMutationVariables = {
  input: DeleteFavoriteInput,
  condition?: ModelFavoriteConditionInput | null,
};

export type DeleteFavoriteMutation = {
  deleteFavorite?:  {
    __typename: "Favorite",
    id: string,
    userId?: string | null,
    resourceType?: string | null,
    dashboard_oid?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetDataSetupQueryVariables = {
  id: string,
};

export type GetDataSetupQuery = {
  getDataSetup?:  {
    __typename: "dataSetup",
    id?: string | null,
    school_id?: string | null,
    dimension_or_metrics?: DMTYPE | null,
    field_key?: string | null,
    friendly_name?: string | null,
    field_description?: string | null,
    field_type_GDS?: FIELD_TYPE_GDS | null,
    field_type_PBI?: FIELD_TYPE_PBI | null,
    data_source?: Array< DATASOURCE | null > | null,
    data_set?: string | null,
    api_endpoint?: string | null,
    name_by_school?: string | null,
    worksheet_name?: string | null,
    googlesheet_name?: string | null,
    worksheet_id?: string | null,
    googlesheet_id?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListDataSetupsQueryVariables = {
  filter?: ModeldataSetupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDataSetupsQuery = {
  listDataSetups?:  {
    __typename: "ModeldataSetupConnection",
    items:  Array< {
      __typename: "dataSetup",
      id?: string | null,
      school_id?: string | null,
      dimension_or_metrics?: DMTYPE | null,
      field_key?: string | null,
      friendly_name?: string | null,
      field_description?: string | null,
      field_type_GDS?: FIELD_TYPE_GDS | null,
      field_type_PBI?: FIELD_TYPE_PBI | null,
      data_source?: Array< DATASOURCE | null > | null,
      data_set?: string | null,
      api_endpoint?: string | null,
      name_by_school?: string | null,
      worksheet_name?: string | null,
      googlesheet_name?: string | null,
      worksheet_id?: string | null,
      googlesheet_id?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type DataSetupByWorksheetIdQueryVariables = {
  worksheet_id?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModeldataSetupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DataSetupByWorksheetIdQuery = {
  DataSetupByWorksheetId?:  {
    __typename: "ModeldataSetupConnection",
    items:  Array< {
      __typename: "dataSetup",
      id?: string | null,
      school_id?: string | null,
      dimension_or_metrics?: DMTYPE | null,
      field_key?: string | null,
      friendly_name?: string | null,
      field_description?: string | null,
      field_type_GDS?: FIELD_TYPE_GDS | null,
      field_type_PBI?: FIELD_TYPE_PBI | null,
      data_source?: Array< DATASOURCE | null > | null,
      data_set?: string | null,
      api_endpoint?: string | null,
      name_by_school?: string | null,
      worksheet_name?: string | null,
      googlesheet_name?: string | null,
      worksheet_id?: string | null,
      googlesheet_id?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAccountQueryVariables = {
  id: string,
};

export type GetAccountQuery = {
  getAccount?:  {
    __typename: "Account",
    id: string,
    company?: string | null,
    company_loweredcased?: string | null,
    company_description?: string | null,
    url?: string | null,
    disabled?: boolean | null,
    piiInformation?: boolean | null,
    isSchoolAdmin?: boolean | null,
    email?: string | null,
    users?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    data_setup?:  {
      __typename: "ModeldataSetupConnection",
      nextToken?: string | null,
    } | null,
    accountStatus?: ACCT_STATUS | null,
    stripeCustomerID?: string | null,
    stripeSubscriptionID?: string | null,
    stripePriceID?: string | null,
    planID?: string | null,
    metadata?: string | null,
    owners?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    viewers?: Array< string | null > | null,
    datasource?: Array< DATASOURCE | null > | null,
    dataprovider?: Array< DATAPROVIDER | null > | null,
    advancedList?:  {
      __typename: "ModelAdvancedListDetailConnection",
      nextToken?: string | null,
    } | null,
    request_googlesheet?:  {
      __typename: "ModelGoogleSheetDetailConnection",
      nextToken?: string | null,
    } | null,
    recurringSync?:  {
      __typename: "ModelRecurringSyncConnection",
      nextToken?: string | null,
    } | null,
    apihistory?:  {
      __typename: "ModelCountOfAPIHistoryConnection",
      nextToken?: string | null,
    } | null,
    dataSet?:  {
      __typename: "ModelDataSetDetailConnection",
      nextToken?: string | null,
    } | null,
    requests?:  {
      __typename: "ModelAccountRequestConnection",
      nextToken?: string | null,
    } | null,
    plan?:  {
      __typename: "Plan",
      id: string,
      name: string,
      planDetails?: string | null,
      Targets?: string | null,
      bulletPoints?: Array< string | null > | null,
      listed?: boolean | null,
      order?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type ListAccountsQueryVariables = {
  filter?: ModelAccountFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAccountsQuery = {
  listAccounts?:  {
    __typename: "ModelAccountConnection",
    items:  Array< {
      __typename: "Account",
      id: string,
      company?: string | null,
      company_loweredcased?: string | null,
      company_description?: string | null,
      url?: string | null,
      disabled?: boolean | null,
      piiInformation?: boolean | null,
      isSchoolAdmin?: boolean | null,
      email?: string | null,
      users?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      accountStatus?: ACCT_STATUS | null,
      stripeCustomerID?: string | null,
      stripeSubscriptionID?: string | null,
      stripePriceID?: string | null,
      planID?: string | null,
      metadata?: string | null,
      owners?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      viewers?: Array< string | null > | null,
      datasource?: Array< DATASOURCE | null > | null,
      dataprovider?: Array< DATAPROVIDER | null > | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAdvancedListDetailQueryVariables = {
  id: string,
};

export type GetAdvancedListDetailQuery = {
  getAdvancedListDetail?:  {
    __typename: "AdvancedListDetail",
    id: string,
    school_id?: string | null,
    list_name?: string | null,
    list_id?: string | null,
    datasource?: string | null,
    dataset?: string | null,
    status?: STATUS | null,
    preconfigure?: boolean | null,
    last_sync_date?: string | null,
    interval_type?: INTERVAL_TYPE | null,
    recurring_sync_status?: boolean | null,
    custom_interval?: number | null,
    custom_type?: CUSTOM_TYPE | null,
    custom_value?: number | null,
    is_initial_sync?: STATUS | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListAdvancedListDetailsQueryVariables = {
  filter?: ModelAdvancedListDetailFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAdvancedListDetailsQuery = {
  listAdvancedListDetails?:  {
    __typename: "ModelAdvancedListDetailConnection",
    items:  Array< {
      __typename: "AdvancedListDetail",
      id: string,
      school_id?: string | null,
      list_name?: string | null,
      list_id?: string | null,
      datasource?: string | null,
      dataset?: string | null,
      status?: STATUS | null,
      preconfigure?: boolean | null,
      last_sync_date?: string | null,
      interval_type?: INTERVAL_TYPE | null,
      recurring_sync_status?: boolean | null,
      custom_interval?: number | null,
      custom_type?: CUSTOM_TYPE | null,
      custom_value?: number | null,
      is_initial_sync?: STATUS | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDataPackageDetailQueryVariables = {
  id: string,
};

export type GetDataPackageDetailQuery = {
  getDataPackageDetail?:  {
    __typename: "DataPackageDetail",
    id: string,
    school_id?: string | null,
    package_name?: string | null,
    datasource?: string | null,
    dataset?: string | null,
    status?: STATUS | null,
    last_sync_date?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListDataPackageDetailsQueryVariables = {
  filter?: ModelDataPackageDetailFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDataPackageDetailsQuery = {
  listDataPackageDetails?:  {
    __typename: "ModelDataPackageDetailConnection",
    items:  Array< {
      __typename: "DataPackageDetail",
      id: string,
      school_id?: string | null,
      package_name?: string | null,
      datasource?: string | null,
      dataset?: string | null,
      status?: STATUS | null,
      last_sync_date?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetGoogleSheetDetailQueryVariables = {
  id: string,
};

export type GetGoogleSheetDetailQuery = {
  getGoogleSheetDetail?:  {
    __typename: "GoogleSheetDetail",
    id: string,
    school_id?: string | null,
    created_by?: string | null,
    googlesheet_link: string,
    googlesheet_name?: string | null,
    googlesheet_id?: string | null,
    worksheet_name?: string | null,
    worksheet_id?: string | null,
    unique_field?: string | null,
    is_obtained?: boolean | null,
    interval_type?: INTERVAL_TYPE | null,
    last_success_sync_date?: string | null,
    last_failure_sync_date?: string | null,
    custom_interval?: number | null,
    is_cancelled?: boolean | null,
    custom_type?: CUSTOM_TYPE | null,
    custom_value?: number | null,
    table_name?: string | null,
    refresh_interval_status?: REFRESH_INTERVAL_STATUS | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListGoogleSheetDetailsQueryVariables = {
  filter?: ModelGoogleSheetDetailFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListGoogleSheetDetailsQuery = {
  listGoogleSheetDetails?:  {
    __typename: "ModelGoogleSheetDetailConnection",
    items:  Array< {
      __typename: "GoogleSheetDetail",
      id: string,
      school_id?: string | null,
      created_by?: string | null,
      googlesheet_link: string,
      googlesheet_name?: string | null,
      googlesheet_id?: string | null,
      worksheet_name?: string | null,
      worksheet_id?: string | null,
      unique_field?: string | null,
      is_obtained?: boolean | null,
      interval_type?: INTERVAL_TYPE | null,
      last_success_sync_date?: string | null,
      last_failure_sync_date?: string | null,
      custom_interval?: number | null,
      is_cancelled?: boolean | null,
      custom_type?: CUSTOM_TYPE | null,
      custom_value?: number | null,
      table_name?: string | null,
      refresh_interval_status?: REFRESH_INTERVAL_STATUS | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetRecurringSyncQueryVariables = {
  id: string,
};

export type GetRecurringSyncQuery = {
  getRecurringSync?:  {
    __typename: "RecurringSync",
    id: string,
    school_id?: string | null,
    success?: boolean | null,
    worksheet_id?: string | null,
    worksheet_name?: string | null,
    interval_type?: string | null,
    sync_for?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type ListRecurringSyncsQueryVariables = {
  filter?: ModelRecurringSyncFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRecurringSyncsQuery = {
  listRecurringSyncs?:  {
    __typename: "ModelRecurringSyncConnection",
    items:  Array< {
      __typename: "RecurringSync",
      id: string,
      school_id?: string | null,
      success?: boolean | null,
      worksheet_id?: string | null,
      worksheet_name?: string | null,
      interval_type?: string | null,
      sync_for?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCountOfAPIHistoryQueryVariables = {
  id: string,
};

export type GetCountOfAPIHistoryQuery = {
  getCountOfAPIHistory?:  {
    __typename: "CountOfAPIHistory",
    id: string,
    school_id?: string | null,
    api_key?: string | null,
    dataset_type?: string | null,
    count?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type ListCountOfAPIHistorysQueryVariables = {
  filter?: ModelCountOfAPIHistoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCountOfAPIHistorysQuery = {
  listCountOfAPIHistorys?:  {
    __typename: "ModelCountOfAPIHistoryConnection",
    items:  Array< {
      __typename: "CountOfAPIHistory",
      id: string,
      school_id?: string | null,
      api_key?: string | null,
      dataset_type?: string | null,
      count?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDataSetDetailQueryVariables = {
  id: string,
};

export type GetDataSetDetailQuery = {
  getDataSetDetail?:  {
    __typename: "DataSetDetail",
    id: string,
    school_id?: string | null,
    data_set?: string | null,
    data_source?: DATASOURCE | null,
    purchased_date?: string | null,
    status?: STATUS | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListDataSetDetailsQueryVariables = {
  filter?: ModelDataSetDetailFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDataSetDetailsQuery = {
  listDataSetDetails?:  {
    __typename: "ModelDataSetDetailConnection",
    items:  Array< {
      __typename: "DataSetDetail",
      id: string,
      school_id?: string | null,
      data_set?: string | null,
      data_source?: DATASOURCE | null,
      purchased_date?: string | null,
      status?: STATUS | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetVeracrossDetailQueryVariables = {
  id: string,
};

export type GetVeracrossDetailQuery = {
  getVeracrossDetail?:  {
    __typename: "VeracrossDetail",
    id: string,
    school_id?: string | null,
    veracross_client_id?: string | null,
    veracross_client_secret?: string | null,
    scope?: string | null,
    school_route?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListVeracrossDetailsQueryVariables = {
  filter?: ModelVeracrossDetailFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListVeracrossDetailsQuery = {
  listVeracrossDetails?:  {
    __typename: "ModelVeracrossDetailConnection",
    items:  Array< {
      __typename: "VeracrossDetail",
      id: string,
      school_id?: string | null,
      veracross_client_id?: string | null,
      veracross_client_secret?: string | null,
      scope?: string | null,
      school_route?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAccountRequestQueryVariables = {
  id: string,
};

export type GetAccountRequestQuery = {
  getAccountRequest?:  {
    __typename: "AccountRequest",
    id: string,
    account: string,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListAccountRequestsQueryVariables = {
  filter?: ModelAccountRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAccountRequestsQuery = {
  listAccountRequests?:  {
    __typename: "ModelAccountRequestConnection",
    items:  Array< {
      __typename: "AccountRequest",
      id: string,
      account: string,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPlanQueryVariables = {
  id: string,
};

export type GetPlanQuery = {
  getPlan?:  {
    __typename: "Plan",
    id: string,
    name: string,
    planDetails?: string | null,
    Targets?: string | null,
    bulletPoints?: Array< string | null > | null,
    listed?: boolean | null,
    order?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListPlansQueryVariables = {
  filter?: ModelPlanFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPlansQuery = {
  listPlans?:  {
    __typename: "ModelPlanConnection",
    items:  Array< {
      __typename: "Plan",
      id: string,
      name: string,
      planDetails?: string | null,
      Targets?: string | null,
      bulletPoints?: Array< string | null > | null,
      listed?: boolean | null,
      order?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetWebProfilesQueryVariables = {
  id: string,
};

export type GetWebProfilesQuery = {
  getWebProfiles?:  {
    __typename: "WebProfiles",
    id: string,
    account: string,
    platform: SUPPORTED_PLATFORM,
    profile_id?: string | null,
    metadata?: string | null,
    enabled?: boolean | null,
    last_sync_date?: string | null,
    disconnect?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListWebProfilessQueryVariables = {
  filter?: ModelWebProfilesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListWebProfilessQuery = {
  listWebProfiless?:  {
    __typename: "ModelWebProfilesConnection",
    items:  Array< {
      __typename: "WebProfiles",
      id: string,
      account: string,
      platform: SUPPORTED_PLATFORM,
      profile_id?: string | null,
      metadata?: string | null,
      enabled?: boolean | null,
      last_sync_date?: string | null,
      disconnect?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOAuthCredsQueryVariables = {
  id: string,
};

export type GetOAuthCredsQuery = {
  getOAuthCreds?:  {
    __typename: "OAuthCreds",
    id: string,
    account: string,
    platform: SUPPORTED_PLATFORM,
    refresh_token?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListOAuthCredssQueryVariables = {
  filter?: ModelOAuthCredsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOAuthCredssQuery = {
  listOAuthCredss?:  {
    __typename: "ModelOAuthCredsConnection",
    items:  Array< {
      __typename: "OAuthCreds",
      id: string,
      account: string,
      platform: SUPPORTED_PLATFORM,
      refresh_token?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetIntegrationSyncHistoryQueryVariables = {
  id: string,
};

export type GetIntegrationSyncHistoryQuery = {
  getIntegrationSyncHistory?:  {
    __typename: "IntegrationSyncHistory",
    id: string,
    account: string,
    platform: SUPPORTED_PLATFORM,
    profile_id: string,
    source: SYNC_SOURCE,
    status: SYNC_STATUS,
    createdAt: string,
    detail?: string | null,
    updatedAt: string,
  } | null,
};

export type ListIntegrationSyncHistorysQueryVariables = {
  filter?: ModelIntegrationSyncHistoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListIntegrationSyncHistorysQuery = {
  listIntegrationSyncHistorys?:  {
    __typename: "ModelIntegrationSyncHistoryConnection",
    items:  Array< {
      __typename: "IntegrationSyncHistory",
      id: string,
      account: string,
      platform: SUPPORTED_PLATFORM,
      profile_id: string,
      source: SYNC_SOURCE,
      status: SYNC_STATUS,
      createdAt: string,
      detail?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetIntegrationSyncHistoryByDateQueryVariables = {
  platform?: SUPPORTED_PLATFORM | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelIntegrationSyncHistoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetIntegrationSyncHistoryByDateQuery = {
  getIntegrationSyncHistoryByDate?:  {
    __typename: "ModelIntegrationSyncHistoryConnection",
    items:  Array< {
      __typename: "IntegrationSyncHistory",
      id: string,
      account: string,
      platform: SUPPORTED_PLATFORM,
      profile_id: string,
      source: SYNC_SOURCE,
      status: SYNC_STATUS,
      createdAt: string,
      detail?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetApiKeyQueryVariables = {
  id: string,
};

export type GetApiKeyQuery = {
  getApiKey?:  {
    __typename: "ApiKey",
    id: string,
    account: string,
    key: string,
    keyid: string,
    disabled: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListApiKeysQueryVariables = {
  filter?: ModelApiKeyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListApiKeysQuery = {
  listApiKeys?:  {
    __typename: "ModelApiKeyConnection",
    items:  Array< {
      __typename: "ApiKey",
      id: string,
      account: string,
      key: string,
      keyid: string,
      disabled: boolean,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetFAQQueryVariables = {
  id: string,
};

export type GetFAQQuery = {
  getFAQ?:  {
    __typename: "FAQ",
    id: string,
    question: string,
    answer: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListFAQsQueryVariables = {
  filter?: ModelFAQFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFAQsQuery = {
  listFAQs?:  {
    __typename: "ModelFAQConnection",
    items:  Array< {
      __typename: "FAQ",
      id: string,
      question: string,
      answer: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetFirebaseTokensQueryVariables = {
  id: string,
};

export type GetFirebaseTokensQuery = {
  getFirebaseTokens?:  {
    __typename: "FirebaseTokens",
    id: string,
    token?: string | null,
    user?: string | null,
    userDetails?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListFirebaseTokenssQueryVariables = {
  filter?: ModelFirebaseTokensFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFirebaseTokenssQuery = {
  listFirebaseTokenss?:  {
    __typename: "ModelFirebaseTokensConnection",
    items:  Array< {
      __typename: "FirebaseTokens",
      id: string,
      token?: string | null,
      user?: string | null,
      userDetails?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDatasourceQueryVariables = {
  id: string,
};

export type GetDatasourceQuery = {
  getDatasource?:  {
    __typename: "datasource",
    id: string,
    data_provider: DATAPROVIDER,
    data_source_name?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListDatasourcesQueryVariables = {
  filter?: ModeldatasourceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDatasourcesQuery = {
  listDatasources?:  {
    __typename: "ModeldatasourceConnection",
    items:  Array< {
      __typename: "datasource",
      id: string,
      data_provider: DATAPROVIDER,
      data_source_name?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetNotificationTypeQueryVariables = {
  id: string,
};

export type GetNotificationTypeQuery = {
  getNotificationType?:  {
    __typename: "NotificationType",
    id: string,
    notification_type: NOTIFICATION_TYPE,
    code?: string | null,
    title?: string | null,
    notification_text?: string | null,
    content?: string | null,
    metadata?: string | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type ListNotificationTypesQueryVariables = {
  filter?: ModelNotificationTypeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNotificationTypesQuery = {
  listNotificationTypes?:  {
    __typename: "ModelNotificationTypeConnection",
    items:  Array< {
      __typename: "NotificationType",
      id: string,
      notification_type: NOTIFICATION_TYPE,
      code?: string | null,
      title?: string | null,
      notification_text?: string | null,
      content?: string | null,
      metadata?: string | null,
      createdAt?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserNotificationPreferenceQueryVariables = {
  id: string,
};

export type GetUserNotificationPreferenceQuery = {
  getUserNotificationPreference?:  {
    __typename: "UserNotificationPreference",
    id: string,
    user?: string | null,
    userDetails?: string | null,
    notificationType?: string | null,
    emailNotification?: boolean | null,
    inAppNotification?: boolean | null,
    webPushNotification?: boolean | null,
    createdAt?: string | null,
    updatedAt: string,
    notificationTypeDetails?:  {
      __typename: "NotificationType",
      id: string,
      notification_type: NOTIFICATION_TYPE,
      code?: string | null,
      title?: string | null,
      notification_text?: string | null,
      content?: string | null,
      metadata?: string | null,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
  } | null,
};

export type ListUserNotificationPreferencesQueryVariables = {
  filter?: ModelUserNotificationPreferenceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserNotificationPreferencesQuery = {
  listUserNotificationPreferences?:  {
    __typename: "ModelUserNotificationPreferenceConnection",
    items:  Array< {
      __typename: "UserNotificationPreference",
      id: string,
      user?: string | null,
      userDetails?: string | null,
      notificationType?: string | null,
      emailNotification?: boolean | null,
      inAppNotification?: boolean | null,
      webPushNotification?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type NotificationTypeByUserQueryVariables = {
  user?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserNotificationPreferenceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type NotificationTypeByUserQuery = {
  NotificationTypeByUser?:  {
    __typename: "ModelUserNotificationPreferenceConnection",
    items:  Array< {
      __typename: "UserNotificationPreference",
      id: string,
      user?: string | null,
      userDetails?: string | null,
      notificationType?: string | null,
      emailNotification?: boolean | null,
      inAppNotification?: boolean | null,
      webPushNotification?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserNotificationQueryVariables = {
  id: string,
};

export type GetUserNotificationQuery = {
  getUserNotification?:  {
    __typename: "UserNotification",
    id: string,
    user?: string | null,
    userDetails?: string | null,
    userNotificationPreference?: string | null,
    title?: string | null,
    content?: string | null,
    message?: string | null,
    code?: string | null,
    metadata?: string | null,
    isRead?: boolean | null,
    emailSent?: boolean | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type ListUserNotificationsQueryVariables = {
  filter?: ModelUserNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserNotificationsQuery = {
  listUserNotifications?:  {
    __typename: "ModelUserNotificationConnection",
    items:  Array< {
      __typename: "UserNotification",
      id: string,
      user?: string | null,
      userDetails?: string | null,
      userNotificationPreference?: string | null,
      title?: string | null,
      content?: string | null,
      message?: string | null,
      code?: string | null,
      metadata?: string | null,
      isRead?: boolean | null,
      emailSent?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetInitialSyncDetailsQueryVariables = {
  id: string,
};

export type GetInitialSyncDetailsQuery = {
  getInitialSyncDetails?:  {
    __typename: "InitialSyncDetails",
    id: string,
    school_id?: string | null,
    data_provider?: DATAPROVIDER | null,
    data_source?: DATASOURCE | null,
    last_sync_date?: string | null,
    initial_sync_status?: STATUS | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListInitialSyncDetailssQueryVariables = {
  filter?: ModelInitialSyncDetailsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListInitialSyncDetailssQuery = {
  listInitialSyncDetailss?:  {
    __typename: "ModelInitialSyncDetailsConnection",
    items:  Array< {
      __typename: "InitialSyncDetails",
      id: string,
      school_id?: string | null,
      data_provider?: DATAPROVIDER | null,
      data_source?: DATASOURCE | null,
      last_sync_date?: string | null,
      initial_sync_status?: STATUS | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetRulesQueryVariables = {
  id: string,
};

export type GetRulesQuery = {
  getRules?:  {
    __typename: "Rules",
    id?: string | null,
    school_id?: string | null,
    field_key?: string | null,
    data_source?: DATASOURCE | null,
    data_set?: string | null,
    rule_values?: string | null,
    created_by?: string | null,
    updated_by?: string | null,
    rule_type?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListRulessQueryVariables = {
  filter?: ModelRulesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRulessQuery = {
  listRuless?:  {
    __typename: "ModelRulesConnection",
    items:  Array< {
      __typename: "Rules",
      id?: string | null,
      school_id?: string | null,
      field_key?: string | null,
      data_source?: DATASOURCE | null,
      data_set?: string | null,
      rule_values?: string | null,
      created_by?: string | null,
      updated_by?: string | null,
      rule_type?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDaslValuesQueryVariables = {
  id: string,
};

export type GetDaslValuesQuery = {
  getDaslValues?:  {
    __typename: "DaslValues",
    id?: string | null,
    field_key?: string | null,
    field_values?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type ListDaslValuessQueryVariables = {
  filter?: ModelDaslValuesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDaslValuessQuery = {
  listDaslValuess?:  {
    __typename: "ModelDaslValuesConnection",
    items:  Array< {
      __typename: "DaslValues",
      id?: string | null,
      field_key?: string | null,
      field_values?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCsvDetailQueryVariables = {
  id: string,
};

export type GetCsvDetailQuery = {
  getCsvDetail?:  {
    __typename: "CsvDetail",
    id: string,
    school_id?: string | null,
    created_by?: string | null,
    csv_name?: string | null,
    unique_field?: string | null,
    is_obtained?: boolean | null,
    is_cancelled?: boolean | null,
    status?: STATUS | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCsvDetailsQueryVariables = {
  filter?: ModelCsvDetailFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCsvDetailsQuery = {
  listCsvDetails?:  {
    __typename: "ModelCsvDetailConnection",
    items:  Array< {
      __typename: "CsvDetail",
      id: string,
      school_id?: string | null,
      created_by?: string | null,
      csv_name?: string | null,
      unique_field?: string | null,
      is_obtained?: boolean | null,
      is_cancelled?: boolean | null,
      status?: STATUS | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPreviewDetailQueryVariables = {
  id: string,
};

export type GetPreviewDetailQuery = {
  getPreviewDetail?:  {
    __typename: "PreviewDetail",
    id: string,
    school_id?: string | null,
    created_by?: string | null,
    data_set?: string | null,
    show_fields?: string | null,
    hidden_fields?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListPreviewDetailsQueryVariables = {
  filter?: ModelPreviewDetailFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPreviewDetailsQuery = {
  listPreviewDetails?:  {
    __typename: "ModelPreviewDetailConnection",
    items:  Array< {
      __typename: "PreviewDetail",
      id: string,
      school_id?: string | null,
      created_by?: string | null,
      data_set?: string | null,
      show_fields?: string | null,
      hidden_fields?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetQsdashboardMapQueryVariables = {
  id: string,
};

export type GetQsdashboardMapQuery = {
  getQsdashboardMap?:  {
    __typename: "QsdashboardMap",
    id: string,
    school_id?: string | null,
    dashboard_id?: string | null,
    dashboard_name?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListQsdashboardMapsQueryVariables = {
  filter?: ModelQsdashboardMapFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListQsdashboardMapsQuery = {
  listQsdashboardMaps?:  {
    __typename: "ModelQsdashboardMapConnection",
    items:  Array< {
      __typename: "QsdashboardMap",
      id: string,
      school_id?: string | null,
      dashboard_id?: string | null,
      dashboard_name?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetQsdashboardDetailQueryVariables = {
  id: string,
};

export type GetQsdashboardDetailQuery = {
  getQsdashboardDetail?:  {
    __typename: "QsdashboardDetail",
    id: string,
    school_id?: string | null,
    dashboard_id?: string | null,
    dashboard_name?: string | null,
    dashboard_name_public?: string | null,
    dashboard_description?: string | null,
    dashboard_type?: QS_DASHBOARD_TYPE | null,
    topic_id?: string | null,
    author?: string | null,
    tags?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    isFavoriteOf?:  {
      __typename: "ModelFavoriteConnection",
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListQsdashboardDetailsQueryVariables = {
  filter?: ModelQsdashboardDetailFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListQsdashboardDetailsQuery = {
  listQsdashboardDetails?:  {
    __typename: "ModelQsdashboardDetailConnection",
    items:  Array< {
      __typename: "QsdashboardDetail",
      id: string,
      school_id?: string | null,
      dashboard_id?: string | null,
      dashboard_name?: string | null,
      dashboard_name_public?: string | null,
      dashboard_description?: string | null,
      dashboard_type?: QS_DASHBOARD_TYPE | null,
      topic_id?: string | null,
      author?: string | null,
      tags?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetFavoriteQueryVariables = {
  id: string,
};

export type GetFavoriteQuery = {
  getFavorite?:  {
    __typename: "Favorite",
    id: string,
    userId?: string | null,
    resourceType?: string | null,
    dashboard_oid?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListFavoritesQueryVariables = {
  filter?: ModelFavoriteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFavoritesQuery = {
  listFavorites?:  {
    __typename: "ModelFavoriteConnection",
    items:  Array< {
      __typename: "Favorite",
      id: string,
      userId?: string | null,
      resourceType?: string | null,
      dashboard_oid?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateAccountSubscriptionVariables = {
};

export type OnCreateAccountSubscription = {
  onCreateAccount?:  {
    __typename: "Account",
    id: string,
    company?: string | null,
    company_loweredcased?: string | null,
    company_description?: string | null,
    url?: string | null,
    disabled?: boolean | null,
    piiInformation?: boolean | null,
    isSchoolAdmin?: boolean | null,
    email?: string | null,
    users?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    data_setup?:  {
      __typename: "ModeldataSetupConnection",
      nextToken?: string | null,
    } | null,
    accountStatus?: ACCT_STATUS | null,
    stripeCustomerID?: string | null,
    stripeSubscriptionID?: string | null,
    stripePriceID?: string | null,
    planID?: string | null,
    metadata?: string | null,
    owners?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    viewers?: Array< string | null > | null,
    datasource?: Array< DATASOURCE | null > | null,
    dataprovider?: Array< DATAPROVIDER | null > | null,
    advancedList?:  {
      __typename: "ModelAdvancedListDetailConnection",
      nextToken?: string | null,
    } | null,
    request_googlesheet?:  {
      __typename: "ModelGoogleSheetDetailConnection",
      nextToken?: string | null,
    } | null,
    recurringSync?:  {
      __typename: "ModelRecurringSyncConnection",
      nextToken?: string | null,
    } | null,
    apihistory?:  {
      __typename: "ModelCountOfAPIHistoryConnection",
      nextToken?: string | null,
    } | null,
    dataSet?:  {
      __typename: "ModelDataSetDetailConnection",
      nextToken?: string | null,
    } | null,
    requests?:  {
      __typename: "ModelAccountRequestConnection",
      nextToken?: string | null,
    } | null,
    plan?:  {
      __typename: "Plan",
      id: string,
      name: string,
      planDetails?: string | null,
      Targets?: string | null,
      bulletPoints?: Array< string | null > | null,
      listed?: boolean | null,
      order?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnUpdateAccountSubscriptionVariables = {
};

export type OnUpdateAccountSubscription = {
  onUpdateAccount?:  {
    __typename: "Account",
    id: string,
    company?: string | null,
    company_loweredcased?: string | null,
    company_description?: string | null,
    url?: string | null,
    disabled?: boolean | null,
    piiInformation?: boolean | null,
    isSchoolAdmin?: boolean | null,
    email?: string | null,
    users?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    data_setup?:  {
      __typename: "ModeldataSetupConnection",
      nextToken?: string | null,
    } | null,
    accountStatus?: ACCT_STATUS | null,
    stripeCustomerID?: string | null,
    stripeSubscriptionID?: string | null,
    stripePriceID?: string | null,
    planID?: string | null,
    metadata?: string | null,
    owners?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    viewers?: Array< string | null > | null,
    datasource?: Array< DATASOURCE | null > | null,
    dataprovider?: Array< DATAPROVIDER | null > | null,
    advancedList?:  {
      __typename: "ModelAdvancedListDetailConnection",
      nextToken?: string | null,
    } | null,
    request_googlesheet?:  {
      __typename: "ModelGoogleSheetDetailConnection",
      nextToken?: string | null,
    } | null,
    recurringSync?:  {
      __typename: "ModelRecurringSyncConnection",
      nextToken?: string | null,
    } | null,
    apihistory?:  {
      __typename: "ModelCountOfAPIHistoryConnection",
      nextToken?: string | null,
    } | null,
    dataSet?:  {
      __typename: "ModelDataSetDetailConnection",
      nextToken?: string | null,
    } | null,
    requests?:  {
      __typename: "ModelAccountRequestConnection",
      nextToken?: string | null,
    } | null,
    plan?:  {
      __typename: "Plan",
      id: string,
      name: string,
      planDetails?: string | null,
      Targets?: string | null,
      bulletPoints?: Array< string | null > | null,
      listed?: boolean | null,
      order?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnDeleteAccountSubscriptionVariables = {
};

export type OnDeleteAccountSubscription = {
  onDeleteAccount?:  {
    __typename: "Account",
    id: string,
    company?: string | null,
    company_loweredcased?: string | null,
    company_description?: string | null,
    url?: string | null,
    disabled?: boolean | null,
    piiInformation?: boolean | null,
    isSchoolAdmin?: boolean | null,
    email?: string | null,
    users?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    data_setup?:  {
      __typename: "ModeldataSetupConnection",
      nextToken?: string | null,
    } | null,
    accountStatus?: ACCT_STATUS | null,
    stripeCustomerID?: string | null,
    stripeSubscriptionID?: string | null,
    stripePriceID?: string | null,
    planID?: string | null,
    metadata?: string | null,
    owners?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    viewers?: Array< string | null > | null,
    datasource?: Array< DATASOURCE | null > | null,
    dataprovider?: Array< DATAPROVIDER | null > | null,
    advancedList?:  {
      __typename: "ModelAdvancedListDetailConnection",
      nextToken?: string | null,
    } | null,
    request_googlesheet?:  {
      __typename: "ModelGoogleSheetDetailConnection",
      nextToken?: string | null,
    } | null,
    recurringSync?:  {
      __typename: "ModelRecurringSyncConnection",
      nextToken?: string | null,
    } | null,
    apihistory?:  {
      __typename: "ModelCountOfAPIHistoryConnection",
      nextToken?: string | null,
    } | null,
    dataSet?:  {
      __typename: "ModelDataSetDetailConnection",
      nextToken?: string | null,
    } | null,
    requests?:  {
      __typename: "ModelAccountRequestConnection",
      nextToken?: string | null,
    } | null,
    plan?:  {
      __typename: "Plan",
      id: string,
      name: string,
      planDetails?: string | null,
      Targets?: string | null,
      bulletPoints?: Array< string | null > | null,
      listed?: boolean | null,
      order?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnCreateAdvancedListDetailSubscriptionVariables = {
};

export type OnCreateAdvancedListDetailSubscription = {
  onCreateAdvancedListDetail?:  {
    __typename: "AdvancedListDetail",
    id: string,
    school_id?: string | null,
    list_name?: string | null,
    list_id?: string | null,
    datasource?: string | null,
    dataset?: string | null,
    status?: STATUS | null,
    preconfigure?: boolean | null,
    last_sync_date?: string | null,
    interval_type?: INTERVAL_TYPE | null,
    recurring_sync_status?: boolean | null,
    custom_interval?: number | null,
    custom_type?: CUSTOM_TYPE | null,
    custom_value?: number | null,
    is_initial_sync?: STATUS | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateAdvancedListDetailSubscriptionVariables = {
};

export type OnUpdateAdvancedListDetailSubscription = {
  onUpdateAdvancedListDetail?:  {
    __typename: "AdvancedListDetail",
    id: string,
    school_id?: string | null,
    list_name?: string | null,
    list_id?: string | null,
    datasource?: string | null,
    dataset?: string | null,
    status?: STATUS | null,
    preconfigure?: boolean | null,
    last_sync_date?: string | null,
    interval_type?: INTERVAL_TYPE | null,
    recurring_sync_status?: boolean | null,
    custom_interval?: number | null,
    custom_type?: CUSTOM_TYPE | null,
    custom_value?: number | null,
    is_initial_sync?: STATUS | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteAdvancedListDetailSubscriptionVariables = {
};

export type OnDeleteAdvancedListDetailSubscription = {
  onDeleteAdvancedListDetail?:  {
    __typename: "AdvancedListDetail",
    id: string,
    school_id?: string | null,
    list_name?: string | null,
    list_id?: string | null,
    datasource?: string | null,
    dataset?: string | null,
    status?: STATUS | null,
    preconfigure?: boolean | null,
    last_sync_date?: string | null,
    interval_type?: INTERVAL_TYPE | null,
    recurring_sync_status?: boolean | null,
    custom_interval?: number | null,
    custom_type?: CUSTOM_TYPE | null,
    custom_value?: number | null,
    is_initial_sync?: STATUS | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateDataPackageDetailSubscriptionVariables = {
};

export type OnCreateDataPackageDetailSubscription = {
  onCreateDataPackageDetail?:  {
    __typename: "DataPackageDetail",
    id: string,
    school_id?: string | null,
    package_name?: string | null,
    datasource?: string | null,
    dataset?: string | null,
    status?: STATUS | null,
    last_sync_date?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateDataPackageDetailSubscriptionVariables = {
};

export type OnUpdateDataPackageDetailSubscription = {
  onUpdateDataPackageDetail?:  {
    __typename: "DataPackageDetail",
    id: string,
    school_id?: string | null,
    package_name?: string | null,
    datasource?: string | null,
    dataset?: string | null,
    status?: STATUS | null,
    last_sync_date?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteDataPackageDetailSubscriptionVariables = {
};

export type OnDeleteDataPackageDetailSubscription = {
  onDeleteDataPackageDetail?:  {
    __typename: "DataPackageDetail",
    id: string,
    school_id?: string | null,
    package_name?: string | null,
    datasource?: string | null,
    dataset?: string | null,
    status?: STATUS | null,
    last_sync_date?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateGoogleSheetDetailSubscriptionVariables = {
};

export type OnCreateGoogleSheetDetailSubscription = {
  onCreateGoogleSheetDetail?:  {
    __typename: "GoogleSheetDetail",
    id: string,
    school_id?: string | null,
    created_by?: string | null,
    googlesheet_link: string,
    googlesheet_name?: string | null,
    googlesheet_id?: string | null,
    worksheet_name?: string | null,
    worksheet_id?: string | null,
    unique_field?: string | null,
    is_obtained?: boolean | null,
    interval_type?: INTERVAL_TYPE | null,
    last_success_sync_date?: string | null,
    last_failure_sync_date?: string | null,
    custom_interval?: number | null,
    is_cancelled?: boolean | null,
    custom_type?: CUSTOM_TYPE | null,
    custom_value?: number | null,
    table_name?: string | null,
    refresh_interval_status?: REFRESH_INTERVAL_STATUS | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateGoogleSheetDetailSubscriptionVariables = {
};

export type OnUpdateGoogleSheetDetailSubscription = {
  onUpdateGoogleSheetDetail?:  {
    __typename: "GoogleSheetDetail",
    id: string,
    school_id?: string | null,
    created_by?: string | null,
    googlesheet_link: string,
    googlesheet_name?: string | null,
    googlesheet_id?: string | null,
    worksheet_name?: string | null,
    worksheet_id?: string | null,
    unique_field?: string | null,
    is_obtained?: boolean | null,
    interval_type?: INTERVAL_TYPE | null,
    last_success_sync_date?: string | null,
    last_failure_sync_date?: string | null,
    custom_interval?: number | null,
    is_cancelled?: boolean | null,
    custom_type?: CUSTOM_TYPE | null,
    custom_value?: number | null,
    table_name?: string | null,
    refresh_interval_status?: REFRESH_INTERVAL_STATUS | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteGoogleSheetDetailSubscriptionVariables = {
};

export type OnDeleteGoogleSheetDetailSubscription = {
  onDeleteGoogleSheetDetail?:  {
    __typename: "GoogleSheetDetail",
    id: string,
    school_id?: string | null,
    created_by?: string | null,
    googlesheet_link: string,
    googlesheet_name?: string | null,
    googlesheet_id?: string | null,
    worksheet_name?: string | null,
    worksheet_id?: string | null,
    unique_field?: string | null,
    is_obtained?: boolean | null,
    interval_type?: INTERVAL_TYPE | null,
    last_success_sync_date?: string | null,
    last_failure_sync_date?: string | null,
    custom_interval?: number | null,
    is_cancelled?: boolean | null,
    custom_type?: CUSTOM_TYPE | null,
    custom_value?: number | null,
    table_name?: string | null,
    refresh_interval_status?: REFRESH_INTERVAL_STATUS | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateRecurringSyncSubscriptionVariables = {
};

export type OnCreateRecurringSyncSubscription = {
  onCreateRecurringSync?:  {
    __typename: "RecurringSync",
    id: string,
    school_id?: string | null,
    success?: boolean | null,
    worksheet_id?: string | null,
    worksheet_name?: string | null,
    interval_type?: string | null,
    sync_for?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnUpdateRecurringSyncSubscriptionVariables = {
};

export type OnUpdateRecurringSyncSubscription = {
  onUpdateRecurringSync?:  {
    __typename: "RecurringSync",
    id: string,
    school_id?: string | null,
    success?: boolean | null,
    worksheet_id?: string | null,
    worksheet_name?: string | null,
    interval_type?: string | null,
    sync_for?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnDeleteRecurringSyncSubscriptionVariables = {
};

export type OnDeleteRecurringSyncSubscription = {
  onDeleteRecurringSync?:  {
    __typename: "RecurringSync",
    id: string,
    school_id?: string | null,
    success?: boolean | null,
    worksheet_id?: string | null,
    worksheet_name?: string | null,
    interval_type?: string | null,
    sync_for?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnCreateCountOfAPIHistorySubscriptionVariables = {
};

export type OnCreateCountOfAPIHistorySubscription = {
  onCreateCountOfAPIHistory?:  {
    __typename: "CountOfAPIHistory",
    id: string,
    school_id?: string | null,
    api_key?: string | null,
    dataset_type?: string | null,
    count?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnUpdateCountOfAPIHistorySubscriptionVariables = {
};

export type OnUpdateCountOfAPIHistorySubscription = {
  onUpdateCountOfAPIHistory?:  {
    __typename: "CountOfAPIHistory",
    id: string,
    school_id?: string | null,
    api_key?: string | null,
    dataset_type?: string | null,
    count?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnDeleteCountOfAPIHistorySubscriptionVariables = {
};

export type OnDeleteCountOfAPIHistorySubscription = {
  onDeleteCountOfAPIHistory?:  {
    __typename: "CountOfAPIHistory",
    id: string,
    school_id?: string | null,
    api_key?: string | null,
    dataset_type?: string | null,
    count?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnCreateVeracrossDetailSubscriptionVariables = {
  id?: string | null,
};

export type OnCreateVeracrossDetailSubscription = {
  onCreateVeracrossDetail?:  {
    __typename: "VeracrossDetail",
    id: string,
    school_id?: string | null,
    veracross_client_id?: string | null,
    veracross_client_secret?: string | null,
    scope?: string | null,
    school_route?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateVeracrossDetailSubscriptionVariables = {
  id?: string | null,
};

export type OnUpdateVeracrossDetailSubscription = {
  onUpdateVeracrossDetail?:  {
    __typename: "VeracrossDetail",
    id: string,
    school_id?: string | null,
    veracross_client_id?: string | null,
    veracross_client_secret?: string | null,
    scope?: string | null,
    school_route?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteVeracrossDetailSubscriptionVariables = {
  id?: string | null,
};

export type OnDeleteVeracrossDetailSubscription = {
  onDeleteVeracrossDetail?:  {
    __typename: "VeracrossDetail",
    id: string,
    school_id?: string | null,
    veracross_client_id?: string | null,
    veracross_client_secret?: string | null,
    scope?: string | null,
    school_route?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateIntegrationSyncHistorySubscriptionVariables = {
};

export type OnCreateIntegrationSyncHistorySubscription = {
  onCreateIntegrationSyncHistory?:  {
    __typename: "IntegrationSyncHistory",
    id: string,
    account: string,
    platform: SUPPORTED_PLATFORM,
    profile_id: string,
    source: SYNC_SOURCE,
    status: SYNC_STATUS,
    createdAt: string,
    detail?: string | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateIntegrationSyncHistorySubscriptionVariables = {
};

export type OnUpdateIntegrationSyncHistorySubscription = {
  onUpdateIntegrationSyncHistory?:  {
    __typename: "IntegrationSyncHistory",
    id: string,
    account: string,
    platform: SUPPORTED_PLATFORM,
    profile_id: string,
    source: SYNC_SOURCE,
    status: SYNC_STATUS,
    createdAt: string,
    detail?: string | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteIntegrationSyncHistorySubscriptionVariables = {
};

export type OnDeleteIntegrationSyncHistorySubscription = {
  onDeleteIntegrationSyncHistory?:  {
    __typename: "IntegrationSyncHistory",
    id: string,
    account: string,
    platform: SUPPORTED_PLATFORM,
    profile_id: string,
    source: SYNC_SOURCE,
    status: SYNC_STATUS,
    createdAt: string,
    detail?: string | null,
    updatedAt: string,
  } | null,
};

export type OnCreateApiKeySubscriptionVariables = {
};

export type OnCreateApiKeySubscription = {
  onCreateApiKey?:  {
    __typename: "ApiKey",
    id: string,
    account: string,
    key: string,
    keyid: string,
    disabled: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateApiKeySubscriptionVariables = {
};

export type OnUpdateApiKeySubscription = {
  onUpdateApiKey?:  {
    __typename: "ApiKey",
    id: string,
    account: string,
    key: string,
    keyid: string,
    disabled: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteApiKeySubscriptionVariables = {
};

export type OnDeleteApiKeySubscription = {
  onDeleteApiKey?:  {
    __typename: "ApiKey",
    id: string,
    account: string,
    key: string,
    keyid: string,
    disabled: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateFirebaseTokensSubscriptionVariables = {
};

export type OnCreateFirebaseTokensSubscription = {
  onCreateFirebaseTokens?:  {
    __typename: "FirebaseTokens",
    id: string,
    token?: string | null,
    user?: string | null,
    userDetails?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateFirebaseTokensSubscriptionVariables = {
};

export type OnUpdateFirebaseTokensSubscription = {
  onUpdateFirebaseTokens?:  {
    __typename: "FirebaseTokens",
    id: string,
    token?: string | null,
    user?: string | null,
    userDetails?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteFirebaseTokensSubscriptionVariables = {
};

export type OnDeleteFirebaseTokensSubscription = {
  onDeleteFirebaseTokens?:  {
    __typename: "FirebaseTokens",
    id: string,
    token?: string | null,
    user?: string | null,
    userDetails?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateUserNotificationSubscriptionVariables = {
};

export type OnCreateUserNotificationSubscription = {
  onCreateUserNotification?:  {
    __typename: "UserNotification",
    id: string,
    user?: string | null,
    userDetails?: string | null,
    userNotificationPreference?: string | null,
    title?: string | null,
    content?: string | null,
    message?: string | null,
    code?: string | null,
    metadata?: string | null,
    isRead?: boolean | null,
    emailSent?: boolean | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateUserNotificationSubscriptionVariables = {
};

export type OnUpdateUserNotificationSubscription = {
  onUpdateUserNotification?:  {
    __typename: "UserNotification",
    id: string,
    user?: string | null,
    userDetails?: string | null,
    userNotificationPreference?: string | null,
    title?: string | null,
    content?: string | null,
    message?: string | null,
    code?: string | null,
    metadata?: string | null,
    isRead?: boolean | null,
    emailSent?: boolean | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteUserNotificationSubscriptionVariables = {
};

export type OnDeleteUserNotificationSubscription = {
  onDeleteUserNotification?:  {
    __typename: "UserNotification",
    id: string,
    user?: string | null,
    userDetails?: string | null,
    userNotificationPreference?: string | null,
    title?: string | null,
    content?: string | null,
    message?: string | null,
    code?: string | null,
    metadata?: string | null,
    isRead?: boolean | null,
    emailSent?: boolean | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type OnCreateInitialSyncDetailsSubscriptionVariables = {
};

export type OnCreateInitialSyncDetailsSubscription = {
  onCreateInitialSyncDetails?:  {
    __typename: "InitialSyncDetails",
    id: string,
    school_id?: string | null,
    data_provider?: DATAPROVIDER | null,
    data_source?: DATASOURCE | null,
    last_sync_date?: string | null,
    initial_sync_status?: STATUS | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateInitialSyncDetailsSubscriptionVariables = {
};

export type OnUpdateInitialSyncDetailsSubscription = {
  onUpdateInitialSyncDetails?:  {
    __typename: "InitialSyncDetails",
    id: string,
    school_id?: string | null,
    data_provider?: DATAPROVIDER | null,
    data_source?: DATASOURCE | null,
    last_sync_date?: string | null,
    initial_sync_status?: STATUS | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteInitialSyncDetailsSubscriptionVariables = {
};

export type OnDeleteInitialSyncDetailsSubscription = {
  onDeleteInitialSyncDetails?:  {
    __typename: "InitialSyncDetails",
    id: string,
    school_id?: string | null,
    data_provider?: DATAPROVIDER | null,
    data_source?: DATASOURCE | null,
    last_sync_date?: string | null,
    initial_sync_status?: STATUS | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateCsvDetailSubscriptionVariables = {
};

export type OnCreateCsvDetailSubscription = {
  onCreateCsvDetail?:  {
    __typename: "CsvDetail",
    id: string,
    school_id?: string | null,
    created_by?: string | null,
    csv_name?: string | null,
    unique_field?: string | null,
    is_obtained?: boolean | null,
    is_cancelled?: boolean | null,
    status?: STATUS | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateCsvDetailSubscriptionVariables = {
};

export type OnUpdateCsvDetailSubscription = {
  onUpdateCsvDetail?:  {
    __typename: "CsvDetail",
    id: string,
    school_id?: string | null,
    created_by?: string | null,
    csv_name?: string | null,
    unique_field?: string | null,
    is_obtained?: boolean | null,
    is_cancelled?: boolean | null,
    status?: STATUS | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteCsvDetailSubscriptionVariables = {
};

export type OnDeleteCsvDetailSubscription = {
  onDeleteCsvDetail?:  {
    __typename: "CsvDetail",
    id: string,
    school_id?: string | null,
    created_by?: string | null,
    csv_name?: string | null,
    unique_field?: string | null,
    is_obtained?: boolean | null,
    is_cancelled?: boolean | null,
    status?: STATUS | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreatePreviewDetailSubscriptionVariables = {
};

export type OnCreatePreviewDetailSubscription = {
  onCreatePreviewDetail?:  {
    __typename: "PreviewDetail",
    id: string,
    school_id?: string | null,
    created_by?: string | null,
    data_set?: string | null,
    show_fields?: string | null,
    hidden_fields?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdatePreviewDetailSubscriptionVariables = {
};

export type OnUpdatePreviewDetailSubscription = {
  onUpdatePreviewDetail?:  {
    __typename: "PreviewDetail",
    id: string,
    school_id?: string | null,
    created_by?: string | null,
    data_set?: string | null,
    show_fields?: string | null,
    hidden_fields?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeletePreviewDetailSubscriptionVariables = {
};

export type OnDeletePreviewDetailSubscription = {
  onDeletePreviewDetail?:  {
    __typename: "PreviewDetail",
    id: string,
    school_id?: string | null,
    created_by?: string | null,
    data_set?: string | null,
    show_fields?: string | null,
    hidden_fields?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateQsdashboardDetailSubscriptionVariables = {
};

export type OnCreateQsdashboardDetailSubscription = {
  onCreateQsdashboardDetail?:  {
    __typename: "QsdashboardDetail",
    id: string,
    school_id?: string | null,
    dashboard_id?: string | null,
    dashboard_name?: string | null,
    dashboard_name_public?: string | null,
    dashboard_description?: string | null,
    dashboard_type?: QS_DASHBOARD_TYPE | null,
    topic_id?: string | null,
    author?: string | null,
    tags?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    isFavoriteOf?:  {
      __typename: "ModelFavoriteConnection",
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateQsdashboardDetailSubscriptionVariables = {
};

export type OnUpdateQsdashboardDetailSubscription = {
  onUpdateQsdashboardDetail?:  {
    __typename: "QsdashboardDetail",
    id: string,
    school_id?: string | null,
    dashboard_id?: string | null,
    dashboard_name?: string | null,
    dashboard_name_public?: string | null,
    dashboard_description?: string | null,
    dashboard_type?: QS_DASHBOARD_TYPE | null,
    topic_id?: string | null,
    author?: string | null,
    tags?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    isFavoriteOf?:  {
      __typename: "ModelFavoriteConnection",
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteQsdashboardDetailSubscriptionVariables = {
};

export type OnDeleteQsdashboardDetailSubscription = {
  onDeleteQsdashboardDetail?:  {
    __typename: "QsdashboardDetail",
    id: string,
    school_id?: string | null,
    dashboard_id?: string | null,
    dashboard_name?: string | null,
    dashboard_name_public?: string | null,
    dashboard_description?: string | null,
    dashboard_type?: QS_DASHBOARD_TYPE | null,
    topic_id?: string | null,
    author?: string | null,
    tags?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    isFavoriteOf?:  {
      __typename: "ModelFavoriteConnection",
      nextToken?: string | null,
    } | null,
  } | null,
};
